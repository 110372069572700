import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import I18n from "redux-i18n";
import HttpsRedirect from "react-https-redirect";
import CommonReducer from "./common/redux/CommonReducer";
import { translations } from "./common/i18n/business-translations/translations";
import { updateServiceWorker } from "./common/redux/actions/ServiceWorkerActions";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Maintenance from "./components/Maintenance/Maintenance";

import "./index.css";
import { NotificationsProvider } from "./providers/NotificationsProvider";

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  CommonReducer,
  composeEnhances(applyMiddleware(thunk))
);

const maintenance = process.env.REACT_APP_BUSINESS_MAINTENANCE
  ? process.env.REACT_APP_BUSINESS_MAINTENANCE
  : false;

if (maintenance === "true") {
  ReactDOM.render(<Maintenance />, document.getElementById("root"));
} else {
  ReactDOM.render(
    <HttpsRedirect disabled={process.env.NODE_ENV === "development"}>
      <Provider store={store}>
        <I18n translations={translations}>
          <NotificationsProvider>
            <Router>
              <App />
            </Router>
          </NotificationsProvider>
        </I18n>
      </Provider>
    </HttpsRedirect>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const registrationWaiting = registration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          store.dispatch(updateServiceWorker(registration));
        }
      });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
