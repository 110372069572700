import { omit } from "lodash";
import { updateObject } from "../Utility";
import {
  MY_JOBS_GET_START,
  MY_JOBS_GET_SUCCESS,
  MY_JOBS_GET_FAIL,
  MY_JOBS_POST_START,
  MY_JOBS_POST_SUCCESS,
  MY_JOBS_POST_FAIL,
  MY_JOBS_PATCH_START,
  MY_JOBS_PATCH_SUCCESS,
  MY_JOBS_PATCH_FAIL,
  MY_JOBS_ARCHIVE_START,
  MY_JOBS_ARCHIVE_SUCCESS,
  MY_JOBS_ARCHIVE_FAIL,
  MY_JOBS_DELETE_SUCCESS,
  MY_JOBS_END_RECRUITMENT_START,
  MY_JOBS_END_RECRUITMENT_SUCCESS,
  MY_JOBS_END_RECRUITMENT_FAIL,
  MY_JOBS_GO_ONLINE_SUCCESS,
  MY_JOBS_GO_OFFLINE_SUCCESS,
  MY_JOBS_SET_FORM_DATA,
  JOB_APPLICATION_CHANGE_STATUS_SUCCESS,
  JOB_APPLICATION_SUBMITTED_NOTIFICATION,
  NOTIFICATION_JOB_APPLICATION_CHANGE_STATUS,
} from "../actions/MyJobsActions";

const initialState = {
  items: {},
  loading: false,
  formData: {}, // when adding new job, keep data in a state
  posting: false,
  patching: false,
  fetched: false,
  error: null,
};

// GET:
const setMyJobFormData = (state, action) => {
  return updateObject(state, {
    ...state,
    formData: {
      ...state.formData,
      [action.id]: {
        ...action.data,
      },
    },
  });
};

// GET:
const getMyJobsStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: null,
  });
};

const getMyJobsSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.myJobs.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
};

const getMyJobsFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
  });
};

// POST:
const postMyJobStart = (state, action) => {
  return updateObject(state, {
    ...state,
    posting: true,
  });
};

const postMyJobSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: {
      ...state.items,
      [action.myJob.id]: action.myJob,
    },
    posting: false,
  });
};

const postMyJobFail = (state, action) => {
  return updateObject(state, {
    posting: false,
  });
};

// PATCH:
const patchMyJobStart = (state, action) => {
  return updateObject(state, {
    patching: true,
  });
};

const patchMyJobSuccess = (state, action) => {
  return updateObject(state, {
    items: {
      ...state.items,
      [action.myJob.id]: {
        ...state.items[action.myJob.id],
        ...action.myJob,
      },
    },
    patching: false,
  });
};

const patchMyJobFail = (state, action) => {
  return updateObject(state, {
    patching: false,
  });
};

// ARCHIVE:
const archiveMyJobStart = (state, action) => {
  return updateObject(state, {
    archiving: true,
  });
};

const archiveMyJobSuccess = (state, action) => {
  return updateObject(state, {
    items: {
      ...state.items,
      [action.myJob.id]: {
        ...state.items[action.myJob.id],
        ...action.myJob,
      },
    },
    archiving: false,
  });
};

const archiveMyJobFail = (state, action) => {
  return updateObject(state, {
    archiving: false,
  });
};

// DELETE:
const deleteMyJobSuccess = (state, action) => {
  return updateObject(state, {
    items: omit(state.items, action.id),
  });
};

// ONLINE:
const goOnlineMyJobSuccess = (state, action) => {
  return updateObject(state, {
    items: {
      ...state.items,
      [action.myJob.id]: {
        ...state.items[action.myJob.id],
        ...action.myJob,
      },
    },
  });
};

// OFFLINE:
const goOfflineMyJobSuccess = (state, action) => {
  return updateObject(state, {
    items: {
      ...state.items,
      [action.myJob.id]: {
        ...state.items[action.myJob.id],
        ...action.myJob,
      },
    },
  });
};

// END RECRUITMENT:
const endRecruitmentMyJobStart = (state, action) => {
  return updateObject(state, {
    stopping: true,
  });
};

const endRecruitmentMyJobSuccess = (state, action) => {
  return updateObject(state, {
    items: {
      ...state.items,
      [action.myJob.id]: {
        ...state.items[action.myJob.id],
        ...action.myJob,
      },
    },
    stopping: false,
  });
};

const endRecruitmentMyJobFail = (state, action) => {
  return updateObject(state, {
    stopping: false,
  });
};

// JOB APPLICTION VIEW:
const jobApplicationStatusUpdateSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: {
      ...state.items,
      [action.application.job]: {
        ...state.items[action.application.job],
        applications:
          state.items[action.application.job]?.applications?.length > 0 &&
          state.items[action.application.job]?.applications.map((app) => {
            if (app.id === action.application.id) {
              return { ...app, ...action.application, resume: app.resume };
            } else {
              return app;
            }
          }),
      },
    },
  });
};

const jobApplicationSubmittedNotification = (state, action) => {
  return updateObject(state, {
    ...state,
    items: {
      ...state.items,
      [action.application.job]: {
        ...state.items[action.application.job],
        applications: [
          ...state.items[action.application.job].applications,
          action.application,
        ],
      },
    },
  });
};

const jobApplicationStatusChangeNotification = (state, action) => {
  return updateObject(state, {
    ...state,
    items: {
      ...state.items,
      [action.application.job]: {
        ...state.items[action.application.job],
        applications: state.items[action.application.job].applications.map(
          (app) => {
            if (app.id === action.application.id) {
              return { ...app, ...action.application, resume: app.resume };
            } else {
              return app;
            }
          }
        ),
      },
    },
  });
};

const MyJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    // ADD NEW JOB FORM DATA
    case MY_JOBS_SET_FORM_DATA:
      return setMyJobFormData(state, action);

    // GET:
    case MY_JOBS_GET_START:
      return getMyJobsStart(state, action);
    case MY_JOBS_GET_SUCCESS:
      return getMyJobsSuccess(state, action);
    case MY_JOBS_GET_FAIL:
      return getMyJobsFail(state, action);

    // POST:
    case MY_JOBS_POST_START:
      return postMyJobStart(state, action);
    case MY_JOBS_POST_SUCCESS:
      return postMyJobSuccess(state, action);
    case MY_JOBS_POST_FAIL:
      return postMyJobFail(state, action);

    // PATCH:
    case MY_JOBS_PATCH_START:
      return patchMyJobStart(state, action);
    case MY_JOBS_PATCH_SUCCESS:
      return patchMyJobSuccess(state, action);
    case MY_JOBS_PATCH_FAIL:
      return patchMyJobFail(state, action);

    // ARCHIVE:
    case MY_JOBS_ARCHIVE_START:
      return archiveMyJobStart(state, action);
    case MY_JOBS_ARCHIVE_SUCCESS:
      return archiveMyJobSuccess(state, action);
    case MY_JOBS_ARCHIVE_FAIL:
      return archiveMyJobFail(state, action);

    // END RECRUITMENT:
    case MY_JOBS_END_RECRUITMENT_START:
      return endRecruitmentMyJobStart(state, action);
    case MY_JOBS_END_RECRUITMENT_SUCCESS:
      return endRecruitmentMyJobSuccess(state, action);
    case MY_JOBS_END_RECRUITMENT_FAIL:
      return endRecruitmentMyJobFail(state, action);

    // DELETE:
    case MY_JOBS_DELETE_SUCCESS:
      return deleteMyJobSuccess(state, action);

    // ONLINE
    case MY_JOBS_GO_ONLINE_SUCCESS:
      return goOnlineMyJobSuccess(state, action);

    // OFFLINE
    case MY_JOBS_GO_OFFLINE_SUCCESS:
      return goOfflineMyJobSuccess(state, action);

    // JOB APPLICATION
    case JOB_APPLICATION_CHANGE_STATUS_SUCCESS:
      return jobApplicationStatusUpdateSuccess(state, action);

    // NOTIFICATIONS
    case JOB_APPLICATION_SUBMITTED_NOTIFICATION:
      return jobApplicationSubmittedNotification(state, action);
    case NOTIFICATION_JOB_APPLICATION_CHANGE_STATUS:
      return jobApplicationStatusChangeNotification(state, action);

    default:
      return state;
  }
};

export default MyJobsReducer;
