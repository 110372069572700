export const translations = {
  kh: {
    "Update is available": "ការធ្វើបច្ចុប្បន្នភាពកម្មវិធីអាចធ្វើបាននៅពេលនេះ",
    "Please press OK to refresh the app.":
      "សូមចុច អូខេ ដើម្បីបើកកម្មវិធីសារឡើងវិញ",
    "Loading..": "កំពុងដំណើរការ..",
    Male: "ប្រុស",
    Female: "ស្រី",
    "Prefer not to say": "មិនចង់និយាយ",
    "Non-Binary": "មិនបង្ហាញ",
    "Passport Include": "រួមបញ្ចូលទាំងលិខិតឆ្លងដែន",
    "Passport Not Include": "មិនរួមបញ្ចូលលិខិតឆ្លងដែន",
    Myanmar: "មីយ៉ាន់ម៉ា",
    Thailand: "ថៃ",
    Cambodia: "កម្ពុជា",
    Laos: "ឡាវ",
    Nepal: "នេប៉ាល់",
    Indonesia: "ឥណ្ឌូនេស៊ី",
    Indian: "ឥណ្ឌា",
    Bangladeshi: "បង់ក្លាដែស",
    Others: "ផ្សេងៗទៀត",
    Sunday: "ថ្ងៃអាទិត្យ",
    Monday: "ថ្ងៃច័ន្ទ",
    Tuesday: "ថ្ងៃអង្គារ",
    Wednesday: "ថ្ងៃពុធ",
    Thursday: "ថ្ងៃព្រហស្បតិ៍",
    Friday: "ថ្ងៃសុក្រ",
    Saturday: "ថ្ងៃសៅរ៍",
    "It Will Vary": "វានឹងប្រែប្រួល",
    Monthly: "ប្រចាំខែ",
    "Daily Wage Rate": "អត្រាប្រាក់ឈ្នួលប្រចាំថ្ងៃ",
    "Once A Month": "មួយខែម្ដង",
    "Twice A Month": "មួយខែពីរដង",
    "Every Week": "រៀងរាល់សប្ដាហ៍",
    "Every 10 days": "រៀងរាល់ ១០ ថ្ងៃ",
    Other: "ផ្សេងៗ",
    Regularly: "ទៀងទាត់",
    "From time to time": "ពី​មួយពេល​ទៅ​​មួយពេល",
    Infrequently: "ញឹកញាប់",
    Never: "មិនដែល",
    Day: "វេនថ្ងៃ",
    Swing: "វេនប្រែប្រួល",
    Night: "វេនយប់",
    Rotating: "វេនប្ដូរវិលជុំ",
    Variable: "វេនមិនទៀងទាត់",
    "Full-time": "ពេញម៉ោង",
    "Part-time": "ក្រៅម៉ោង",
    "Moved General Waitlist": "បញ្ជូនទៅបញ្ជីឈ្មោះរង់ចាំទូទៅ",
    Waitlisted: "បញ្ជីឈ្មោះរង់ចាំ",
    "Contract Signed": "បានចុះកិច្ចសន្យា",
    Accepted: "ត្រូវបានទទួលយក",
    Applied: "បានដាក់ពាក្យស្នើរសុំ",
    Downloaded: "ត្រូវបានទាញយក",
    Offered: "ត្រូវបានផ្ដល់ជូន",
    Rejected: "ត្រូវបានបដិសេធ",
    Shortlisted: "ត្រូវបានសម្រាំងរើស",
    Viewed: "ត្រូវបានពិនិត្យមើល",
    "Documents Requested": "ឯកសារត្រូវបានស្នើរសុំ",
    "Documents request accepted": "ការស្នើរសុំឯកសារត្រួវបានទទួលយក",
    "Documents request rejected": "ការស្នើរសុំឯកសារត្រូវបានបដិសេធ",
    "HR Assistant": "ជំនួយការផ្នែកធនធានមនុស្ស",
    "HR Interpreter": "អ្នកបកប្រែផ្នែកធនធានមនុស្ស",
    "HR Manager": "អ្នក​គ្រប់គ្រង​ផ្នែកធនធានមនុស្ស",
    "HR Officer": "មន្រ្តីធនធានមនុស្ស",
    "Candidate pays fees in advance, then is reimbursed by company after starting work":
      "បេក្ខជនបង់ថ្លៃជាមុន បន្ទាប់មកនឹងត្រូវសងត្រលប់ទៅវិញដោយក្រុមហ៊ុននៅពេលចាប់ផ្តើមការងារ",
    "Company pays all fees in advance": "ក្រុមហ៊ុនបង់ថ្លៃសេវាទាំងអស់ជាមុន",
    "India (+91)": "ឥណ្ឌា(+91 )",
    "Myanmar (+95)": "មីយ៉ាន់ម៉ា​ (+95)",
    "Nepal (+977)": "នេប៉ាល់ (+977)",
    "Thailand (+66)": "ថៃ​ (+66)",
    "Malaysia (+60)": "ម៉ាឡេស៊ី​ (+60)",
    "Cambodia (+855)": "កម្ពុជា (+855)",
    "A Little Bit": "បន្តិចចន្តួច",
    Moderately: "មធ្យម",
    "Very Well": "ល្អប្រសើរ",
    None: "គ្មាន",
    Some: "ខ្លះៗ",
    Proficient: "មានជំនាញ",
    English: "អង់គ្លេស",
    Thai: "ថៃ",
    "Bahasa Malaysia": "បាហាសា ម៉ាឡេស៊ី",
    Hindi: "ហិណ្ឌូ",
    "Cannot read": "មិនអាចអាន",
    APPLICABLE: "អាចដាក់ពាក្យបាន",
    APPLIED: "បានដាក់ពាក្យ",
    VIEWED: "បានពិនិត្យមើល",
    DOWNLOADED: "បានទាញយក",
    SHORTLISTED: "បានសម្រាំងយក",
    OFFERED: "ការងារត្រូវបានផ្តល់ជូន",
    ACCEPTED: "យល់ព្រមទទួលការងារដែលផ្តល់",
    REJECTED_BY_BUSINESS: "ត្រូវបានបដិសេដដោយអ្នកជ្រើសរើស",
    REJECTED_BY_APPLICANT: "ត្រូវបានបដិសេធដោយបេក្ខជន",
    CONTRACT_SIGNED: "បានចុះកិច្ចសន្យា",
    APPLICANT_NA: "មិនមានបេក្ខជន",
    WAITLISTED: "ត្រូវបានដាក់បញ្ចូលក្នុងបញ្ជីរង់ចាំ",
    MOVED_GENERAL_WAITLIST: "ត្រូវបានបញ្ចូនទៅបញ្ជីរង់ចាំទូទៅ",
    "STATUS N/A": "ស្ថានភាពមិនមាន",
    "Cannot speak": "មិនអាចអ្នកនិយាយ",
    "Network Error!": "ប្រព័ន្ធមានបញ្ហា",
    "User with this username already exists":
      "ឈ្មោះអ្នកប្រើប្រាស់នេះមានរួចទៅហើយ",
    "You are trying to login with Business User Account !":
      "អ្នកកំពុងតែព្យាយាមចូលដោយប្រើគណនីប្រើប្រាស់សម្រាប់អាជីវកម្ម!",
    "There was an authentication problem": "មានបញ្ហាការផ្ទៀងផ្ទាត់",
    "Basic Information": "ព័ត៌មានទូទៅ",
    Gender: "ភេទ",
    "Date of Birth": "ថ្ងៃ ខែ ឆ្នាំ កំណើត",
    "Marital Status": "ស្ថានភាពគ្រួសារ (រៀបការ ឬ នៅលីវ)",
    Language: "ភាសា",
    Education: "កម្រិតការសិក្សាអប់រំ",
    "Phone number": "លេខទូរស័ព្ទ",
    Email: "អ៊ីម៉ែល",
    Address: "អាសយដ្ឋាន",
    "National ID": "លេខអត្តសញ្ញាណបណ្ណ",
    "Passport Number": "លេខលិខិតឆ្លងដែន",
    "Expires at": "ផុតកំណត់នៅ",
    "Measurements & Health": "លក្ខណៈសម្បត្តិ និង សុខភាព",
    Weight: "ទំងន់",
    kg: "គីឡូក្រាម",
    lb: "ផោន",
    Height: "កំពស់",
    cm: "សង់ទីម៉ែត្រ",
    ft: "ហ្វីត",
    in: "អ៊ីង",
    "Have undergone medical operation recently":
      "ធ្លាប់បានធ្វើការវះកាត់ផ្នែកវេជ្ជសាស្ត្រថ្មីៗនេះ",
    yes: "បាទ/ចាស",
    no: "ទេ",
    "Work Experience": "បទពិសោធន៍ការងារ",
    "{duration} years": "{duration} ឆ្នាំ",
    "Advanced Search": "ស្វែងរកលំអិត",
    "Workers who have more than 12 months of experience in this industry":
      "ពលករដែលមានបទពិសោធន៍ច្រើនជាង ១២ ខែនៅក្នុងឧស្សាហកម្មនេះ",
    Industry: "ឧស្សាហកម្ម",
    "Workers {language} proficiency": "ពលករស្ទាត់ជំនាញលើភាសា  {language}",
    "Language Proficiency": "ភាសាដែលស្ទាត់ជំនាញ",
    "Workers current location": "ទីតាំងបច្ចុប្បន្នរបស់ពលករ",
    Province: "ខេត្ដ",
    "Application has been rejected": "ពាក្យស្នើសុំត្រូវបានបដិសេធ",
    "An error occurred while rejecting application.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើង ខណៈពេលបដិសេធពាក្យស្នើសុំការងារ",
    "A request for documents has been sent.": "ការស្នើសុំឯកសារត្រូវបានបញ្ជូនទៅ",
    "An error occurred while sending request for documents.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលស្នើរសុំឯកសារ",
    "Job offer has been successfully sent.":
      "ការផ្តល់ជូនការងារត្រូវបានផ្ញើដោយជោគជ័យ",
    "An error occurred while sending job offer.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលកំពុងផ្ញើការផ្តល់ជូនការងារ",
    "Application has been shortlisted": "ពាក្យស្នើសុំត្រូវបានសម្រាំងយក",
    "An error occurred while shortlisting application.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលកំពុងសម្រាំងរើសពាក្យស្នើសុំ",
    "Working Experience": "បទពិសោធន៍ការងារ",
    "{experience} years": "{experience} ឆ្នាំ",
    View: "ពិនិត្យមើល",
    "Are you sure you would like to shortlist the application? This action cannot be undone later.":
      "តើអ្នកប្រាកដទេថាអ្នកចង់សម្រាំងយកពាក្យស្នើសុំនេះ? អ្នកនឹងមិនអាចត្រលប់ក្រោយបានទេ បន្ទាប់ពីសម្រាំងយករួច",
    Continue: "បន្ដ",
    Cancel: "បោះបង់",
    Shortlist: "សម្រាំងយក",
    "Are you sure you would like to send the job offer? This action cannot be undone later.":
      "តើអ្នកប្រាកដទេថាអ្នកចង់ផ្ញើចេញនូវសំណើរការងារ? សកម្មភាពនេះមិនអាចសារឡើងវិញបានទេនៅពេលក្រោយ",
    "Send offer": "ផ្ញើការផ្តល់ជូន",
    "Are you sure you would like to request the documents? This action cannot be undone later":
      "តើអ្នកប្រាកដទេថាអ្នកចង់ស្នើរសុំឯកសារ? សកម្មភាពនេះមិនអាចសាឡើងវិញបានទេនៅពេលក្រោយ",
    "Request documents": "ស្នើសុំឯកសារ",
    "Please select one of the reasons below.":
      "សូមជ្រើសរើសហេតុផលមួយក្នុងចំណោមហេតុផលខាងក្រោម",
    "Other rejection reason": "មូលហេតុបដិសេធនផ្សេងទៀត",
    Reject: "បដិសេធ",
    new: "ថ្មី",
    "{number} documents attached": "{number} ឯកសារដែលត្រូវបានភ្ជាប់ជាមួយ",
    "Documents requested {timeAgo}": "ឯកសារត្រូវបានស្នើរសុំ​ {តាំងពីដើមមក}",
    Yes: "បាទ/ចាស",
    No: "ទេ",
    From: "ពី",
    To: "ទៅ",
    Passport: "លិខិតឆ្លងដែន",
    Included: "រួមបញ្ចូល",
    "Application has been waitlisted successfully and sent to application #{id}.":
      "ពាក្យស្នើសុំត្រូវបានដាក់បញ្ចូលក្នុងបញ្ជីឈ្មោះរង់ចាំបានដោយជោគជ័យ និង បញ្ជូនទៅពាក្យពាក្យស្នើសុំ #{id} ",
    "An error occurred while waitlisting the application #{id}.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលដាក់ពាក្យស្នើសុំទៅបញ្ជីឈ្មោះរង់ចាំ #{id} ។",
    "Job offer Expiry Date has been successfully updated.":
      "កាលបរិច្ឆេទកំណត់ការផ្ដល់ការងារត្រូវបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ។",
    "Roster Application": "បញ្ជីឈ្មោះពាក្យស្នើសុំការងារ",
    "Offered will Expire at: ": "ការផ្ដល់ជូននឹងត្រូវផុតកំណត់នៅ៖",
    "Update Expiry Date": "ធ្វើបច្ចប្បន្នភាពកាលបរិច្ឆេទផុតកំណត់",
    "Are you sure you would like to move the selected applicants to waitlist? This action cannot be undone later.":
      "តើអ្នកពិតជាចង់ដាក់ពាក្យស្នើសុំដែលបានជ្រើសរើសទៅក្នុងបញ្ជីឈ្មោះរង់ចាំមែនទេ? សកម្មភាពនេះមិនអាចសារឡើងវិញបានទេ",
    "Move to waitlist": "ប្ដូរទៅបញ្ជីឈ្មោះរង់ចាំ",
    "Quick Filter": "សម្រាំងមើលរហ័ស",
    "Select Available Shift": "ជ្រើសរើសវេនការងារដែលមាន",
    "{number} matches": "{number}  ត្រួតស៊ីគ្នា",
    "Select city": "ជ្រើសរើសស្រុក",
    "Select country": "ជ្រើសរើសប្រទេស",
    "Select currency": "ជ្រើសរើសរូបិយប័ណ្ណ",
    "Select Day of Week Off": "ជ្រើសរើសថ្ងៃឈប់សម្រាកប្រចាំសប្តាហ៍ៈ",
    "Select Level": "ជ្រើសរើសកំរិត",
    "Select policy type": "ជ្រើសរើសប្រភេទគោលនយោបាយ",
    "Select employer": "ជ្រើសរើសនិយោជក",
    "Other/not listed here": "ផ្សេងទៀត/មិនត្រូវបានដាក់បញ្ចូលនៅទីនេះទេ",
    "Select size": "ជ្រើសរើសទំហំ",
    "Select Industry": "ជ្រើសរើសឧស្សាហកម្ម",
    "Select position category": "ជ្រើសរើសប្រភេទមុខតំណែង",
    "Select job type": "ជ្រើសរើសប្រភេទការងារ",
    "Select level": "ជ្រើសរើសកម្រិត",
    "Select Language": "ជ្រើសរើសភាសា",
    "Select Status": "ជ្រើសរើសស្ថានភាព",
    "Select Overtime Expectations": "ជ្រើសរើសការងារថែមដែលរំពឹងទុក",
    "Select terms": "ជ្រើសរើសប្រភេទ",
    "Select timing": "ជ្រើសរើសម៉ោងពេល",
    "Select Reason": "ជ្រើសរើសមូលហេតុ",
    "Select state": "ជ្រើសរើសរដ្ឋ/ខេត្ត",
    "Select industry": "ជ្រើសរើសឧស្សាហកម្ម",
    Verification: "ការផ្ទៀងផ្ទាត់",
    Account: "គណនី",
    "My jobs": "ការដាក់បង្ហោះការងារ",
    "Post new": "ដាក់បង្ហោះការងារថ្មី",
    Logout: "ចាកចេញ",
    Metrics: "តារាងទិន្នន័យ",
    Surveys: "ការស្ទង់មតិ",
    "Verify RA": "ផ្ទៀងផ្ទាត់ដោយភ្នាក់ងារជ្រើសរើស",
    "Verify Employers": "ផ្ទៀងផ្ទាត់ដោយនិយោជក",
    "My account": "គណនី",
    Profile: "ព័ត៌មានអំពីក្រុមហ៊ុន",
    Resume: "ប្រវត្ដិរូប",
    Roster: "បញ្ជីឈ្មោះ",
    Waitlist: "បញ្ជីឈ្មោះរង់ចាំ",
    "This business is already managed by another user. Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "អាជីវកម្មនេះត្រូវបានគ្រប់គ្រងដោយអ្នកប្រើប្រាស់ផ្សេងទៀតរួចទៅហើយ៕ តើអ្នកប្រាកដទេថាអ្នកចង់បញ្ចូលគ្នាជាមួយភ្នាក់ងារជ្រើសរើសនេះ? សកម្មភាពនេះមិនអាចធ្វើសាឡើងវិញបានទេនៅពេលក្រោយ!",
    "This business is managed by Issara. Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "អាជីវកម្មនេះត្រូវបានគ្រប់គ្រងដោយស្ថាប័ន ឥស្សរា តើអ្នកប្រាកដទេថាអ្នកចង់រួមបញ្ចូលគ្នាជាមួយភ្នាក់ងារជ្រើសរើស? សកម្មភាពនេះមិនអាចធ្វើសាឡើងវិញនៅពេលក្រោយបានទេ!",
    "Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "តើអ្នកប្រាកដទេថាអ្នកចង់រួមបញ្ចូលភ្នាក់ងារជ្រើសរើសនេះ? សកម្មភាពនេះមិនអាចសាឡើងវិញបានទេនៅពេលក្រោយបានទេ!",
    "Yes. Merge": "បាទ/ចាស រួមបញ្ចូល",
    "This business is already in our database. Merge it.":
      "អាជីវកម្មមានក្នុងទិន្នន័យរួចទៅហើយ។  រួមបញ្ចូល",
    Merge: "រួមបញ្ចូល",
    Name: "ឈ្មោះ",
    "Managed By": "​គ្រប់គ្រង​ដោយ",
    "Businesses have been successfully merged":
      "ក្រុមហ៊ុនត្រូវបានរួមបញ្ចូលគ្នាដោយជោគជ័យ",
    "An error occurred while merging. Please contact a developer.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលកំពុងធ្វើការរួមបញ្ចូលគ្នា។  សូមទាក់ទងទៅក្រុមការងារបច្ចេកទេស",
    "Search ...": "ស្វែងរក...",
    "No employers found": "ស្វែងរកមិនឃើញនិយោជក",
    "recruiter loaded": "ភ្នាក់ងារជ្រើសរើសត្រូវបានទាញយក",
    "employers loaded": "និយោជកត្រូវបានទាញយក",
    "Load More employers": "ទាញយកនិយោជកបន្ថែម",
    "License Number": "លេខអាជ្ញាបណ្ណ",
    "No recruiters found": "មិនមានភ្នាក់ងារជ្រើសរើសត្រូវបានរកឃើញ",
    "recruiters loaded": "ភ្នាក់ងារជ្រើសរើសត្រូវបានទាញយក",
    "Load More Recruiters": "ទាញយកភ្នាក់ងារជ្រើសរើសបន្ថែម",
    "Delete Cover Photo": "លុបរូបភាពនេះ",
    "Add Cover Photo": "ដាក់បញ្ចូលរូបភាពគម្រប",
    "Uploading....": "កំពុងដំណើរការ...",
    "Upload Cropped Cover Photo": "ដាក់បញ្ចូលរូបថតគម្របដែលបានកាត់តម្រឹម",
    "Delete This Photo": "លុបរូបភាពនេះ",
    "Available image upload": "រូបភាពដែលបានដាក់បញ្ចូល",
    "Add business image": "ដាក់បញ្ចូលរូបថតក្រុមហ៊ុន",
    "Upload Cropped Image": "ដាក់បញ្ចូលរូបភាពដែលបានកាត់តម្រឹម",
    "Password reset link was successfully sent to {email}. Please check your inbox.":
      "តំណភ្ជាប់ដើម្បីផ្លាស់ប្ដូរពាក្យសម្ងាត់ថ្មីត្រូវបានផ្ញើរដោយជោគជ័យទៅកាន់ {email} ។ សូមពិនិត្យមើលប្រអប់សាររបស់អ្នក",
    "Profile has been successfully updated.":
      "ពត៌មានអំពីអ្នកត្រូវបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ",
    "An error occurred while updating profile. Please contact a developer.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលកំពុងធ្វើបច្ចុប្បន្នភាពព័ត៌មានអំពីអ្នក។ សូមទាក់ទងទៅអ្នកបង្កើតកម្មវិធី",
    "Please use one of the following formats: .jpeg, .jpg or .png":
      "សូមប្រើប្រាស់ទម្រង់មួយក្នុងចំណោមទម្រង់ខាងក្រោម៖  .jpeg, .jpg or .png",
    "Push notifications have been successfully enabled":
      "ការជូនដំណឹងត្រូវបានបើកដំណើរការដោយជោគជ័យ",
    "Sample Notification": "គំរូនៃការជូនដំណឹង",
    "You will receive me whenever new job application submitted or content added.":
      "អ្នកនឹងទទួលការជូនដំណឹង នៅពេលណាដែលពាក្យស្នើសុំការងារថ្មីត្រូវបានបញ្ជូន ឬ មាតិកាថ្មីត្រូវបានដាក់បន្ថែម",
    "If you blocked push notifications, you can only undo it in your browser settings.":
      "ប្រសិនបើអ្នកបិទការជូនដំណឹង អ្នកអាចដោះវាឡើងវិញបានដោយគ្រាន់តែចូលទៅក្នុងការកំណត់នៃកម្មវិធីរុករករបស់អ្នក",
    "Push notifications have been successfully disabled":
      "ការជូនដំណឹងត្រូវបានបិទដោយជោគជ័យ",
    "Verified Business": "អាជីវកម្មបានផ្ទៀងផ្ទាត់រួច",
    Type: "ប្រភេទ",
    Employer: "និយោជក",
    Joined: "ចូលរួម",
    Updated: "បានធ្វើបច្ចុប្បន្នភាព",
    "Push notifications are not supported.": "ការជូនដំណឹងមិនដំណើរការទេ",
    "Push notifications": "ការជូនដំណឹង",
    "Are you sure you want to reset the password?":
      "តើអ្នកប្រាកដទេថា អ្នកចង់កំណត់ពាក្យសម្ងាត់សារឡើងវិញ?",
    "The reset link will be sent to this email address: {email}":
      "តំណភ្ជាប់សម្រាប់កំណត់ពាក្យសម្ងាត់សារឡើងវិញនឹងត្រូវបានផ្ញើទៅអាសយដ្ឋានអ៊ីមែលនេះ៖  {email}",
    Reset: "កំណត់សាឡើងវិញ",
    "Reset password": "កំណត់លេខសម្ងាត់សាឡើងវិញ",
    "Enable view mode": "បើកមុខងារពិនិត្យមើល",
    "Enable edit mode": "បើកមុខងារកែតម្រូវ",
    "Basic information": "ព័ត៌មានបឋម",
    Logo: "រូបសញ្ញា (ឡូហ្គូ)",
    "Update logo": "ប្ដូររូបសញ្ញាថ្មី",
    "Company Name ({lang})": "ឈ្មោះក្រុមហ៊ុន({lang})",
    "Please fill in the company name": "សូមបំពេញឈ្មោះក្រុមហ៊ុន",
    "Company Name": "ឈ្មោះក្រុមហ៊ុន",
    "Working since": "ប្រតិបត្តិការការងារតាំងពី",
    "Registration number": "អត្តលេខចុះឈ្មោះ",
    "Max length of the registration number is 12":
      "ចំនួនលេខច្រើនបំផុតនៃអត្តលេខចុះឈ្មោះគឺ ១២តួ",
    "Example: 027/2019": "ឧទាហរណ៍៖ 027/2019",
    Size: "ទំហំ",
    "About us ({lang})": "អំពីយើង ({lang})",
    "Contact information": "ទំនាក់ទំនងសាកសួរព័ត៌មាន",
    "Main contact name": "ឈ្មោះអ្នកទំនាក់ទំនងសំខាន់",
    "Main contact email": "អ៊ីម៉ែលទំនាក់ទំនង",
    "This is not a valid email.": "អ៊ីម៉ែលនេះមិនត្រឹមត្រូវទេ",
    "Website (URL)": "គេហទំព័រ (URL)",
    "Website(URL) format is incorrect": "ទម្រង់គេហទំព័រ (URL) មិនត្រឹមត្រូវ",
    "Facebook (URL)": "ហ្វេសប៊ុក (URL)",
    "Facebook url format is incorrect": "ទម្រង់(URL) ហ្វេសប៊ុកមិនត្រឹមត្រូវ",
    "Viber (phone number)": "វាយប័រ (លេខទូរស័ព្ទ)",
    "Please use the following format: +999999999":
      "សូមប្រើប្រាស់ទម្រង់ដូចនេះ៖ +999999999",
    "Line (ID)": "ឡាញ (ID)",
    "Line format is incorrect": "ទម្រង់ ឡាញ មិនត្រឹមត្រូវ",
    "Phone numbers": "លេខទូរស័ព្ទ",
    "Add new": "បន្ថែមថ្មី",
    "Country, State, City": "ប្រទេស ខេត្ត ស្រុក",
    "Apt, Building, Street ({lang})": "អឺផាតមិន, អាគារ, ផ្លូវ({lang})",
    "Please fill in the address details": "សូមបំពេញអាសយដ្ឋានលំអិត",
    Zip: "លេខកូដតំបន់",
    "Update Business Profile": "ធ្វើបច្ចុប្បន្នភាពព័ត៌មានអំពីក្រុមហ៊ុន",
    Description: "ការពិពណ៌នា",
    "Contact email": "អ៊ីម៉ែលទំនាក់ទំនង",
    Website: "គេហទំព័រ",
    Facebook: "ហ្វេសប៊ុក",
    Viber: "វ៉ាយប័រ",
    Line: "ឡាញ",
    Country: "ប្រទេស",
    State: "ខេត្ត",
    City: "ស្រុក",
    "Apt, Building, Street": "អឺផាតមិន, អាគារ, ផ្លូវ",
    "Application Details:": "ពាក្យស្នើសុំលំអិត",
    Back: "ត្រលប់ក្រោយ",
    "Full Name": "ឈ្មោះពេញ",
    "Phone Number": "លេខទូរស័ព្ទ",
    "Current Address": "អាសយដ្ឋានបច្ចុប្បន្ន",
    "Passport Expiry Date": "កាលបរិច្ឆេទផុតកំណត់លិខិតឆ្លងដែន ",
    "After going through the job description, do you think you can do the job well?":
      "បន្ទាប់ពីបានពិនិ្យមើលបរិយាយការងារ តើអ្នកគិតថាអ្នកអាចធ្វើការងារនេះបាន?",
    "Do you know someone working here?":
      "តើអ្នកស្គាល់នរណាម្នាក់ធ្វើការនៅទីនេះទេ?",
    "Languages Proficiency": "ភាសាដែលស្ទាត់ជំនាញ",
    "Work experience": "បទពិសោធន៍ការងារ",
    Duration: "រយៈពេល",
    Remark: "កំណត់សម្គាល់",
    "Uploaded Documents": "ឯកសារត្រូវបានដាក់បញ្ចូល",
    Pinkcard: "ប័ណ្ណស៊ីជម្ពូ",
    Workpermit: "ប័ណ្ណអនុញ្ញាតការងារ",
    Visa: "ទិដ្ឋាការ (វីសា)",
    "Cancellation Letter": "លិខិតលុបឈ្មោះ",
    "Download/Print": "ទាញយក/បោះពុម្ភ",
    "Are you sure you would like to request the documents? This action cannot be undone later.":
      "តើអ្នកប្រាកដទេថាអ្នកចង់ស្នើរសុំឯកសារ? សកម្មភាពនេះមិនអាចសាឡើងវិញបានទេនៅពេលក្រោយ",
    "Application #{id} has been successfully rejected":
      "ពាក្យស្នើសុំ #{id} ត្រូវបានបដិសេធ",
    "A request for documents for application #{id} has been sent":
      "ការស្នើសុំឯកសារសម្រាប់ដាក់ពាក្យត្រូវបានផ្ញើរទៅ",
    "Application #{id} has been successfully shortlisted":
      "ពាក្យស្នើសុំ #{id} ត្រូវបានសម្រាំងយកដោយជោគជ័យ",
    "An error occurred while shortlisting application #{id}.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលកំពុងសម្រាំងរើសពាក្យស្នើសុំ #{id} ។",
    "Job offer has been successfully sent to application #{id}.":
      "ការផ្តល់ជូនការងារត្រូវបានផ្ញើរទៅកាន់ពាក្យស្នើសុំ #{id} ដោយជោគជ័យ",
    "An error occurred while sending job offer to application #{id}.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេល កំពុងផ្ញើរការផ្តល់ជូនការងារទៅកាន់ពាក្យស្នើសុំ #{id} ។",
    "View Applications": "ពិនិត្យមើលពាក្យស្នើសុំ",
    "Pending ({count})": "នៅក្នុងដំណាក់កាលរង់ចាំ ({count})",
    "Shortlisted ({count})": "ត្រូវបានសម្រាំងរើស({count})",
    "Offered ({count})": "បានផ្ដល់ជូន ({count})",
    "Rejected by applicant ({count})": "ត្រូវបានបដិសេធដោយបេក្ខជន ({count})",
    "Rejected by business ({count})": "ត្រូវបានបដិសេធដោយក្រុមហ៊ុន ({count})",
    "Accepted ({count})": "បានទទួលយក  ({count})",
    "Select all ({number})": "ជ្រើសរើសទាំងអស់​ ({number}) ",
    "Deselect all ({number})": "លុបទាំងអស់ ({number})",
    "Reject ({number})": "បដិសេធ  ({number})",
    "Are you sure you would like to shortlist selected applications? This action cannot be undone later":
      "តើអ្នកប្រាកដទេថា អ្នកចង់សម្រាំងយកពាក្យស្នើរសុំទាំងនេះ? សកម្មភាពនេះមិនអាចសារឡើងវិញបានទេ",
    "Shortlist ({number})": "បានសម្រាំងយក ({count})",
    "Request documents ({number})": "ស្នើរសុំឯកសារ  ({number})",
    "Are you sure you would like to send the job offer to selected applicants? This action cannot be undone later.":
      "តើអ្នកប្រាកដទេថាអ្នកចង់ផ្ញើរការផ្តល់ជូនការងារទៅកាន់បេក្ខជនដែលបានជ្រើសរើសយក? សកម្មភាពនេះមិនអាចសាឡើងវិញបានទេនៅពេលក្រោយ",
    "Send offer ({number})": "ផ្ញើរការផ្តល់ជូន  ({number})",
    "Your job has been successfully posted":
      "ការងាររបស់អ្នកត្រូវបានដាក់ប្រកាសដោយជោគជ័យ",
    "View posted jobs": "មើលការងារដែលបានដាក់ប្រកាស",
    "Post new job": "ដាក់ប្រកាសការងារថ្មី",
    "An error occurred while saving draft.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលរក្សាទុកសេចក្តីព្រាង",
    "Job has been successfully saved as a draft":
      "សេចក្ដីពង្រាងនៃការងារត្រូវបានរក្សាទុកដោយជោគជ័យ",
    "Create new job": "បង្កើតការងារថ្មី",
    "View drafts": "ពិនិត្យមើលសេចក្ដីព្រាង",
    "Post job": "ដាក់ប្រកាសការងារ",
    "Open to": "បើកចំពោះ",
    Nationality: "សញ្ជាតិ",
    "Choose at least one nationality": "ជ្រើសរើសយ៉ាងហោចណាស់សញ្ជាតិមួយ",
    Documents: "ឯកសារ",
    "Job Information": "ព័ត៌មានអំពីការងារ",
    "Job Title": "មុខតំណែងនៃការងារ",
    "Please enter job title": "សូមបញ្ចូលមុខតំណែងការងារ",
    "Job title": "មុខតំណែងនៃការងារ",
    "Please select industry": "ជ្រើសរើសឧស្សាហកម្ម",
    "Job position category": "ប្រភេទមុខតំណែងការងារ",
    "Job type": "ប្រភេទការងារ",
    "Please select job type": "សូមជ្រើសរើសប្រភេទការងារ",
    "Expiry date": "ផុតកំណត់ថ្ងៃទី",
    "Please choose expire date": "សូមជ្រើសរើសកាលបរិច្ឆេទផុតកំណត់",
    "Headcount needed": "ចំនួនពលករដែលត្រូវការ",
    "Please input headcount needed": "សូមដាក់បញ្ចូលចំនួនពលករដែលត្រូវការ",
    "Number of employees for this position you would like to recruit":
      "ចំនួនពលករសម្រាប់មុខតំណែងនេះដែលអ្នកចង់ជ្រើសរើស",
    "Contract duration": "រយៈពេលកិច្ចសន្យាការងារ",
    Months: "ខែ",
    "Please input duration": "សូមបញ្ចូលរយៈពេល",
    "Duration of contract, in months": "រយៈពេលកិច្ចសន្យាគិតជាខែ",
    Location: "ទីកន្លែង",
    "Please select country": "សូមជ្រើសរើសប្រទេស",
    "The country of workplace": "ប្រទេសនៃកន្លែងធ្វើការ",
    "Please select state": "សូមជ្រើសរើសខេត្ត",
    "Please select city": "សូមជ្រើសរើសស្រុក",
    "{language} translation": "{language} ការបកប្រែ",
    "Please enter title": "សូមបញ្ចូលមុខតំណែង",
    "Name of the recruited job": "ឈ្មោះការងារដែលបានជ្រើសរើសរួច",
    "Position details": "មុខតំណែងលំអិត",
    "Please enter position details": "សូមបញ្ចូលមុខតំណែងលំអិត",
    "Job Description": "ការពិពណ៌នាអំពីការងារ",
    "Please enter job description": "សូមបញ្ចូលការពិពណ៌នាអំពីការងារ",
    "Please describe the responsibilities and environment of this position":
      "សូមពិពណ៌នាអំពីការទទួលខុសត្រូវ និង បរិយាកាស នៃមុខតំណែងនេះ",
    "Applicant Requirements": "តម្រូវការនៃលក្ខខណ្ឌសម្រាប់បេក្ខជន",
    "Please enter applicant requirements":
      "សូមបញ្ចូលលក្ខខណ្ឌតម្រូវសម្រាប់បេក្ខជន",
    "Please describe the prerequisite skills or qualifications needed to do this job sucessfully, if any":
      "សូមពណ៌នាអំពីជំនាញ ឬ លក្ខណៈសម្បត្ដិដែលតម្រូវក្នុងការធ្វើការងារបានដោយជោគជ័យ, ប្រសិនបើមាន",
    "Contact name": "ឈ្មោះទំនាក់ទំនង",
    "Please enter contact name": "សូមបញ្ចូលឈ្មោះទំនាក់ទំនង",
    "Please provide the name of the contact person for {language} applicants":
      "សូមផ្តល់ឈ្មោះអ្នកទំនាក់ទំនង {language} សម្រាប់បេក្ខជន ",
    "Please enter contact phone number": "សូមបញ្ចូលលេខទូរស័ព្ទទាក់ទង",
    "Please provide the phone number of the contact person for {language} applicants":
      "សូមផ្ដល់លេខទូរស័ព្ទរបស់អ្នកទំនាក់ទំនង  {language} សម្រាប់បេក្ខជន ",
    "Contact job title": "បញ្ជីលេខទំនាក់ទំនងមុខតំណែងនៃការងារ",
    "Please select contact job title":
      "សូមជ្រើសរើស លេខទំនាក់ទំនងនៃមុខតំណែងការងារ",
    "Recruitment Information": "ព័ត៌មានអំពីការជ្រើសរើស",
    "Do you have an ethical recruitment policy?":
      "តើអ្នកមានគោលនយោបាយនៃការជ្រើសរើសប្រកបដោយក្រមសីលធម៌ដែរទេ?",
    "Ethical recruitment policies specify more transparent and ethical conduct in the recruitment of workers":
      "គោលនយោបាយនៃការជ្រើសរើសប្រកបដោយក្រមសីលធម៌បញ្ជាក់ពី តម្លាភាព និង ក្រមសីលធម៌វិជ្ជាជីវៈ ក្នុងការជ្រើសរើសពលករ",
    "Do you have an employer pays policy?":
      "តើអ្នកមានគោលនយោបាយដែលនិយោជកបង់ថ្លៃនៃការជ្រើសរើសដែរឬទេ?",
    "Employer pays policies specify that all costs of recruitment are paid by the employer, and it is guaranteed that zero fees will be charged to workers, including for document processing and fees to recruiters":
      "គោលនយោបាយដែលនិយោជកបង់ថ្លៃនៃការជ្រើសរើសបញ្ជាក់ថា រាល់ការចំណាយលើការជ្រើសរើសពលករត្រូវបានបង់ដោយនិយោជក ហើយវាត្រូវបានធានាថានឹងមិនគិតថ្លៃសេវាពីពលកររួមទាំងការដំណើរការឯកសារ និង ថ្លៃឈ្នួលសម្រាប់អ្នកជ្រើសរើសពលករ",
    "Employer Pays Policy Type":
      "ប្រភេទនៃគោលនយោបាយដែលនិយោជកបង់ថ្លៃនៃការជ្រើសរើស",
    "Please select policy type": "សូមជ្រើសរើសប្រភេទនៃគោលនយោបាយ",
    "Who pays the visa fees?": "តើអ្នកណាជាអ្នកបង់ថ្លៃទិដ្ឋាការ/វីសារ? ",
    Worker: "ពលករ",
    Amount: "ចំនួន",
    "Who pays the work permit fees?":
      "តើអ្នកណាជាអ្នកបង់ថ្លៃបណ្ណអនុញ្ញាតការងារ?",
    "Who pays the medical checkup fees?":
      "តើអ្នកណាជាអ្នកបង់ថ្លៃពិនិត្យសុខភាព?​",
    "Contract Information: Pay": "ព័ត៌មាននៃកិច្ចសន្យា៖ បង់ប្រាក់",
    "Terms of payment": "លក្ខខណ្ឌនៃការចំណាយ",
    "Please select terms of payment": "សូមបញ្ចូលលក្ខខណ្ឌនៃការចំណាយ",
    "Please note whether the employee will be paid according to a daily or monthly rate, and how frequently they will be paid":
      "សូមកត់សម្គាល់ តើនិយោជិកនឹងត្រូវបានទូទាត់ឱ្យថ្លៃឈ្នួលតាមអត្រាប្រចាំថ្ងៃ ឬ ប្រចាំខែ ហើយតើពួកគេនឹងត្រូវបង់ប្រាក់ញឹកញាប់ប៉ុណ្ណា",
    "Timing of payment": "ពេលវេលានៃការផ្ដល់ប្រាក់ឈ្នួល",
    "Please select or create timing of payment":
      "សូមជ្រើសរើស ឬ បង្កើត ពេលវេលានៃការផ្ដល់ប្រាក់ឈ្នួល",
    "Please note whether the employee will be paid according to once a month or twice a month rate, and how frequently they will be paid":
      "សូមកត់សម្គាល់ តើនិយោជិកនឹងត្រូវបានទូទាត់ឱ្យថ្លៃឈ្នួលតាមអត្រា មួយដង ឬ ពីរដងក្នុងមួយខែ ហើយតើពួកគេនឹងត្រូវបង់ប្រាក់ញឹកញាប់ប៉ុណ្ណា",
    "Timing of Payment Other": "ពេលវេលានៃការផ្ដល់ប្រាក់ឈ្នួលផ្សេងទៀត",
    "Please input Timing of Payment Other":
      "សូមដាក់បញ្ចូលពេលវេលានៃការផ្ដល់ប្រាក់ឈ្នួលផ្សេងទៀត",
    "Other type of Payment Timing":
      "ប្រភេទផ្សេងទៀតនៃពេលវេលានៃការផ្ដល់ប្រាក់ឈ្នួល",
    "Enter Timing of Payment Other":
      "បញ្ចូលពេលវេលានៃការផ្ដល់ប្រាក់ឈ្នួលផ្សេងទៀត",
    Currency: "រូបិយបណ្ណ",
    "Please select currency": "ជ្រើសរើសរូបិយបណ្ណ",
    "Monthly salary": "ប្រាក់ខែ",
    "{currency}/month": " {currency}/ខែ",
    "Please input salary": "សូមបញ្ចូលប្រាក់ខែ",
    "Monthly payment to employee, which must be equal to or greater than the minimum wage for the province":
      "ការទូទាត់ប្រាក់ឈ្នួលប្រចាំខែទៅដល់និយោជិក ត្រូវតែស្មើនឹង ឬច្រើនជាងប្រាក់ឈ្នួលអប្បបរមាតាមខេត្ត",
    "Daily Wage": "ប្រាក់ឈ្នួលប្រចាំថ្ងៃ",
    "{currency}/day": " {currency}/ថ្ងៃ",
    "Please input daily wage rate": "សូមបញ្ចូលអត្រាប្រាក់ឈ្នួលប្រចាំថ្ងៃ",
    "Daily wage amount for 8 hours of work, equal to or greater than the minimum daily wage for the province":
      "ចំនួនប្រាក់ឈ្នួលប្រចាំថ្ងៃសម្រាប់ការងារ ៨ ម៉ោងត្រូវតែ ស្មើនឹង ឬច្រើនជាងប្រាក់ឈ្នួលប្រចាំថ្ងៃអប្បបរមារបស់ខេត្ត",
    "Available Shifts": "វេនការងារដែលទំនេរ",
    "What shift is this position expected to offer?":
      "តើមុខតំណែងនេះរំពឹងទុកសម្រាប់វេនការងារមួយណា?",
    "These are the available shift choices the position offers.":
      "នេះជាជម្រើសនៃការផ្លាស់ប្តូរវេនការងារដែលមាននៅក្នុងមុខតំណែងនេះ",
    "Overtime Expectation": "ការងារបន្ថែមម៉ោងដែលរំពឹងទុក",
    "How often is this position expected to offer voluntary OT?":
      "តើមុខតំណែងនេះរំពឹងថានឹងផ្តល់ជូនការធ្វើការបន្ថែមម៉ោងដោយស្ម័គ្រចិត្តច្រើនប៉ុណ្ណា?",
    "How often the position will require overtime.":
      "តើតំណែងនេះតម្រូវអោយមានការធ្វើការបន្ថែមម៉ោងច្រើនប៉ុណ្ណា?",
    "Day of Week Off": "ថ្ងៃឈប់សម្រាកប្រចាំសប្តាហ៍",
    "What day of the week is the day off for this position?":
      "តើថ្ងៃណាជាថ្ងៃឈប់សម្រាកប្រចាំសប្តាហ៍សម្រាប់មុខតំណែងនេះ?",
    "Helps workers to understand how fixed their schedule will be.":
      "ជួយពន្យល់ពលករឱ្យយល់ថា តើម៉ោងពេលធ្វើការងាររបស់ពួកគេនឹងត្រូវបានកំណត់យ៉ាងណា",
    "Minimum Working Hours": "ម៉ោងធ្វើការអប្បបរមា",
    "What are the minimum number of hours per week expected for this position?":
      "តើចំនួនម៉ោងអប្បបរមាប៉ុន្មាន ក្នុងមួយសប្តាហ៍ដែលត្រូវបានរំពឹងទុកក្នុងមុខតំណែងនេះ?",
    "Minimum number of hours per week expected for this position?":
      "ចំនួនម៉ោងតិចបំផុតក្នុងមួយសប្តាហ៍ ដែលត្រូវបានរំពឹងទុកនៅក្នុងមុខតំណែងនេះ",
    "Regular OT": "ការងារថែមម៉ោងធម្មតា",
    "Please input regular OT rate":
      "សូមបញ្ចូលអត្រាប្រាក់ឈ្នួលការងារថែមម៉ោងធម្មតា",
    "{currency}/hour": "{amount} {currency}/ម៉ោង",
    "Payment rate per hour for overtime worked on a normal working day to employee, which by law must be at least 1.5x regular hourly rate":
      "អត្រាប្រាក់ឈ្នួលក្នុងមួយម៉ោងសម្រាប់ការងារបន្ថែមម៉ោងនៅក្នុងថ្ងៃធ្វើការធម្មតា យោងតាមច្បាប់ត្រូវផ្ដល់ជូនអត្រាប្រាក់ឈ្នួលយ៉ាងតិច ១.៥ ដង នៃអត្រាប្រាក់ឈ្នួលនៃម៉ោងធ្វើការធម្មតា",
    "Day-Off Rate": "អត្រាប្រាក់ឈ្នួលក្នុងថ្ងៃឈប់សម្រាក",
    "Please input normal working hours rate during holiday":
      "សូមបញ្ចូលអត្រាប្រាក់ឈ្នួលសម្រាប់ការធ្វើការថ្ងៃឈប់សម្រាក",
    "Payment rate for working on the normally scheduled day off must be at least 2x the daily wage rate according to law":
      "អត្រាការប្រាក់ឈ្នួលសម្រាប់ការធ្វើការនៅថ្ងៃឈប់សម្រាក ត្រូវផ្ដល់ឲ្យយ៉ាងហោចណាស់ ២ ដងនៃអត្រាប្រាក់ឈ្នួលប្រចាំថ្ងៃធ្ម្មតា បើយោងតាមច្បាប់",
    "Holiday OT": "ការងារបន្ថែមម៉ោងនៅថ្ងៃឈប់សម្រាក",
    "Please input Day-Off Rate": "សូមបញ្ចូលអត្រាប្រាក់ឈ្នួលក្នុងថ្ងៃឈប់សម្រាក",
    "Payment rate per hour for overtime worked on a holiday, which by law must be at least 3x regular hourly rate":
      "អត្រាប្រាក់ឈ្នួលក្នុងមួយម៉ោងសម្រាប់ការងារបន្ថែមម៉ោងដែលធ្វើការនៅថ្ងៃឈប់សម្រាក បើយោងតាមច្បាប់ត្រូវមានអត្រាយ៉ាងតិច ៣ ដង នៃអត្រាប្រាក់ឈ្នួលម៉ោងធ្វើការនៅថ្ងៃធម្មតា",
    Benefits: "អត្ថប្រយោជន៍",
    "How many days of paid annual leave are provided per year?":
      "តើមានការឈប់សម្រាកប្រចាំឆ្នាំដែលមានប្រាក់ឈ្នួល ប៉ុន្មានថ្ងៃក្នុងមួយឆ្នាំ?",
    "Please input number": "សូមបញ្ចូលលេខ",
    "How many days of paid sick leave are provided per year?":
      "តើមានការឈប់សម្រាកឈឺដែលមានប្រាក់ឈ្នួល ប៉ុន្មានថ្ងៃក្នុងមួយឆ្នាំ?",
    "How many days of paid business leave are provided per year?":
      "តើមានការឈប់សម្រាកដោយមានធុរៈ(ឡាកិច)ដែលមានប្រាក់ឈ្នួល ប៉ុន្មានថ្ងៃក្នុងមួយឆ្នាំ?",
    "How many days of paid national holidays are provided per year?":
      "តើមានការឈប់សម្រាកបុណ្យជាតិដែលមានប្រាក់ឈ្នួល ប៉ុន្មានថ្ងៃក្នុងមួយឆ្នាំ?",
    "Are social security and maternity leave benefits provided as required by law?":
      "តើមានអត្ថប្រយោជន៍ធានារ៉ាប់រងសង្គម និង ការឈប់សម្រាកលំហែមាតុភាពដែលត្រូវបានផ្តល់ជូនស្របតាមច្បាប់ ដែរឬទេ?",
    "Yes, these benefits are provided":
      "បាទ/ចាស៎, អត្ថប្រយោជន៍ទាំងនេះត្រូវបានផ្ដល់អោយ",
    "No, these benefits are not provided":
      "ទេ, អត្ថប្រយោជន៍ទាំងនេះមិនត្រូវបានផ្ដល់អោយទេ",
    "Benefits details": "អត្ថប្រយោជន៍លំអិត",
    Accommodation: "ការស្នាក់នៅ",
    "Accommodation is on site": "ការស្នាក់នៅត្រូវបានផ្ដល់ជូននៅកន្លែងការងារ",
    "Family members can live together": "សមាជិកគ្រួសារអាចរស់នៅជាមួយគ្នាបាន",
    "Rent/utilities auto deducted from payroll":
      "ការជួល និង ថ្លៃប្រើប្រាស់ផ្សេងៗ ត្រូវបានកាត់កងក្នុងបញ្ជីបើកប្រាក់ខែ",
    "Accommodation details": "ការស្នាក់នៅលំអិត",
    "Please fill in accommodation details":
      "សូមបញ្ចូលព័ត៌មានលំអិតអំពីការស្នាក់នៅ",
    "Living arrangement details for the employees":
      "ព័ត៌មានលំអិតអំពីការរៀបចំការរស់នៅសម្រាប់និយោជិក",
    "Rent/utilites deduction details":
      "ការកាត់កងលំអិតនៃ ការជួល និងថ្លៃប្រើប្រាស់ផ្សេងៗ",
    "Please fill in rent details":
      "សូមបញ្ចូលព័ត៌មានលំអិតនៃការជួល និង ថ្លៃប្រើប្រាស់ផ្សេងៗ",
    "Safe, grievance mechanism": "មានយន្ដការបណ្ដឹងតវ៉ាដែលមានសុវត្ថិភាព",
    "Probation period": "រយៈពេលសាកល្បងការងារ",
    "Grievance mechanism details": "ព័ត៌មានលំអិតអំពីយន្ដការនៃបណ្ដឹងតវ៉ា",
    "Please input grievance mechanism details":
      "សូមបញ្ចូលព័ត៌មានលំអិតអំពីយន្ដការនៃបណ្ដឹងតវ៉ា",
    "Probation period details": "ព័ត៌មានលំអិតអំពីរយៈនៃការសាកល្បងការងារ",
    "Please input probation period details": "សូមបញ្ចូលរយៈធ្វើការសាកល្បងលំអិត",
    Preview: "ពិនិត្យមើល",
    "Save draft": "រក្សាទុកសេចក្តីព្រាង",
    Post: "ដាក់បង្ហាញ",
    "You cannot make changes after posting the job. Are you sure you want to post this job?":
      "អ្នកមិនអាចធ្វើការផ្លាស់ប្តូរបានបន្ទាប់ពីអ្នកបានដាក់ការប្រកាសការងាររបស់អ្នករួចហើយ។ តើអ្នកប្រាកដទេថាអ្នកចង់ដាក់ការប្រកាសការងារនេះ?",
    "Job Preview": "ពិនិត្យមើលការងារ",
    "General Information": "ព័ត៌មាន​ទូទៅ",
    "No information were provided": "គ្មានព័ត៌មានត្រូវបានផ្ដល់អោយ",
    "Open To": "បើកចំពោះ",
    "No of Headcount Needed": "មិនត្រូវការពលករបន្ថែម",
    "Job will be expired on": "ការប្រកាសការងារនឹងផុតកំណត់នៅថ្ងៃ",
    "Pink Card Holder": "អ្នកកាន់បណ្ណស៊ីជម្ពូ",
    "Passport Holder": "អ្នកកាន់លិខិតឆ្លងដែន",
    "Job Position Category": "ប្រភេទមុខតំណែង",
    "Job Type": "ប្រភេទការងារ",
    "Contract Duration": "រយៈពេលកិច្ចសន្យាការងារ",
    "{duration} months": "{duration} ខែ",
    "Job Position Details": "ព័ត៌មានលំអិតអំពីមុខតំណែង",
    "Job Requirements": "លក្ខខណ្ឌតម្រូវសម្រាប់ការងារ",
    "Have an ethical recruitment policy":
      "មានគោលនយោបាយជ្រើសរើសប្រកបដោយក្រមសីលធម៌",
    "Have an employer pays policy": "មានគោលនយោបាយនិយោជកចេញថ្លៃក្នុងការជ្រើសរើស",
    "Who pays visa fees?": "តើអ្នកណាជាអ្នកបង់ថ្លៃទិដ្ឋាការ? ",
    "Who pays work permit fees?":
      "តើអ្នកណាជាអ្នកចេញថ្លៃទៅលើបណ្ណអនុញ្ញាតការងារ?",
    "Who pays medical checkup fees?": "តើអ្នកណាជាអ្នកបង់ថ្លៃពិនិត្យសុខភាព?​",
    "Terms of Payment": "លក្ខខណ្ឌនៃប្រាក់ឈ្នួល",
    "Timing of Payment": "ពេលវេលានៃការផ្ដល់ប្រាក់ឈ្នួល",
    Salary: "ប្រាក់ខែ",
    "OT Rate Regular": "អត្រាប្រាក់ឈ្នួលការងារថែមម៉ោងធម្មតា",
    "OT Rate Holiday": "អត្រាប្រាក់ឈ្នួលការងារថែមម៉ោងថ្ងៃឈប់សម្រាកបុណ្យជាតិ",
    "Holiday Rate": "អត្រាប្រាក់ឈ្នួលថ្ងៃឈប់សម្រាកបុណ្យជាតិ",
    "Other Benefits": "អត្ថប្រយោជន៍ផ្សេងៗ",
    "Benefits Details": "អត្ថប្រយោជន៍លំអិត",
    "Other Information": "ព័ត៌មានផ្សេងៗ",
    "Accommodation on-site is available":
      "មានផ្ដល់ជូនកន្លែងស្នាក់នៅនៅនឹងកន្លែងធ្វើការ",
    "Rent & utilities autodeducted from payroll":
      "ការជួល និង ថ្លៃប្រើប្រាស់ទឹក ភ្លើង ត្រូវបានកាត់កងក្នុងបញ្ជីបើកប្រាក់ខែ",
    "Safe & functioning grievance mechanism":
      "យន្តការបណ្តឹងតវ៉ាប្រកបដោយសុវត្ថិភាព និង មានដំណើរការ",
    "Accommodation Details": "ព័ត៌មានលំអិតអំពីការស្នាក់នៅ",
    "Contact Information": "ទំនាក់ទំនងសាកសួរព័ត៌មាន",
    Edit: "កែរសម្រួល",
    "Job has been successfully archived.": "ការងារត្រូវបានដាក់បញ្ចូលដោយជោគជ័យ",
    "An error occurred while archiving draft.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលកំពុងរក្សាទុកសេចក្តីព្រាង",
    "Job has been successfully deleted.": "ការងារត្រូវបានលុបចោលដោយជោគជ័យ",
    "An error occurred while deleting job.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលកំពុងលុបចោលការងារ",
    "Recruitment has been successfully stopped.":
      "ការប្រកាសជ្រើសរើសត្រូវបានបញ្ឈប់",
    "An error occurred while stopping recruitment.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលបញ្ឈប់ការប្រកាសជ្រើសរើស",
    "Job has been successfully set to offline.":
      "ការងារត្រូវបានដាក់អោយដំណើរការដោយមិនចាំបាច់មានអ៊ីនធឺណិតបានដោយជោគជ័យ",
    "An error occurred while setting job to offline.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលដាក់ការងារអោយដំណើរការដោយមិនចាំបាច់មានអ៊ីនធឺណិត",
    "Job has been successfully set to online.":
      "ការងារត្រូវបានដាក់អោយដំណើរការដោយប្រើប្រាស់អ៊ីនធឺណិតបានដោយជោគជ័យ",
    "An error occurred while setting job to online.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលដាក់ការងារអោយដំណើរការដោយប្រើប្រាស់អ៊ីនធឺណិត",
    Title: "មុខតំណែង",
    Posted: "បានដាក់បង្ហាញ",
    Expires: "ផុតកំណត់",
    Views: "ពិនិត្យមើល",
    Applicants: "បេក្ខជន",
    "Filled Vacancies": "បានបំពេញឱកាសការងារ",
    Online: "អនឡាញ",
    Action: "សកម្មភាព",
    "Are you sure you would like to end the recruitment? This action cannot be undone later!":
      "តើអ្នកប្រាកដទេថាអ្នកចង់បញ្ចប់ការប្រកាសជ្រើសរើស? សកម្មភាពនេះមិនអាចសាឡើងវិញបានទេនៅពេលក្រោយ!",
    "End recruitment": "បញ្ចប់ការជ្រើសរើស",
    "Are you sure you would like to archive this job?":
      "តើអ្នកប្រាកដទេថាអ្នកចង់រក្សាទុកការងារនេះ?",
    Archive: "រក្សាទុក",
    Saved: "បានរក្សាទុក",
    Archived: "បានរក្សាទុក",
    "Are you sure you would like to delete this job?":
      "តើអ្នកប្រាកដទេថាអ្នកចង់រលុបការងារនេះ?",
    Delete: "លុប",
    "View Jobs:": "ពិនិ្យមើលការងារៈ",
    "Posted ({count})": "បានដាក់បង្ហាញ({count})",
    "Closed ({count})": "បានបិទ({count})",
    "Drafts ({count})": "ព្រាង ({count})",
    "Archived ({count})": "បានរក្សាទុក ({count})",
    "Position category": "ប្រភេទមុខតំណែង",
    "Please select timing of payment":
      "សូមជ្រើសរើសពេលវេលានៃការផ្ដល់ប្រាក់ឈ្នួល",
    "Please input Day-Off OT":
      "សូមបញ្ចូលអត្រាប្រាក់ឈ្នួលសម្រាប់ការងារបន្ថែមម៉ោង នៅថ្ងៃឈប់សម្រាក",
    "Account Verification Status": "ការផ្ទៀងផ្ទាត់គណនី",
    Registration: "ការចុះឈ្មោះ",
    "Fill out the registration form": "បំពេញបែបបទនៃការចុះឈ្មោះ",
    "Email Verification": "ការផ្ទៀងផ្ទាត់អ៊ីម៉ែល",
    "Please verify your email using the link sent to {email}":
      "សូមផ្ទៀងផ្ទាត់អ៊ីម៉ែលរបស់អ្នកដោយប្រើប្រាស់តំណដែលបានផ្ញើទៅ{email}",
    "Business Verification": "ការផ្ទៀងផ្ទាត់អាជីវកម្ម",
    "Issara will contact you to verify that you are actually the owner of this business":
      "ឥស្សរា នឹងទាក់ទងទៅអ្នក ដើម្បីផ្ទៀងផ្ទាត់ថាតើអ្នកពិតជាម្ចាស់អាជីវកម្មនេះមែនទេ",
    Actions: "សកម្មភាព",
    "Are you sure this business is not in our database yet?":
      "តើអ្នកប្រាកដទេ អាជីវកម្មនេះគឺមិនទាន់មាននៅក្នុងប្រព័ន្ធផ្ទកទិន្នន័យយើងនៅឡើយទេ",
    "This business is not in our database yet. Verify as new.":
      "អាជីវកម្មនេះ គឺមិនមាននៅក្នុងប្រព័ន្ធផ្ទកទិន្នន័យយើងនៅឡើយទេ៕  ចាត់ទុកថាជាក្រុមហ៊ុនថ្មី",
    "Are you sure you want to reject verification and delete this user?":
      "តើអ្នកប្រាកដទេថា អ្នកចង់បដិសេធការផ្ទៀតផ្ទាត់នេះ និងលុបអ្នកប្រើប្រាស់នេះ?",
    "The user couldn't prove ownership of the business. Reject it.":
      "អ្នកប្រើប្រាស់មិនអាចបញ្ជាក់ថាជាម្ចាស់នៃអាជីវកម្មនេះ៕ បដិសេធវា",
    "Owner's Email": "អ៊ីម៉ែលរបស់ម្ចាស់អាជីវកម្ម",
    Registered: "ចុះឈ្មោះរួចរាល់",
    "Business has been successfully verified":
      "ក្រុមហ៊ុនត្រូវបានបញ្ជាក់ដោយជោគជ័យ",
    "An error occurred while verifying business. Please contact a developer.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលកំពុងធ្វើការបញ្ជាក់ពីព័ត៌មានអំពីអាជីវកម្មរបស់អ្នក។ សូមទាក់ទងទៅក្រុមការងារបច្ចែកទេសរបស់យើង",
    "Business has been successfully removed":
      "ព័ត៏មានពីអាជីវកម្មត្រូវបានលុបដោយជោគជ័យ",
    "An error occurred while rejecting account. Please contact a developer.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលកំពុងធ្វើបដិសេធគណនេយ្យអ្នកប្រើប្រាស់ ។ សូមទាក់ទងទៅក្រុមការងារបច្ចេកទេសរបស់យើង",
    "Merge Dialogue": "ការរួមបញ្ចូលការសន្ទនា",
    Close: "បិទ",
    "To be merged": "នឹងត្រូវរួមបញ្ចូលគ្នា",
    Into: "នៅក្នុង",
    "Awaiting Employers": "បញ្ជីនៃការរង់ចាំរបស់និយោជក",
    "Total ({count})": "សរុប ({count})",
    "No awaiting employers to verify": "មិនមាននិយោជករង់ចាំការបញ្ជាក់",
    Previous: "ទំព័រមុន",
    Next: "បន្ទាប់",
    "Previously verified employers": "និយោជកដែលបានបញ្ជាក់ពីមុន",
    "No previously verified employers": "មិននិយោជកដែលបានបញ្ជាក់ពីមុន",
    "N/A": "មិនមានព័ត៌មាន",
    Verify: "បញ្ជាក់",
    "Awaiting Recruitment agencies": "ភ្នាក់ងារជ្រើសរើសដែលកំពុងរង់ចាំ",
    "No businesses awaiting verification": "មិនមានអាជីវកម្មរង់ចាំការបញ្ជាក់",
    "Previously verified Recruiters": "ភ្នាក់ងារជ្រើសរើសដែលបានបញ្ជាក់ពីមុន",
    "No previously verified businesses": "មិនមានអាជីវកម្មដែលបានបញ្ជាក់ពីមុន",
    "Survey Details": "សេចក្តីលម្អិតពីការស្រង់មតិ",
    "Identification Card": "អត្តសញ្ញាណប័ណ្ណ",
    "Birth Certificate": "សំបុត្រកំណើត",
    "Family Record Book": "សៀវភៅគ្រួសារ",
    "Residence Book": "សៀវភៅស្នាក់នៅ",
    "Covid Vaccine Certificate": "ប័ណ្ណចាក់វ៉ាក់សាំងកូវីត-១៩",
    NRC: "កាត NRC",
    "Household List": "បញ្ជីឈ្មោះគ្រួសារ",
    "Passport (PJ)": "លិខិតឆ្លងដែន (PJ)",
    "Labour Card": "ប័ណ្ណការងារ",
    "Generating PDF ...": "កំពុងបំលែងទៅជា PDF ...",
    "Generate PDF & Download": "បំលែងទៅជា PDF & ទាញយក",
    "Request documents dfsdfwe": "ឯកសារស្នើសុំ",
    "Application #{id} has been successfully contract signed":
      "ពាក្យស្នើសុំ #{id} ត្រូវបានចុះកិច្ចសន្យាដោយជោគជ័យ",
    "An error occurred while contract signing application #{id}.":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលចុះកិច្ចសន្យាពាក្យស្នើសុំ #{id} ",
    "Application has been moved to general waitlisted on application #{id}.":
      "ពាក្យស្នើសុំត្រូវបានប្ដួរទៅបញ្ជីឈ្មោះរង់ចាំទូទៅសម្រាប់ពាក្យស្នើសុំ #{id}",
    "An error occurred while moving the application #{id}. to general waitlist":
      "មានបញ្ហាបច្ចេកទេសកើតឡើងខណៈពេលកំពុងប្ដូរពាក្យស្នើសុំ #{id} ទៅកាន់បញ្ជីឈ្មោះរង់ចាំទូទៅ",
    "Waitlisted ({count})": "បញ្ជីឈ្មោះរង់ចាំ ({count})",
    "Contract Signed ({count})": "បានចុះកិច្ចសន្យា ({count})",
    'Are you sure you would like to change the status to "Contract Signed" to the selected applications? This action cannot be undone later':
      'តើអ្នកពិតជាចង់ប្ដូរពាក្យស្នើសុំដែលបានចុចជ្រើសរើសទៅជា "បានចុះកិច្ចសន្យា" មែនទេ? សកម្មភាពនេះមិនអាចសារឡើងវិញបានទេ',
    "Contract Signed ({number})": "បានចុះកិច្ចសន្យា ({number})",
    "Move to waitlist ({number})": "ប្ដូរទៅបញ្ជីឈ្មោះរង់ចាំ ({number})",
    "Are you sure you would like to move the selected applicants to General Waitlist? This action cannot be undone later.":
      "តើពិតជាចង់ប្ដូរពាក្យស្នើសុំដែលបានចុចជ្រើសរើសទាំងនេះទៅកាន់បញ្ជីឈ្មោះរង់ចាំទូទៅមែនទេ? សកម្មភាពនេះមិនអាចសារឡើងវិញបានទេ",
    "Move to General Waitlist ({number})":
      "ប្ដូរទៅបញ្ជីឈ្មោះរង់ចាំទូទៅ  ({number})",
    "Please select an offer expiry date":
      "សូមជ្រើសរើសកាលបរិច្ឆេទផុតកំណត់នៃការផ្ដល់ជូន",
    "Update Now": "ធ្វើបច្ចុប្បន្នភាពឥលូវនេះ",
    "Offer Now": "ផ្ដល់ជូនឥលូវនេះ",
    "Offer expiry date will be {expiry_date}":
      "ការបរិច្ឆេទផុតកំណត់នៃការផ្ដល់ជូន {expiry_date}",
    "Resume Detail Information": "ព័ត៌មានលំអិតអំពីប្រវិត្តិរូបសង្ខេប",
    "Resume Information": "ព័ត៌មានអំពីប្រវិត្តិរូបសង្ខេប",
    "The job seeker have National ID": "អ្នកស្វែងរកការងារមានអត្តសញ្ញាណបណ្ណ",
    "The job seeker does not have a National ID":
      "អ្នកស្វែងរកការងារមិនមានអត្តសញ្ញាណបណ្ណ",
    "The job seeker have passport": "អ្នកស្វែងរកការងារមានលិខិតឆ្លងដែន",
    "The job seeker does not have a passport":
      "អ្នកស្វែងរកការងារមិនមានលិខិតឆ្លងដែន",
    "Read Level": "កំរិតនៃការអាន",
    "Speak Level": "កំរិតនៃការនិយាយ",
    "Applied At": "បានដាក់ពាក្យស្នើរសុំនៅ",
    "Resume View": "ពិនិត្យមើលប្រវត្ដិរូបសង្ខេប",
    "General Waitlist": "បញ្ជីឈ្មោះរង់ចាំទូទៅ",
    Jobseekers: "អ្នកស្វែងរកការងារ ",
    "(Burmese)": "(មីយ៉ាន់ម៉ា)",
    "(English)": "(អង់គ្លេស)",
    "(Nepali)": "(នេប៉ាល់)",
    "(Thailand)": "(ថៃ)",
    "(Khmer)": "(ខ្មែរ)",
    "Update/Post job": "ការធ្វើបច្ចុប្បន្នភាព/ការប្រកាសជ្រើសរើសការងារ",
    "This job is for the country ! ({country})": "ការងារនេះសម្រាប់ប្រទេស!",
    "Need to choose a country for this job!":
      "ចាំបាច់ត្រូវការជ្រើសរើសប្រទេសសម្រាប់ការងារនេះ!",
    "Please select the sending country !": "សូមជ្រើសរើសប្រទេសគោលដៅ",
    Malaysia: "ម៉ាឡេស៊ី",
    UAE: "អារ៉ាប់រួម",
    "Employer Information": "ត៌មានអំពីនិយោជក",
    "Please select employer": "សូមជ្រើសរើសនិយោជក",
    "Please input employer's name": "សូមបញ្ចូលឈ្មោះនិយោជក",
    "The name of the Employer": "ឈ្មោះនិយោជក",
    "The country where the workplace is located": "ប្រទេសនៃកន្លែងធ្វើការ",
    "The state where the workplace is located": "ខេត្តនៃកន្លែងធ្វើការ",
    "The city where the workplace is located": "ស្រុកនៃកន្លែងធ្វើការ",
    "Please select size": "សូមជ្រើសរើសទំហំ",
    "Nationalities of Workers working at this workplace": "សញ្ជាតិនៃពលករ",
    "Please specify the nationalities of workers working at this workplace.":
      "សូមបញ្ពាក់ពីសញ្ជាតិរបស់ពលករដែលកំពុងធ្វើការនៅកន្លែងធ្វើការនេះ",
    "Headcount by Genders": "ចំនួននិយោជិកដែលត្រូវការទៅតាមភេទ",
    Genders: "ភេទ ",
    "Male headcount needed": "ចំនួននិយោជិកបុរសដែលត្រូវការ",
    "Female headcount needed": "ចំនួននិយោជិកស្រីដែលត្រូវការ",
    "Demand Pre-approval date":
      "កាលបរិច្ឆេទដែលទទួលបានការអនុម័តបឋមនៃបញ្ជីតម្រូវការពលករ",
    "Demand Approved Date":
      "កាលបរិច្ឆេទដែលទទួលបានការអនុម័តនៃបញ្ជីតម្រូវការពលករ",
    "Please choose demand pre-approval date":
      "សូមជ្រើសរើសកាលបរិច្ឆេទដែលទទួលបានការអនុម័តបឋមនៃបញ្ជីតម្រូវការពលករ",
    "Please choose demand approved date":
      "សូមជ្រើសរើសកាលបរិច្ឆេទដែលទទួលបានការអនុម័តនៃបញ្ជីតម្រូវការពលករ",
    "LT number": "លេខ LT",
    "Demand number": "ចំនួនពលករដែលត្រូវការ",
    "Please enter LT number": "សូមបញ្ចូលលេខ LT",
    "Please enter demand number": "សូមបញ្ជូលចំនួនពលករដែលត្រូវការ",
    "Application Closed Date": "កាលបរិច្ឆេទនៃការបិទទទួលពាក្យស្នើសុំ",
    "Please choose Application Closed date":
      "សូមជ្រើសរើសកាលបរិច្ឆេទនៃការបិទទទួលពាក្យស្នើសុំ",
    "Email to receive notifications for this job announcement":
      "អ៊ីម៉ែលដើម្បីទទួលការជូនដំណឹងសម្រាប់សេចក្ដីប្រកាសការងារនេះ",
    "Please enter a valid email address":
      "សូមបញ្ចូលអាសយដ្ឋានអ៊ីមែលដែលប្រើប្រាស់",
    "ER Information": "ព័ត៌មានអំពីការជ្រើសរើសប្រកបដោយក្រមសីលធម៌វិជ្ជាជីវៈ",
    "Does the employer have an ethical recruitment policy?":
      "តើនិយោជកមានគោលនយោបាយនៃការជ្រើសរើសប្រកបដោយក្រមសីលធម៌ដែរឬទេ?",
    Have: "មាន",
    "Not Have": "មិនមាន",
    "Does the RA have an ethical recruitment policy?":
      "តើទីភ្នាក់ងារជ្រើសរើសពលករមានគោលនយោបាយនៃការជ្រើសរើសប្រកបដោយក្រមសីលធម៌ដែរឬទេ?",
    "Does the RA have an ethical recruitment policy? is required":
      "តើទីភ្នាក់ងារជ្រើសរើសពលករមានគោលនយោបាយនៃការជ្រើសរើសប្រកបដោយក្រមសីលធម៌ដែរឬទេ? ចាំបាច់ត្រូវឆ្លើយ",
    "Does the employer have an employer pays policy?":
      "តើនិយោជកមានគោលនយោបាយដែលនិយោជកបង់ថ្លៃនៃការជ្រើសរើសដែរឬទេ?",
    "Monthly salary ({currency}/month)": "ប្រាក់ខែ{salary} {currency}/ខែ",
    "How much will be provided for meals allowance?":
      "តើប្រាក់ឧបត្ថម្ភថ្លៃអាហារដែលត្រូវផ្តល់មានចំនួនប៉ុន្មាន?",
    "Please enter a meal allowance.": "សូមបញ្ចូលចំនួនប្រាក់ឧបត្ថម្ភថ្លៃអាហារ",
    "Please enter a meal allowance of no less than 300 AED.":
      "សូមបញ្ចូលចំនួនប្រាក់ឧត្ថម្ភថ្លៃអាហារមិនឱ្យតិចជាង300 AED",
    "Any additional leave ?": "តើមានច្បាប់ឈប់សម្រាកបន្ថែមដទៃទៀតទេ?",
    "Add Detail Here": "បន្ថែមព័ត៌មានលំអិតនៅទីនេះ",
    "Any additional leave ? (This is optional)":
      "ព័ត៌មានលំអិតអំពីច្បាប់ឈប់សម្រាកបន្ថែមដទៃទៀត​ (អាចរំលងមិនឆ្លើយក៏បាន)",
    "Add detail here (optional)":
      "បន្ថែមព័ត៌មានលំអិតនៅទីនេះ ​(អាចរំលងមិនឆ្លើយក៏បាន)",
    "Are social security benefits provided as required by law?":
      "តើមានអត្ថប្រយោជន៍ដែលត្រូវបានផ្តល់ស្របទៅតាមច្បាប់ ដែរឬទេ?",
    "Skill training information": "ព័ត៌មានអំពីការបណ្ដុះបណ្ដាលជំនាញ",
    "Is skill training required for this job?":
      "តើការងារនេះទាមទារការបណ្ដុះបណ្ដាលជំនាញដែរឬទេ?",
    "if so, who will cover those costs?":
      "ប្រសិនបើទាមទារ តើអ្នកណាជាអ្នករាប់រងលើការចំណាយ?",
    "if workwes have to pay, please provide the amount they should pay":
      "ប្រសិនបើពលករជាអ្នកចំណាយ សូមផ្ដល់នូវចំនួនទឹកប្រាក់ដែលពលករត្រូវចំណាយ",
    "Cost of skill-training?": "តម្លៃនៃការបណ្ដុះបណ្ដាលជំនាញ",
    "Cost of skill-training? is required":
      "តម្លៃនៃការបណ្ដុះបណ្ដាលជំនាញ? ចាំបាច់ត្រូវឆ្លើយ",
    "Accommodation  during skill training?":
      "ការស្នាក់នៅកំលុងពេលនៃការបណ្ដុះបណ្ដាលជំនាញ?",
    "Please specify, does the employer provide accommodation during skill-training of Workers":
      "សូមបញ្ជាក់៖ តើនិយោជកមានផ្ដល់កន្លែងស្នាក់នៅកំលុងពេលនៃការបណ្ដុះបណ្ដាលជំនាញដល់ពលករដែរឬទេ?",
    "Accommodation  during skill training? is required":
      "ការស្នាក់នៅកំលុងពេលនៃការបណ្ដុះបណ្ដាលជំនាញ? ចាំបាច់ត្រូវឆ្លើយ",
    "Meals during skill training?":
      "តើមានផ្ដល់អាហារកំលុងពេលនៃការបណ្ដុះបណ្ដាលជំនាញដែរឬទេ?",
    "Please specify, does the employer provide meals during skill-training of Workers":
      "សូមបញ្ជាក់៖ តើនិយោជកមានផ្ដល់អាហារកំលុងពេលនៃការបណ្ដុះបណ្ដាលជំនាញដែរឬទេ?",
    "Meals during skill training? is required":
      "តើមានផ្ដល់អាហារកំលុងពេលនៃការបណ្ដុះបណ្ដាលជំនាញដែរឬទេ? ចាំបាច់ត្រូវឆ្លើយ",
    "Based on individual cost/spending.": "អាស្រ័យលើចំណាយ/ថ្លៃផ្ទាល់ខ្លួន",
    "Transportation costs to and from for attending skill training?":
      "តើមានថ្លៃធ្វើដំណើរទៅមកសម្រាប់ចូលរួមវគ្គបណ្ដុះបណ្ដាលជំនាញដែរឬទេ?",
    "Transportation costs to and from for attending skill training? is required":
      "តើមានថ្លៃធ្វើដំណើរទៅមកសម្រាប់ចូលរួមវគ្គបណ្ដុះបណ្ដាលជំនាញដែរឬទេ? ចាំបាច់ត្រូវឆ្លើយ",
    "Brief description of skill training":
      "រៀបរាប់សង្ខេបអំពីវគ្គបណ្ដុះបណ្ដាលជំនាញ",
    "Please fill the brief description of skill training":
      "សូមបំពេញសេចក្ដីរៀបរាប់សង្ខេបអំពីវគ្គបណ្ដុះបណ្ដាលជំនាញ",
    "Costs for Pre-departure": "តម្លៃសម្រាប់មុនចេញដំណើរ",
    "Is pre-departure orientation training required for this job?":
      "សម្រាប់ការងារនេះតើទាមទារឲ្យមានវគ្គបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរដែរឬទេ?",
    "Cost of pre-departure orientation training?":
      "តម្លៃនៃវគ្គបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ?",
    "Cost of pre-departure orientation training? is required":
      "តម្លៃនៃវគ្គបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ? ចាំបាច់ត្រូវឆ្លើយ",
    "Accommodation during pre-departure orientation training?":
      "ការស្នាក់នៅកំលុងពេលបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ?",
    "Please specify, does the employer provide accommodation during pre-departure orientation training of Workers":
      "សូមបញ្ជាក់៖ តើនិយោជកផ្ដល់កន្លែងស្នាក់នៅកំលុងពេលបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរដល់ពលករដែរឬទេ?",
    "Accommodation during pre-departure orientation training? is required":
      "ការស្នាក់នៅកំលុងពេលបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ? ចាំបាច់ត្រូវឆ្លើយ",
    "Meals during pre-departure orientation training?":
      "អាហារកំលុងពេលបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ?",
    "Please specify, does the employer provide meals during pre-departure orientation training of Workers":
      "សូមបញ្ជាក់៖ តើនិយោជកមានផ្ដល់អាហារកំលុងពេលបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរដល់ពលករដែរឬទេ?",
    "Meals during pre-departure orientation training? is required":
      "អាហារកំលុងពេលបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ? ចាំបាច់ត្រូវឆ្លើយ",
    "Transportation costs to and from pre-departure orientation training?":
      "ថ្លៃធ្វើដំណើរទៅមកសម្រាប់វគ្គបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ?",
    "Transportation costs to and from pre-departure orientation training? is required":
      "ថ្លៃធ្វើដំណើរទៅមកសម្រាប់វគ្គបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ? ចាំបាច់ត្រូវឆ្លើយ",
    "Additional information for Pre-departure orientation course":
      "ព័ត៌មានបន្ថែមសម្រាប់វគ្គបណ្ដុះបណ្ដាលណែនាំអំពីការរៀបចំមុនចេញដំណើរ",
    "Please fill in additional information for Pre-departure orientation course (number of days, location, kind of training, etc)":
      "សូមបំពេញព័ត៌មានបន្ថែមសម្រាប់វគ្គបណ្ដុះបណ្ដាលណែនាំមុនធ្វើដំណើរ (ចំនួនថ្ងៃ ទីតាំង ប្រភេទវគ្គបណ្ដុះបណ្ដាល ផ្សេងៗ)",
    "Costs and fees for recruitment in the origin country":
      "តម្លៃ និង កម្រៃសេវា សម្រាប់ការជ្រើសរើសនៅក្នុងប្រទេសដើម",
    "Select the origin country currency": "ជ្រើសរើសរូបិយប័ណ្ណនៃប្រទេសដើម",
    "Origin country Currency": "រូបិយប័ណ្ណនៃប្រទេសដើម",
    "Recruitment service fee to RA and sub-agent?":
      "តម្លៃសេវាជ្រើសរើសដល់ទីភ្នាក់ងារជ្រើសរើសពលករ និង ភ្នាក់ងាររង?",
    "Recruitment service fee to RA and sub-agent? is required":
      "តម្លៃសេវាជ្រើសរើសដល់ទីភ្នាក់ងារជ្រើសរើសពលករ និង ភ្នាក់ងាររង? ចាំបាច់ត្រូវឆ្លើយ",
    "Accommodation costs for contract signing":
      "ថ្លៃស្នាក់នៅសម្រាប់មកចុះកិច្ចសន្យា?",
    "Accommodation costs for contract signing is required":
      "ថ្លៃស្នាក់នៅសម្រាប់មកចុះកិច្ចសន្យា? ចាំបាច់ត្រូវឆ្លើយ",
    "Medical check-up fee?": "ថ្លៃសេវាត្រួតពិនិត្យសុខភាព?",
    "Medical check-up fee? is required":
      "ថ្លៃសេវាត្រួតពិនិត្យសុខភាព? ចាំបាច់ត្រូវឆ្លើយ",
    "Passport application fee?": "ថ្លៃធ្វើលិខិតឆ្លងដែន ?",
    "Passport application fee? is required":
      "ថ្លៃធ្វើលិខិតឆ្លងដែន ? ចាំបាច់ត្រូវឆ្លើយ",
    "Overseas Worker Identification Card (OWIC) fee?":
      "ថ្លៃធ្វើបណ្ណពលករកម្ពុជាទៅធ្វើការនៅបរទេស (OCWC)?",
    "Overseas Worker Identification Card (OWIC) fee? is required":
      "ថ្លៃធ្វើបណ្ណពលករកម្ពុជាទៅធ្វើការនៅបរទេស (OCWC)? ចាំបាច់ត្រូវឆ្លើយ",
    "Travel costs for a job interview?":
      "ថ្លៃធ្វើដំណើរសម្រាប់មកសម្ភាសន៍ការងារ?",
    "Travel costs for a job interview? is required":
      "ថ្លៃធ្វើដំណើរសម្រាប់មកសម្ភាសន៍ការងារ? ចាំបាច់ត្រូវឆ្លើយ",
    "Transportation cost to and from for contract signing?":
      "ថ្លៃធ្វើដំណើរទៅមកសម្រាប់មកចុះកិច្ចសន្យា",
    "Transportation cost to and from for contract signing? is required":
      "ថ្លៃធ្វើដំណើរទៅមកសម្រាប់មកចុះកិច្ចសន្យា? ចាំបាច់ត្រូវឆ្លើយ",
    "Meals for contract signing?": "អាហារសម្រាប់មកចុះកិច្ចសន្យា?",
    "Meals for contract signing? is required":
      "អាហារសម្រាប់មកចុះកិច្ចសន្យា? ចាំបាច់ត្រូវឆ្លើយ",
    "Transportation costs to the border or airport?":
      "ថ្លៃធ្វើដំណើរទៅកាន់ព្រំដែន ឬព្រលានយន្តហោះ?",
    "Transportation costs to the border or airport? is required":
      "ថ្លៃធ្វើដំណើរទៅព្រំដែន ឬព្រលានយន្តហោះ? ចាំបាច់ត្រូវឆ្លើយ",
    "Costs and fees for recruitment in the destination country":
      "តម្លៃ និង កម្រៃសេវា សម្រាប់ការជ្រើសរើសនៅប្រទេសធ្វើការ",
    "Select the desitnation country currency":
      "ជ្រើសរើសរូបិយប័ណ្ណនៃប្រទេសគោលដៅ",
    "Destination country Currency": "រូបិយប័ណ្ណនៃប្រទេសគោលដៅ",
    "Transportation cost from the border or airport to the workplace (or to the destination country)?":
      "ថ្លៃធ្វើដំណើរពីព្រំដែនទៅកន្លែងធ្វើការ ឬពីព្រលានយន្តហោះទៅកន្លែងធ្វើការ (ឬទៅកាន់ប្រទេសគោលដៅ)?",
    "Transportation cost from the border or airport to the workplace (or to the destination country)? is required":
      "ថ្លៃធ្វើដំណើរពីព្រំដែនទៅកន្លែងធ្វើការ ឬពីព្រលានយន្តហោះទៅកន្លែងធ្វើការ (ឬទៅកាន់ប្រទេសគោលដៅ)? ចាំបាច់ត្រូវឆ្លើយ",
    "Visa fee?": "ថ្លៃទិដ្ឋាការ (វីសា)?",
    "Visa fees is required": "ថ្លៃទិដ្ឋាការ (វីសា)? ចាំបាច់ត្រូវឆ្លើយ",
    "Work permit?": "ថ្លៃប័ណ្ណអនុញ្ញាតការងារ?",
    "Work Permit fees is required":
      "ថ្លៃប័ណ្ណអនុញ្ញាតការងារ? ចាំបាច់ត្រូវឆ្លើយ",
    "Medical check-up fee (if I have to take another check up)?":
      "ថ្លៃសេវាត្រួតពិនិត្យសុខភាព (ប្រសិនបើមានការត្រួតពិនិត្យសុខភាពមួយទៀត)?",
    "Medical check-up fee (if I have to take another check up)? is required":
      "ថ្លៃសេវាត្រួតពិនិត្យសុខភាព (ប្រសិនបើមានការត្រួតពិនិត្យសុខភាពមួយទៀត)? ចាំបាច់ត្រូវឆ្លើយ",
    "Hospitalization Insurance": "ធានារ៉ាប់រងការសំរាកព្យាបាលជម្ងឺ",
    "Other Costs": "តម្លៃផ្សេងៗទៀត",
    "Please provide any additional information on other costs that are not being covered above":
      "សូមផ្ដល់នូវព័ត៌មានបន្ថែមទៅលើតម្លៃផ្សេងទៀតដែលមិនបានរៀបរាប់ខាងលើ",
    "Please provide any additional information on other costs that are not being covered above. ":
      "សូមផ្ដល់នូវព័ត៌មានបន្ថែមទៅលើតម្លៃផ្សេងទៀតដែលមិនបានរៀបរាប់ខាងលើ",
    "Information on repatriation": "ព័ត៌មានអំពីការធ្វើមាតុភូមិនិវត្តន៍",
    "Please describe how you and the employer would arrange the repatriation of migrant workers":
      "សូមរៀបរាប់អំពីរបៀបដែលអ្នក និង និយោជក រៀបចំការធ្វើមាតុភូមិនិវត្តន៍សម្រាប់ពលករចំណាកស្រុក",
    "Please describe how you and the employer would arrange the repatriation of migrant workers after the completion of the contract, if the worker has difficulties performing the job or if workers need to return to the origin country for personal reasons.":
      "សូមរៀបរាប់អំពីរបៀបដែលអ្នក និង និយោជក រៀបចំការធ្វើមាតុភូមិនិវត្តន៍សម្រាប់ពលករចំណាកស្រុកក្រោយពីបានបញ្ចប់កិច្ចសន្យា ឬប្រសិនបើពលករមានបញ្ហាលំបាកក្នុងការធ្វើការ ឬ ពលករចាំបាច់ត្រូវត្រលប់មកប្រទេសកំណើតវិញដោយសារហេតុផលផ្ទាល់ខ្លួន",
    "Employer Name": "ឈ្មោះនិយោជក",
    "Employer Address": "អាសយដ្ឋានរបស់និយោជក",
    "Employer Size": "ទំហំនៃក្រុមហ៊ុន",
    "Number of Male Needed": "ចំនួនពលករបុរសដែលត្រូវការៈ",
    "Number of Female Needed": "ចំនួនពលករស្រីដែលត្រូវការៈ",
    "NRC Card Holder": "អ្នកកាន់កាប់អត្តសញ្ញាណបណ្ណ",
    "OWIC Card Holder": "អ្នកកាន់កាប់ប័ណ្ណពលករកម្ពុជាទៅធ្វើការនៅបរទេស (OCWC)",
    "SKILL TRAINING INFORMATION": "ព័ត៌មានអំពីការបណ្ដុះបណ្ដាលជំនាញ",
    "Who pays cost of skill-training?":
      "តើអ្នកណាជាអ្នកចំណាយទៅលើវគ្គបណ្ដុះបណ្ដាលជំនាញ?",
    "Does the employer provide accommodation during skill training?":
      "តើនិយោជកផ្ដល់ជូនកន្លែងស្នាក់នៅកំលុងពេលនៃការបណ្ដុះបណ្ដាលជំនាញដែរឬទេ?",
    "Does the employer provide meals during skill training?":
      "តើនិយោជកផ្ដល់អាហារកំលុងពេលបណ្ដុះបណ្ដាលជំនាញដែរឬទេ?",
    "Who pays transportation costs to and from for attending skill training?":
      "តើអ្នកណាជាអ្នកចំណាយលើថ្លៃធ្វើដំណើរទៅមកសម្រាប់ចូលរួមវគ្គបណ្ដុះបណ្ដាលជំនាញ?",
    "COSTS FOR PRE-DEPARTURE": "ថ្លៃរៀបចំមុនចេញដំណើរ",
    "Who pays cost of pre-departure orientation training?":
      "តើអ្នកណាជាអ្នកចំណាយលើថ្លៃក្នុងការបណ្ដុះបណ្ដាលណែនាំអំពីការរៀបចំមុនចេញដំណើរ? ",
    "Who pays for acoomodation during pre-departure orientation training?":
      "តើអ្នកណាជាអ្នកចំណាយលើការស្នាក់នៅកំលុងពេលបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ?",
    "Who pays for meals during pre-departure orientation training?":
      "តើអ្នកណាជាអ្នកចេញថ្លៃអាហារកំលុងពេលបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ?",
    "Who pays the cost of transportation to and from pre-departure orientation training?":
      "តើអ្នកណាជាអ្នកចំណាយថ្លៃធ្វើដំណើរទៅមកសម្រាប់ការបណ្ដុះបណ្ដាលណែនាំអំពីការរៀបចំមុនចេញដំណើរ?",
    "COSTS AND FEES FOR RECRUITMENT IN THE ORIGIN COUNTRY":
      "តម្លៃ និង កម្រៃសេវា សម្រាប់ការជ្រើសរើសនៅក្នុងប្រទេសដើម",
    "Who pays the recruitment service fee to RA and sub-agent?":
      "តើអ្នកណាជាអ្នកចំណាយលើថ្លៃសេវាជ្រើសរើសទៅដល់ទីភ្នាក់ងារជ្រើសរើស?",
    "Who pays travel costs for a job interview?":
      "តើអ្នកណាជាអ្នកចំណាយលើថ្លៃធ្វើដំណើរសម្រាប់មកសម្ភាសន៍ការងារ?",
    "Who pays accommodation costs for contract signing":
      "តើអ្នកណាជាអ្នកចំណាយលើថ្លៃស្នាក់នៅសម្រាប់មកចុះកិច្ចសន្យា?",
    "Who pays transportation cost to and from for contract signing?":
      "តើអ្នកណាជាអ្នកចំណាយលើថ្លៃធ្វើដំណើរទៅមកក្នុងការមកចុះកិច្ចសន្យា?",
    "Who pays for meals during contract signing?":
      "តើអ្នកណាជាអ្នកចេញថ្លៃអាហារកំលុងពេលមកចុះកិច្ចសន្យា?",
    "Who pays medical check-up fee?":
      "តើអ្នកណាជាអ្នកចំណាយលើថ្លៃពិនិត្យសុខភាព?​",
    "Who pays passport application fee?":
      "តើអ្នកណាជាអ្នកចំណាយលើការធ្វើលិខិតឆ្លងដែន?",
    "Who pays Overseas Worker Identification Card (OWIC) fee?":
      "តើអ្នកណាជាអ្នកចំណាយលើបណ្ណពលករកម្ពុជាទៅធ្វើការនៅបរទេស (OCWC)?",
    "Who pays transportation costs to the border or airport?":
      "តើអ្នកណាជាអ្នកចំណាយលើថ្លៃធ្វើដំណើរទៅកាន់ព្រំដែន ឬ ព្រលានយន្តហោះ?",
    "COSTS AND FEES FOR RECRUITMENT IN THE DESTINATION COUNTRY":
      "តម្លៃ និង កម្រៃសេវា សម្រាប់ការជ្រើសរើសនៅក្នុងប្រទេសគោលដៅ",
    "Who pays transportation cost from the border or airport to the workplace (or to the destination country)?":
      "តើអ្នកណាជាអ្នកចំណាយលើថ្លៃធ្វើដំណើរពីព្រំដែនទៅកន្លែងធ្វើការ ឬពីព្រលានយន្តហោះទៅកន្លែងធ្វើការ (ឬទៅកាន់ប្រទេសគោលដៅ)?",
    "Who pays Visa fee?": "តើអ្នកណាជាអ្នកចំណាយលើថ្លៃទិដ្ឋាការ? ",
    "Who pays work permit?": "តើអ្នកណាជាអ្នកចំណាយលើបណ្ណអនុញ្ញាតការងារ?",
    "Who pays medical check-up fee (if I have to take another check up)?":
      "តើអ្នកណាជាអ្នកចំណាយលើថ្លៃពិនិត្យសុខភាព (ប្រសិនបើពលករត្រូវពិនិត្យសុខភាពមួយទៀត)?",
    "Who pays for Hospitalization Insurance?":
      "តើអ្នកណាជាអ្នកចំណាយលើធានារ៉ាប់រងសំរាកព្យាបាលជម្ងឺ?",
    "COVID-19 RELATED COSTS AND PROCEDURES":
      "តម្លៃ និង នីតិវិធី ពាក់ព័ន្ធទៅនឹងកូវីត ១៩",
    "Who pays for the mandatory PCR test fee prior to departure?":
      "តើអ្នកណាជាអ្នកចំណាយលើការធ្វើតេស្តកូវីត១៩ មុនចេញដំណើរ?",
    "Who pays the PCR test fee on Thailand side?":
      "តើអ្នកណាជាអ្នកបង់ថ្លៃធ្វើតេស្តកូវីត-១៩ពេលមកដល់ប្រទេសថៃ?",
    "Who pays for the quarantine in the destination country?":
      "តើអ្នកណាជាអ្នកចំណាយលើការធ្វើចត្តាឡីស័កនៅប្រទេសដែលត្រូវធ្វើការ?",
    "Who pays for the Covid-19 insurance fee in the destination country?":
      "តើអ្នកណាជាអ្នកចំណាយលើថ្លៃធានារ៉ាប់រងកូវីត១៩ នៅប្រទេសដែលត្រូវធ្វើការ?",
    "Who pays for the Covid-19 vaccination fee?":
      "តើអ្នកណាជាអ្នកចំណាយលើថ្លៃចាក់វ៉ាក់សាំងកូវីត១៩?",
    "Other costs": "តម្លៃផ្សេងៗទៀត",
    "Meals Allowance": "ប្រាក់ឧបត្ថម្ភថ្លៃអាហារ",
    "Any additional leave detail":
      "ព័ត៌មានលំអិតអំពីច្បាប់ឈប់សម្រាកបន្ថែមដទៃទៀត",
    "Edit & Post": "កែ និង ដាក់បង្ហាញ",
    "Account:": "គណនី:",
    "Type:": "ប្រភេទ:",
    "Email:": "អ៊ីម៉ែល:",
    "Joined:": "បានចូលរួម:",
    "Updated:": "បានធ្វើបច្ចុប្បន្នភាព:",
    "Please Select An Employer To Fill Employer's Information":
      "សូមជ្រើសរើសនិយោជក ដើម្បីបំពេញព័ត៌មានរបស់និយោជក",
    "Please Select An Employer": "សូមជ្រើសរើសនិយោជក ",
    "Select Size": "ជ្រើសរើសទំហំ",
    "Please input title": "សូមបញ្ចូលមុខតំណែង",
    "Additional information": "ព័ត៌មានបន្ថែម",
    "Profile:": "ព័ត៌មានអំពីក្រុមហ៊ុន៖",
    "Address was successfully updated.":
      "អាសយដ្ឋានត្រូវបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ",
    "Something went wrong while updating the address. Please contact a developer":
      "មានបញ្ហាកើតឡើងនៅពេលដែលធ្វើបច្ចុប្បន្នភាពអាសយដ្ឋាន។ សូមទាក់ទងអ្នកបង្កើតកម្មវិធី",
    "Please enter Main Contact Name": "សូមបញ្ចូលឈ្មោះទំនាក់ទំនង",
    "Company name": "ឈ្មោះ ក្រុមហ៊ុន",
    "Please enter Company name": "សូមបញ្ចូលឈ្មោះក្រុមហ៊ុន",
    "Please select Working since": "សូមជ្រើសរើស ចាប់ផ្ដើមប្រតិបត្តិការតាំងពី",
    "License number": "លេខអាជ្ញាបណ្ណ:",
    "Please enter License number": "សូមបញ្ចូលលេខអាជ្ញាប័ណ្ណ",
    "Max length of the license number is 12":
      "ចំនួនលេខអតិបរមានៃលេខអាជ្ញាបណ្ណគឺ ១២ខ្ទង់",
    "Valid till": "មានសុពលភាពរហូតដល់",
    "Please select Valid till": "សូមជ្រើសរើស មានសុពលភាពរហូតដល់",
    "Thai License number": "លេខអាជ្ញាបណ្ណនៅប្រទេសថៃ:",
    "Please select Thai License Number": "សូមជ្រើសរើស លេខអាជ្ញាបណ្ណនៅប្រទេសថៃ:",
    "Thai License Expiry Date": "កាលបរិច្ឆេទផុតលេខអាជ្ញាបណ្ណនៅប្រទេសថៃ៖",
    "Please select Thai License Expiry Date":
      "សូមជ្រើសរើស កាលបរិច្ឆេទផុតលេខអាជ្ញាបណ្ណនៅប្រទេសថៃ៖",
    "Please describe the years of experience to specific industries to which countries, the years of experience to specific industries to which countries with zero costs and sub agents information":
      "សូមរៀបរាប់ចំនួនឆ្នាំនៃបទពិសោធន៍ដោយបញ្ជាក់ពីវិស័យឧស្សាហកម្ម និង ប្រទេសដែលបញ្ចូនពលករ, ចំនួនឆ្នាំនៃបទពិសោធន៍ដោយបញ្ជាក់ពីវិស័យឧស្សាហកម្ម និង ប្រទេសដែលបញ្ចូនពលករដែលមិនគិតថ្លៃសេវា ហើយនិងព័ត៌មានអំពីភ្នាក់ងារនៅតាមតំបន់ (ប្រសិនបើមាន)។ ",
    "About us": "អំពីយើង",
    "Discard changes": "លុបការផ្លាស់ប្ដូរ",
    Save: "រក្សាទុក",
    "Main contact name:": "ឈ្មោះទំនាក់ទំនង៖",
    "Company name:": "ឈ្មោះក្រុមហ៊ុន៖",
    "Working since:": "ប្រតិបត្តិការចាប់ពី៖",
    "License number:": "លេខអាជ្ញាបណ្ណ:",
    "Valid till:": "មានសុពលភាពរហូតដល់៖",
    "Thai license number:": "លេខអជ្ញាបណ្ណនៅប្រទេសថៃ",
    "Thai license expiry date:": "កាលបរិច្ឆេទផុតលេខអាជ្ញាបណ្ណនៅប្រទេសថៃ",
    "Business Images": "រូបភាពក្រុមហ៊ុន",
    "Upload Cover Photo": "ដាក់បញ្ចូលរូបថតគម្របទំព័រ",
    "Please enter email": "សូមបញ្ចូលអ៊ីម៉ែល",
    "Please enter Phone Number": "សូមបញ្ចូលលេខទូរស័ព្ទ",
    "Add more": "បន្ថែមទៀត",
    "Website:": "គេហទំព័រ៖",
    "Facebook:": "ហ្វេសប៊ុក៖",
    "Line:": "ឡាញ៖",
    "Viber:": "វ៉ាយប័រ៖",
    "Phone numbers:": "លេខទូរស័ព្ទ៖",
    "Please select Country, State, City": "សូមជ្រើសរើស ប្រទេស ខេត្ត ស្រុក",
    "Country:": "ប្រទេស៖",
    "State/Province:": "រដ្ឋ/ខេត្ត៖",
    "City/District:": "ទីក្រុង/ស្រុក៖",
    "Street:": "ផ្លូវ៖",
    "Zip:": "លេខកូដ​តំបន់៖",
    "Issara will contact you to verify that you actually the owner of this business":
      "ឥស្សរា នឹងទាក់ទងទៅអ្នក ដើម្បីផ្ទៀងផ្ទាត់ថាអ្នកពិតជាម្ចាស់អាជីវកម្មនេះមែន",
    "Successfully shortlisted the resume with job !":
      "បានសម្រាំងយកប្រវិត្តិរួបសង្ខេបជាមួយការងារដោយជោគជ័យ !",
    "Moved to general waitlist": "ប្ដូរទៅបញ្ជីឈ្មោះរង់ចាំទូទៅ",
    "Are you sure you would like to shortlist this jobseeker? This action cannot be undone later!":
      "តើអ្នកពិតជាចង់សម្រាំងយកអ្នកស្វែងរកការងារនេះមែនទេ? សកម្មភាពនេះមិនអាចសាឡើងវិញបានទេនៅពេលក្រោយ!",
    "Shortlist now": "សម្រាំងយកឥលូវនេះ",
    "Please Choose A Job to shortlist the Jobseeker":
      "សូមជ្រើសរើសការងារមួយក្នុងការសម្រាំងយកអ្នកស្វែងរកការងារ",
    "Resume Detail": "ប្រវត្ដិរូបសង្ខេបលំអិត",
    "Shortlisting...": "កំពុងសម្រាំងយក...",
    "Successfully shortlisted the resume !":
      "សម្រាំងយកប្រវត្តិរូបសង្ខេបបានដោយជោគជ័យ !",
    "Search name": "ស្វែងរកតាមឈ្មោះ",
    "Search Phone Number": "ស្វែងរកតាមលេខទូរសព្ទ",
    "Detail View": "ពិនិត្យមើលលំអិត",
    "Shortlisted At": "ត្រូវបានសម្រាំងយកនៅ",
    "Viewed At": "ត្រូវបានពិនិត្យមើលនៅ",
    "Applied resumes": "ប្រវត្តិរូបសង្ខេបនៃពាក្យស្នើរសុំ",
    "Shortlisted resumes": "ប្រវត្តិរូបសង្ខេបដែលបានសម្រាំងយក",
    "Viewed resumes": "ប្រវត្តិរូបសង្ខេបដែលបានពិនិត្យមើល",
    "Fetching...": "កំពុងទាញយក",
    "Shortlist with JOBS": "សម្រាំងយកជាមួយនឹងការងារ",
    "Are you sure you would like to shortlist ? This action cannot be undone later!":
      "តើអ្នកប្រាកដទេថាអ្នកចង់សម្រាំងយកពាក្យស្នើសុំនេះ? សកម្មភាពនេះមិនអាចសាឡើងវិញបានទេនៅពេលក្រោយ!",
    "Golden Dreams": "សុបិន្ដ ឥស្សរា",
    "Golden Dreams Marketplace ©{date} Created by {issara}":
      "ទីផ្សារការងារ សុបិន្ដ ឥស្សរា© {date} បង្កើតដោយ {issara}",
    "Privacy Policy": "គោលការណ៍​​ឯកជនភាព",
    Disclaimer: "ការបដិសេធ",
    "Issara Staff": "បុគ្គលិកឥស្សរា",
    Recruiter: "ទីភ្នាក់ងារជ្រើសរើសពលករ",
    "Golden Dreams Marketplace ©{date} Created by Issara Institute":
      "ទីផ្សារការងារ សុបិន្ដ ឥស្សរា© {date} បង្កើតដោយវិទ្យាស្ថានឥស្សរា",
    "Reset your password": "កំណត់លេខសម្ងាត់សាឡើងវិញ",
    "The input is not valid Email": "អ៊ីម៉ែលដែលបានបញ្ចូលមិនត្រឹមត្រូវទេ",
    "Please input your Email": "សូមបញ្ចូលអ៊ីម៉ែលរបស់អ្នក!",
    "Send reset link": "ផ្ញើរតំណភ្ជាប់ដើម្បីកំណត់ឡើងវិញ",
    "We will send password reset link to this email address, please change your password within 24 hours.":
      "យើងនឹងផ្ញើតំណភ្ជាប់ដើម្បីផ្លាស់ប្ដូរពាក្យសម្ងាត់ថ្មីទៅកាន់អាសយដ្ឋានអ៊ីមែលនេះ សូមផ្លាស់ប្តូរពាក្យសម្ងាត់របស់អ្នកក្នុងរយៈពេល ២៤ ម៉ោង",
    "Golden Dreams Job Marketplace": "ការងារនៅលើ ទីផ្សារការងារ សុបិន្ដ ឥស្សរា",
    "Powered by Issara Institute": "បង្កើតឡើងដោយវិទ្យាស្ថាន ឥស្សរា",
    "Register and login here to modernize and digitize how your business manages labour recruitment ->":
      "ចុះឈ្មោះ និងចូលទីនេះ ដើម្បីធ្វើទំនើបកម្ម និង អភិវឌ្ឍបច្ចេកវិទ្យា ពីរបៀបដែលអាជីវកម្មរបស់អ្នកគ្រប់គ្រងការជ្រើសរើសពលករ ->",
    Login: "ចូលទៅកាន់គណនី",
    Password: "ពាក្យសម្ងាត់",
    "Forgot Password?": "ភ្លេចពាក្យសម្ងាត់?",
    "Please input your password!": "សូមបញ្ចូលពាក្យសម្ងាត់របស់អ្នក",
    "Not registered?": "មិនទាន់បានចុះឈ្មោះ?",
    "Create an account": "បង្កើតគណនី",
    "Oops! You tried to log in with a job seeker account. Please check and try again.":
      "ឱ! អ្នកបានព្យាយាមចូលដោយប្រើគណនីស្វែងរកការងារ។ សូមពិនិត្យមើល ហើយព្យាយាមម្តងទៀត។",
    "Log in with Business Account": "ចូលដោយប្រើគណនីអាជីវកម្ម",
    "Sorry, you are not authorized to access this page.":
      "សូមអធ្យាស្រ័យអ្នកមិនត្រូវបានអនុញ្ញាតឲ្យចូលទៅកាន់គេហទំព័រនេះទេ",
    "Use another account": "ប្រើគណនីផ្សេង",
    "Go back to console": "ត្រលប់ទៅកាន់ទំព័រដើម",
    "Sorry, the page you visited does not exist.":
      "សូមអភ័យទោស ទំព័រដែលអ្នកបានចូលមើលមិនមាននោះទេ",
    "Back Home": "ថយទៅទំព័រដើម",
    "Successfully changed password": "បានប្ដូរពាក្យសម្ងាត់ដោយជោគជ័យ",
    "You'll be redirected to login page shortly":
      "អ្នកនឹងត្រូវបានបញ្ជូនបន្តទៅកាន់ទំព័រសម្រាប់ចូលទៅកាន់គណនីក្នុងពេលឆាប់ៗនេះ",
    "Go to login page": "ទៅកាន់ទំព័រសម្រាប់ចូលទៅកាន់គណនី",
    "Password reset failed": "ការកំណត់ពាក្យសម្ងាត់ឡើងវិញបានបរាជ័យ",
    "The reset link has expired. Please request a new one.":
      "តំណភ្ជាប់ផ្លាស់ប្ដូរពាក្យសម្ងាត់ថ្មីបានផុតកំណត់ហើយ។ សូមស្នើរសុំជាថ្មី",
    "New Password": "ពាក្យសម្ងាត់​ថ្មី",
    "Confirm Password": "បញ្ជាក់ពាក្យសម្ងាត់",
    "Please confirm your password!": "សូមបញ្ជាក់ពាក្យសម្ងាត់របស់អ្នក!",
    "The two passwords that you entered do not match!":
      "ពាក្យសម្ងាត់ទាំងពីរដែលអ្នកបានបញ្ចូលមិនស៊ីគ្នាទេ!",
    "Update password": "ធ្វើបច្ចុប្បន្នភាពពាក្យសម្ងាត់",
    "Create Account": "បង្កើតគណនី",
    "We are:": "ពួក​យើង​គឺជា៖",
    "Please select your business type!": "សូមជ្រើសរើសប្រភេទអាជីវកម្មរបស់អ្នក!",
    "Recruitment Agency": "ភ្នាក់ងារជ្រើសរើសពលករ",
    "Business Name": "ឈ្មោះ​អាជីវកម្ម",
    "What is your offical business name?":
      "តើឈ្មោះអាជីវកម្មផ្លូវការរបស់អ្នកគឺជាអ្វី?",
    "Please input your business name!": "សូមបញ្ចូលឈ្មោះអាជីវកម្មរបស់អ្នក!",
    "The input is not valid E-mail!": "អ៊ីម៉ែលដែលដាក់បញ្ចូលមិនត្រឹមត្រូវទេ!",
    "Please input your E-mail!": "សូមបញ្ចូលអ៊ីម៉ែលរបស់អ្នក!",
    "Create your free account": "បង្កើតគណនីឥតគិតថ្លៃរបស់អ្នក",
    "By clicking register, you agree to our {privacy_policy}.":
      "តាមរយៈការចុចចុះឈ្មោះអ្នកយល់ព្រមទៅនឹង {privacy_policy}  របស់យើង ",
    "privacy policy": "គោលការណ៍​ឯកជនភាព",
    "Already have an account?": "តើអ្នកមានគណនីហើយឬនៅ?",
    "Login here": "ចូលទៅកាន់គណនីនៅទីនេះ",
    "Email verification in progress..": "ការផ្ទៀងផ្ទាត់អ៊ីម៉ែលកំពុងដំណើរការ ..",
    "Successfully verified email": "បានផ្ទៀងផ្ទាត់អ៊ីម៉ែលដោយជោគជ័យ",
    "Please follow further instructions in the console":
      "សូមធ្វើតាមការណែនាំបន្ថែមនៅក្នុងទំព័រដើម",
    "Go to console": "ចូលទៅកាន់ទំព័រដើម",
    "Email verification failed": "ការផ្ទៀងផ្ទាត់អ៊ីម៉ែលបានបរាជ័យ",
    "This link has already expired.": "តំណភ្ជាប់នេះបានផុតកំណត់រួចហើយ",
  },
  options: {},
  mm: {
    "Update is available": "ဗားရှင်းအသစ်ထွက်ပါပြီ။ အသုံးပြုရန်တစ်ချက်နှိပ်ပါ။ ",
    "Please press OK to refresh the app.":
      "အပ်ပလီကေးရှင်းကို အသစ်ပြန်စရန် OK ကိုနှိပ်ပေးပါ။",
    "Loading..": "လုပ်ဆောင်နေဆဲ",
    Male: "ကျား",
    Female: "မ",
    Anonymous: "",
    "Prefer not to say": "မဖော်ပြလိုပါ",
    "Non-Binary": "",
    "Passport Include": "ပါစ်စပို့ မတွဲထားသူများ",
    "Passport Not Include": "ပါစ်စပို့ ပုံတွဲမထားသူများ",
    Myanmar: "မြန်မာ",
    Thailand: "ထိုင်း",
    Cambodia: "ကမ္ဘောဒီးယား",
    Laos: "လာအို",
    Nepal: "နီပေါ",
    Indonesia: "အင်ဒိုနီးရှား",
    Indian: "အိန္ဒိယလူမျိုး",
    Bangladeshi: "ဘင်္ဂလားဒေ့ရှ်",
    Others: "အခြား",
    Sunday: "တနင်္ဂနွေ",
    Monday: "တနင်္လာ",
    Tuesday: "အင်္ဂါ",
    Wednesday: "ဗုဒ္ဓဟူး",
    Thursday: "ကြာသာပဒေး",
    Friday: "သောကြာ",
    Saturday: "စနေ",
    "It Will Vary": "အမျိုးမျိုးပြောင်းလဲနိုင်သည်",
    Monthly: "လစဉ်",
    "Daily Wage Rate": "နေ့စားခ နှုန်းထား",
    "Once A Month": "တစ်လ တစ်ခါ",
    "Twice A Month": "တစ်လ နှစ်ခါ",
    "Every Week": "တစ်ပတ်တစ်ကြိမ်",
    "Every 10 days": "၁၀ရက် တစ်ကြိမ်",
    Other: "အခြား",
    Regularly: "ပုံမှန် ",
    "From time to time": "တစ်ခါတစ်ရံ",
    Infrequently: "မကြာခဏ",
    Never: "ဘယ်တော့မှ",
    Day: "နေ့",
    Swing: "တစ်လှည့်စီ ပြောင်းလဲ နိုင်သည်",
    Night: "ည",
    Rotating: "အလှည့်ကျ",
    Variable: "အမျိုးမျိုးဖြစ်နိုင်သည်",
    "Full-time": "အချိန်ပြည့်",
    "Part-time": "အချိန်ပိုင်း",
    "Moved General Waitlist": "ရွှေ့ထားသော စောင့်ဆိုင်းစာရင်းများ",
    Waitlisted: "စောင့်ဆိုင်းစာရင်းမှာ ရွေးချယ်ထားလိုက်ပါပြီ",
    "Contract Signed": "စာချုပ်မှာ လက်မှတ်ထိုးပြီးပြီ။",
    Accepted: "လက်ခံခဲ့သည်",
    Applied: "လျှောက်ထားပြီး",
    Downloaded: "ဒေါင်းလုပ်ချပြီးပါပြီ",
    Offered: "ကမ်းလှမ်းခဲ့သည်",
    Rejected: "မရွေးချယ်ခဲ့ပါ",
    Shortlisted: "ပထမအဆင့်​ရွေးချယ်ထားလိုက်ပါပြီ",
    Viewed: "ကြည့်ရှုပြီး",
    "Documents Requested": "စာရွက်စာတမ်းအထောက်အထားများကြည့်ရန်တောင်းထားသည်",
    "Documents request accepted":
      "စာရွက်စာတမ်းအထောက်အထားများကြည့်ရန်တောင်းထားသည်ကို အလုပ်လျှောက်သူမှ လက်ခံလိုက်ပါပြီ",
    "Documents request rejected":
      "စာရွက်စာတမ်းအထောက်အထားများကြည့်ရန်တောင်းထားသည်ကို အလုပ်လျှောက်သူမှ လက်မခံပါ",
    "HR Assistant": "HR လက်ထောက်",
    "HR Interpreter": "HR စကားပြန်",
    "HR Manager": "HR မန်နေဂျာ",
    "HR Officer": "HR အရာရှိ",
    "Candidate pays fees in advance, then is reimbursed by company after starting work":
      "လုပ်သားမှ ဦးစွာစရိတ်ကျခံကာ အလုပ်ဝင်ပြီး နောက်ပိုင်းမှ လုပ်သားအား ကုန်ကျစရိတ် ပြန်ပေးခြင်း",
    "Company pays all fees in advance":
      "အလုံးစုံကုန်ကျစားရိတ် ကို ကုမ္မဏီ မှ ကြိုတင် ကျခံပေးခြင်း",
    "India (+91)": "",
    "Myanmar (+95)": "မြန်မာ (+၉၅)",
    "Nepal (+977)": "နီပေါ (+၉၇၇)",
    "Thailand (+66)": "ထိုင်း (+၆၆)",
    "Malaysia (+60)": "မလေးရှား (+၆၀)",
    "Cambodia (+855)": "ကမ္ဘောဒီးယား (+၈၅၅)",
    "A Little Bit": "အနည်းငယ်",
    Moderately: "အလယ်အလတ်",
    "Very Well": "ကောင်းကောင်း",
    None: "မပြောတတ်ပါ",
    Some: "နည်းနည်းပြောတတ်ပါသည်",
    Proficient: "ကောင်းကောင်းပြောတတ်ပါသည်",
    English: "အင်္ဂလိပ် ဘာသာ",
    Thai: "ထိုင်း ဘာသာ",
    "Bahasa Malaysia": "မလေးရှား ဘာသာ",
    Hindi: "ဟင်ဒီဘာသာစကား",
    "Cannot read": "ဖတ်မတတ်ပါ",
    APPLICABLE: "အလုပ်လျှောက်လွှာ‌ပို့ရန်ကြည့်မည်",
    APPLIED: "လျှောက်ထားပြီး",
    VIEWED: "အလုပ်လျှောက်လွှာကြည့်ပြီးသူ",
    DOWNLOADED: "ဒေါင်းလုတ်ဆွဲထားပြီး",
    SHORTLISTED: "ပထမဦးစားပေးအဆင့်မှာ ရွေးထားပြီးသူ",
    OFFERED: "အလုပ်ကမ်းလှမ်းထားပြီးသူ",
    ACCEPTED: "အလုပ်လက်ခံထား",
    REJECTED_BY_BUSINESS: "အလုပ်ရှင်မှ ငြင်းပယ်ထားသည့်စာရင်း ",
    REJECTED_BY_APPLICANT:
      "လျှောက်လွှာတင်ထားသူကိုယ်တိုင်ငြင်းပယ်ထားသည့်စာရင်း  ",
    CONTRACT_SIGNED: "စာချုပ်ချုပ်ဆိုထားသည့်စာရင်း  ",
    APPLICANT_NA: "လျှောက်လွှာတင်ထားသူမရှိပါ ",
    WAITLISTED: "စောင့်ဆိုင်းစာရင်း",
    MOVED_GENERAL_WAITLIST: "အထွေထွေ စောင့်ဆိုင်းစာရင်း မှာ",
    "STATUS N/A": "လက်ရှိအနေအထား (မရှိ)  ",
    "Cannot speak": "ပြောမတတ်ပါ",
    "Basic Information": "အခြေခံ အချက်အလက်",
    Gender: "ကျား/မ",
    "Date of Birth": "မွေးနေ့",
    "Marital Status": "အိမ်ထောင် ရှိ/မရှိ",
    Language: "ဘာသာစကား",
    Education: "ပညာအရည်အချင်း",
    "Phone number": "ဖုန်းနံပါတ်",
    Email: "အီးမေးလ်",
    Address: "လိပ်စာ",
    "National ID": "နိုင်ငံသားစီစစ်ရေးအမှတ်",
    "Passport Number": "ပါတ်စ်စပို့ နံပါတ်",
    "Expires at": "ပတ်စပို့သက်တမ်းကုန်ဆုံးရက်",
    "Measurements & Health": "ခန္ဓာ အနေအထား နှင့် ကျန်းမာရေး",
    Weight: "အလေးချိန်",
    kg: "ကီလိုဂရမ်",
    lb: "ပေါင်",
    Height: "အရပ်အမြင့်",
    cm: "စင်တီမီတာ",
    ft: "ပေ",
    in: "လက်မ",
    "Have undergone medical operation recently":
      "မကြာသေးခင်က ခွဲစိတ်မှု ပြုခဲ့သည်",
    yes: "ရှိသည်",
    no: "မရှိပါ",
    "Work Experience": "အလုပ်အတွေ့အကြုံ",
    "{duration} years": "{duration} နှစ်",
    "Advanced Search": "ပိုမိုရှာဖွေရန်",
    "Workers who have more than 12 months of experience in this industry":
      "ဤလုပ်ငန်းတွင်အတွေ့အကြုံ ၁၂ လကျော်ရှိသောအလုပ်သမားများ",
    Industry: "လုပ်ငန်း",
    "Workers {language} proficiency":
      "အလုပ်သမား၏ {language} ဘာသာစကားကျွမ်းကျင်မှု",
    "Language Proficiency": "ထိုင်းစကားပြောတတ်ပါသလား",
    "Workers current location": "အလုပ်သမား၏လက်ရှိတည်နေရာ",
    Province: "ပြည်နယ် / ခရိုင်",
    "Application has been rejected": "အလုပ်လျှောက်ထားခြင်းကိုငြင်းပယ်လိုက်သည်",
    "An error occurred while rejecting application.":
      "အလုပ်လျှောက်လွှာကိုငြင်းပယ်နေစဉ်တစ်ခုခုအမှားဖြစ်သွားပါသည်",
    "A request for documents has been sent.":
      "အလုပ်လျှောက်သူ၏စာရွက်စာတမ်းများအားကြည့်ခွင့်ပေးရန် အကြောင်းကြားပြီးပါပြီ",
    "An error occurred while sending request for documents.":
      "အလုပ်လျှောက်သူ၏စာရွက်စာတမ်းများအားကြည့်ခွင့်ပေးရန် အကြောင်းကြားစဉ် အမှားဖြစ်သွားပါသည်",
    "Job offer has been successfully sent.":
      "အလုပ်ကမ်းလှမ်းစာကိုအောင်မြင်စွာပေးပို့ပြီးပါပြီ",
    "An error occurred while sending job offer.":
      "အလုပ်ကမ်းလှမ်းစာ ပေးပို့ ရာတွင် အမှားအယွင်းတစ်စုံတစ်ရာ ဖြစ်သွားပါသည်။",
    "Application has been shortlisted":
      "အလုပ်လျှောက်လွှာကို  ပထမအဆင့်​ရွေးချယ်သည့်စာရင်းတွင် ထည့်ထားလိုက်ပြီ",
    "An error occurred while shortlisting application.":
      "အလုပ်လျှောက်လွှာကို ပထမအဆင့်​ရွေးချယ်သည့်စာရင်းတွင်ထည့်နေစဉ် အမှားတစ်စုံ တစ်ရာဖြစ်ပေါ်သည်",
    "Working Experience": "အလုပ်အတွေ့အကြုံ",
    "{experience} years": "{experience}  နှစ်",
    View: "ကြည့်မည်",
    "Are you sure you would like to shortlist the application? This action cannot be undone later.":
      "ဤအလုပ်လျှောက်လွှာကို ပထမအဆင့်​ရွေးချယ်သည့်စာရင်းတွင်ထည့်ရန် သေချာပါသလား။ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန် ပြင်၍ မရပါ။",
    Continue: "ရှေ့ဆက်မည်",
    Cancel: "ပါယ်ဖျက်သည်",
    Shortlist: "ဆန်ခါတင်စာရင်းထဲထည့်မည်",
    "Are you sure you would like to send the job offer? This action cannot be undone later.":
      "အလုပ်ကမ်းလှမ်းစာပို့ရန်သေချာပါသလား။ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန် ပြင်၍ မရပါ။",
    "Send offer": "အလုပ်ကမ်းလှမ်းစာ ပေးပို့ မည်",
    "Are you sure you would like to request the documents? This action cannot be undone later":
      "စာရွက်စာတမ်းအထောက်အထားများကြည့်ရန်  အလုပ်လျှောက်သူထံမှတောင်းရန်သေချာပါသလား။ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန် ပြင်၍ မရပါ။",
    "Request documents": "အထောက်အထားစာရွက်စာတမ်းကြည့်ရန်တောင်းမည်",
    "Please select one of the reasons below.":
      "အောက်ပါ အကြောင်းပြချက် အနက် တစ်ခုကို ရွေးချယ်ပါ",
    "Other rejection reason": "မရွေးချယ်ခြင်း ဆိုင်ရာ အခြားအကြောင်းပြချက်",
    Reject: " မရွေးချယ်ပါ",
    new: "အသစ်",
    "{number} documents attached": "ပူးတွဲတင်ထားသောစာရွက်စာတမ်း {number} မျိုး",
    "Documents requested {timeAgo}":
      "{timeAgo}က စာရွက်စာတမ်းအထောက်အထားများကြည့်ရန်တောင်းထားသည်",
    Yes: "ဟုတ်သည်",
    No: "မဟုတ်ပါ",
    From: "မှ",
    To: "သို့",
    Passport: "ပါတ်စ်စပို့ နံပါတ်",
    Included: "ပါ၀င်သော",
    "Application has been waitlisted successfully and sent to application #{id}.":
      "သင့်အလုပ်လျှောက်လွှာကို လျှောက်လွှာအမှတ်စဥ် (ID)  #{id} အဖြစ် စောင့်ဆိုင်းစာရင်းမှာ မှတ်ထားပေးပြီပါပြီ။",
    "An error occurred while waitlisting the application #{id}.":
      "အလုပ်လျှောက်လွှာ အမှတ်စဥ်#{id} ကို စောင့်ဆိုင်းစာရင်းသွင်းနေစဥ်မှာ နည်းပညာပိုင်းဆိုင်ရာ ချို့ယွင်းချက်ဖြစ်ပေါ်သွားသည်။",
    "Job offer Expiry Date has been successfully updated.":
      "အလုပ်ကမ်းလှမ်းမှု ရက်စွဲကုန်ဆုံးရက်ကို နောက်ထပ်သက်တမ်းတိုးထားပြီးပါပြီ။",
    "Roster Application": " ကြိုတင်ထားသော အလုပ်လျှောက်လွှာများ",
    "Offered will Expire at: ": "အလုပ်ကမ်းလှမ်းမှုကုန်ဆုံးမည့်ရက်: ",
    "Update Expiry Date": "ကုန်ဆုံးရက်စွဲ ကိုသက်တမ်းတိုးမည်",
    "Are you sure you would like to move the selected applicants to waitlist? This action cannot be undone later.":
      "ယခုမှတ်ထားသောအလုပ်လျှောက်လွှာကို စောင့်ဆိုင်း စာရင်းထဲထည့်ရန် သေချာပါသလား။ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန် ပြင်၍ မရပါ။",
    "Move to waitlist": "စောင့်ဆိုင်းစာရင်းထဲကို ရွေ့ပါမည်။",
    "Quick Filter": "အမြန် စိစစ်သည်",
    "Select Available Shift": "အလုပ်ဝင်နိုင်သော အဆိုင်းကို ရွေးပါ",
    "{number} matches": "ကိုက်ညီမှုရှိသူ {number} ဦး",
    "Select city": "မြို့ရွေးချယ်ပါ",
    "Select country": "နိုင်ငံရွေးချယ်ပါ",
    "Select currency": "ငွေကြေးရွေးချယ်ပါ",
    "Select Day of Week Off": "တစ်ပတ်တွင်ရမည့်နားရက် ရွေးပေးပါ",
    "Select Level": "ပညာအရည်အချင်းကိုရွေးပါ",
    "Select policy type": "မူဝါဒအမျိုးအစားကိုရွေးချယ်ပါ",
    "Select employer": "အလုပ်ရှင် ရွေးချယ်ပါ",
    "Other/not listed here": "အခြား/ ဤ နေရာတွင် မပါရှိပါ",
    "Select size": "လုပ်ငန်းအရွယ်အစားရွေးပေးပါ",
    "Select Industry": "လုပ်ငန်းအမျိုးအစားရွေးပေးပါ",
    "Select position category": "ရာထူးအမျိုးအစားရွေးပေးပါ",
    "Select job type": "အလုပ် အမျိုးအစား ရွေးချယ်ပါ",
    "Select level": "အဆင့်ရွေးချယ်ပါ",
    "Select Language": "ဘာသာစကား ရွေးချယ်ပါ",
    "Select Status": "အိမ်ထောင်ရေးအခြေအနေကိုရွေးပါ",
    "Select Overtime Expectations": "အချိန်ပိုအလုပ်ချိန်ရနိုင်မှု ရွေးပေးပါ",
    "Select terms": "လုပ်ခပေးမည့်ပုံစံရွေးချယ်ပါ",
    "Select timing": "လုပ်ခထုတ်ပေးမည့်အချိန်ရွေးချယ်ပါ",
    "Select Reason": "အလုပ်လျောက်လွှာကို မရွေးချယ်ရသည့်အကြောင်းအရင်းကိုရွေးပါ",
    "Select state": "ပြည်နယ် ရွေးချယ်ပါ",
    "Select industry": "လုပ်ငန်းအမျိုးအစားရွေးပေးပါ",
    Verification: "စမ်းစစ်အတည်ပြုခြင်း",
    Account: "အကောင့်",
    "My jobs": "အလုပ်များ",
    "Post new": "အလုပ်အသစ်တင်မည်",
    Logout: "အကောင့်ထွက်မည်",
    Metrics: "ဇယား",
    Surveys: "စစ်တမ်း",
    "Verify RA": "အတည်ပြုထားသည့်အေဂျင်စီများ",
    "Verify Employers": "အတည်ပြုထားသည့် လုပ်ငန်းရှင်များ",
    "My account": "ရွှေအိပ်မက်အကောင့်",
    Profile: "ကုမ္ပဏီအချက်အလက်",
    Resume: "အလုပ်လျှောက်လွှာ (ကိုယ်ရေး အချက်အလက်)",
    Roster: "အလုပ်လျှောက်လွှာCVများကိုမှတ်ထားသောစာရင်း",
    Waitlist: "စောင့်ဆိုင်းစာရင်း",
    "This business is already managed by another user. Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "ဒီလုပ်ဆောင်ချက်ကို အခြားအသုံးပြုသူတစ်ယောက ထိန်းချုပ်ထားပါသည်။ ဒီအေဂျင်စီ နာမည်နဲ့ ‌စုစည်းရန် သေချာပြီလား။ ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန်ပြင်၍ မရပါ!",
    "This business is managed by Issara. Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "ဒီလုပ်ဆောင်ချက်ကို အစ်ဆာရာကနေ ထိန်းချုပ်ထားပါသည်။ ဒီအေဂျင်စီ နာမည်နဲ့ ‌စုစည်းရန် သေချာပြီလား။ ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန်ပြင်၍ မရပါ!",
    "Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "ဒီအေဂျင်စီနာမည်နဲ့ စုစည်းရန်သေချာပါသလား။ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန်ပြင်၍ မရပါ!",
    "Yes. Merge": "ပေါင်းစည်းမည် ",
    "This business is already in our database. Merge it.":
      "ဒီကုမ္ပဏီ ရဲ့ အချက်အလက်များ  ဒေတာ ဘေ့ (မှတ်တမ်း) လုပ်ထားပြီးသားဖြစ်လို့ ပေါင်းစည်းပါမည်။",
    Merge: "ပေါင်းစည်းမည်",
    Name: "အမည်",
    "Managed By": "Managed By ",
    "Businesses have been successfully merged":
      "ကုမ္ပဏီ အချက်အလက် ၂ ခုကို အောင်မြင်စွာ စုစည်းလိုက်ပါပြီ",
    "An error occurred while merging. Please contact a developer.":
      "ကုမ္ပဏီအချက်အလက်များကို စုစည်းရန် ပြင်ဆင်နေစဉ် အမှားဖြစ်သွားပါသည်၊ ကျေးဇူးပြု၍ ရွှေအိပ်မက် သို့ ဆက်သွယ်ပါ",
    "Search ...": "ရှာဖွေနေသည်.....",
    "No employers found": "ရှာမတွေ့ပါ",
    "recruiter loaded": "လုပ်သားစုစောင်းသူစာရင်း များယူပေးပြီးပါပြီ",
    "employers loaded": "အလုပ်ရှင်များ စာရင်း ယူပေးပြီးပါပြီ",
    "Load More employers": "နောက်ထပ်ကြည့်ရန်",
    "License Number": "လုပ်ငန်း လိုင်စင် အမှတ် ",
    "No recruiters found": "လုပ်သားစုစောင်းသူများ မရှိပါ",
    "recruiters loaded": "လုပ်သားစုစောင်းသူများ ",
    "Load More Recruiters": "နောက်ထပ်ကြည့်ရန်",
    "Delete Cover Photo": "ကာဗာဓါတ်ပုံပြန်ဖျက်မည်",
    "Add Cover Photo": "ကာဗာဓါတ်ပုံတင်ရန်ဤနေရာကိုနိုပ်ပါ",
    "Uploading....": "လုပ်ဆောင်နေဆဲ",
    "Upload Cropped Cover Photo": "ဖြတ်ထားသောလုပ်ငန်းကာဗာပုံကိုတင်ရန်",
    "Delete This Photo": "ဤဓါတ်ပုံကိုပြန်ဖျက်မည်",
    "Available image upload": "တင်လို့ရသေးသောဓါတ်ပုံအရေအတွက်",
    "Add business image": "ဓါတ်ပုံတင်ပါ",
    "Upload Cropped Image": "ဖြတ်ထားသောပုံကိုတင်ရန်",
    "Password reset link was successfully sent to {email}. Please check your inbox.":
      "လျို့ဝှက်ကုတ် ပြောင်းလဲရမည့်လင့်ခ်ကို {email} သို့အောင်မြင်စွာပို့ပြီးပါပြီ။ ကျေးဇူးပြု၍ သင်၏အီးမေးလ်ကိုစစ်ပေးပါ။",
    "Profile has been successfully updated.":
      "ကုမ္ပဏီအချက်အလက်များကို အောင်မြင်စွာ ပြင်ဆင်ပြီးပါပြီ",
    "An error occurred while updating profile. Please contact a developer.":
      "ကုမ္ပဏီအချက်အလက်များကိုပြင်ဆင်နေစဉ် အမှားဖြစ်သွားပါသည်၊ ကျေးဇူးပြု၍ ရွှေအိပ်မက် သို့ ဆက်သွယ်ပါ",
    "Please use one of the following formats: .jpeg, .jpg or .png":
      "ဤ အမျိုးအစားများထဲမှ တစ်ခုကို သာ အသုံးပြုပါ jpeg, .jpg or .png",
    "Push notifications have been successfully enabled":
      "အသိပေးချက်ရယူရန် လုပ်ဆောင်ခြင်း အောင်မြင်ပါပြီ",
    "Sample Notification": "အချက်ပြစာနိုတီ နမူနာ",
    "You will receive me whenever new job application submitted or content added.":
      "သတင်းစဉ်သစ် တင်တိုင်း၊ အလုပ်လျှောက်လွှာသစ်ဝင်တိုင်း အချက်ပြစာနိုတီရရှိပါမည်",
    "If you blocked push notifications, you can only undo it in your browser settings.":
      "အချက်ပြစာနိုတီရရှိခြင်းကိုပိတ်ထားခဲ့ပါက ဘရောက်ဆာ ဆက်တင် တွင်သာ ပြုပြင် ပြောင်းလဲ နိုင်သည်",
    "Push notifications have been successfully disabled":
      "အချက်ပြစာနိုတီပေးပို့ခြင်းကို အောင်မြင်စွာပိတ်ထားလိုက်ပါပြီ",
    "Verified Business": "အတည်ပြုထားသောလုပ်ငန်း",
    Type: "အမျိုးအစား",
    Employer: "လုပ်ငန်း",
    Joined: "စတင်အသုံးပြုခဲ့သည်",
    Updated: "ပြင်ဆင်ခဲ့သည်",
    "Push notifications are not supported.":
      "အချက်ပြစာနိုတီများကို ပံ့ပိုးမထားပါ",
    "Push notifications": "အသိပေးချက် ပေးပို့မည်",
    "Are you sure you want to reset the password?":
      "လျို့ဝှက်ကုတ် ပြောင်းမှာ သေချာပြီလား",
    "The reset link will be sent to this email address: {email}":
      "လျို့ဝှက်ကုဒ်ပြန်လည်သတ်မှတ်ရမည့်လင့်ခ်ကိုဤအီးမေးလ်လိပ်စာ {email} သို့ပို့လိုက်ပါပြီ",
    Reset: "ပြန်လည် သတ်မှတ်မည်",
    "Reset password": "လျို့ဝှက်ကုတ် ပြန်လည် သတ်မှတ်မည်",
    "Enable view mode": "အချက်အလက်ကြည့်ရန် အနေအထားသို့ပြောင်းမည်",
    "Enable edit mode": "အချက်အလက်များပြင်ဆင်မည်",
    "Basic information": "အခြေခံ အချက်အလက်",
    Logo: "အမှတ်တံဆိပ်",
    "Update logo": "အမှတ်တံဆိပ်အသစ်တင်မည်",
    "Company Name ({lang})": "ကုမ္မဏီ အမည်({lang})",
    "Please fill in the company name": "ကုမ္မဏီ အမည်ဖြည့်ပေးပါ",
    "Company Name": "ကုမ္မဏီ အမည်",
    "Working since": "လုပ်ငန်းစတင်ခဲ့သည်",
    "Registration number": "လုပ်ငန်းမှတ်ပုံတင်အမှတ်",
    "Max length of the registration number is 12":
      "လုပ်ငန်းမှတ်ပုံတင်အမှတ်တွင် ဂဏန်းအများဆုံး၁၂လုံးထိသာရှိပါသည်",
    "Example: 027/2019": "ဥပမာ ၀၂၇/ ၂၀၁၉",
    Size: "လုပ်ငန်းအရွယ်အစား",
    "About us ({lang})": "ကျွနု်ပ် တို့ အကြောင်း({lang})",
    "Contact information": "ဆက်သွယ်ရန်",
    "Main contact name": "အဓိကဆက်သွယ်ရမည့်သူအမည်",
    "Main contact email": "အဓိက ဆက်သွယ်ရန် အီးမေးလ်",
    "This is not a valid email.": "ဤအီးမေးလ်လိပ်စာ မမှန်ပါ",
    "Website (URL)": "ဝက်ဘ် ဆိုက် ( URL)",
    "Website(URL) format is incorrect": "ဝက်ဘ် ဆိုက် ( URL) လိပ်စာ မမှန်ပါ",
    "Facebook (URL)": "ဖေ့စ်စဘုတ်( URL )",
    "Facebook url format is incorrect": "ဖေ့စ်စဘုတ်( URL ) လိပ်စာမမှန်ပါ",
    "Viber (phone number)": "ဘိုင်ဘာ နံပါတ် (phone number)",
    "Please use the following format: +999999999":
      "ဖော်ပြပါ ပုံစံအား အသုံးပြုပါ +999999999",
    "Line (ID)": "လိုင်းစိမ်း ( ID)",
    "Line format is incorrect": "လိုင်းစိမ်း ပုံစံ မမှန်ပါ",
    "Phone numbers": "ဖုန်းနံပါတ်",
    "Add new": "အသစ်ဖြည့်စွက်ပါ",
    "Country, State, City": "နိုင်ငံ ပြည်နယ်/ တိုင်း  မြို့",
    "Apt, Building, Street ({lang})": "အိမ်အမှတ် / တိုက်အမည်/ လမ်း  ({lang})",
    "Please fill in the address details": "လိပ်စာအပြည့်အစုံဖြည့်ပေးပါ",
    Zip: "စာပို့သင်္ကေတ အမှတ်",
    "Update Business Profile":
      "ကုမ္ပဏီအချက်အလက်အကြောင်းကို မှတ်သားသိမ်းဆည်းမယ်",
    Description: "လုပ်ငန်းအကြောင်းဖော်ပြချက်",
    "Contact email": " ဆက်သွယ်ရန် အီးမေးလ်",
    Website: "ဝက်ဘ် ဆိုက် ",
    Facebook: "ဖေ့စ်စဘုတ်",
    Viber: "ဘိုင်ဘာ နံပါတ်",
    Line: "လိုင်းစိမ်း ",
    Country: "နိုင်ငံ",
    State: "ပြည်နယ်",
    City: "မြို့",
    "Apt, Building, Street": "အိမ်အမှတ် / တိုက်အမည်/ လမ်း",
    "Application Details:": "လျှောက်လွှာ အသေးစိပ် အချက်အလက်များ",
    Back: "နောက်သို့ပြန်သွားမည်",
    "Full Name": "အမည် အပြည့် အစုံ",
    "Phone Number": "ဖုန်းနံပါတ်",
    "Current Address": "လက်ရှိနေရပ်လိပ်စာ",
    "Passport Expiry Date": "ပါတ်စပို့သက်တမ်းကုန်ဆုံးရက်",
    "After going through the job description, do you think you can do the job well?":
      "အလုပ်နဲ့ပတ်သက်တဲ့အချက်အလက်​တွေကို​သေ​သေချာချာဖတ်ပြီးသွားပြီဆို​တော့ ဒီအလုပ်ကို​ကောင်းကောင်းလုပ်နိုင်မယ်လို့ ထင်သလား။",
    "Do you know someone working here?":
      "အခုလျှောက်တဲ့အလုပ်မှာ လုပ်နေတဲ့ဆွေမျိုး၊သူငယ်ချင်း၊ အသိမိတ်ဆွေရှိပါသလား။",
    "Languages Proficiency": "ထိုင်းစကားပြောတတ်ပါသလား",
    "Work experience": "အလုပ်အတွေ့အကြုံ",
    Duration: "ကြာချိန်",
    Remark: "မှတ်ချက်",
    "Uploaded Documents": "တင်ထားသောစာရွက်စာတမ်းအထောက်အထားများ",
    Pinkcard: "ပန်းရောင်ကတ်",
    Workpermit: "ဝေါ့ခ်ပါမစ်",
    Visa: "ဗီဇာ",
    "Cancellation Letter": "အလုပ်ပယ်ဖျက်စာ",
    "Download/Print": "ဒေါင်းလုပ်ဆွဲမည်/ ပရင့်ထုတ်မည်",
    "Are you sure you would like to request the documents? This action cannot be undone later.":
      "စာရွက်စာတမ်းအထောက်အထားများကြည့်ရန်  အလုပ်လျှောက်သူထံမှတောင်းရန်သေချာပါသလား။ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန် ပြင်၍ မရပါ။",
    "Application #{id} has been successfully rejected":
      "အလုပ်လျှောက်ထားသည့် #{id}ကိုငြင်းပယ်လိုက်သည်",
    "A request for documents for application #{id} has been sent":
      "အလုပ်လျှောက်ထားသည့် #{id} အား သူ၏စာရွက်စာတမ်းအထောက်အထားများပေးပို့ရန် အကြောင်းကြားပြီးပါပြီ",
    "Application #{id} has been successfully shortlisted":
      "အလုပ်လျှောက်ထားသည့် #{id} ကို ပထမအဆင့်​ရွေးချယ်ထားလိုက်ပြီ",
    "An error occurred while shortlisting application #{id}.":
      "အလုပ်လျှောက်ထားသည့် #{id} ကို ပထမအဆင့်​ရွေးချယ်သည့်စာရင်းထဲ ထည့်နေစဉ် အမှားတစ်စုံ တစ်ရာဖြစ်ပေါ်သွားသည်",
    "Job offer has been successfully sent to application #{id}.":
      "အလုပ်လျှောက်ထားသည့် #{id}အတွက် အလုပ်ကမ်းလှမ်းစာကိုအောင်မြင်စွာပေးပို့ပြီးပါပြီ",
    "An error occurred while sending job offer to application #{id}.":
      "အလုပ်လျှောက်ထားသည့် #{id}အတွက် အလုပ်ကမ်းလှမ်းစာကိုပေးပို့နေစဉ် အမှားတစ်စုံ တစ်ရာဖြစ်ပေါ်သွားသည်",
    "View Applications": "အလုပ်လျှောက်လွှာများကိုကြည့်မည်",
    "Pending ({count})": "စောင့်နေသူ ({count}) ယောက်",
    "Shortlisted ({count})": "ပထမအဆင့်​ရွေးချယ်ထားသူ ({count})ယောက်",
    "Offered ({count})": "အလုပ်ကမ်းလှမ်းခံရသူ ({count}) ယောက်",
    "Rejected by applicant ({count})": "အလုပ်လျှောက်သူမှငြင်းပါယ်ပြီး({count})",
    "Rejected by business ({count})": "အလုပ်ရှင်မှငြင်းပါယ်င်္ပြး({count})",
    "Accepted ({count})": "အလုပ်ကမ်းလှမ်းသည်ကိုလက်ခံထားသူ{count})ယောက်",
    "Select all ({number})": "({number})ယောက် အားလုံးကိုမှတ်မည်",
    "Deselect all ({number})": "({number})ကို ပြန်မမှတ်တော့ပါ ",
    "Reject ({number})": " ({number}) ယောက်ကိုငြင်းပယ်မည်",
    "Are you sure you would like to shortlist selected applications? This action cannot be undone later":
      "ယခုမှတ်ထားသောအလုပ်လျှောက်လွှာကို ပထမအဆင့်​ရွေးချယ်သည့်စာရင်းထဲထည့်ရန် သေချာပါသလား။ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန် ပြင်၍ မရပါ။",
    "Shortlist ({number})": "ပထမအဆင့်​ရွေးချယ်သည့်စာရင်း   ({count})ယောက်",
    "Request documents ({number})":
      "စာရွက်စာတမ်းအထောက်အထားကြည့်ရန်တောင်းထားသူအရေအတွက် ({number}) ယောက်",
    "Are you sure you would like to send the job offer to selected applicants? This action cannot be undone later.":
      "ယခုမှတ်ထားသောအလုပ်လျှောက်ထားသူများဆီသို့ အလုပ်ကမ်းလှမ်းစာပို့ရန်သေချာပါသလား။ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန် ပြင်၍ မရပါ။",
    "Send offer ({number})": "({number}) ကိုအလုပ်ကမ်းလှမ်းစာ ပေးပို့ မည်",
    "Your job has been successfully posted":
      "အလုပ်ကြော်ငြာကိုအောင်မြင်စွာတင်ထားလိုက်ပါပြီ",
    "View posted jobs": "ကြော်ငြာ ထားသည့် အလုပ်များကို ကြည့်မည်",
    "Post new job": "အလုပ်ခေါ်စာအသစ် တင်မည်",
    "An error occurred while saving draft.":
      "အလုပ်ခေါ်စာ စာကြမ်းကို သိမ်းဆည်းနေစဉ်အမှားဖြစ်သွားပါသည်",
    "Job has been successfully saved as a draft":
      "အလုပ်ခေါ်စာ စာကြမ်းကို အောင်မြင်စွာသိမ်းဆည်းလိုက်ပါပြီ",
    "Create new job": "အလုပ်ခေါ်စာသစ် ဖန်တီးမည်",
    "View drafts": "စာကြမ်း ကြည့်ရှုမည်",
    "Post job": "အလုပ်ခေါ်စာ တင်မည်",
    "Open to": "လက်ခံသည်",
    Nationality: "နိုင်ငံသား",
    "Choose at least one nationality":
      "အနည်းဆုံး နိုင်ငံသား တစ်မျိုးရွေးချယိပါ",
    Documents: "စာရွက်စာတမ်းအထောက်အထားများ",
    "Job Information": "အလုပ်အကြောင်းအရာ",
    "Job Title": "အလုပ်အကိုင်အမည်",
    "Please enter job title": "အလုပ်အကိုင်အမည်ထည့်ပေးပါ",
    "Job title": "အလုပ်အကိုင်အမည်",
    "Please select industry": "လုပ်ငန်းအမျိုးအစားရွေးပေးပါ",
    "Job position category": "လုပ်ရမည့်အလုပ်ရာထူးအမျိုးအစား",
    "Job type": "အလုပ်အမျိုးအစား",
    "Please select job type": "အလုပ်အမျိုးအစားရွေးချယ်ပါ",
    "Expiry date": "အလုပ်ခေါ်စာ ပိတ်မည့်ရက်",
    "Please choose expire date": "အလုပ်ခေါ်စာ ပိတ်မည့်ရက်ကို ရွေးပေးပါ",
    "Headcount needed": "ခေါ်ယူလိုသောလုပ်သားအရေအတွက်",
    "Please input headcount needed": "ခေါ်ယူလိုသောလုပ်သားအရေအတွက်ဖြည့်ပေးပါ",
    "Number of employees for this position you would like to recruit":
      "ဤရာထူးအတွက် ခေါ်ယူလိုလုပ်သားဦးရေ",
    "Contract duration": "စာချုပ်သက်တမ်း",
    Months: "လ",
    "Please input duration": "စာချုပ်သက်တမ်း ဖြည့်ပေးပါ",
    "Duration of contract, in months": "စာချုပ်သက်တမ်း၊ လအလိုက်",
    Location: "တည်နေရာ",
    "Please select country": "နိုင်ငံရွေးချယ်ပါ",
    "The country of workplace": "လုပ်ငန်းတည်ရှိရာ နိုင်ငံ",
    "Please select state": "ပြညိနယ်ရွေးချယ်ပါ",
    "Please select city": "မြို့ရွေးချယ်ပါ",
    "{language} translation": "{language}  ဘာသာပြန်ချက်",
    "Please enter title": "အလုပ်အကိုင်အမည်ထည့်ပါ",
    "Name of the recruited job": "အလုပ်အကိုင်အမည်",
    "Position details": "အလုပ်ရာထူးအသေးစိတ်",
    "Please enter position details": "အလုပ်ရာထူးအသေးစိတ်ဖော်ပြပေးပါ",
    "Job Description": "အလုပ်အကြောင်းအသေးစိတ်",
    "Please enter job description": "အလုပ်အကြောင်းအသေးစိတ်ဖော်ပြပေးပါ",
    "Please describe the responsibilities and environment of this position":
      "ဤရာထူးနှင့်ပတ်သက်၍ လုပ်ကိုင်ရမည့်ပတ်ဝန်းကျင်နှင့် တာဝန်ကိုဖော်ပြပေးပါ",
    "Applicant Requirements": "အလုပ်လျှောက်ရန်လိုအပ်ချက်",
    "Please enter applicant requirements":
      "အလုပ်လျှောက်ရန်လိုအပ်ချက်ဖော်ပြပေးပါ",
    "Please describe the prerequisite skills or qualifications needed to do this job sucessfully, if any":
      "ဤလုပ်ငန်းတာဝန် ကို အောင်မြင်စွာ လုပ်ကိုင် နိုင်ရန် လိုအပ်သော အရည်အချင်း ကိုဖော်ပြပါ",
    "Contact name": "ဆက်သွယ်ရမည့်တာဝန်ခံ",
    "Please enter contact name": "ဆက်သွယ်ရမည့်တာဝန်ခံအမည်ကို ရေးပါ",
    "Please provide the name of the contact person for {language} applicants":
      "{language} ဖြင့်လျှောက်ထားသူများအတွက်ဆက်သွယ်ရမည့်တာဝန်ခံအမည်ကို ဖော်ပြပေးပါ",
    "Please enter contact phone number":
      "ဆက်သွယ်ရမည့်တာဝန်ခံ၏ ဖုန်းနံပါတ်ဖြည့်ပေးပါ",
    "Please provide the phone number of the contact person for {language} applicants":
      "{language} ဖြင့်လျှောက်ထားသူများအတွက်ဆက်သွယ်ရမည့်တာဝန်ခံ၏ဖုန်းနံပါတ်ကို ဖော်ပြပေးပါ",
    "Contact job title": "ဆက်သွယ်ရမည့်တာဝန်ခံ၏အလုပ်အကိုင်ရာထူး",
    "Please select contact job title":
      "ဆက်သွယ်ရမည့်တာဝန်ခံ၏အလုပ်အကိုင်ရာထူးကိုရွေးပေးပါ",
    "Recruitment Information": "လုပ်သားခေါ်ယူ စုဆောင်းခြင်း သတင်းအချက် အလက်",
    "Do you have an ethical recruitment policy?":
      "ကျင့်ဝတ်နဲ့ညီတဲ့လုပ်သားစုဆောင်းရေးဆိုင်ရာမူဝါဒရှိပါသလား",
    "Ethical recruitment policies specify more transparent and ethical conduct in the recruitment of workers":
      "ကျင့်ဝတ်နှင့်ညီသည့်လုပ်သားစုဆောင်းခြင်းမူဝါဒများမှာပွင့်လင်းမြင်သာမှုရှိပြီး လုပ်သားစုဆောင်းရာတွင် ကျင့်ဝတ်နှင့်အညီလုပ်ဆောင်ခြင်း။",
    "Do you have an employer pays policy?":
      "လုပ်သားစုဆောင်းတဲ့ကုန်ကျစားရိတ်ကိုအလုပ်ရှင်ကပေးရတဲ့မူဝါဒရှိပါသလား",
    "Employer pays policies specify that all costs of recruitment are paid by the employer, and it is guaranteed that zero fees will be charged to workers, including for document processing and fees to recruiters":
      "လုပ်သားစုဆောင်းခြင်းနှင့်ပတ်သက်သည့်ကုန်ကျစားရိတ်များအားလုံးကို အလုပ်ရှင်မှကျခံရမည်ဟုသတ်မှတ်ထားပြီး အလုပ်သမားမှ စာရွက်စာတမ်းအဆင့်ဆင့်လျှောက်ထားခြင်းနှင့်ဝန်ဆောင်ခအပါအဝင် ပိုက်ဆံလုံးဝမပေးရစေရန်အာမခံသည်။",
    "Employer Pays Policy Type":
      "အလုပ်ရှင်မှ အလုပ်ရှာဖွေရေး ကုန်ကျစားရိတ်ကျခံခြင်း နည်းစနစ်များ",
    "Please select policy type": "မူဝါဒအမျိုးအစားရွေးပေးပါ",
    "Who pays the visa fees?": "ဗီဇာကြေးကို ဘယ်သူကပေးရမလဲ",
    Worker: "အလုပ်သမား",
    Amount: "ပမာဏ",
    "Who pays the work permit fees?": "အလုပ်ပါမစ်ကြေးကို ဘယ်သူကပေးရမလဲ",
    "Who pays the medical checkup fees?": "ဆေးစစ်ခကိုရော ဘယ်သူကပေးရမလဲ",
    "Contract Information: Pay": "လုပ်ခပေးချေခြင်းဆိုင်ရာ စာချုပ်အချက်အလက်များ",
    "Terms of payment": " လုပ်ခဘယ်လိုပေးမလဲ",
    "Please select terms of payment": "လုပ်ခ ပေးချေပုံ ရွေးချယ်ပါ",
    "Please note whether the employee will be paid according to a daily or monthly rate, and how frequently they will be paid":
      "အလုပ်သမားလုပ်ခ ကို နေ့စဉ်ပေးသလား လခအနေဖြင့်ပေးသလား တစ်လလျင် ဘယ်နှစ်ကြိမ် ပေးသလဲ",
    "Timing of payment": "လုပ်အားခကိုဘယ်အချိန်ပေးမလဲ",
    "Please select or create timing of payment":
      "လုပ်ခ ပေးချေမည့်အချိန်ကိုရွေးပေးပါ (သို့) ဖြည့်ပေးပါ",
    "Please note whether the employee will be paid according to once a month or twice a month rate, and how frequently they will be paid":
      "အလုပ်သမားလုပ်အားခ တစ်လ တစ်ခါ ပေးသလား တစ်လနှစ်ခါ ပေးသလား  တစ်လလျှင် ဘယ်နှစ်ကြိမ် ပေးသလဲ",
    "Timing of Payment Other": "လုပ်ခကို အခြားဘယ်အချိန်မှာပေးချေမှာပါလဲ",
    "Please input Timing of Payment Other":
      "ကျေးဇူးပြု၍ လုပ်ခပေးချေမည့် အခြားအချိန်ကိုဖြည့်ပေးပါ",
    "Other type of Payment Timing": "အခြားလုပ်အားခပေးချေသည့်ပုံစံ",
    "Enter Timing of Payment Other": "လုပ်ခပေးချေမည့် အခြားအချိန်ကိုဖြည့်ပေးပါ",
    Currency: "ငွေကြေး",
    "Please select currency": "ငွေကြေးရွေးချယ်ပါ",
    "Monthly salary": "လစဉ် လုပ်ခနှုန်းထား",
    "{currency}/month": "{currency}/ လအလိုက်",
    "Please input salary": "လစာပမာဏဖော်ပြပေးပါ",
    "Monthly payment to employee, which must be equal to or greater than the minimum wage for the province":
      "အလုပ်သမား ၏ လစဉ် လုပ်ခသည် ခရိုင်အလိုက် အနိမ့်ဆုံး လစာနှုန်းအတိုင်း သို့မဟုတ် ကျော်လွန်သော ပမာဏ ဖြစ်ရမည်",
    "Daily Wage": "နေ့စားခ",
    "{currency}/day": "{currency}/ နေ့အလိုက်",
    "Please input daily wage rate": "နေ့စားခနှုန်းထားဖော်ပြပေးပါ",
    "Daily wage amount for 8 hours of work, equal to or greater than the minimum daily wage for the province":
      "အလုပ်သမား ၏ ပုံမှန်ချိန် တစ်နေ့ ၈နာရီ လုပ်ခသည်  ခရိုင်အလိုက် အနိမ့်ဆုံး လုပ်ခနှုန်းအတိုင်း သို့မဟုတ် ကျော်လွန်သော ပမာဏ ဖြစ်ရမည်",
    "Available Shifts": "အလုပ်ဝင်နိုင်သော အဆိုင်း",
    "What shift is this position expected to offer?":
      "ဤရာထူးအသည် မည်သည့် အဆိုင်းတွင်လုပ်ကိုင်ရမည်နည်း",
    "These are the available shift choices the position offers.":
      "ဤရာထူးအတွက် ဝင်ရောက်လုပ်ကိုင်နိုင်သော အဆိုင်းများ",
    "Overtime Expectation": "အချိန်ပိုအလုပ်ချိန်ရနိုင်မှု",
    "How often is this position expected to offer voluntary OT?":
      "ဤရာထူးအတွက် အချိန်ပိုမေတ္တာဖြင့်ဘယ်လောက်ဆင်းပေးရန် ယာထားပါသလဲ။",
    "How often the position will require overtime.":
      "ဤရာထူးအတွက် အချိန်ပိုဘယ်လောက် မကြာခဏဆင်းရန်လိုအပ်ပါသလဲ",
    "Day of Week Off": "တစ်ပတ်တွင်ရမည့်နားရက်",
    "What day of the week is the day off for this position?":
      "ဤရာထူးအတွက် တစ်ပါတ်တာ အတွင်းနားရက် သည် ဘယ်နေ့ဖြစ်သနည်း",
    "Helps workers to understand how fixed their schedule will be.":
      "အလုပ်သမား ၏ အလုပ်ချိန် စီမံထားရှိမှု ကို ဖော်ပြပါ",
    "Minimum Working Hours": "တစ်ပတ်တွင်ရှိရမည့် အနည်းဆုံးအလုပ်ချိန်",
    "What are the minimum number of hours per week expected for this position?":
      "ဤရာထူးအတွက် တစ်ပတ်တွင်အနည်းဆုံးအလုပ်လုပ်ရမည့်အလုပ်ချိန်ကိုဖော်ပြပေးပါ",
    "Minimum number of hours per week expected for this position?":
      "ဤရာထူးအတွက် တစ်ပတ်တွင်အနည်းဆုံးအလုပ်လုပ်ရမည့်အလုပ်ချိန်",
    "Regular OT": "ပုံမှန်အိုတီကြေး",
    "Please input regular OT rate": "ပုံမှန်အိုတီကြေးဖြည့်ပေးပါ",
    "{currency}/hour": "{currency} /နာရီအလိုက်",
    "Payment rate per hour for overtime worked on a normal working day to employee, which by law must be at least 1.5x regular hourly rate":
      "ပုံမှန်အလုပ်လုပ်ရက်တွင်ပေးရမည့် အိုတီကြေးသည် ထိုင်းဥပဒေအရ အနည်းဆုံး တစ်နာရီပုံမှန်လုပ်ခ၏ တစ်ဆခွဲရှိရမည်။ (တစ်နာရီပုံမှန်လုပ်ခ × ၁.၅)",
    "Day-Off Rate": "အလုပ်ပိတ်ရက်/နားရက် လုပ်ခနှုန်း",
    "Please input normal working hours rate during holiday":
      "အစိုးရရုံးပိတ်ရက်အတွင်း သာမာန် တစ်နေ့ ၈နာရီ အလုပ်ချိန် အတွက် လုပ်ခကို ဖော်ပြပါ",
    "Payment rate for working on the normally scheduled day off must be at least 2x the daily wage rate according to law":
      "အလုပ်မလုပ်ရသည့်နေ့တွင် အလုပ်ဆင်းရပါက ထိုင်းဥပဒေအရ ပုံမှန်နေ့စားခ၏ ၂ဆ ပေးရမည်။ (ပုံမှန်နေ့စားခ × ၂)",
    "Holiday OT": "အစိုးရရုံးပိတ်ရက် အိုတီကြေး",
    "Please input Day-Off Rate":
      "အလုပ်ပိတ်ရက်/နားရက် လုပ်ခနှုန်းကို ဖြည့်ပေးပါ",
    "Payment rate per hour for overtime worked on a holiday, which by law must be at least 3x regular hourly rate":
      "အစိုးရရုံးပိတ်ရက်တွင်ပေးရမည့် တစ်နာရီစာအိုတီကြေးသည် ထိုင်းနိုင်ငံဥပဒေအရ အနည်းဆုံး တစ်နာရီပုံမှန်လုပ်ခ၏ ၃ဆ ရှိရမည်",
    Benefits: "ခံစားခွင့်များ",
    "How many days of paid annual leave are provided per year?":
      "လစာဖြင့်ခွင့်ခံစားခွင့် တစ်နှစ်လျင်ဘယ်နှစ်ရက် ရှိပါသလဲ",
    "Please input number": "ရက်အရေအတွက်ဖြည့်ပေးပါ",
    "How many days of paid sick leave are provided per year?":
      "လစာဖြင့် နာမကျန်း ခွင့် တစ်နှစ်လျှင် ဘယ်နှစ်ရက် ရှိပါသလဲ",
    "How many days of paid business leave are provided per year?":
      "လစာဖြင့် ကိုယ်ရေးကိုယ်တာခွင့် တစ်နှစ်လျှင် ဘယ်နှစ်ရက် ရှိပါသလဲ",
    "How many days of paid national holidays are provided per year?":
      "လစာဖြင့် အစိုးရရုံးပိတ်ရက် ခံစားခွင့် တစ်နှစ်လျှင် ဘယ်နှစ်ရက် ရှိပါသလဲ",
    "Are social security and maternity leave benefits provided as required by law?":
      "ဥပဒေပါ ပကန်ဆန်ခွန်ခံစားခွင့် ၊ မီးဖွားခွင့် ခံစားခွင့်ရော ရှိပါသလား ?",
    "Yes, these benefits are provided": "ထိုခံစားခွင့်များကို ပေးပါမည်။",
    "No, these benefits are not provided": "ထိုခံစားခွင့်များကို မပေးပါ။",
    "Benefits details": "အကျိုးခံစားခွင့် အသေးစိတ်",
    Accommodation: "နေရာထိုင်ခင်း",
    "Accommodation is on site": "စက်ရုံပိုင် နေစရာ အဆောင်",
    "Family members can live together": "မိသားစုပါ နေထိုင် နိုင်သည်",
    "Rent/utilities auto deducted from payroll":
      "အဆောင်ခ၊ ရေ/မီး လစာမှ ဖြတ်မည်",
    "Accommodation details": "နေရာထိုင်ခင်း အသေးစိတ်",
    "Please fill in accommodation details": "နေရာထိုင်ခင်း အသေးစိတ်ဖော်ပြပေးပါ",
    "Living arrangement details for the employees":
      "အလုပ်သမားအတွက် အလုပ်ရှင်မှ နေစရာ အဆောင် ပံ့ပိုးထောက်ပံ့မှု ကိုဖော်ပြပါ",
    "Rent/utilites deduction details":
      "အဆောင်ခ၊ ရေ/မီး လစာမှ ဖြတ်တောက်မှုအသေးစိတ်",
    "Please fill in rent details":
      "အဆောင်ခ၊ ရေ/မီး လစာမှ ဖြတ်တောက်မှုအသေးစိတ်ကိုဖော်ပြပေးပါ",
    "Safe, grievance mechanism":
      "လိုအပ်ချက် နှင့် ပြဿနာ အခက်အခဲများကို လုံခြုံစွာတင်ပြနိုင်ခြင်း",
    "Probation period": "အစမ်းခန့် ကာလ",
    "Grievance mechanism details":
      "လိုအပ်ချက် နှင့် ပြဿနာ အခက်အခဲများကို လုံခြုံစွာတင်ပြနိုင်မှု အသေးစိတ်",
    "Please input grievance mechanism details":
      "လိုအပ်ချက် နှင့် ပြသနာတင်ပြ နိုင်မှု အသေးစိတ်ကို ဖော်ပြပေးပါ",
    "Probation period details": "အစမ်းခန့် ကာလ အကြောင်း အသေးစိတ်",
    "Please input probation period details":
      " အစမ်းခန့် ကာလ အကြောင်းအသေးစိတ်ဖော်ပြပေးပါ",
    Preview: "ခြုံငုံစစ်ဆေးရန်",
    "Save draft": "စာကြမ်းသိမ်းဆည်းရန်",
    Post: "အလုပ်ခေါ်စာတင်မည်",
    "You cannot make changes after posting the job. Are you sure you want to post this job?":
      "အလုပ်ခေါ်စာတင်ပြီးနောက်အပြောင်းအလဲများ ပြုလုပ်၍ မရပါ။ ဤအလုပ်ကိုတင်ရန် သေချာပါပြီလား။",
    "Job Preview": "အလုပ်ခေါ်စာ အစမ်းကြည့်ရှုခြင်း",
    "General Information": "အခြေခံအချက်အလက်များ",
    "No information were provided":
      "မည့်သည့်အကြောင်းအရာအချက်အလက်မှ ဖော်ပြပေးမထားပါ",
    "Open To": "လက်ခံသည်",
    "No of Headcount Needed": "ခေါ်ယူလိုသောလုပ်သားအရေအတွက်",
    "Job will be expired on": "အလုပ်ခေါ်စာ ပိတ်မည့် ရက်",
    "Pink Card Holder": "ပန်းရောင်ကဒ် ကိုင်ဆောင်သူ",
    "Passport Holder": "ပါစ်စပို့ ကိုင်ဆောင်သူ",
    "Job Position Category": "လုပ်ရမည့်အလုပ်ရာထူးအမျိုးအစား",
    "Job Type": "အလုပ်အမျိုးအစား",
    "Contract Duration": "စာချုပ်သက်တမ်း",
    "{duration} months": "{duration} လ",
    "Job Position Details": "တာဝန်ယူရမည့် နေရာ အသေးစိတ်",
    "Job Requirements": "လိုအပ်ချက်",
    "Have an ethical recruitment policy":
      "ကျင့်ဝတ်နဲ့ညီတဲ့လုပ်သားစုဆောင်းရေးဆိုင်ရာမူဝါဒ ရှိသည်",
    "Have an employer pays policy":
      "လုပ်သားစုဆောင်းတဲ့ကုန်ကျစားရိတ်ကိုအလုပ်ရှင်ကပေးရတဲ့မူဝါဒရှိပါသည်",
    "Who pays visa fees?": "ဗီဇာကြေးကို ဘယ်သူကပေးရမလဲ",
    "Who pays work permit fees?": "အလုပ်ပါမစ်ကြေးကို ဘယ်သူကပေးရမလဲ",
    "Who pays medical checkup fees?": "ဆေးစစ်ခကိုရော ဘယ်သူကပေးရမလဲ",
    "Terms of Payment": " လုပ်ခဘယ်လိုပေးမလဲ",
    "Timing of Payment": "လုပ်ခဘယ်အချိန်ထုတ်ပေးမလဲ",
    Salary: "လုပ်ခ/လစာ",
    "OT Rate Regular": "ပုံမှန်အိုတီကြေး",
    "OT Rate Holiday": "အစိုးရရုံးပိတ်ရက်အိုတီကြေး",
    "Holiday Rate": "အစိုးရရုံးပိတ်ရက် လုပ်ခနှုန်း",
    "Other Benefits": "အခြားခံစားခွင့်များ",
    "Benefits Details": "အကျိုးခံစားခွင့် အသေးစိတ်",
    "Other Information": "အခြား",
    "Accommodation on-site is available": "စက်ရုံမှစီစဉ်ပေးသည့်အဆောင်ရှိသည်",
    "Rent & utilities autodeducted from payroll":
      "အဆောင်ခ၊ ရေ/မီး လစာမှ ဖြတ်မည်",
    "Safe & functioning grievance mechanism":
      "လိုအပ်ချက် နှင့် ပြဿနာ အခက်အခဲများကို လုံခြုံစွာတင်ပြနိုင်ခြင်း",
    "Accommodation Details": "နေရာထိုင်ခင်း အသေးစိတ် အချက်အလက်",
    "Contact Information": "ဆက်သွယ်ရန်",
    Edit: "ပြန်ပြင်ရန်",
    "Job has been successfully archived.":
      "အလုပ်ခေါ်စာကြော်ငြာအား ဆက်လက် မဆောင်ရွက်ပဲ ဖယ်ထားပြီးပါပြီ",
    "An error occurred while archiving draft.":
      "အလုပ်ခေါ်စာကြော်ငြာအား ဖယ်ထားခြင်း ၌ အမှားတစ်စုံတစ်ရာ ဖြစ်ပေါ်သည်",
    "Job has been successfully deleted.": "အလုပ်ခေါ်စာ ပါယ်ဖျက်ပြီးပါပြီ",
    "An error occurred while deleting job.":
      "အလုပ်ခေါ်စာ ပါယ်ဖျက်ခြင်း၌ အမှားတစ်စုံတစ်ရာ ဖြစ်သွားပါသည်",
    "Recruitment has been successfully stopped.":
      "အလုပ်ခေါ်ဆိုခြင်းအား အောင်မြင်စွာရပ်ထားလိုက်ပါပြီ",
    "An error occurred while stopping recruitment.":
      "အလုပ်ခေါ်ဆိုခြင်းအား ရပ်ထားရန်လုပ်ဆောင်နေစဉ် အမှားဖြစ်သွားပါသည်",
    "Job has been successfully set to offline.":
      "အလုပ်တင်ထားခြင်းအားအောင်မြင်စွာရပ်ထားလိုက်ထားပါပြီ",
    "An error occurred while setting job to offline.":
      "အလုပ်တင်ထားခြင်းအားရပ်နေစဉ် တစ်ခုခုအမှားဖြစ်ပေါ်သွားပါသည်",
    "Job has been successfully set to online.":
      "အလုပ်တင်ထားခြင်းကိုအောင်မြင်စွာပြန်ဖွင့်လိုက်ပါပြီ",
    "An error occurred while setting job to online.":
      "အလုပ်တင်ထားခြင်းကိုပြန်ဖွင့်နေစဉ် တစ်ခုခုအမှားဖြစ်ပေါ်သွားပါသည်",
    Title: "အလုပ်အကိုင်အမည်",
    Posted: "အလုပ်လျှောက်လွှာဖွင့်ရက်",
    Expires: "အလုပ်လျှောက်လွှာပိတ်ရက်",
    Views: "ကြည့်ရှုထားသည့်အရေအတွက်",
    Applicants: "အလုပ် လျှောက်ထားကြသူများ",
    "Filled Vacancies": "လူပြည့်သွားသည့်အလုပ်များ",
    Online: "အလုပ်တင်ထားသည်",
    Action: "လုပ်ဆောင်မည်",
    "Are you sure you would like to end the recruitment? This action cannot be undone later!":
      "အလုပ်ခေါ်ဆိုခြင်းအား ရပ်ဆိုင်းရန်သေချာပါသလား။ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန်ပြင်၍ မရပါ!",
    "End recruitment": "အလုပ်ခေါ်ဆိုခြင်းအား ရပ်ဆိုင်းမည်",
    "Are you sure you would like to archive this job?":
      "ဤအလုပ်ခေါ်စာကြော်ငြာအား ဆက်လက် မလုပ်ဆောင်ပဲ ဖယ်ထား ရန် သေချာပြီလား",
    Archive: "ဆက်လက်မဆောင်ရွက်ပဲ ဖယ်ထားမည်",
    Saved: "မှတ်ထားသောအလုပ်လျှောက်လွှာများ",
    Archived: "ဆက်လက်မဆောင်ရွက်ပဲ ဖယ်ထားမည်",
    "Are you sure you would like to delete this job?":
      "ဤအလုပ်ခေါ်စာကြော်ငြာအား ဖျက်သိမ်း ရန် သေချာပြီလား",
    Delete: "ဖျက်သိမ်းမည်",
    "View Jobs:": "အလုပ်ကြော်ငြာများအား  ကြည့်မည်",
    "Posted ({count})": "တင်ထားသောအလုပ်များ ({count})",
    "Closed ({count})": "ခေါ်ယူမှုရပ်ဆိုင်းပြီးသောအလုပ်များ ({count})",
    "Drafts ({count})": "အလုပ်ခေါ်စာမူကြမ်းများ ({count})",
    "Archived ({count})": "ဖယ်ထားသောအလုပ်များ ({count})",
    "Position category": "ရာထူးအမျိုးအစား",
    "Please select timing of payment": "လုပ်ခ ပေးချေချိန်ရွေးချယ်ပါ",
    "Please input Day-Off OT": "အလုပ်ပိတ်ရက်အိုတီ ကြေး ဖြည့်ပေးပါ",
    "Account Verification Status": "အကောင့် စမ်းစစ်အတည်ပြုမှု အခြေအနေ",
    Registration: "စာရင်းသွင်းမည်",
    "Fill out the registration form": "စာရင်းသွင်းရန်ဖောင်ဖြည့်မည်",
    "Email Verification": "အီးမေးလ်းဖြင့် စမ်းစစ်အတည်ပြုခြင်း",
    "Please verify your email using the link sent to {email}":
      "{email} သို့ပို့ထားသောလင့်ခ်ကို နိုပ်၍ သင်၏အီးမေးလ်ကိုအတည်ပြုပေးပါ။",
    "Business Verification": "လုပ်ငန်းအတည်ပြုခြင်း",
    "Issara will contact you to verify that you are actually the owner of this business":
      "ဤလုပ်ငန်း၏ပိုင်ရှင်အမှန်သင်ဖြစ်ကြောင်းအတည်ပြုရန် အစ်ဆာရာမှ သင့်အားဆက်သွယ်လာပါလိမ့်မည်",
    Actions: "လုပ်ဆောင်ချက်များ",
    "Are you sure this business is not in our database yet?":
      "ဒီကုမ္ပဏီ အချက်အလက်များ မှတ်တမ်းမှာ မရှိသေးဘူးဆိုတာ သေချာလား?",
    "This business is not in our database yet. Verify as new.":
      "ဒီကုမ္ပဏီ အချက်အလက်များ သင့် မှတ်တမ်းမှာ မရှိသေးပါ။ ကုမ္ပဏီအသစ်ဖြင့် အတည်ပြုပါမည်။",
    "Are you sure you want to reject verification and delete this user?":
      "ဒီအကောင့်   အတည်ပြုချင်းကို ငြင်းပယ်ပြီး ဖျက်ဖို့ လုပ်မယ်ဆိုတာ သေချာပြီလား? ",
    "The user couldn't prove ownership of the business. Reject it.":
      "ဒီလုပ်ငန်းရှင်မှန်ကန်ကြောင်းသက်သေမပြနိုင်ပါ။ ငြင်းပယ်ပါမည်။",
    "Owner's Email": "ကုမ္ပဏီအီးမေးလ်",
    Registered: "မှတ်ပုံ တင်ထား",
    "Business has been successfully verified":
      "ဒီကုမ္ပဏီနာမည်ကို အတည်ပြုပြီးပါပြီ။",
    "An error occurred while verifying business. Please contact a developer.":
      "ကုမ္ပဏီအချက်အလက်များကိုပြင်ဆင်နေစဉ် အမှားဖြစ်သွားပါသည်၊ ကျေးဇူးပြု၍ ရွှေအိပ်မက် သို့ ဆက်သွယ်ပါ  ",
    "Business has been successfully removed":
      "ဒီကုမ္ပဏီနာမည်နဲ့ အတည်ပြုခြင်းအား အောင်မြင်စွာ ဖယ်ထုတ်ပြီးပါပြီ။",
    "An error occurred while rejecting account. Please contact a developer.":
      "ကုမ္ပဏီအချက်အလက်များကို ငြင်းပယ်ရန် ပြင်ဆင်နေစဉ် အမှားဖြစ်သွားပါသည်၊ ကျေးဇူးပြု၍ ရွှေအိပ်မက် သို့ ဆက်သွယ်ပါ",
    "Merge Dialogue": "စုစည်းမည်",
    Close: "ပိတ်မည်",
    "To be merged": "ပေါင်းစည်းမည်",
    Into: "ထဲသို့",
    "Awaiting Employers": "အလုပ်ရှင်များ",
    "Total ({count})": "စုစုပေါင်း ({count})",
    "No awaiting employers to verify": "အတည်ပြုရန် မရှိပါ",
    Previous: "အရင်က",
    Next: "နောက်တစ်ခု",
    "Previously verified employers": "အရင် အတည်ပြုထားပြီးပါပ",
    "No previously verified employers":
      "အရင် အတည်ပြုထားသောလုပ်ငန်းနာမည် မရှိပါ။",
    "N/A": "မရှိပါ",
    Verify: "အတည်ပြုမည်",
    "Awaiting Recruitment agencies": "အလုပ်ရှာဖွေရေးအေဂျင်စီ",
    "No businesses awaiting verification":
      "မည်သည့်လုပ်ငန်းရှင်မှ အတည်ပြုရန်မရှိပါ",
    "Previously verified Recruiters": "အရင် အတည်ပြုထားပြီး အေဂျင်စီများ",
    "No previously verified businesses": "အတည်ပြုထားသောလုပ်ငန်းနာမည် မရှိပါ။",
    "Survey Details": "စစ်တမ်း အသေးစိတ်",
    "Identification Card": "နိုင်ငံသားစီစစ်ရေးကတ်ပြား",
    "Birth Certificate": "မွေးစာရင်း",
    "Family Record Book": "အိမ်ထောင်စုစာရင်း",
    "Residence Book": "နေထိုင်ရာမှတ်တမ်းစာအုပ်",
    "Covid Vaccine Certificate": "ကိုဗစ် ၁၉ ကာကွယ်ဆေးထိုးမှတ်တမ်းကတ်ပြား",
    NRC: "နိုင်ငံသားစီစစ်ရေးမှတ်ပုံတင်ကတ်ပြား",
    "Household List": "အိမ်ထောင်စုစာရင်း",
    "Passport (PJ)": "PJ ပါတ်စ်စပို့ ",
    "Labour Card": "လေဘာကတ်",
    "Generating PDF ...": "PDF ဖိုင်ပြင်ဆင်နေပါသည်။",
    "Generate PDF & Download": "PDF ဖိုင်ကို ပြောင်းပြီး ဒေါင်းလုတ်ဆွဲမည်။",
    "Request documents dfsdfwe": "အထောက်အထားစာရွက်စာတမ်းကြည့်ရန်တောင်းမည် ",
    "Application #{id} has been successfully contract signed":
      "အလုပ်လျှောက်လွှာအမှတ်စဥ် #{id}ကို လက်မှတ်ထိုးပြီးပါပြီ။",
    "An error occurred while contract signing application #{id}.":
      "အလုပ်လျှောက်လွှာအမှတ်စဥ် #{id} အား လက်မှတ်ထိုးနေစဥ် အမှားတစ်စုံ တစ်ရာဖြစ်ပေါ်သွားသည်",
    "Application has been moved to general waitlisted on application #{id}.":
      "အလုပ်လျှောက်လွှာကို အထွေထွေ စောင့်ဆိုင်းစာရင်း ထဲသို့ လျှောက်လွှာအမှတ်စဥ်  #{id} ဖြင့် ပြောင်းရွှေ့လိုက်ပါပြီ။",
    "An error occurred while moving the application #{id}. to general waitlist":
      "လျှောက်လွှာအမှတ်စဥ်  #{id}. ကို အထွေထွေစောင့်ဆိုင်းစာရင်းထဲ ထည့်နေစဉ်မှာ အမှားတစ်စုံ တစ်ရာဖြစ်ပေါ်သွားသည်",
    "Waitlisted ({count})": "စောင့်ဆိုင်းစာရင်းမှာ ရှိနေသူ  ({count})ယောက်",
    "Contract Signed ({count})": "လက်မှတ်ထိုးပြီးသွားသူ ({count}) ယောက်",
    'Are you sure you would like to change the status to "Contract Signed" to the selected applications? This action cannot be undone later':
      "ယခုမှတ်ထားသောအလုပ်လျှောက်လွှာကို လက်မှတ်ထိုးပြီးသည့် စာရင်းထဲထည့်ရန် သေချာပါသလား။ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန် ပြင်၍ မရပါ။",
    "Contract Signed ({number})": "လက်မှတ်ထိုးပြီးသည့် စာရင်း   ({count})ယောက်",
    "Move to waitlist ({number})":
      "စောင့်ဆိုင်းစာရင်းသို့ ရွှေ့ထားသည့် ဦးရေ   ({count})ယောက်",
    "Are you sure you would like to move the selected applicants to General Waitlist? This action cannot be undone later.":
      "ယခုမှတ်ထားသောအလုပ်လျှောက်လွှာကို အထွေထွေစောင့်ဆိုင်းစာရင်းထဲထည့်ရန် သေချာပါသလား။ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန် ပြင်၍ မရပါ။",
    "Move to General Waitlist ({number})":
      "အထွေထွေစောင့်ဆိုင်းစာရင်းသို့ ရောက်သွားသည့်ဦးရေ    ({count})ယောက်",
    "Please select an offer expiry date":
      "အလုပ်ကမ်းလှမ်းမှုရက်စွဲ ကုန်ဆုံးရက်ကိုရွေးပေးပါ",
    "Update Now": "အသစ်တင်မည် ",
    "Offer Now": "အလုပ်ကမ်းလှမ်းမည်",
    "Offer expiry date will be {expiry_date}":
      "အလုပ်ကမ်းလှမ်းမှု ကုန်ဆုံးမည့်ရက်:  {expiry_date}",
    "Resume Detail Information": "ကိုယ်ရေးအချက်အလက် အသေးစိတ်",
    "Resume Information": "ကိုယ်ရေးအချက်အလက်",
    "The job seeker have National ID":
      "အလုပ်လျှောက်ထားသူမှာ မှတ်ပုံတင်ရှိပါသည်။",
    "The job seeker does not have a National ID":
      "အလုပ်လျှောက်ထားသူမှာ မှတ်ပုံတင် မရှိပါ။",
    "The job seeker have passport":
      "အလုပ်လျှောက်ထားသူမှာ နိုင်ငံကူးလက်မှတ် (ပတ်စပို့) ရှိပါသည်။",
    "The job seeker does not have a passport":
      "အလုပ်လျှောက်ထားသူမှာ နိုင်ငံကူးလက်မှတ် (ပတ်စပို့) မရှိပါ။",
    "Read Level": "အဖတ်",
    "Speak Level": "အပြော",
    "Applied At": "လျှောက်ထားပြီး  ",
    "Resume View": "အလုပ်လျှောက်လွှာအား ကြည့်ပြီးပါပြီ",
    "General Waitlist": "အထွေထွေစောင့်ဆိုင်းစာရင်း",
    Jobseekers: "အလုပ်ရှာဖွေသူ",
    "(Burmese)": "မြန်မာ",
    "(English)": "အင်္ဂလိပ်",
    "(Nepali)": "နီပေါဘာသာ",
    "(Thailand)": "ထိုင်း ဘာသာ",
    "(Khmer)": "ခမာ ဘာသာ",
    "Update/Post job": "အလုပ်ခေါ်စာ တင်မည် ",
    "This job is for the country ! ({country})":
      "အခုအလုပ်က  ({country}) နိုင်ငံမှာ ရောက်ရှိနေသူအတွက် ဖြစ်ပါတယ်။",
    "Need to choose a country for this job!":
      "ဒီအလုပ်အတွက် နိုင်ငံရွေးချယ်ရန်လိုပါသည်။",
    "Please select the sending country !": "နိုင်ငံရွေးချယ်ပါ",
    Malaysia: "မလေးရှား",
    UAE: "",
    "Employer Information": "အလုပ်ရှင် ဆိုင်ရာ သတင်းအချက်အလက်",
    "Please select employer": "အလုပ်ရှင် ရွေးချယ်ပါ",
    "Please input employer's name": "အလုပ်ရှင်အအမည် ဖြည့်ပေးပါ",
    "The name of the Employer": "လုပ်ငန်းအမည်",
    "The country where the workplace is located": "အလုပ်ခွင်တည်ရှိရာ နိုင်ငံ",
    "The state where the workplace is located": "အလုပ်ခွင်တည်ရှိရာပြည်နယ်",
    "The city where the workplace is located": "အလုပ်ခွင်တည်ရှိရာမြို့",
    "Please select size": "လုပ်ငန်းအရွယ်အစားရွေးပေးပါ",
    "Nationalities of Workers working at this workplace":
      "လာရောက်လုပ်ကိုင်ကြသည့် နိုင်ငံအလိုက်လုပ်သားများ",
    "Please specify the nationalities of workers working at this workplace.":
      "လာရောက်လုပ်ကိုင်ကြသည့် နိုင်ငံအလိုက်လုပ်သားများ",
    "Headcount by Genders":
      "ခေါ်ယူလိုသောလုပ်သားအရေအတွက်ကို ကျား/မဖြင့်ဖော်ပြမည်",
    "Male headcount needed": "ခေါ်ယူလိုသော အမျိုးသားလုပ်သားအရေအတွက်",
    "Female headcount needed": "ခေါ်ယူလိုသော အမျိုးသမီးလုပ်သားအရေအတွက်",
    "Demand Pre-approval date": "အလုပ်ခေါ်စာ ကြိုတင် ခွင့်ပြုလိုက်သည့်ရက်စွဲ",
    "Demand Approved Date": "အလုပ်ခေါ်စာ ခွင့်ပြုလိုက်သည့်ရက်စွဲ",
    "Please choose demand pre-approval date":
      "အလုပ်ခေါ်စာ ကြိုတင် ခွင့်ပြုလိုက်သည့်ရက်စွဲကိုရွေးပေးပါ",
    "Please choose demand approved date":
      "အလုပ်ခေါ်စာ ခွင့်ပြုလိုက်သည့်ရက်စွဲကိုရွေးပေးပါ",
    "LT number": "LT နံပါတ်",
    "Demand number": "အလုပ်ခေါ်စာအမှတ်",
    "Please enter LT number": "LT နံပါတ်ဖြည့်ပါ",
    "Please enter demand number": "အလုပ်ခေါ်စာအမှတ်ကိုဖြည့်ပေးပါ",
    "Application Closed Date": "အလုပ်လျှောက်လွှာပိတ်မည့်ရက်",
    "Please choose Application Closed date":
      "အလုပ်လျှောက်လွှာပိတ်မည့်ရက်ရွေးပေးပါ",
    "Email to receive notifications for this job announcement":
      "ဤအလုပ်ကို လျှောက်လာကြမည့် CVများကို သီးသန့်လက်ခံပေးမည့် အီးမေးလ်လိပ်စာကိုဖြည့်ပေးပါ",
    "Please enter a valid email address": "အီးမေးလ်အမှန်ထည့်ပါ",
    "ER Information": "ကျင့်ဝတ်နှင့်အညီလုပ်သားစုဆောင်းခြင်း",
    "Does the employer have an ethical recruitment policy?":
      "ဤအလုပ်ကိုခေါ်တဲ့အလုပ်ရှင်မှာ ကျင့်ဝတ်နဲ့ညီတဲ့လုပ်သားစုဆောင်းရေးဆိုင်ရာမူဝါဒရှိပါသလား",
    Have: "ရှိပါတယ်",
    "Not Have": "မရှိဘူး",
    "Does the RA have an ethical recruitment policy?":
      "ယခုအေဂျင်စီမှာရော ကျင့်ဝတ်နဲ့ညီတဲ့လုပ်သားစုဆောင်းရေးဆိုင်ရာမူဝါဒရှိပါသလား",
    "Does the RA have an ethical recruitment policy? is required":
      "ယခုအေဂျင်စီမှာရော ကျင့်ဝတ်နဲ့ညီတဲ့လုပ်သားစုဆောင်းရေးဆိုင်ရာမူဝါဒရှိပါသလား။ (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Does the employer have an employer pays policy?":
      "ဤအလုပ်ကိုခေါ်တဲ့အလုပ်ရှင်မှာ လုပ်သားစုဆောင်းတဲ့ကုန်ကျစားရိတ်ကိုအလုပ်ရှင်ကပေးရတဲ့မူဝါဒရှိပါသလား",
    "Monthly salary ({currency}/month)": "{currency}/ လအလိုက်",
    "How much will be provided for meals allowance?": "",
    "Please enter a meal allowance.": "အစား အသောက် ခွင့်ပြုချက်ထည့်ပါ ",
    "Please enter a meal allowance of no less than 300 AED.": "",
    "Any additional leave ?": "အခြားသီးသန့်ခွင့်ရက်များ ",
    "Add Detail Here": "အသေးစိတ်",
    "Any additional leave ? (This is optional)":
      "အခြားသီးသန့်ခွင့်ရက်များ အသေးစိတ် (မဖြည့်လည်းရပါတယ်)",
    "Add detail here (optional)": "အသေးစိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Are social security benefits provided as required by law?":
      "ဥပဒေပါ ပကန်ဆန်ခွန်ခံစားခွင့် ၊ မီးဖွားခွင့် ခံစားခွင့်ရော ရှိပါသလား? ",
    "Skill training information": "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်း",
    "Is skill training required for this job?":
      "ဤအလုပ်အတွက် ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်ရန်လိုအပ်ပါသလား။",
    "if so, who will cover those costs?":
      "အကယ်၍ တတ်ရောက်ရန်လိုအပ်ပါက မည်သူမှ ကုန်ကျစရိတ်ကျခံပေးရပါမလဲ။",
    "if workwes have to pay, please provide the amount they should pay":
      "အလုပ်သမားကျမှ စရိတ်ကျခံရန်လိုအပ်ပါက ကုန်ကျခံရမည့်ပမာဏကိုဖော်ပြပေးပါ။",
    "Cost of skill-training?": "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်း ကုန်ကျစရိတ်",
    "Cost of skill-training? is required":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်း ကုန်ကျစရိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Accommodation  during skill training?":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် နေစရိတ်",
    "Please specify, does the employer provide accommodation during skill-training of Workers":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် ကုန်ကျမည့်နေစရိတ်ကို အလုပ်ရှင်မှ ပေး/မပေးကို အသေးစိတ်ဖော်ပြပေးပါ",
    "Accommodation  during skill training? is required":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် နေစရိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Meals during skill training?":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် စားစရိတ်",
    "Please specify, does the employer provide meals during skill-training of Workers":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် ကုန်ကျမည့်စားစရိတ်ကို အလုပ်ရှင်မှ ပေး/မပေးကို အသေးစိတ်ဖော်ပြပေးပါ",
    "Meals during skill training? is required":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် စားစရိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Based on individual cost/spending.":
      "မိမိအသုံးစရိတ်ပေါ်ကုန်ကျသည့်အတိုင်း ",
    "Transportation costs to and from for attending skill training?":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်ရန်အတွက် အသွားအပြန်သွားစရိတ်",
    "Transportation costs to and from for attending skill training? is required":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်ရန်အတွက် အသွားအပြန်သွားစရိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Brief description of skill training":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းအကြောင်း",
    "Please fill the brief description of skill training":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းအကြောင်းဖော်ပြပေးပါ။",
    "Costs for Pre-departure": "ပြည်ပမထွက်ခွာမီ ကုန်ကျစရိတ်",
    "Is pre-departure orientation training required for this job?":
      "ဤအလုပ်အတွက် အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းတက်ရန်လိုအပ်ပါသလား။",
    "Cost of pre-departure orientation training?":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းအတွက်ကုန်ကျစရိတ်",
    "Cost of pre-departure orientation training? is required":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းအတွက်ကုန်ကျစရိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Accommodation during pre-departure orientation training?":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းတက်နေစဉ် နေရာထိုင်ခင်း",
    "Please specify, does the employer provide accommodation during pre-departure orientation training of Workers":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းတက်နေစဉ် နေရာထိုင်ခင်းကို အလုပ်ရှင်ကတာဝန်ယူပေးမှာလား။ အသေးစိတ်ဖော်ပြပေးပါ။",
    "Accommodation during pre-departure orientation training? is required":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းတက်နေစဉ် နေရာထိုင်ခင်း (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Meals during pre-departure orientation training?":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် စားသောက်ရေး",
    "Please specify, does the employer provide meals during pre-departure orientation training of Workers":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် စားသောက်ရေးကို အလုပ်ရှင်မှ တာဝန်ယူပေးမှာလား။ အသေးစိတ်ဖော်ပြပေးပါ။",
    "Meals during pre-departure orientation training? is required":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် စားသောက်ရေး (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Transportation costs to and from pre-departure orientation training?":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်ဖို့ လမ်းခရီးစရိတ်",
    "Transportation costs to and from pre-departure orientation training? is required":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်ဖို့ လမ်းခရီးစရိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Additional information for Pre-departure orientation course":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းအကြောင်း အခြားအချက်အလက်များ",
    "Please fill in additional information for Pre-departure orientation course (number of days, location, kind of training, etc)":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းအကြောင်း အခြားအချက်အလက်များ ( တက်ရမည့်ရက်အရေအတွက်၊ တက်ရမည့်နေရာ၊ သင်တန်းအကြောင်းစသည့်ဖြင့်) ဖော်ပြပေးပါ။",
    "Costs and fees for recruitment in the origin country":
      "မိခင်နိုင်ငံတွင်း လုပ်သားခေါ်ယူစုဆောင်းမှုစရိတ်များ",
    "Select the origin country currency": "မိခင် (မူလ) နိုင်ငံ  ကို ရွေးချယ်ပါ",
    "Origin country Currency": "မိခင် (မူလ) နိုင်ငံ သုံးငွေကြေး",
    "Recruitment service fee to RA and sub-agent?":
      "အေဂျင်စီနှင့် လက်အောက်ခံကိုယ်စားလှယ်များအတွက် လုပ်သားခေါ်ယူစုဆောင်းမှုဝန်ဆောင်ခ",
    "Recruitment service fee to RA and sub-agent? is required":
      "အေဂျင်စီနှင့် လက်အောက်ခံကိုယ်စားလှယ်များအတွက် လုပ်သားခေါ်ယူစုဆောင်းမှုဝန်ဆောင်ခ (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Accommodation costs for contract signing":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ နေရာထိုင်ခင်းစရိတ်",
    "Accommodation costs for contract signing is required":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ နေရာထိုင်ခင်းစရိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Medical check-up fee?": "ရောဂါ(၆)မျိုးဆေးစစ်ခ",
    "Medical check-up fee? is required":
      "ရောဂါ(၆)မျိုးဆေးစစ်ခ (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Passport application fee?": "ပတ်စပို့လျှောက်သည့် ကုန်ကျစရိတ်",
    "Passport application fee? is required":
      "ပတ်စပို့လျှောက်သည့် ကုန်ကျစရိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Overseas Worker Identification Card (OWIC) fee?":
      "ပြည်ပအလုပ်ကိုင်အလုပ်သမားသတ်သေခံကတ်ပြား လျှောက်သည့်ကုန်ကျစရိတ်",
    "Overseas Worker Identification Card (OWIC) fee? is required":
      "ပြည်ပအလုပ်ကိုင်အလုပ်သမားသတ်သေခံကတ်ပြား လျှောက်သည့်ကုန်ကျစရိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Travel costs for a job interview?":
      "အလုပ်အင်တာဗျူးဖြေရန် သွားရသည့်လမ်းခရီးကုန်ကျစရိတ်",
    "Travel costs for a job interview? is required":
      "အလုပ်အင်တာဗျူးဖြေရန် သွားရသည့်လမ်းခရီးကုန်ကျစရိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Transportation cost to and from for contract signing?":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ လမ်းခရီးစရိတ်",
    "Transportation cost to and from for contract signing? is required":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ လမ်းခရီးစရိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Meals for contract signing?":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ စားသောက်ရေးစရိတ်",
    "Meals for contract signing? is required":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ စားသောက်ရေးစရိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Transportation costs to the border or airport?":
      "လေဆိပ် (သို့မဟုတ်)နယ်စပ်ထိသွားရမည့် လမ်းခရီးကုန်ကျစရိတ် ",
    "Transportation costs to the border or airport? is required":
      "လေဆိပ် (သို့မဟုတ်)နယ်စပ်ထိသွားရမည့် လမ်းခရီးကုန်ကျစရိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်) ",
    "Costs and fees for recruitment in the destination country":
      "သွားရောက်အလုပ်လုပ်မည့်နိုင်ငံတွင်း လုပ်သားခေါ်ယူစုဆောင်းမှု စရိတ်များ",
    "Select the desitnation country currency":
      "မိခင် (မူရင်း) နိုင်ငံရဲ့ ငွေကြေး ကို ရွေးပါ",
    "Destination country Currency": "မိခင် (မူရင်း) နိုင်ငံရဲ့ ငွေကြေး ",
    "Transportation cost from the border or airport to the workplace (or to the destination country)?":
      "နယ်စပ် (သို့) လေဆိပ်မှ ဆိုက်ရောက်နိုင်ငံမှာ ရှိတဲ့ အလုပ်လုပ်ရမည့်စက်ရုံထိ လမ်းခရီးကုန်ကျစရိတ်",
    "Transportation cost from the border or airport to the workplace (or to the destination country)? is required":
      "နယ်စပ် (သို့) လေဆိပ်မှ ဆိုက်ရောက်နိုင်ငံမှာ ရှိတဲ့ အလုပ်လုပ်ရမည့်စက်ရုံထိ လမ်းခရီးကုန်ကျစရိတ် (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Visa fee?": "ဗီဇာကြေး",
    "Visa fees is required": "ဗီဇာကြေး (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Work permit?": "ဝေါ့ခ်ပါမစ်ကြေး",
    "Work Permit fees is required": "ဝေါ့ခ်ပါမစ်ကြေး (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Medical check-up fee (if I have to take another check up)?":
      "တကယ်လို့နောက်တစ်ကြိမ်ဆေးစစ်ဖို့လိုလာပါက ဆေးစစ်ခ",
    "Medical check-up fee (if I have to take another check up)? is required":
      "တကယ်လို့နောက်တစ်ကြိမ်ဆေးစစ်ဖို့လိုလာပါက ဆေးစစ်ခ (ဖြည့်ပေးရန်လိုအပ်ပါသည်)",
    "Hospitalization Insurance": "ဆေးရုံ  အာမခံ",
    "Other Costs": "အခြားစရိတ်များ",
    "Please provide any additional information on other costs that are not being covered above":
      "အပေါ်တွင်ဖော်ပြထားသည့်စရိတ်များအပြင် အခြားအချက်အလက်များရှိပါကဖော်ပြပေးပါ",
    "Please provide any additional information on other costs that are not being covered above. ":
      "အပေါ်တွင်ဖော်ပြထားသည့်စရိတ်များအပြင် အခြားအချက်အလက်များရှိပါကဖော်ပြပေးပါ",
    "Information on repatriation": "နေရပ်ပြန်ရေးဆိုင်ရာ အချက်အလက်",
    "Please describe how you and the employer would arrange the repatriation of migrant workers":
      "အေဂျင်စီနှင့်အလုပ်ရှင်မှ ရွေ့ပြောင်းလုပ်သားများနေရပ်ပြန်ရေးအတွက် မည်သို့စီစဉ်ထားသည်ကိုဖော်ပြပေးပါ။",
    "Please describe how you and the employer would arrange the repatriation of migrant workers after the completion of the contract, if the worker has difficulties performing the job or if workers need to return to the origin country for personal reasons.":
      "အေဂျင်စီနှင့်အလုပ်ရှင်မှ ရွေ့ပြောင်းလုပ်သားများစာချုပ်သက်တမ်းကုန်သည်အထိအလုပ်လုပ်ပြီးသည့်အခါနေရပ်ပြန်ရေးအတွက် မည်သို့စီစဉ်ထားသည်ကိုဖော်ပြပေးပါ။ (သို့) အကယ်၍ လုပ်သားများမှ စာချုပ်သက်တမ်းမပြည့်မီ အလုပ်မလုပ်နိုင်၍ဖြစ်စေ အကြောင်းကြောင်းကြောင့်နေရပ်ပြန်လို့အခါ မည်သို့စီစဉ်ထားသည်ကိုဖော်ပြပေးပါ",
    "Employer Name": "အလုပ်ရှင်အမည်",
    "Employer Address": "အလုပ်ရှင်လိပ်စာ",
    "Employer Size": "လုပ်ငန်းအရွယ်အစား",
    "Number of Male Needed": "ခေါ်ယူလိုသော အမျိုးသားလုပ်သားအရေအတွက်",
    "Number of Female Needed": "ခေါ်ယူလိုသော အမျိုးသမီးလုပ်သားအရေအတွက်",
    "NRC Card Holder": "မှတ်ပုံတင်ကတ်ကိုင်ဆောင်သူ",
    "OWIC Card Holder": "ပြည်ပအလုပ်ကိုင်အလုပ်သမားသတ်သေခံကတ်ကိုင်ဆောင်သူ",
    "SKILL TRAINING INFORMATION": "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်း",
    "Who pays cost of skill-training?":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်း ကုန်ကျစရိတ်ကိုဘယ်သူပေးမှာလဲ။",
    "Does the employer provide accommodation during skill training?":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် နေရာထိုင်ခင်းကို အလုပ်ရှင်မှ တာဝန်ယူပေးမှာလား။",
    "Does the employer provide meals during skill training?":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် စားသောက်ရေးကို အလုပ်ရှင်မှ တာဝန်ယူပေးမှာလား။",
    "Who pays transportation costs to and from for attending skill training?":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်ဖို့ လမ်းခရီးစရိတ်ကို ဘယ်သူပေးမှာလဲ။",
    "COSTS FOR PRE-DEPARTURE": "ပြည်ပမထွက်ခွာမီ ကုန်ကျစရိတ်",
    "Who pays cost of pre-departure orientation training?":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းအတွက်ကုန်ကျစရိတ်ကို ဘယ်သူကပေးရမှာလဲ။",
    "Who pays for acoomodation during pre-departure orientation training?":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းတက်နေစဉ် နေရာထိုင်ခင်းအတွက်ကုန်ကျစရိတ်ကို ဘယ်သူကပေးရမှာလဲ။",
    "Who pays for meals during pre-departure orientation training?":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် စားသောက်ရေးအတွက်ကုန်ကျစရိတ်ကို ဘယ်သူကပေးရမှာလဲ။",
    "Who pays the cost of transportation to and from pre-departure orientation training?":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းတက်နေစဉ် သွားရလာရကုန်ကျစရိတ်ကို ဘယ်သူကပေးရမှာလဲ။",
    "COSTS AND FEES FOR RECRUITMENT IN THE ORIGIN COUNTRY":
      "မိခင်နိုင်ငံတွင်း လုပ်သားခေါ်ယူစုဆောင်းမှုစရိတ်များ",
    "Who pays the recruitment service fee to RA and sub-agent?":
      "အေဂျင်စီနှင့် လက်အောက်ခံကိုယ်စားလှယ်များအတွက် လုပ်သားခေါ်ယူစုဆောင်းမှုဝန်ဆောင်ခကို ဘယ်သူကပေးရမှာလဲ။",
    "Who pays travel costs for a job interview?":
      "အလုပ်အင်တာဗျူးဖြေရန် သွားရသည့်လမ်းခရီးကုန်ကျစရိတ်ကိုဘယ်သူပေးမှာလဲ။",
    "Who pays accommodation costs for contract signing":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ နေရာထိုင်ခင်းစရိတ်ကို ဘယ်သူကပေးရမှာလဲ။",
    "Who pays transportation cost to and from for contract signing?":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ လမ်းခရီးစရိတ်ကို ဘယ်သူကပေးရမှာလဲ။",
    "Who pays for meals during contract signing?":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ စားသောက်ရေးအတွက်ကုန်ကျစရိတ်ကို ဘယ်သူကပေးရမှာလဲ။",
    "Who pays medical check-up fee?":
      "ရောဂါ၆မျိုး ဆေးစစ်ခကိုရော ဘယ်သူကပေးရမလဲ။",
    "Who pays passport application fee?":
      "ပတ်စပို့လျှောက်သည့် ကုန်ကျစရိတ်ကို ဘယ်သူကပေးရမလဲ။",
    "Who pays Overseas Worker Identification Card (OWIC) fee?":
      "ပြည်ပအလုပ်ကိုင်အလုပ်သမားသတ်သေခံကတ်ပြား လျှောက်သည့်ကုန်ကျစရိတ်ကို ဘယ်သူကပေးရမှာလဲ။",
    "Who pays transportation costs to the border or airport?":
      "လေဆိပ် (သို့မဟုတ်)နယ်စပ်ထိသွားရမည့် လမ်းခရီးကုန်ကျစရိတ်ကို ဘယ်သူကပေးရမှာလဲ။ ",
    "COSTS AND FEES FOR RECRUITMENT IN THE DESTINATION COUNTRY":
      "သွားရောက်အလုပ်လုပ်မည့်နိုင်ငံတွင်း လုပ်သားခေါ်ယူစုဆောင်းမှု စရိတ်များ",
    "Who pays transportation cost from the border or airport to the workplace (or to the destination country)?":
      "ထိုင်းနယ်စပ်မှ သူနိုင်ငံထဲ (သို့) အလုပ်လုပ်ရမည့်စက်ရုံထိ လမ်းခရီးကုန်ကျစရိတ်ကို ဘယ်သူကပေးရမှာလဲ။ ",
    "Who pays Visa fee?": "ဗီဇာကြေးကို ဘယ်သူကပေးရမလဲ။",
    "Who pays work permit?": "အလုပ်ပါမစ်ကြေးကို ဘယ်သူကပေးရမလဲ။",
    "Who pays medical check-up fee (if I have to take another check up)?":
      "တကယ်လို့နောက်တစ်ကြိမ်ဆေးစစ်ဖို့လိုလာပါက ဆေးစစ်ခကိုဘယ်သူကပေးရမလဲ။",
    "Who pays for Hospitalization Insurance?":
      "ဆေးရုံအာမခံအတွက် ကုန်ကျစရိတ်ကို ဘယ်သူကပေးရမလဲ။",
    "COVID-19 RELATED COSTS AND PROCEDURES":
      "ကိုဗစ်-၁၉ရောဂါဆိုင်ကုန်ကျစရိတ်နှင့် လုပ်ငန်းစဉ်များ",
    "Who pays for the mandatory PCR test fee prior to departure?":
      "မဖြစ်မနေစစ်ရမည့် PCR test ဆေးစစ်ခကို ဘယ်သူကပေးရမှာလဲ။",
    "Who pays the PCR test fee on Thailand side?":
      "ထိုင်းနိုင်ငံဘက်တွင်စစ်ရမည့် PCR test ဆေးစစ်ခကို ဘယ်သူကပေးရမှာလဲ။",
    "Who pays for the quarantine in the destination country?":
      "အလုပ်လုပ်မည့်နိုင်ငံသို့ရောက်သည့်အခါ ကွာရန်တင်းကုန်ကျစရိတ်ကို ဘယ်သူကပေးရမှာလဲ။",
    "Who pays for the Covid-19 insurance fee in the destination country?":
      "အလုပ်လုပ်မည့်နိုင်ငံသို့သွားသည့်အခါ လိုအပ်တဲ့ ကိုဗစ်-၁၉ရောဂါ အာမခံကြေးကို ဘယ်သူကပေးရမှာလဲ။",
    "Who pays for the Covid-19 vaccination fee?":
      "ကိုဗစ်-၁၉ရောဂါကာကွယ်ဆေးဖို့ကို ဘယ်သူကပေးရမှာလဲ။",
    "Other costs": "အခြားကုန်ကျစရိတ်များ",
    "Meals Allowance": "",
    "Any additional leave detail": "အခြားသီးသန့်ခွင့်ရက်များ အသေးစိတ်",
    "Edit & Post": "အလုပ်ခေါ်စာကိုပြင်ပြီးတင်မည်",
    "Account:": "အကောင့်",
    "Type:": "အမျိုးအစား",
    "Email:": "အီးမေးလ်",
    "Joined:": "စတင်အသုံးပြုခဲ့သည်",
    "Updated:": "ပြင်ဆင်ခဲ့သည်",
    "Please Select An Employer To Fill Employer's Information":
      "အလုပ်ရုံ/စက်ရုံအကြောင်းဖြည့်ရန် လုပ်ငန်းအမည်ကိုရွေးပေးပါ",
    "Please Select An Employer": "လုပ်ငန်းအမည်ကိုရွေးပေးပါ",
    "Select Size": "လုပ်ငန်းအရွယ်အစားရွေးပေးပါ",
    "Please input title": "အလုပ်အကိုင်အမည်ထည့်ပါ",
    "Additional information": "အခြားအချက်အလက်များ",
    "Profile:": "ကုမ္ပဏီအချက်အလက်",
    "Address was successfully updated.":
      "လိပ်စာကိုအောင်မြင်စွာပြင်ဆင်ပြီးပါပြီ",
    "Something went wrong while updating the address. Please contact a developer":
      "လိပ်စာကိုပြင်ဆင်နေစဉ် အမှားတစ်ခုခုဖြစ်ပေါ်သွားပါသည်၊ ကျေးဇူးပြု၍ ရွှေအိပ်မက် သို့ ဆက်သွယ်ပါ",
    "Please enter Main Contact Name": "ဆက်သွယ်ရမည့်တာဝန်ခံအမည်ကို ရေးပါ",
    "Company name": "ကုမ္မဏီ အမည်",
    "Please enter Company name": "အေဂျင်စီအမည်ကို ရေးပါ",
    "Please select Working since": "လုပ်ငန်းစတင်ခဲ့သည့်အချိန်ကိုရွေးပါ",
    "License number": "လုပ်ငန်း လိုင်စင် အမှတ်",
    "Please enter License number": "လုပ်ငန်း လိုင်စင်အမှတ်ကိုဖြည့်ပေးပါ",
    "Max length of the license number is 12":
      "လုပ်ငန်း လိုင်စင်တွင် ဂဏန်းအများဆုံး၁၂လုံးထိသာရှိပါသည်",
    "Valid till": "သက်တမ်း နောက်ဆုံးနေ့",
    "Please select Valid till": "သက်တမ်း နောက်ဆုံးနေ့ရွေးပေးပါ",
    "Thai License number": "ထိုင်းလိုင်စင်အမှတ်",
    "Please select Thai License Number": "ထိုင်းလိုင်စင်အမှတ်ကိုဖြည့်ပေးပါ",
    "Thai License Expiry Date": "ထိုင်းလိုင်စင်သက်တမ်းကုန်ဆုံးရက်",
    "Please select Thai License Expiry Date":
      "ထိုင်းလိုင်စင်သက်တမ်းကုန်ဆုံးရက်ကိုရွေးပေးပါ",
    "Please describe the years of experience to specific industries to which countries, the years of experience to specific industries to which countries with zero costs and sub agents information":
      "ကျေးဇူးပြု၍ သက်ဆိုင်ရာနိုင်ငံသို့လုပ်သားများအားပို့ပေးနေသည့် လုပ်ငန်းအမျိုးအစားလိုက် အတွေ့ အကြုံသက်တမ်းခုနှစ်၊ အကယ်၍ Zero costဖြင့် လုပ်သားများအားပို့ဆောင်ပေးဖူးပါက ပို့ဆောင်ပေးဖူးသည့် နိင်ငံနှင့် လုပ်ငန်းအမျိုးအစားအလိုက် ဖော်ပြပေးပါ။ နယ်ကိုယ်စားလှယ်များရှိပါကလည်း နယ်ကိုယ့်စားလှယ်ထားရှိထားသည့် မြို့နယ် စသည်ဖြင့် မိမိလုပ်ငန်းအကြောင်းအကျဉ်းကို ဖော်ပြပေးပါ။",
    "About us": "အေဂျင်စီအကြောင်း",
    "Discard changes": "အပြောင်းအလဲ ဆက်လက်မလုပ်ပါ",
    Save: "သိမ်းဆည်းမည်",
    "Main contact name:": "အဓိက ဆက်သွယ်ရန်",
    "Company name:": "ကုမ္မဏီ အမည်",
    "Working since:": "လုပ်ငန်းစတင်ခဲ့သည်",
    "License number:": "လိုင်စင် အမှတ် -",
    "Valid till:": "သက်တမ်း နောက်ဆုံးနေ့",
    "Thai license number:": "ထိုင်းလိုင်စင်အမှတ်",
    "Thai license expiry date:": "ထိုင်းလိုင်စင်သက်တမ်းကုန်ဆုံးရက်",
    "Business Images": "လုပ်ငန်းဓါတ်ပုံများ",
    "Upload Cover Photo": "လုပ်ငန်းကာဗာပုံကိုတင်ရန်",
    "Please enter email": "အီးမေးလ်ထည့်ပါ",
    "Please enter Phone Number": "ဖုန်းနံပါတ်ဖြည့်ပေးပါ",
    "Add more": "ဖုန်းနံပါတ်ထပ်ထည့်ရန်",
    "Website:": "ဝက်ဘ် ဆိုက် ( URL)",
    "Facebook:": "ဖေ့စ်စဘုတ်( URL )",
    "Line:": "လိုင်းစိမ်း ( ID)",
    "Viber:": "ဘိုင်ဘာ နံပါတ်",
    "Phone numbers:": "ဖုန်းနံပါတ်",
    "Please select Country, State, City":
      "နိုင်ငံ ပြည်နယ်/ တိုင်း  မြို့ ရွေးပေးပါ",
    "Country:": "နိုင်ငံ",
    "State/Province:": "ပြည်နယ် / ခရိုင်",
    "City/District:": "မြို့ / မြို့နယ်",
    "Street:": "လမ်း",
    "Zip:": "စာပို့သင်္ကေတ အမှတ်",
    "Issara will contact you to verify that you actually the owner of this business":
      "လုပ်ငန်းရှင် ဟုတ်/မဟုတ် စမ်းစစ် အတည်ပြုခြင်းအတွက် အစ်ဆာရာမှ သင့်ထံ ဆက်သွယ်လာပါမည်။",
    "Successfully shortlisted the resume with job !":
      "ဒီအလုပ်အတွက် ပထမဦးစားပေးစာရင်းမှာ ထည့်သွင်းလိုက်ပါပြီ။",
    "Moved to general waitlist":
      "အထွေထွေစောင့်ဆိုင်းစာရင်းထဲကို ရွှေ့လိုက်ပါပြီ",
    "Are you sure you would like to shortlist this jobseeker? This action cannot be undone later!":
      "ဤအလုပ်လျှောက်လွှာကို ပထမအဆင့်​ရွေးချယ်သည့်စာရင်းတွင်ထည့်ရန် သေချာပါသလား။ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန် ပြင်၍ မရပါ။ ",
    "Shortlist now": "ဆန်ခါတင်စာရင်းထဲထည့်မည် ",
    "Please Choose A Job to shortlist the Jobseeker":
      "အလုပ်လျှောက်ထားသူအား ပထမဦးစားပေးစာရင်းမှာ ထည့်ချင်တဲ့ အလုပ်ကို ရွေးပါ",
    "Resume Detail": "ကိုယ်ရေးအချက်အလက် အသေးစိတ်",
    "Shortlisting...": "ပထမ ဦးစားပေး စာရင်းထဲထည့်မည်",
    "Successfully shortlisted the resume !":
      "အလုပ်လျှောက်လွှာအား ပထမ ဦးစားပေးစာရင်းထဲသို့ ထည့်သွင်းလိုက်ပါပြီ",
    "Search name": "နာမည်ဖြင့် ရှာမည် ",
    "Search Phone Number": "ဖုန်းနံပါတ်ဖြင့်ရှာမည် ",
    "Detail View": "အသေးစိတ်ကြည့်ရန်",
    "Shortlisted At": "ပထမအဆင့်​ရွေးချယ်ထားလိုက်ပါပြီ ",
    "Viewed At": "ကြည့်ရှုပြီး ",
    "Applied resumes": "လျှောက်ထားပြီး ",
    "Shortlisted resumes": "ပထမအဆင့်​ရွေးချယ်ထားလိုက်ပါပြီ ",
    "Viewed resumes": "ကြည့်ရှုပြီး ",
    "Fetching...": "အချက်အလက်များ ရယူနေသည်",
    "Shortlist with JOBS": "ဆန်ခါတင်စာရင်းထဲထည့်မည် ",
    "Are you sure you would like to shortlist ? This action cannot be undone later!":
      "ဤအလုပ်လျှောက်လွှာကို ပထမအဆင့်​ရွေးချယ်သည့်စာရင်းတွင်ထည့်ရန် သေချာပါသလား။ဤလုပ်ဆောင်ချက်ကိုနောက်မှပြန် ပြင်၍ မရပါ။ ",
    "Golden Dreams": "ရွှေအိပ်မက်",
    "Golden Dreams Marketplace ©{date} Created by {issara}":
      "ရွှေအိပ်မက်အလုပ်ရှာဖွေရာနေရာ ©{date} တွင် {issara}မှ ဖန်တီးသည်",
    "Privacy Policy": "ကိုယ်​ရေးကိုယ်တာအချက်အလက်လုံခြုံ​ရေးဆိုင်ရာမူဝါဒ",
    Disclaimer: "ရှင်းလင်းချက်",
    "Issara Staff": "အစ်ဆာရာဝန်ထမ်း",
    Recruiter: "လုပ်သားစုစောင်းသူ",
    "Golden Dreams Marketplace ©{date} Created by Issara Institute":
      "ရွှေအိပ်မက်အလုပ်ရှာဖွေရာနေရာ ©{date} တွင် {issara}မှ ဖန်တီးသည်",
    "Reset your password": "လျို့ဝှက်ကုတ် ပြန်လည် သတ်မှတ်မည်",
    "The input is not valid Email": "ဤအီးမေးလ်လိပ်စာ မမှန်ပါ",
    "Please input your Email": "အီးမေးလ် ထည့်သွင်းပါ",
    "Send reset link": "လျို့ဝှက်ကုဒ်ပြန်လည်သတ်မှတ်ရန်လင့်ခ်ပို့ပါ",
    "We will send password reset link to this email address, please change your password within 24 hours.":
      "အီးမေးလ်လိပ်စာသို့လျို့ဝှက်ကုဒ်ပြန်လည်သတ်မှတ်ရန်လင့်ခ်ကိုပို့ပေးပြီး ၂၄နာရီအတွင်းလျို့ဝှက်ကုဒ်ပြောင်းရပါမည်",
    "Golden Dreams Job Marketplace": "ရွှေအိပ်မက်အလုပ်ရှာဖွေရာနေရာ",
    "Powered by Issara Institute": "အစ်ဆာရာမှ ပံ့ပိုးသည်",
    "Register and login here to modernize and digitize how your business manages labour recruitment ->":
      "သင့်လုပ်ငန်း၏ လုပ်သားစုစောင်းရေးအား ခေတ်မှီနည်းပညာ သုံး၍ ဆောင်ရွက်ရန် ဤ နေရာမှာ စာရင်းသွင်း၍ အကောင့်ဝင်ပါ",
    Login: "အကောင့်ဝင်ရန်",
    Password: "လျို့ဝှက်ကုဒ်",
    "Forgot Password?": "လျို့ဝှက်ကုတ် မေ့နေပါသလား?",
    "Please input your password!": "လျှို့ဝှက်ကုတ် ရိုက်ထည့်ပါ",
    "Not registered?": "မှတ်ပုံ မတင်ရသေးတာလား?",
    "Create an account": "အကောင့်ဖွင့်မည်",
    "Oops! You tried to log in with a job seeker account. Please check and try again.":
      "သည်းခံပါ သင်သည် အလုပ်ရှာဖွေသူအကောင့်တစ်ခုဖြင့် ဝင်ရောက်ရန် ကြိုးစားခဲ့သည်။ ကျေးဇူးပြု၍ စစ်ဆေးပြီး ထပ်မှန် စမ်းကြည့်ပါ။",
    "Log in with Business Account": "လုပ်ငန်းအကောင့်ဖြင့် ဝင်ရောက်ပါ။",
    "Sorry, you are not authorized to access this page.":
      "ဝမ်းနည်းပါတယ်၊ ဤစာမျက်နှာကို သင်ဝင်ရောက်ကြည့်ရှုခွင့်မရှိပါ။",
    "Use another account": "အခြားအကောင့် အသုံးပြုပါ",
    "Go back to console": "ခလုတ်သို့ ပြန်သွားပါ",
    "Sorry, the page you visited does not exist.":
      "ဝမ်းနည်းပါတယ်။ သင်ဝင်ရောက်သော စာမျက်နှာ ရှိမနေပါ",
    "Back Home": "ပင်မ စာမျက်နှာသို့ ပြန်သွားပါ",
    "Successfully changed password":
      "လျို့ဝှက်ကုတ် အောင်မြင်စွာ ပြောင်းလဲပြီးပါပြီ",
    "You'll be redirected to login page shortly":
      "အကောင့်ဝင်ရန် နေရာသို့ မကြာမှီ သင်ရောက်ရှိပါမည်",
    "Go to login page": "အကောင့်ဝင်ရန် နေရာသို့ ပြန်သွားပါ",
    "Password reset failed": "လျို့ဝှက်ကုတ် ပြောင်းလဲခြင်း မအောင်မြင်ပါ",
    "The reset link has expired. Please request a new one.":
      "လျို့ဝှက်ကုတ် ပြောင်းလဲနိုင်သောလင့်ခ် သက်တမ်းကုန်ပါပြီ လင့်ခ် အသစ်ထပ်မံတောင်းခံပါ",
    "New Password": "လျို့ဝှက်ကုတ် အသစ်",
    "Confirm Password": "လျို့ဝှက်ကုတ်အသစ် ကို အတည်ပြုပါ",
    "Please confirm your password!": "လျို့ဝှက်ကုတ်အသစ် ကို အတည်ပြုပါ",
    "The two passwords that you entered do not match!":
      "သင်ရေးသွင်းသော လျို့ဝှက်ကုတ် အသစ် နှစ်ခု တူညီမှု ရှိမနေပါ",
    "Update password": "လျို့ဝှက်ကုတ်အား ပြင်ဆင်ပါ",
    "Create Account": "အကောင့်ဖွင့်မည်",
    "We are:": "ကျွန်ုပ်တို့အကြောင်း",
    "Please select your business type!": "လုပ်ငန်းအမျိုးအစားရွေးချယ်ပါ",
    "Recruitment Agency": "အလုပ်ရှာဖွေရေးအေဂျင်စီ",
    "Business Name": "လုပ်ငန်းအမည်",
    "What is your offical business name?": "သင်၏ တရားဝင် လုပ်ငန်းအမည် ဖော်ပြပါ",
    "Please input your business name!": "သင်၏ လုပ်ငန်းအမည် ကို ရေးသွင်းပါ",
    "The input is not valid E-mail!": "ဖြည့်သွင်းထားသည့်အီးမေးလ်ပုံစံမမှန်ပါ",
    "Please input your E-mail!": "အီးမေးလ် ထည့်သွင်းပေးပါ",
    "Create your free account": "အကောင့် အခမဲ့ဖွင့်ပါ",
    "By clicking register, you agree to our {privacy_policy}.":
      "မှတ်ပုံတင်မည် ကို နှိပ်လိုက်ခြင်းသည် ကျွနု်ပ် တို့ရဲ့ သုံးစွဲသူ သဘောတူညီချက် နှင့် ကိုယ်​ရေးကိုယ်တာအချက်အလက်လုံခြုံ​ရေးဆိုင်ရာမူဝါဒကို လိုက်နာရန် လက်ခံလိုက်မည်ဖြစ်ပါသည်",
    "privacy policy": "ကိုယ်​ရေးကိုယ်တာအချက်အလက်လုံခြုံ​ရေးဆိုင်ရာမူဝါဒ",
    "Already have an account?": "အကောင့်ဖွင့်ပြီးသားလား ?",
    "Login here": "ဤနေရာ တွင် အကောင့်ဝင်ပါ",
    "Email verification in progress..": "အီးမေးလ်အားအတည်ပြုနေဆဲ",
    "Successfully verified email": "အီးမေးလ်ကိုအောင်မြင်စွာအတည်ပြုပြီးပြီ",
    "Please follow further instructions in the console":
      "အကူအညီရယူရန်နောက်ထပ်ညွှန်ကြားချက်များကိုဆောင်ရွက်ပါ",
    "Go to console": "အကူအညီရယူရန်",
    "Email verification failed": "အီးမေးလ်ဖြင့်အတည်ပြုခြင်းမအောင်မြင်ပါ",
    "This link has already expired.": "ဤလင့်ခ်ကိုသုံး၍မရတော့ပါ",
  },
  ne: {
    "Update is available": "अपडेट उपलब्ध छ",
    "Please press OK to refresh the app.":
      "कृपया एप रिफ्रेस गर्न ठीक छ थिच्नुहोस्।",
    "Loading..": "लोडिङ भैरहेको छ.....",
    Male: "पुरुष",
    Female: "महिला",
    "Prefer not to say": "म भन्न चाहान्",
    "Non-Binary": "गैर-द्विआधारी (गैर-बाइनरी)",
    "Passport Include": "पासपोर्ट समावेश",
    "Passport Not Include": "पासपोर्ट समावेश छैन",
    Myanmar: "मियनमार",
    Thailand: "थाइलेण्ड",
    Cambodia: "कम्बोडिया",
    Laos: "लाओस",
    Nepal: "नेपाल",
    Indonesia: "इन्डोनेसिया",
    Indian: "भारतीय",
    Bangladeshi: "बंगलादेशी",
    Others: "अन्य",
    Sunday: "आइतबार",
    Monday: "सोमबार",
    Tuesday: "मंगलबार",
    Wednesday: "बुधबार",
    Thursday: "बिहीबार",
    Friday: "शुक्रबार",
    Saturday: "शनीबार",
    "It Will Vary": "यो फरक हुन सक्ने छ",
    Monthly: "मासिक",
    "Daily Wage Rate": "दैनिक ज्यालादारी दर",
    "Once A Month": "महिनाको एक पटक",
    "Twice A Month": "महिनाको दुइ पटक",
    "Every Week": "हरेक हप्ता",
    "Every 10 days": "हरेक दश दिनमा",
    Other: "अन्य",
    Regularly: "नियमित रुपमा",
    "From time to time": "समय समयमा",
    Infrequently: "वारम्बार",
    Never: "कहिल्यै",
    Day: "दिन",
    Swing: "स्विङ",
    Night: "रात",
    Rotating: "घुमाउँदै",
    Variable: "असमान",
    "Full-time": "पूर्ण कालिन",
    "Part-time": "आंशिक",
    "Moved General Waitlist": "सामान्य प्रतीक्षा सूची सारियो",
    Waitlisted: "प्रतीक्षा सूचीमा",
    "Contract Signed": "सम्झौतामा हस्ताक्षर गरियो ",
    Accepted: "स्वीकार गरिएको",
    Applied: "प्रयोगमा रहेको",
    Downloaded: "डाउनलोड गरिएको",
    Offered: "अफर गरिएको",
    Rejected: "अस्वीकृत गरिएको",
    Shortlisted: "छनोट गरिएको",
    Viewed: "हरिएको",
    "Documents Requested": "कागजातहरु अुनुरोध गरिएको",
    "Documents request accepted": "कागजातहरुको अनुरोध स्वीकार गरेको",
    "Documents request rejected": "कागजातहरुको अनुरोध अस्वीकार गरेको",
    "HR Assistant": "मानव संशाधन सहायक",
    "HR Interpreter": "मानव संंशाधन दोभाषे",
    "HR Manager": "मानव संशाधन प्रबन्धक",
    "HR Officer": "मानव संशाधन अधिकृत",
    "Candidate pays fees in advance, then is reimbursed by company after starting work":
      "कामदारले अग्रिम रुपमा शुल्क तिर्ने र काम गर्न शुरु गरे पछि कम्पनीले फिर्ता दिने",
    "Company pays all fees in advance": "कम्पनीले शुरुमै  सबै शुल्क तिरिदिने",
    "India (+91)": "भारत (+९१)",
    "Myanmar (+95)": "म्यानमार (+९५)",
    "Nepal (+977)": "नेपाल (+९७७)",
    "Thailand (+66)": "थाइल्याण्ड (+६६)",
    "Malaysia (+60)": "मलेसिया (+६०)",
    "Cambodia (+855)": "कम्बोडिया (+८५५)",
    "A Little Bit": "अलिकति",
    Moderately: "मध्यम रूपमा",
    "Very Well": "धेरै राम्रो",
    None: "कुनै पनि होइन",
    Some: "केहि",
    Proficient: "सिपालु",
    English: "अंग्रेजी",
    Thai: "थाइ",
    "Bahasa Malaysia": "बहासा मलेसिया",
    Hindi: "हिन्दी",
    "Cannot read": "पढ्न सकिन्न",
    APPLICABLE: "लागूहुने ",
    APPLIED: "आवेदन दिएको ",
    VIEWED: "बायोडाटा हेरियो",
    DOWNLOADED: "डाउनलोड भएको",
    SHORTLISTED: "छनोट गरिएको",
    OFFERED: "प्रस्ताव गरेको",
    ACCEPTED: "स्वीकार गरेको",
    REJECTED_BY_BUSINESS: "व्यवसाय द्वारा अस्वीकृत",
    REJECTED_BY_APPLICANT: "आवेदक द्वारा अस्वीकृत",
    CONTRACT_SIGNED: "सम्झौतामा हस्ताक्षर भयो",
    APPLICANT_NA: "आवेदक NA",
    WAITLISTED: "प्रतीक्षा सूचीमा",
    MOVED_GENERAL_WAITLIST: "प्रतीक्षा सूचीमा पठाउनुहोस्",
    "STATUS N/A": "स्थिति N/A",
    "Cannot speak": "बोल्न नसक्ने",
    "Network Error!": "नेटवर्कमा समस्या!",
    "User with this username already exists":
      "यस प्रयोगकर्ताको युजरनेम पहिले नै प्रयोग भएको छ",
    "You are trying to login with Business User Account !":
      "तपाईं व्यापार प्रयोगकर्ता खाताको साथ लगइन गर्न प्रयास गर्दै हुनुहुन्छ!",
    "There was an authentication problem": "प्रमाणीकरणमा समस्या आयो",
    "Basic Information": "आधारभूत जानकारी",
    Gender: "लिङ्ग",
    "Date of Birth": "जन्म मिति",
    "Marital Status": "बैवाहिक स्थिती",
    Language: "भाषा",
    Education: "शिक्षा",
    "Phone number": "फोन नम्बर",
    Email: "इमेल",
    Address: "ठेगाना",
    "National ID": "राष्ट्रिय परिचय पत्र",
    "Passport Number": "राहदानी नम्बर",
    "Expires at": "समाप्त हुने मिति",
    "Measurements & Health": "नापजाँच र स्वास्थ्य",
    Weight: "तौल",
    kg: "किलो ग्राम",
    lb: "पाउण्ड",
    Height: "उचाई",
    cm: "सेण्टिमिटर",
    ft: "फिट",
    in: "ईन्च",
    "Have undergone medical operation recently":
      "हालसालै कुनै स्वास्थ्य सम्बन्धी शल्यक्रिया गरेको",
    yes: "छ ",
    no: "छैन",
    "Work Experience": "कामको अनुभव",
    "{duration} years": "{duration} वर्ष",
    "Advanced Search": "विशेष खोजी",
    "Workers who have more than 12 months of experience in this industry":
      "यो उद्दोग वा क्षेत्रमा १२ वर्ष भन्दा बढि कामको अनुभव भएका कामदारहरु",
    Industry: "उद्दोग वा क्षेत्र",
    "Workers {language} proficiency": "कामदारको {language} अब्बलता",
    "Language Proficiency": "भाषा सम्बन्धी अब्बलता",
    "Workers current location": "कामदारको हालको ठेगाना",
    Province: "प्रदेश",
    "Application has been rejected": "निवेदन अस्वीकृत गरिएको",
    "An error occurred while rejecting application.":
      "निवेदन अस्वीकृत गर्ने क्रममा त्रुटि देखायो",
    "A request for documents has been sent.":
      "कागजातहरुको लागी अनुरोध गरिएको छ",
    "An error occurred while sending request for documents.":
      "कागजातहरुको लागी अनुरोध गर्दा त्रुटि देखायो",
    "Job offer has been successfully sent.":
      "रोजगारको प्रस्ताव सफलता पूर्वक पठाइयो",
    "An error occurred while sending job offer.":
      "रोजगारको प्रस्ताव पठाउँदा त्रुटि देखायो",
    "Application has been shortlisted": "निबेदन छनोट गरिएको छ",
    "An error occurred while shortlisting application.":
      "निबेदन छनोट गर्दा त्रुटि देखायो",
    "Working Experience": "कामको अनुभव",
    "{experience} years": "{experience} वर्ष",
    View: "हेर्नुहोस्",
    "Are you sure you would like to shortlist the application? This action cannot be undone later.":
      "तपाई यो निवेदन छनोट गर्न निश्चित हुनुहुन्छ? पछि पुन यस पेजमा फर्कन मिल्दैन।",
    Continue: "अघि बढ्नुहोस्",
    Cancel: "रद्द गर्नुहोस्",
    Shortlist: "छनोट गर्नुहोस्",
    "Are you sure you would like to send the job offer? This action cannot be undone later.":
      "तपाई यो रोजगारको प्रस्ताव पठाउन निश्चित हुनुहुन्छ? पछि पुन यस पेजमा फर्कन मिल्दैन।",
    "Send offer": "प्रस्ताव पठाउनुहोस्",
    "Are you sure you would like to request the documents? This action cannot be undone later":
      "तपाई कागजातका लागी अनुरोध गर्नु निश्चित हुनुहुन्छ? पछि पुन यस पेजमा फर्कन मिल्दैन।",
    "Request documents": "कागजात अनुरोध गर्नुहोस्",
    "Please select one of the reasons below.":
      "कृपया तलका मध्ये एउटा कारण छान्नुहोस्",
    "Other rejection reason": "अस्विकार गर्ने अन्य कारणहरु",
    Reject: "अस्विकार गर्नेुस्",
    new: "नयाँ",
    "{number} documents attached": "{number} कागजात समावेस गरिएको",
    "Documents requested {timeAgo}": "कागजात अनुरोध गरिएको {timeAgo}",
    Yes: "छ",
    No: "छैन",
    From: "बाट",
    To: "लाई",
    Passport: "राहदानी",
    Included: "समावेस गरिएको",
    "Application has been waitlisted successfully and sent to application #{id}.":
      "आवेदन सफलतापूर्वक प्रतीक्षा सूचीमा राखिएको छ र #{id} मा पठाइएको छ।",
    "An error occurred while waitlisting the application #{id}.":
      "एप्लिकेसन #{id} लाई प्रतीक्षा सूचीमा राख्दा त्रुटि भयो।",
    "Job offer Expiry Date has been successfully updated.":
      "रोजगार प्रस्तावको म्याद सकिने मिति सफलतापूर्वक अपडेट गरिएको छ।",
    "Roster Application": "रोस्टर आवेदन",
    "Offered will Expire at: ": "प्रस्तावको समयावधि समाप्त हुनेछ मिति:",
    "Update Expiry Date": "समाप्ति मिति",
    "Are you sure you would like to move the selected applicants to waitlist? This action cannot be undone later.":
      "के तपाइँ चयन गरिएका आवेदकहरूलाई प्रतीक्षा सूचीमा सार्न निश्चित हुनुहुन्छ ? यो कार्य पछि पुना गर्न सकिँदैन।",
    "Move to waitlist": "प्रतीक्षा सूचीमा सार्नुहोस्",
    "Quick Filter": "छिटो फिल्टर गर्नुहोस्",
    "Select Available Shift": "उपलब्ध शिफ्ट छान्नुहोस्",
    "{number} matches": "{number} मेल खान्छ",
    "Select city": "शहर छान्नुहोस्",
    "Select country": "देश छान्नुहोस्",
    "Select currency": "मुद्रा छान्नुहोस्",
    "Select Day of Week Off": "साप्ताहिक विदाको दिन छान्नुहोस्",
    "Select Level": "तह छान्नुहोस्",
    "Select policy type": "नितीको प्रकार छान्नुहोस्",
    "Select employer": "रोजगारदाता छान्नुहोस्",
    "Other/not listed here": "अन्यसूचिमा नभएका छान्नुहोस्",
    "Select size": "आकार छान्नुहोस्",
    "Select Industry": "उद्दोग छान्नुहोस्",
    "Select position category": "पदको प्रकार छान्नुहोस्",
    "Select job type": "रोजगार वा कामको प्रकार छान्नुहोस्",
    "Select level": "तह छान्नुहोस्",
    "Select Language": "भाषा छान्नुहोस्",
    "Select Status": "स्थिति छान्नुहोस्",
    "Select Overtime Expectations": "ओभरटाइमको चाहना छान्नु होस्",
    "Select terms": "शर्तहरु छान्नु होस्",
    "Select timing": "समय छान्नुहोस्",
    "Select Reason": "कारण छान्नुहोस्",
    "Select state": "अवस्था छान्नुहोस्",
    "Select industry": "उद्दोग छान्नुहोस्",
    Verification: "प्रमाणीकरण",
    Account: "खाता",
    "My jobs": "मेरो रोजगारीहरु",
    "Post new": "नयाँ पोस्ट गर्नुहोस्",
    Logout: "लगआउट गर्नुहोस्",
    Metrics: "मेट्रिक्स",
    Surveys: "सर्वेक्षणहरू",
    "Verify RA": "RA प्रमाणित गर्नुहोस्",
    "Verify Employers": "रोजगारदाताहरू प्रमाणित गर्नुहोस्",
    "My account": "मेरो खाता",
    Profile: "वायोडाटा",
    Resume: "बायोडाटा",
    Roster: "नामावली",
    Waitlist: " प्रतीक्षा सूची",
    "This business is already managed by another user. Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "यो व्यवसाय पहिले नै अर्को प्रयोगकर्ता द्वारा व्यवस्थित गरिएको छ। के तपाईं यो भर्नाकर्तासँग मर्ज गर्न चाहनुहुन्छ? यो कार्य पछि अनडू गर्न सकिँदैन।",
    "This business is managed by Issara. Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "यो व्यवसाय Issara द्वारा संचालित छ। के तपाईं निश्चित रूपमा यो भर्नाकर्तासँग मर्ज गर्न चाहनुहुन्छ? यो कार्य पछि अनडू गर्न सकिँदैन।",
    "Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "के तपाईं यो भर्नाकर्तासँग मर्ज गर्न चाहनुहुन्छ? यो कार्य पछि अनडू गर्न सकिँदैन।",
    "Yes. Merge": "कृपया यसलाई मार्ज गर्नुहोस्",
    "This business is already in our database. Merge it.":
      "यो व्यवसाय पहिले नै हाम्रो डेटाबेस मा छ। यसलाई मर्ज गर्नुहोस्।",
    Merge: "मर्ज",
    Name: "नाम",
    "Managed By": "द्वारा संचालित",
    "Businesses have been successfully merged":
      "व्यवसायहरू सफलतापूर्वक मर्ज भयो",
    "An error occurred while merging. Please contact a developer.":
      "मर्ज गर्दा त्रुटि भयो। कृपया प्राविधिकसँग सम्पर्क गर्नुहोस्।",
    "Search ...": "खोज...",
    "No employers found": "कुनै पनि रोजगारदाता भेटिएन",
    "recruiter loaded": "भर्नाकर्ता लोड भयो",
    "employers loaded": "रोजगारदाताहरु लोड भयो ",
    "Load More employers": "थप रोजगारदाताहरु लोड गर्नुहोस्",
    "License Number": "इजाजत् नम्बर /लाइसेन्स नम्बर",
    "No recruiters found": "कुनै भर्नाकर्ता फेला परेनन्",
    "recruiters loaded": "भर्नाकर्ता लोड भयो",
    "Load More Recruiters": "थप भर्नाकर्ता लोड गर्नुहोस्",
    "Delete Cover Photo": "कभर फोटो मेटाउनुहोस्",
    "Add Cover Photo": "कभर फोटो थप्नुहोस्",
    "Uploading....": "अपलोड भइरहेको छ...",
    "Upload Cropped Cover Photo": "काटेर ठिक्क पारिएको फोटो अपलोड गर्नुहोस्",
    "Delete This Photo": "यो फोटो मेटाउनुहोस्",
    "Available image upload": "उपलब्ध फोटो अपलोड गर्नुहोस्",
    "Add business image": "ब्यावसायको फोटो हाल्नुहोस् गर्नुहोस्",
    "Upload Cropped Image": "काटेर ठिक्क पारिएको फोटो अपलोड गर्नुहोस्",
    "Password reset link was successfully sent to {email}. Please check your inbox.":
      "पासवर्ड रिसेट गरिएको लिंक इमेलमा {email} पठाइएको छ। कृपया इमेल चेक गर्नुहोस्",
    "Profile has been successfully updated.":
      "व्यक्तिगत विबरण अद्दावधिक गरिएको छ",
    "An error occurred while updating profile. Please contact a developer.":
      "व्यक्तिगत विबरण अद्दावधिक गर्दा त्रुटि देखायो, कृपया कुनै प्राविधिकसँग सम्पर्क गर्नुहोला",
    "Please use one of the following formats: .jpeg, .jpg or .png":
      "कृपया यी मध्ये एउटा ढाँचा प्रयोग गर्नुहोस्ः .jpeg, .jpg or .png",
    "Push notifications have been successfully enabled":
      "आग्रह गर्ने सूचनाहरु सक्रिय पारिएको छ",
    "Sample Notification": "नमूना सूचनाहरु",
    "You will receive me whenever new job application submitted or content added.":
      "जब नया निवेदन वा सामग्री हालिन्छन् तपाईँले मलाई पाउनु हुनेछ।",
    "If you blocked push notifications, you can only undo it in your browser settings.":
      "आग्रह गर्ने सूचनाहरु निेषेध गरिए, यसलाई बाउजर सेटिङमा गएर सुचारू गर्न सकिन्छ।",
    "Push notifications have been successfully disabled":
      "आग्रह गर्ने सूचनाहरु निस्क्रिय पारियो",
    "Verified Business": "कम्पनी प्रमाणीकरण गर्नु होस्",
    Type: "टाइप गर्नुहोस्",
    Employer: "रोजगारदाता",
    Joined: "सामेल हुनुहोस्",
    Updated: "अद्दावधिक गरियो",
    "Push notifications are not supported.":
      "आग्रह गर्ने सूचनाहरु समर्थित् भएनन्",
    "Push notifications": "आग्रह गर्ने सूचनाहरु",
    "Are you sure you want to reset the password?":
      "के तपाई पक्का पासवर्ड रिसेेट गर्न चाहानु हुन्छ?",
    "The reset link will be sent to this email address: {email}":
      "रिसेट लिंक यस इमेलमा पठाइनेछः  {email}",
    Reset: "रिसेट गर्नुहोस्",
    "Reset password": "पासवर्ड रिसेट गर्नुहोस्",
    "Enable view mode": "भ्यू मोड सक्रिय पार्नुहोस्",
    "Enable edit mode": "सम्पादन् मोड सक्रिय पार्नुहोस्",
    "Basic information": "आधारभूत जानकारी",
    Logo: "लोगो",
    "Update logo": "लोगो अपडेट गर्नुहोस्",
    "Company Name ({lang})": "कम्पनीको नाम ({lang})",
    "Please fill in the company name": "कृपया कम्पनीको नाम भर्नुहोस्",
    "Company Name": "कम्पनीको नाम  ",
    "Working since": "देखि काम गर्दै",
    "Registration number": "दर्ता नम्बर",
    "Max length of the registration number is 12":
      "दर्ता नम्बरको अधिकतम लामो १२ हुनु पर्ने",
    "Example: 027/2019": "उदाहरण: ०२७/२०१९",
    Size: "आकार  ",
    "About us ({lang})": "हाम्रो बारेमा ({lang})",
    "Contact information": "सम्पर्कका लागी जानकारी",
    "Main contact name": "मुख्य सम्पर्क नाम",
    "Main contact email": "मुख्य सम्पर्क इमेल",
    "This is not a valid email.": "यो मान्य इमेल होइन।",
    "Website (URL)": "वेबसाइट (URL)",
    "Website(URL) format is incorrect": "वेबसाइट (URL) ढाँचा गलत छ",
    "Facebook (URL)": "फेसबुक (URL)",
    "Facebook url format is incorrect": "Facebook url ढाँचा गलत छ",
    "Viber (phone number)": "भाइबर (फोन नम्बर)",
    "Please use the following format: +999999999":
      "कृपया निम्न ढाँचा प्रयोग गर्नुहोस्: +999999999",
    "Line (ID)": "तपाईको लाइन्(सामाजिक संजाल) नम्बर हाल्नुहोस् ",
    "Line format is incorrect": "लाईनको ढाँचा गलत छ",
    "Phone numbers": "फोन नम्बरहरु",
    "Add new": "नयाँ थप्नुहोस्",
    "Country, State, City": "देश, प्रदेश, शहर",
    "Apt, Building, Street ({lang})": "एप्ट, भवन, सडक ({lang})",
    "Please fill in the address details": "कृपया ठेगाना विवरणहरू भर्नुहोस्",
    Zip: "जिप",
    "Update Business Profile": "व्यापार प्रोफाइल अपडेट गर्नुहोस्",
    Description: "विवरण",
    "Contact email": "सम्पर्क इमेल",
    Website: "वेबसाइट",
    Facebook: "फेसबुक",
    Viber: "भाइबर",
    Line: "लाईन",
    Country: "देश",
    State: "प्रदेश",
    City: "शहर",
    "Apt, Building, Street": "एप्ट, भवन, सडक",
    "Application Details:": "आवेदन विवरण:",
    Back: "पछाडि",
    "Full Name": "पुरा नाम",
    "Phone Number": "फोन नम्बर",
    "Current Address": "हालको ठेगाना",
    "Passport Expiry Date": "राहदानी समाप्ति मिति",
    "After going through the job description, do you think you can do the job well?":
      "कामको विवरण पढिसकेपछि, के तपाईं काम राम्रोसँग गर्न सक्नुहुन्छ जस्तो लाग्छ?",
    "Do you know someone working here?": "यहाँ काम गर्ने कसैलाई चिन्नुहुन्छ?",
    "Languages Proficiency": "भाषाको अव्बलता",
    "Work experience": "कामको अनुभव",
    Duration: "अवधि",
    Remark: "टिप्पणी",
    "Uploaded Documents": "अपलोड गरिएका कागजातहरू",
    Pinkcard: "गुलाबी कार्ड",
    Workpermit: "श्रम अनुमति",
    Visa: "राहदानी",
    "Cancellation Letter": "रद्द पत्र",
    "Download/Print": "डाउनलोड/प्रिन्ट गर्नुहोस्",
    "Are you sure you would like to request the documents? This action cannot be undone later.":
      "के तपाइँ कागजातहरू अनुरोध गर्न निश्चित हुनुहुन्छ? यो कार्य पछि पुन गर्न सकिँदैन।",
    "Application #{id} has been successfully rejected":
      "आवेदन #{id} सफलतापूर्वक अस्वीकार गरिएको छ",
    "A request for documents for application #{id} has been sent":
      "आवेदन #{id} को लागि कागजातहरूको लागि अनुरोध पठाइएको छ",
    "Application #{id} has been successfully shortlisted":
      "आवेदन #{id} सफलतापूर्वक छनोट गरिएको छ",
    "An error occurred while shortlisting application #{id}.":
      "आवेदन #{id} सर्टलिस्ट गर्दा त्रुटि देखायो।",
    "Job offer has been successfully sent to application #{id}.":
      "रोजगार प्रस्ताव सफलतापूर्वक आवेदन #{id} मा पठाइएको छ।",
    "An error occurred while sending job offer to application #{id}.":
      "आवेदन #{id} मा रोजगार प्रस्ताव पठाउँदा त्रुटि देखायो।",
    "View Applications": "आवेदनहरु हेर्नुहोस्",
    "Pending ({count})": "विचाराधीन ({count})",
    "Shortlisted ({count})": "छनोट गरिएको ({count})",
    "Offered ({count})": "प्रस्ताव गरिएको ({count})",
    "Rejected by applicant ({count})": "आवेदक द्वारा अस्वीकृत ({count})",
    "Rejected by business ({count})": "कम्पनी द्वारा अस्वीकृत ({count})",
    "Accepted ({count})": "स्वीकृत ({count})",
    "Select all ({number})": "सबै चयन गर्नुहोस् ({number})",
    "Deselect all ({number})": "सबै ({number}) को चयन हटाउनुहोस्",
    "Reject ({number})": "अस्वीकार ({number})",
    "Are you sure you would like to shortlist selected applications? This action cannot be undone later":
      "के तपाइँ निश्चित रूपमा चयन गरिएका आवेदनहरु सर्टलिस्ट गर्न चाहनुहुन्छ? यो कार्य पछि पुन गर्न सकिँदैन",
    "Shortlist ({number})": "छनोट ({number})",
    "Request documents ({number})": "कागजातहरू अनुरोध गर्नुहोस् ({number})",
    "Are you sure you would like to send the job offer to selected applicants? This action cannot be undone later.":
      "के तपाइँ निश्चितरुपमा आवेदकहरूलाई रोजगार प्रस्ताव पठाउन चाहनुहुन्छ? यो कार्य पछि पुन गर्न सकिँदैन।",
    "Send offer ({number})": "प्रस्ताव पठाउनुहोस् ({number})",
    "Your job has been successfully posted":
      "तपाईको रोजगार सफलतापूर्वक पोस्ट गरिएको छ",
    "View posted jobs": "पोस्ट गरिएका रोजगारहरु हेर्नुहोस्",
    "Post new job": "नयाँ रोजगार पोस्ट गर्नुहोस्",
    "An error occurred while saving draft.": "सेभ गर्दा त्रुटि देखायो",
    "Job has been successfully saved as a draft": "रोजगार ड्राफ्ट सेभ भयो",
    "Create new job": "नयाँ रोजगारी सिर्जना गर्नुहोस्",
    "View drafts": "रोजगार ड्राफ्ट हर्नुहोस्",
    "Post job": "रोजगार पोस्ट गर्नुहोस्",
    "Open to": "खुला गरिएको छ",
    Nationality: "राष्ट्रियता",
    "Choose at least one nationality": "कम्तिमा एउटा राष्ट्रियता छान्नुहोस्",
    Documents: "कागजातहरू",
    "Job Information": "रोजगार जानकारी",
    "Job Title": "रोजगारको शीर्षक",
    "Please enter job title": "कृपया रोजगारको शीर्षक हाल्नुहोस् गर्नुहोस्",
    "Job title": "रोजगारको शीर्षक",
    "Please select industry": "कृपया उद्योग चयन गर्नुहोस्",
    "Job position category": "रोजगारको पदको प्रकार",
    "Job type": "रोजगारको प्रकार",
    "Please select job type": "कृपया रोजगाारको प्रकार छान्नुहोस्",
    "Expiry date": "म्याद सकिने मिति",
    "Please choose expire date": "कृपया म्याद सकिने मिति छान्नुहोस्",
    "Headcount needed": "ब्यक्ति संख्या आवश्यक छ",
    "Please input headcount needed": "ब्यक्ति संख्या हाल्नुहोस्",
    "Number of employees for this position you would like to recruit":
      "यस पदको लागि तपाईले भर्ना गर्ने  कामदारहरुको संख्या",
    "Contract duration": "करार अवधि",
    Months: "महिना",
    "Please input duration": "कृपया अवधि हाल्नुहोस्",
    "Duration of contract, in months": "करारको अवधि, महिनामा",
    Location: "स्थान",
    "Please select country": "कृपया देश चयन गर्नुहोस्",
    "The country of workplace": "कार्यस्थल को देश",
    "Please select state": "कृपया प्रदेश चयन गर्नुहोस्",
    "Please select city": "कृपया सहर चयन गर्नुहोस्",
    "{language} translation": "{language} अनुवाद",
    "Please enter title": "कृपया शीर्षक हाल्नुहोस्",
    "Name of the recruited job": "भर्ना गरिएको कामको नाम",
    "Position details": "पदको विवरण",
    "Please enter position details": "कृपया पद विवरणहरू उल्लेख गर्नुहोस्",
    "Job Description": "रोजगारको विवरण",
    "Please enter job description": "कृपया कामको विवरण उल्लेख गर्नुहोस्",
    "Please describe the responsibilities and environment of this position":
      "कृपया यस पदको जिम्मेवारी र वातावरण वर्णन गर्नुहोस्",
    "Applicant Requirements": "आवेदक आवश्यकताहरू",
    "Please enter applicant requirements":
      "कृपया आवेदक आवश्यकताहरू उल्लेख गर्नुहोस्",
    "Please describe the prerequisite skills or qualifications needed to do this job sucessfully, if any":
      "कृपया यो रोजगार सफलतापूर्वक गर्नको लागि आवश्यक सीप वा योग्यताहरू वर्णन गर्नुहोस्, यदि कुनै छ भने",
    "Contact name": "सम्पर्क नाम",
    "Please enter contact name": "कृपया सम्पर्क नाम उल्लेख गर्नुहोस्",
    "Please provide the name of the contact person for {language} applicants":
      "कृपया {language} आवेदकहरूको लागि सम्पर्क व्यक्तिको नाम उपलब्ध गराउनुहोस्ग",
    "Please enter contact phone number":
      "कृपया सम्पर्क फोन नम्बर उल्लेख गर्नुहोस्",
    "Please provide the phone number of the contact person for {language} applicants":
      "कृपया {language} आवेदकहरूको लागि सम्पर्क व्यक्तिको फोन नम्बर उपलब्ध गराउनुहोस्",
    "Contact job title": "सम्पर्क गर्ने रोजगारको शीर्षक",
    "Please select contact job title":
      "कृपया सम्पर्क गर्ने रोजगारको शीर्षक चयन गर्नुहोस्",
    "Recruitment Information": "भर्ना सम्वन्धी जानकारी",
    "Do you have an ethical recruitment policy?":
      "के तपाइँसँग नितीगत भर्ना नीति छ?",
    "Ethical recruitment policies specify more transparent and ethical conduct in the recruitment of workers":
      "नितीगत भर्ना नीतिहरूले कामदारहरूको भर्नामा थप पारदर्शी र नितीगत आचरण निर्दिष्ट गर्दछ।",
    "Do you have an employer pays policy?":
      "के तपाइँसँग रोजगारदाताले शुल्क तिर्ने नीति छ?",
    "Employer pays policies specify that all costs of recruitment are paid by the employer, and it is guaranteed that zero fees will be charged to workers, including for document processing and fees to recruiters":
      "रोजगारदाताले शुल्क तिर्ने नितिहरुले यो निर्दिष्ट गर्दछ कि भर्नाका सबै लागतहरू रोजगारदाताद्वारा भुक्तान गरिन्छ, र यो सुनिश्चित गर्छ कि कागजी प्रकृया र म्यानपावर कम्पनीलाई दिने भर्ना शुल्क सहित कामदारहरूलाई शून्य लागतमा भर्ना गरिनेछ।",
    "Employer Pays Policy Type": "रोजगारदाताले शुल्क तिर्ने नीतिको प्रकार",
    "Please select policy type": "कृपया नीतिका प्रकार चयन गर्नुहोस्",
    "Who pays the visa fees?": "भिसा शुल्क कसले तिर्छ?",
    Worker: "कामदार",
    Amount: "रकम",
    "Who pays the work permit fees?": "श्रम स्वीकृति शुल्क कसले तिर्छ?",
    "Who pays the medical checkup fees?": "स्वास्थ्य परिक्षण शुल्क कसले तिर्छ?",
    "Contract Information: Pay": "करार समबन्धी जानकारी: तिर्नु पर्ने",
    "Terms of payment": "रकम भुक्तान गर्ने सर्तहरू",
    "Please select terms of payment":
      "कृपया रकम भुक्तानी गर्ने सर्तहरू चयन गर्नुहोस्",
    "Please note whether the employee will be paid according to a daily or monthly rate, and how frequently they will be paid":
      "कृपया ध्यान दिनुहोस् कि कामदारलाई दैनिक वा मासिक दर अनुसार भुक्तान गरिनेछ, र उनीहरूलाई कति पटक भुक्तानी गरिनेछ।",
    "Timing of payment": "भुक्तानीको समय",
    "Please select or create timing of payment":
      "कृपया भुक्तानीको समय चयन गर्नुहोस् वा सिर्जना गर्नुहोस्",
    "Please note whether the employee will be paid according to once a month or twice a month rate, and how frequently they will be paid":
      "कृपया ध्यान दिनुहोस् कि कामदारलाई महिनामा एक पटक वा महिनाको दुई पटकका दरले भुक्तानी गरिनेछ, र उनीहरूलाई कति पटक भुक्तानी गरिनेछ।",
    "Timing of Payment Other": "भुक्तानीको समय अन्य",
    "Please input Timing of Payment Other":
      "कृपया अन्य भुक्तानीको समय उल्लेख गर्नुहोस्",
    "Other type of Payment Timing": "भुक्तानी समय को अन्य प्रकार",
    "Enter Timing of Payment Other": "अन्य भुक्तानीको समय उल्लेख गर्नुहोस्",
    Currency: "मुद्रा",
    "Please select currency": "कृपया मुद्रा चयन गर्नुहोस्",
    "Monthly salary": "मासिक तलब",
    "{currency}/month": "{currency}/महिना",
    "Please input salary": "कृपया तलब उल्लेख गर्नुहोस्",
    "Monthly payment to employee, which must be equal to or greater than the minimum wage for the province":
      "कामदारलाई मासिक तलब, जुन उक्त प्रदेशको लागि न्यूनतम पारिश्रमिक बराबर वा बढी हुनुपर्छ",
    "Daily Wage": "दैनिक ज्याला",
    "{currency}/day": "{currency}/दिन",
    "Please input daily wage rate": "कृपया दैनिक ज्याला दर उल्लेख गर्नुहोस्",
    "Daily wage amount for 8 hours of work, equal to or greater than the minimum daily wage for the province":
      "८ घण्टा कामको लागि दैनिक ज्याला रकम, उक्त प्रदेशको लागि न्यूनतम दैनिक ज्याला बराबर वा बढी ",
    "Available Shifts": "उपलब्ध शिफ्टहरू",
    "What shift is this position expected to offer?":
      "यो पदका लागि काम गर्ने शिफ्ट कुन हुन सक्छ",
    "These are the available shift choices the position offers.":
      "प्रस्तावित पदका लागि यी   शिफ्ट विकल्पहरूउपलब्ध छन्।",
    "Overtime Expectation": "ओभरटाइमको अपेक्षा",
    "How often is this position expected to offer voluntary OT?":
      "यो पदका लागि स्वैच्छिक ओभरटाइम गर्न पाइने कतिको अपेक्षा अपेक्षा गर्न सकिन्छ?",
    "How often the position will require overtime.":
      "यस पदका लागि ओभरटाइम कतिको आवश्यक हुनेछ।",
    "Day of Week Off": "साप्ताहिक बिदाको दिन",
    "What day of the week is the day off for this position?":
      "यो पदको लागि हप्ताको कुन दिन बिदा हुन्छ?",
    "Helps workers to understand how fixed their schedule will be.":
      "कामदारहरूलाई तिनीहरूको सयमतालिका कसरि निश्चित हुनेछ भनेर बुझ्न मद्दत गर्दछ।",
    "Minimum Working Hours": "न्यूनतम कार्य घण्टा",
    "What are the minimum number of hours per week expected for this position?":
      "यस पदका लागि प्रति हप्ता अपेक्षित कार्यघण्टाको न्यूनतम संख्या के हो?",
    "Minimum number of hours per week expected for this position?":
      "यो पदको लागि प्रति हप्ता अपेक्षित कार्यघण्टाको न्यूनतम संख्या?",
    "Regular OT": "नियमित ओभरटाइम",
    "Please input regular OT rate": "कृपया नियमित ओभरटाइम उल्लेख गर्नुहोस्",
    "{currency}/hour": "{currency}/घण्टा",
    "Payment rate per hour for overtime worked on a normal working day to employee, which by law must be at least 1.5x regular hourly rate":
      "कामदारलाई सामान्य कामको दिनमा ओभरटाइमको लागि प्रति घण्टा भुक्तानी दर, जुन कानून अनुसार कम्तिमा 1.5x नियमित घण्टाको दर हुनुपर्छ।",
    "Day-Off Rate": "विदाको दिनमा दर",
    "Please input normal working hours rate during holiday":
      "कृपया छुट्टीको समयमा सामान्य काम गर्ने घण्टाको दर उल्लेख गर्नुहोस्",
    "Payment rate for working on the normally scheduled day off must be at least 2x the daily wage rate according to law":
      "सामान्यतया निर्धारित बिदाको  दिनमा काम गर्ने भुक्तानी दर कानून अनुसार दैनिक ज्याला दरको कम्तिमा 2 गुणा हुनुपर्छ।",
    "Holiday OT": "छुट्टिका दिनमा ओभरटाइम",
    "Please input Day-Off Rate": "छुट्टिका दिनमा ओभरटाइम दर उल्लेख गर्नुहोस्",
    "Payment rate per hour for overtime worked on a holiday, which by law must be at least 3x regular hourly rate":
      "सरकारि विदामा काम गरेको ओभरटाइमको लागि प्रति घण्टा भुक्तानी दर, जुन कानून अनुसार कम्तिमा 3x नियमित घण्टाको दर हुनुपर्छ।",
    Benefits: "सुविधाहरु",
    "How many days of paid annual leave are provided per year?":
      "वार्षिक कति दिन तलबि बिदा प्रदान गरिन्छ?",
    "Please input number": "कृपया नम्बर उल्लेख गर्नुहोस्",
    "How many days of paid sick leave are provided per year?":
      "प्रति वर्ष कति दिन तलबि बिरामी बिदा प्रदान गरिन्छ?",
    "How many days of paid business leave are provided per year?":
      "प्रति वर्ष कम्पनीबाट कति दिनको तलबि बिदा प्रदान गरिन्छ?",
    "How many days of paid national holidays are provided per year?":
      "प्रति वर्ष कति दिन तलबि राष्ट्रिय बिदाहरू प्रदान गरिन्छ?",
    "Are social security and maternity leave benefits provided as required by law?":
      "के सामाजिक सुरक्षा र मातृत्व बिदाको सुविधा कानून अनुसार प्रदान गरिन्छ?",
    "Yes, these benefits are provided": "हो, यी सुविधाहरू प्रदान गरिन्छ",
    "No, these benefits are not provided": "होइन, यी सुविधाहरू प्रदान गरिदैनन्",
    "Benefits details": "सुविधाहरुको विवरण",
    Accommodation: "आवास",
    "Accommodation is on site": "आवास कार्यस्थलमा छ",
    "Family members can live together": "परिवारका सदस्यहरू सँगै बस्न सक्छन्",
    "Rent/utilities auto deducted from payroll":
      "आवास भाडा/सुविधाहरु तलबबाट स्वतः कटौती गरियो",
    "Accommodation details": "आवास सम्वन्धी विवरण",
    "Please fill in accommodation details": "कृपया आवास विवरणहरू भर्नुहोस्",
    "Living arrangement details for the employees":
      "कामदारहरूको लागि बस्ने व्यवस्था सम्व्धी विवरण",
    "Rent/utilites deduction details":
      "आवास भाडा / सुविधाहरु कटौती सम्वन्धी विवरण",
    "Please fill in rent details": "कृपया आवास भाडा विवरण भर्नुहोस्",
    "Safe, grievance mechanism": "सुरक्षित, गुनासो संयन्त्र",
    "Probation period": "परिक्षण काल",
    "Grievance mechanism details": "गुनासो संयन्त्र विवरण",
    "Please input grievance mechanism details":
      "कृपया गुनासो संयन्त्र सम्वन्धी विवरणहरू उल्लेख गर्नुहोस्",
    "Probation period details": "परिक्षण काल सम्वन्धी विवरण",
    "Please input probation period details":
      "परिक्षण काल सम्वन्धी विवरण उल्लेख गर्नुहोस्",
    Preview: "पूर्वावलोकन",
    "Save draft": "ड्राफ्ट सेभ गर्नु होस्",
    Post: "पोस्ट गर्नुहोस्",
    "You cannot make changes after posting the job. Are you sure you want to post this job?":
      "तपाईंले रोजगार पोस्ट गरेपछि परिवर्तनहरू गर्न सक्नुहुन्न। तपाईं यो रोजगार पोस्ट गर्न निश्चित हुनुहुन्छ?",
    "Job Preview": "रोजगार पूर्वावलोकन",
    "General Information": "सामान्य जानकारि",
    "No information were provided": "कुनै जानकारी प्रदान गरिएको छैन",
    "Open To": "लागि खुला",
    "No of Headcount Needed": "आवश्यक ब्यक्ति संक्या",
    "Job will be expired on": "जागिरको समयावधि समाप्त हुने मिति",
    "Pink Card Holder": "गुलाबी कार्ड भएको व्यक्ति",
    "Passport Holder": "राहदानी वाहक",
    "Job Position Category": "राजगारको पदको प्रकार",
    "Job Type": "रोजगारको प्रकार",
    "Contract Duration": "करार अवधि",
    "{duration} months": "{duration} महिना",
    "Job Position Details": "रोजगारको पद सम्वन्धि विवरण",
    "Job Requirements": "रोजगारको लागि आवश्यक कुराहरु",
    "Have an ethical recruitment policy": "नितिगत भर्ना प्रकृया छ",
    "Have an employer pays policy": "रोजगारदाताले शुल्क तिर्ने निति छ",
    "Who pays visa fees?": "भिसा शुल्क कसले तिर्छ?",
    "Who pays work permit fees?": "श्रम स्वीकृति शुल्क कसले तिर्छ?",
    "Who pays medical checkup fees?": "मेडिकल चेकअप शुल्क कसले तिर्छ?",
    "Terms of Payment": "तलब भुक्तानीका सर्तहरू",
    "Timing of Payment": "तलब भुक्तानीको समय",
    Salary: "तलब",
    "OT Rate Regular": "नियमित ओभरटाइम दर",
    "OT Rate Holiday": "विदाको दिनमा ओभरटाइम दर",
    "Holiday Rate": "विदाको दर",
    "Other Benefits": "अन्य सुविधाहरु",
    "Benefits Details": "सुविधा सम्वन्धी विवरणहरु",
    "Other Information": "अन्य जानकारीहरु",
    "Accommodation on-site is available": "कार्यस्थलमा आवास उपलब्ध छ",
    "Rent & utilities autodeducted from payroll":
      "आवास भाडा र सुविधाहरु तलब बाट स्वतः कटौति गरियो",
    "Safe & functioning grievance mechanism":
      "सुरक्षित र संचालित गुनासो संयन्त्र",
    "Accommodation Details": "आवास सम्वन्धि विवरण",
    "Contact Information": "सम्पर्क सम्वन्धी जानकारी",
    Edit: "सम्पादन गर्नुहोस्",
    "Job has been successfully archived.":
      "रोजगार सफलतापूर्वक संग्रह गरिएको छ।",
    "An error occurred while archiving draft.":
      "ड्राफ्ट संग्रह गर्दा त्रुटि देखायो।",
    "Job has been successfully deleted.": "रोजगार सफलतापूर्वक मेटाइएको छ।",
    "An error occurred while deleting job.": "रोजगार मेटाउँदा त्रुटि देखायो",
    "Recruitment has been successfully stopped.":
      "भर्ना सफलतापूर्वक रोकिएको छ।",
    "An error occurred while stopping recruitment.":
      "भर्ती रोक्दा त्रुटि देखायो।",
    "Job has been successfully set to offline.":
      "रोजगार सफलतापूर्वक अफलाइनमा  सेट गरिएको छ।",
    "An error occurred while setting job to offline.":
      "रोजगार अफलाइनमा  सेट गर्दा त्रुटि देखायो।",
    "Job has been successfully set to online.":
      "रोजगार सफलतापूर्वक अनलाइनमा सेट गरिएको छ",
    "An error occurred while setting job to online.":
      "रोजगार अनलाइनमा सेट गर्दा त्रुटि देखायो",
    Title: "शिर्षक",
    Posted: "पोस्ट गरियो",
    Expires: "म्याद सकिन्छ",
    Views: "प्रयोगकर्ताहरु",
    Applicants: "आवेदकहरु",
    "Filled Vacancies": "भरिएको रिक्त पदहरू",
    Online: "अनलाइन",
    Action: "कार्य ",
    "Are you sure you would like to end the recruitment? This action cannot be undone later!":
      "के तपाइँ भर्ना समाप्त गर्न निश्चित हुनुहुन्छ? यो कार्य पछि पुन गर्न सकिँदैन!",
    "End recruitment": "भर्ना अन्त्य गर्नुहोस्",
    "Are you sure you would like to archive this job?":
      "के तपाईं यो रोजगारलाई संग्रह गर्न चाहनुहुन्छ?",
    Archive: "संग्रह गर्नुहोस्",
    Saved: "सेभ गर्नुहोस्",
    Archived: "संग्रह गरियो",
    "Are you sure you would like to delete this job?":
      "के तपाइँ यो रोजगार मेटाउन निश्चित हुनुहुन्छ?",
    Delete: "मेटाउनुहोस्",
    "View Jobs:": "रोजगार हर्नुहोस्",
    "Posted ({count})": "पोस्ट गरिएको ({count})",
    "Closed ({count})": "बन्द गरिएको ({count})",
    "Drafts ({count})": "ड्राफ्ट गरिएको ({count})",
    "Archived ({count})": "संग्रह गरिएको ({count})",
    "Position category": "पदको प्रकार",
    "Please select timing of payment": "कृपया तलब भुक्तानीको समय चयन गर्नुहोस्",
    "Please input Day-Off OT": "कृपया विदाको दिनको ओभरटाइम उल्लेख गर्नुहोस्",
    "Account Verification Status": "खाता प्रमाणीकरण स्थिति",
    Registration: "दर्ता",
    "Fill out the registration form": "दर्ता फारम भर्नुहोस्",
    "Email Verification": "इमेल प्रमाणीकरण",
    "Please verify your email using the link sent to {email}":
      "कृपया {email} मा पठाइएको लिङ्क प्रयोग गरेर आफ्नो इमेल प्रमाणित गर्नुहोस्",
    "Business Verification": "कम्पनि प्रमाणीकरण",
    "Issara will contact you to verify that you are actually the owner of this business":
      "Issara ले तपाईलाई यस कम्पनीको मालिक हो भनी प्रमाणित गर्न सम्पर्क गर्नेछ",
    Actions: "कार्यहरू",
    "Are you sure this business is not in our database yet?":
      "के तपाई पक्का हुनुहुन्छ कि यो व्यवसाय अझै हाम्रो डेटाबेसमा छैन?",
    "This business is not in our database yet. Verify as new.":
      "यो व्यवसाय अझै हाम्रो डेटाबेस मा छैन। यो व्यवसाय नयाँ हो भनेर प्रमाणित गर्नुहोस्।",
    "Are you sure you want to reject verification and delete this user?":
      "के तपाइँ प्रमाणीकरण अस्वीकार गर्न र यो प्रयोगकर्ता हटाउन चाहानु हुन्छ?",
    "The user couldn't prove ownership of the business. Reject it.":
      "प्रयोगकर्ताले व्यवसायको स्वामित्व प्रमाणित गर्न सकेन। यसलाई अस्वीकार गर्नुहोस्।",
    "Owner's Email": "मालिकको इमेल",
    Registered: "दर्ता  भएको ",
    "Business has been successfully verified":
      "व्यवसाय सफलतापूर्वक प्रमाणित गरिएको छ",
    "An error occurred while verifying business. Please contact a developer.":
      "व्यवसाय प्रमाणीकरण गर्दा त्रुटि भयो। कृपया प्राविधिकसँग सम्पर्क गर्नुहोस्।",
    "Business has been successfully removed": "व्यवसाय सफलतापूर्वक हटाइयो",
    "An error occurred while rejecting account. Please contact a developer.":
      "खाता अस्वीकार गर्दा त्रुटि भयो, कृपया कुनै प्राविधिकसँग सम्पर्क गर्नुहोला",
    "Merge Dialogue": "मर्ज संवाद",
    Close: "बन्द ",
    "To be merged": "मर्जगर्न बाँकी",
    Into: "भित्र",
    "Awaiting Employers": "पर्खिरहेका रोजगारदाताहरू",
    "Total ({count})": "जम्मा ({count})",
    "No awaiting employers to verify": "प्रमाणीकरण गर्न रोजगारदाताहरू छैनन्",
    Previous: "अघिल्लो",
    Next: "अर्को",
    "Previously verified employers": "पहिले प्रमाणित भएको रोजगारदाताहरू ",
    "No previously verified employers":
      "पहिले प्रमाणित भएको रोजगारदाताहरू छैनन्",
    "N/A": "N/A (लागु हुँदैन)",
    Verify: "प्रमाणित",
    "Awaiting Recruitment agencies": "पर्खिरहेका भर्ती एजेन्सीहरू",
    "No businesses awaiting verification": "प्रमाणीकरण गर्न व्यवसायहरू छैनन्",
    "Previously verified Recruiters": "पहिले प्रमाणित भएको भर्नाकर्ताहरू ",
    "No previously verified businesses":
      "पहिले प्रमाणित भएको रोजगारदाताहरू छैनन्",
    "Survey Details": "सर्वेक्षण विवरणहरू",
    "Identification Card": "परिचय पत्र",
    "Birth Certificate": "जन्मदर्ता प्रमाण पत्र",
    "Family Record Book": "पारिवारिक रेकर्ड बुक",
    "Residence Book": "निवास बुक",
    "Covid Vaccine Certificate": "कोभिड भ्याक्सिन प्रमाणपत्र",
    NRC: "राष्ट्रिय परिचय पत्र",
    "Household List": "घरपरिवार सूची",
    "Passport (PJ)": "राहदानी (PJ)",
    "Labour Card": "श्रम कार्ड",
    "Generating PDF ...": "PDF सिर्जना गर्दै...",
    "Generate PDF & Download": "PDF बनाउनुहोस र डाउनलोड गर्नुहोस्",
    "Request documents dfsdfwe": "अनुरोध गरिने कागजातहरू",
    "Application #{id} has been successfully contract signed":
      "आवेदले (#id) सफलतापूर्वक सम्झौतामा हस्ताक्षर गरियो",
    "An error occurred while contract signing application #{id}.":
      "आवेदक (ID) द्वारा सम्झौता हस्ताक्षर गर्दा त्रुटि भयो",
    "Application has been moved to general waitlisted on application #{id}.":
      "आवेदन (ID) सामान्य प्रतीक्षा सूचीमा सारिएको छ",
    "An error occurred while moving the application #{id}. to general waitlist":
      "एप्लिकेसन (ID) सामान्य प्रतीक्षा सूचीमा सार्ने क्रममा त्रुटि भयो",
    "Waitlisted ({count})": "प्रतीक्षा सूचीमा ({count})",
    "Contract Signed ({count})": "सम्झौतामा हस्ताक्षर गरियो ({count})",
    'Are you sure you would like to change the status to "Contract Signed" to the selected applications? This action cannot be undone later':
      'के तपाइँ "सम्झौता हस्ताक्षरित" मा परिवर्तन गर्न निश्चित हुनुहुन्छ ? यो कार्य पछि पुन परिवर्तन गर्न सकिँदैन',
    "Contract Signed ({number})": "सम्झौतामा हस्ताक्षर गरियो ({number})",
    "Move to waitlist ({number})": "प्रतीक्षा सूचीमा सार्नुहोस् ({number})",
    "Are you sure you would like to move the selected applicants to General Waitlist? This action cannot be undone later.":
      "के तपाइँ चयन गरिएका आवेदकहरूलाई सामान्य प्रतीक्षा सूचीमा सार्न निश्चित हुनुहुन्छ? यो कार्य पछि अनडू गर्न सकिँदैन।",
    "Move to General Waitlist ({number})":
      "सामान्य प्रतीक्षा सूचीमा सार्नुहोस् ({number})",
    "Please select an offer expiry date":
      "कृपया प्रस्तावको म्याद सकिने मिति चयन गर्नुहोस्",
    "Update Now": "अहिले अपडेट गर्नुहोस्",
    "Offer Now": "प्रस्ताव गर्नुहोस्",
    "Offer expiry date will be {expiry_date}":
      "प्रस्ताव समाप्ति मिति (expiry_date)",
    "Resume Detail Information": "बायोडाटाको विस्तृत जानकारी",
    "Resume Information": "वायोोडाटा सम्वन्धी जानकारी",
    "The job seeker have National ID": "कामदारसँग राष्ट्रिय परिचयपत्र छ",
    "The job seeker does not have a National ID":
      "कामदारसँग राष्ट्रिय परिचयपत्र छैन",
    "The job seeker have passport": "कामदारसँग राहदानी छ",
    "The job seeker does not have a passport": "कामदरासँग राहदानी छैन",
    "Read Level": "पढ्ने स्तर",
    "Speak Level": "बोल्ने स्तर",
    "Applied At": "आवेदन गरेको स्थान",
    "Resume View": "बायोडाटा हेर्नुहोस्",
    "General Waitlist": "सामान्य प्रतीक्षा सूचीमा",
    Jobseekers: "प्रवासी श्रमिक",
    "(Burmese)": "बर्मी",
    "(English)": "(अंग्रेजी)",
    "(Nepali)": "(नेपाली)",
    "(Thailand)": "( थाइल्याण्ड )",
    "(Khmer)": "खमेर",
    "Update/Post job": "रोजगार पोस्ट/अपडेट गर्नुहोस्",
    "This job is for the country ! ({country})":
      "यो काम यस देश {country} को लागी हो! ",
    "Need to choose a country for this job!":
      "यो काम को लागी देश छनोट गर्नुहोस्",
    "Please select the sending country !": "कृपया पठाउने देश चयन गर्नुहोस्!",
    Malaysia: "मलेसिया",
    UAE: "युएई",
    "Employer Information": "रोजगारदाताको जानकारी",
    "Please select employer": "कृपया रोजगारदाता चयन गर्नुहोस्",
    "Please input employer's name": "कृृपया रोजगारदाताको नाम उल्लेख गर्नुहोस्",
    "The name of the Employer": "रोजगारदाताको नाम",
    "The country where the workplace is located": "कार्यस्थल रहेको देश",
    "The state where the workplace is located": " कार्यस्थल रहेको प्रदेश",
    "The city where the workplace is located": "कार्यस्थल रहेको शहर",
    "Please select size": "आकार चयन गर्नुहोस्",
    "Nationalities of Workers working at this workplace":
      "यस कार्यस्थलमा काम गर्ने श्रमिकहरूको राष्ट्रियता",
    "Please specify the nationalities of workers working at this workplace.":
      "कृपया यस कार्यस्थलमा काम गर्ने कामदारहरूको राष्ट्रियता निर्दिष्ट गर्नुहोस्।",
    "Headcount by Genders": "लिङगको आधारमा ब्यक्ति संख्या",
    Genders: "लिङ्ग",
    "Male headcount needed": "आवश्यक पुरुषको संख्या",
    "Female headcount needed": "आवश्यक महिलाको संख्या",
    "Demand Pre-approval date": "पूर्व-स्वीकृति मिति",
    "Demand Approved Date": "माग स्वीकृत मिति",
    "Please choose demand pre-approval date":
      "कृपया मागको पूर्व-स्वीकृति मिति छान्नुहोस्",
    "Please choose demand approved date": "कृपया माग स्वीकृत मिति छान्नुहोस्",
    "LT number": "LT नम्बर",
    "Demand number": "माग नम्बर",
    "Please enter LT number": "कृपया संख्या नम्बर राख्नु गर्नुहोस्",
    "Please enter demand number": "कृपया माग नम्बर उल्लेख गर्नुहोस्",
    "Application Closed Date": "आवेदन बन्द मिति",
    "Please choose Application Closed date":
      "कृपया आवेदन बन्द भएको मिति छान्नुहोस्",
    "Email to receive notifications for this job announcement":
      "यो रोजगारको विज्ञापन हुँदा सूचनाहरू प्राप्त गर्न इमेल गर्नुहोस्",
    "Please enter a valid email address":
      "कृपया मान्य इमेल ठेगाना उल्लेख गर्नुहोस्",
    "ER Information": "नितिगत भर्ना सम्वन्धी जानकारी",
    "Does the employer have an ethical recruitment policy?":
      "के रोजगारदातासँग नितिगत भर्ना नीति छ?",
    Have: "छ",
    "Not Have": "छैन",
    "Does the RA have an ethical recruitment policy?":
      "के म्यानपावार कम्पनी सँग नितिगत भर्ना नीति छ?",
    "Does the RA have an ethical recruitment policy? is required":
      "के म्यानपावार कम्पनी सँग नितिगत भर्ना नीति छ? आवश्यक छ",
    "Does the employer have an employer pays policy?":
      "के रोजगारदातासँग रोजगारदाताले शुल्क तिर्ने नीति छ?",
    "Monthly salary ({currency}/month)": "मासिक तलब ({currency}/महिना)",
    "How much will be provided for meals allowance?":
      "खाना भत्ताको लागि कति प्रदान गरिनेछ?",
    "Please enter a meal allowance.": "कृपया खाना भत्ता हाल्नुहोस्।",
    "Please enter a meal allowance of no less than 300 AED.":
      "कृपया ३०० AED भन्दा माथिको खाना भत्ता हाल्नुहोस्।",
    "Any additional leave ?": "कुनै अतिरिक्त बिदा छ?",
    "Add Detail Here": "यहाँ विवरण थप्नुहोस्",
    "Any additional leave ? (This is optional)":
      "कुनै अतिरिक्त बिदा छ? (यो ऐच्छिक हो)",
    "Add detail here (optional)": "यहाँ विवरण थप्नुहोस् (यो ऐच्छिक हो)",
    "Are social security benefits provided as required by law?":
      "कानुनबमोजिम सामाजिक सुरक्षा भत्ता दिइएको छ ?",
    "Skill training information": "सीप तालीम सम्वन्धी जानकारी",
    "Is skill training required for this job?":
      "के यो कामको लागि सीप तालीम आवश्यक छ?",
    "if so, who will cover those costs?":
      "यदि त्यसो हो भने, ती लागतहरू कसले बेहोर्ने छ?",
    "if workwes have to pay, please provide the amount they should pay":
      "यदि कामदारले  तिर्नुपर्छ भने, कृपया उनीहरूले तिर्नुपर्ने रकम उपलब्ध गराउनुहोस्",
    "Cost of skill-training?": "सीप तालीम को लागत",
    "Cost of skill-training? is required": "सीप-प्रशिक्षण लागत? आवश्यक",
    "Accommodation  during skill training?": "सीप प्रशिक्षण समयमा आवास?",
    "Please specify, does the employer provide accommodation during skill-training of Workers":
      "कृपया स्पष्ट गर्नुहोस्, के रोजगारदाताले कामदारहरूको सीप-प्रशिक्षणको समयमा आवास प्रदान गर्दछ",
    "Accommodation  during skill training? is required":
      "सीप प्रशिक्षण समयमा आवास? आवश्यक",
    "Meals during skill training?": "सीप तालिम समयमा खाना?",
    "Please specify, does the employer provide meals during skill-training of Workers":
      "कृपया स्पष्ट गर्नुहोस्, के रोजगारदाताले कामदारहरूको सीप-प्रशिक्षण समयमा खाना उपलब्ध गराउँछ",
    "Meals during skill training? is required": "सीप तालिम समयमा खाना? आवश्यक",
    "Based on individual cost/spending.": "व्यक्तिगत लागत/खर्च आधारित।",
    "Transportation costs to and from for attending skill training?":
      "सीप तालिममा भाग लिन र जाने यातायात खर्च?",
    "Transportation costs to and from for attending skill training? is required":
      "सीप तालिममा भाग लिन र जाने यातायात खर्च? आवश्यक",
    "Brief description of skill training": "सीप तालिमको संक्षिप्त विवरण",
    "Please fill the brief description of skill training":
      "कृपया सीप तालिमको संक्षिप्त विवरण भर्नुहोस्",
    "Costs for Pre-departure": "पूर्व प्रस्थान को लागी लागत",
    "Is pre-departure orientation training required for this job?":
      "के यो कामको लागि पूर्व प्रस्थान अभिमुखीकरण तालीम आवश्यक छ?",
    "Cost of pre-departure orientation training?":
      "पूर्व प्रस्थान अभिमुखीकरण तालीम को लागत?",
    "Cost of pre-departure orientation training? is required":
      "पूर्व प्रस्थान अभिमुखीकरण तालीम को लागत? आवश्यक",
    "Accommodation during pre-departure orientation training?":
      "पूर्व प्रस्थान अभिमुखीकरण तालीम समयमा आवास?",
    "Please specify, does the employer provide accommodation during pre-departure orientation training of Workers":
      "कृपया स्पष्ट गर्नुहोस्, के रोजगारदाताले कामदारहरूको पूर्व प्रस्थान अभिमुखीकरण तालिमको समयमा आवास प्रदान गर्दछ",
    "Accommodation during pre-departure orientation training? is required":
      "पूर्व प्रस्थान अभिमुखीकरण तालीमको समयमा आवास? आवश्यक",
    "Meals during pre-departure orientation training?":
      "पूर्व प्रस्थान अभिमुखीकरण तालीमको समयमा खाना?",
    "Please specify, does the employer provide meals during pre-departure orientation training of Workers":
      "कृपया स्पष्ट गर्नुहोस्, के रोजगारदाताले कामदारहरूको पूर्व प्रस्थान अभिमुखीकरण तालिममा खाना उपलब्ध गराउनुहुन्छ",
    "Meals during pre-departure orientation training? is required":
      "पूर्व प्रस्थान अभिमुखीकरण तालीमको समयमा खाना? आवश्यक",
    "Transportation costs to and from pre-departure orientation training?":
      "पूर्व-प्रस्थान अभिमुखीकरण तालीमलो लागि आउने जाने यातायात लागत?",
    "Transportation costs to and from pre-departure orientation training? is required":
      "पूर्व-प्रस्थान अभिमुखीकरण तालीमलो लागि आउने जाने यातायात लागत? आवश्यक",
    "Additional information for Pre-departure orientation course":
      "पूर्व प्रस्थान अभिमुखीकरण पाठ्यक्रम को लागी अतिरिक्त जानकारी",
    "Please fill in additional information for Pre-departure orientation course (number of days, location, kind of training, etc)":
      "कृपया पूर्व-प्रस्थान अभिमुखीकरण पाठ्यक्रमको लागि थप जानकारी भर्नुहोस् (दिनहरूको संख्या, स्थान, तालीमको प्रकार, आदि)",
    "Costs and fees for recruitment in the origin country":
      "श्रोत देशमा भर्नाको लागि लागत र शुल्कहरू",
    "Select the origin country currency": "आफ्नो देशको मुद्रा छान्नुहोस्",
    "Origin country Currency": "आफ्नो देशको मुद्रा ",
    "Recruitment service fee to RA and sub-agent?":
      "म्यानपावर कम्पनी र एजेन्टलाई भर्ना सेवा शुल्क?",
    "Recruitment service fee to RA and sub-agent? is required":
      "म्यानपावर कम्पनी र एजेन्टलाई भर्ना सेवा शुल्क? आवश्यक",
    "Accommodation costs for contract signing":
      "करारपत्र हस्ताक्षरको लागि आवास खर्च?  ",
    "Accommodation costs for contract signing is required":
      "करारपत्र हस्ताक्षरको लागि आवास खर्च? आवश्यक",
    "Medical check-up fee?": "स्वास्थ्य परिक्षण शुल्क?",
    "Medical check-up fee? is required": "स्वास्थ्य परिक्षण शुल्क? आवश्यक",
    "Passport application fee?": "राहदानी शुल्क?  ",
    "Passport application fee? is required": "राहदानी शुल्क? आवश्यक",
    "Overseas Worker Identification Card (OWIC) fee?":
      "वैदेशिक श्रमिक पहिचान कार्ड (OWIC) शुल्क?",
    "Overseas Worker Identification Card (OWIC) fee? is required":
      "वैदेशिक श्रमिक पहिचान कार्ड (OWIC) शुल्क?आवश्यक",
    "Travel costs for a job interview?":
      "रोजगार अन्तर्वार्ताको लागी यातायात खर्च?  ",
    "Travel costs for a job interview? is required":
      "रोजगार अन्तर्वार्ताको लागी यातायात खर्च? आवश्यक",
    "Transportation cost to and from for contract signing?":
      "करारपत्र हस्ताक्षर गर्न जाने आउने यातायात खर्च?",
    "Transportation cost to and from for contract signing? is required":
      "ठेक्कामा हस्ताक्षर गर्न र जाने यातायात लागत? आवश्यक",
    "Meals for contract signing?": "करारपत्र हस्ताक्षरको लागि खाना?",
    "Meals for contract signing? is required":
      "करारपत्र हस्ताक्षरको लागि खाना? आवश्यक",
    "Transportation costs to the border or airport?":
      "एयरपोर्ट सम्म पुग्ने यातायात खर्च",
    "Transportation costs to the border or airport? is required":
      "एयरपोर्ट सम्म पुग्ने यातायात खर्च (आवश्यक)",
    "Costs and fees for recruitment in the destination country":
      "गन्तव्य देशमा भर्नाको लागि लागत र शुल्कहरू",
    "Select the desitnation country currency":
      "गन्तव्य मुलुकको मुद्रा छान्नुहोस् गर्नुहोस्",
    "Destination country Currency": "गन्तव्य मुलुकको मुद्रा",
    "Transportation cost from the border or airport to the workplace (or to the destination country)?":
      "नेपालबाट कार्यस्थल (वा गन्तव्य देश) सम्मको यातायात खर्च कसले",
    "Transportation cost from the border or airport to the workplace (or to the destination country)? is required":
      "नेपालबाट कार्यस्थल (वा गन्तव्य देश) सम्मको यातायात खर्च (आवश्यक)",
    "Visa fee?": "भिसा शुल्क?",
    "Visa fees is required": "भिसा शुल्क? आवश्यक",
    "Work permit?": "श्रम अनुमती?",
    "Work Permit fees is required": "श्रम अनुमती? आवश्यक",
    "Medical check-up fee (if I have to take another check up)?":
      "स्वास्थ्य परिक्षण शुल्क (यदि मैले अर्को परिक्षन गर्नु पर्छ भने)?",
    "Medical check-up fee (if I have to take another check up)? is required":
      "स्वास्थ्य परिक्षण शुल्क (यदि मैले अर्को परिक्षन गर्नु पर्छ भने)? आवश्यक",
    "Hospitalization Insurance": "अस्पताल भर्ना बीमा",
    "Other Costs": "अन्य लागतहरु",
    "Please provide any additional information on other costs that are not being covered above":
      "कृपया अन्यकुनै पनि अतिरिक्त लागतहरू भए जानकारी प्रदान गर्नुहोस् जुन माथि समावेश गरिएको छैन",
    "Please provide any additional information on other costs that are not being covered above. ":
      "कृपया अन्यकुनै पनि अतिरिक्त लागतहरू भए जानकारी प्रदान गर्नुहोस् जुन माथि समावेश गरिएको छैन",
    "Information on repatriation": "स्वदेश फिर्ता बारे जानकारी",
    "Please describe how you and the employer would arrange the repatriation of migrant workers":
      "कृपया तपाईं र रोजगारदाताले प्रवासी कामदारहरूलाई स्वदेश फिर्ता गर्ने व्यवस्था कसरी गर्नु भएको छ वर्णन गर्नुहोस्",
    "Please describe how you and the employer would arrange the repatriation of migrant workers after the completion of the contract, if the worker has difficulties performing the job or if workers need to return to the origin country for personal reasons.":
      "कामदारलाई काम गर्न कठिनाइ भएमा वा कामदारहरूलाई व्यक्तिगत कारणले आफ्नो देशमा फर्किनु परेको खण्डमा, र सम्झौता पूरा भएपछि आप्रवासी कामदारहरूलाई स्वदेश फिर्ता गर्ने व्यवस्था तपाईं र रोजगारदाताले कसरी गर्नु हुनेछ कृपया वर्णन गर्नुहोस्।",
    "Employer Name": "रोजगारदाताको नाम",
    "Employer Address": "रोजगादाताको ठेगाना",
    "Employer Size": "रोजगारदाताको आकार",
    "Number of Male Needed": "पुरुष आवश्यक संख्या",
    "Number of Female Needed": "महिला आवश्यक संख्या",
    "NRC Card Holder": "राष्ट्रिय दर्ता कार्ड ( NRC) होल्डर",
    "OWIC Card Holder": "OWIC कार्ड होल्डर",
    "SKILL TRAINING INFORMATION": "सीप तालीमको विवरण",
    "Who pays cost of skill-training?": "सीप-प्रशिक्षणको लागत कसले तिर्छ?",
    "Does the employer provide accommodation during skill training?":
      "के रोजगारदाताले सीप तालिमको समयमा आवास उपलब्ध गराउनुहुन्छ?",
    "Does the employer provide meals during skill training?":
      "के रोजगारदाताले सीप तालिमको समयमा खाना उपलब्ध गराउनुहुन्छ?",
    "Who pays transportation costs to and from for attending skill training?":
      "सीप तालिममा सहभागी हुन जाने आउने यातायात खर्च कसले तिर्छ?",
    "COSTS FOR PRE-DEPARTURE": "पूर्व प्रस्थान को लागी लागत",
    "Who pays cost of pre-departure orientation training?":
      "पूर्व प्रस्थान अभिमुखीकरण तालिमको लागत कसले तिर्छ?",
    "Who pays for acoomodation during pre-departure orientation training?":
      "पूर्व प्रस्थान अभिमुखीकरण तालिमको समयमा आवासको खर्च कसले तिर्छ?",
    "Who pays for meals during pre-departure orientation training?":
      "पूर्व प्रस्थान अभिमुखीकरण तालिमको समयमा खानाको खर्च कसले तिर्छ?",
    "Who pays the cost of transportation to and from pre-departure orientation training?":
      "पूर्व प्रस्थान अभिमुखीकरण तालिममा जाने आउने यातायातको खर्च कसले तिर्छ?",
    "COSTS AND FEES FOR RECRUITMENT IN THE ORIGIN COUNTRY":
      "श्रोत देशमा भर्नाको लागि लागत र शुल्कहरू",
    "Who pays the recruitment service fee to RA and sub-agent?":
      "म्यानपावर कम्पनि र एजेन्टलाई भर्ना सेवा शुल्क कसले तिर्छ?",
    "Who pays travel costs for a job interview?":
      "रोजगारको अन्तर्वार्ताको लागि यात्रा खर्च कसले तिर्छ?",
    "Who pays accommodation costs for contract signing":
      "करारपत्रमा हस्ताक्षरको लागि आवास खर्च कसले तिर्छ",
    "Who pays transportation cost to and from for contract signing?":
      " करार पत्रमा हस्ताक्षर गर्न जाने आउने यातायात खर्च तिर्छ?",
    "Who pays for meals during contract signing?":
      "करारपत्रमा हस्ताक्षर गर्दा खानाको लागि कसले भुक्तानी गर्छ?",
    "Who pays medical check-up fee?": "स्वास्थ्य परिक्षण शुल्क कसले तिर्छ?",
    "Who pays passport application fee?": "राहदानी शुल्क कसले तिर्छ?",
    "Who pays Overseas Worker Identification Card (OWIC) fee?":
      "ओभरसिज वर्कर आइडेन्टिफिकेशन कार्ड (OWIC) शुल्क कसले तिर्छ?",
    "Who pays transportation costs to the border or airport?":
      "सीमा वा विमानस्थलको यातायात खर्च कसले तिर्छ?",
    "COSTS AND FEES FOR RECRUITMENT IN THE DESTINATION COUNTRY":
      "गन्तव्य देशमा भर्नाको लागि लाग्ने लागत र शुल्कहरू",
    "Who pays transportation cost from the border or airport to the workplace (or to the destination country)?":
      "सीमा वा विमानस्थलबाट कार्यस्थल (वा गन्तव्य देश) सम्मको यातायात लागत कसले तिर्छ?",
    "Who pays Visa fee?": "भिसा शुल्क कसले तिर्छ?",
    "Who pays work permit?": "श्रम स्वीकृति कसले तिर्छ?",
    "Who pays medical check-up fee (if I have to take another check up)?":
      "कसले स्वास्थ्य परिक्षण शुल्क कसले तिर्छ (यदि मैले अर्कोस्वास्थ्य परिक्षण गर्नु पर्ने भयो भने)?",
    "Who pays for Hospitalization Insurance?":
      "अस्पताल भर्ना बीमाको सुल्क कसले तिर्छ ?",
    "COVID-19 RELATED COSTS AND PROCEDURES":
      "कोभिड-१९ सँग सम्बन्धित लागत र प्रक्रियाहरू",
    "Who pays for the mandatory PCR test fee prior to departure?":
      "प्रस्थान गर्नु अघि अनिवार्य PCR परीक्षण शुल्क कसले तिर्छ?",
    "Who pays the PCR test fee on Thailand side?":
      "थाइल्याण्ड पक्षमा PCR परीक्षण शुल्क कसले तिर्छ",
    "Who pays for the quarantine in the destination country?":
      "गन्तव्य देशमा क्वारेन्टाइनको लागि कसले तिर्छ?",
    "Who pays for the Covid-19 insurance fee in the destination country?":
      "गन्तव्य देशमा कोभिड-१९ बीमा शुल्क कसले तिर्छ?",
    "Who pays for the Covid-19 vaccination fee?":
      "कोभिड-१९ खोपको शुल्क कसले तिर्छ?",
    "Other costs": "अन्य लागतहरु",
    "Meals Allowance": "खाना भत्ता",
    "Any additional leave detail": "कुनै अतिरिक्त छुट्टी विवरण",
    "Edit & Post": "सम्पादन गर्नुहोस र पोस्ट गर्नुहोस् ",
    "Account:": "खाता",
    "Type:": "प्रकारः",
    "Email:": "इमेलः",
    "Joined:": "सामेल भयो:",
    "Updated:": "अद्यावधिक गरिएको:",
    "Please Select An Employer To Fill Employer's Information":
      "रोजगारदाताको जानकारी भर्नको लागि कृपया एउटा रोजगारदाता चयन गर्नुहोस्",
    "Please Select An Employer": "कृपया एक रोजगारदाता चयन गर्नुहोस्",
    "Select Size": "आकार चयन् गर्नुहोस्",
    "Please input title": "शिर्षक उल्लेख गर्नुहोस्",
    "Additional information": "अतिरिक्त जानकारीहरु",
    "Profile:": "वायोडाटाः",
    "Address was successfully updated.": "ठेगाना सफलतापूर्वक अद्यावधिक गरियो।",
    "Something went wrong while updating the address. Please contact a developer":
      "ठेगाना अपडेट गर्दा केही गल्ति हुन गयो। कृपया कुनै प्राविधिकलाई सम्पर्क गर्नुहोस्",
    "Please enter Main Contact Name":
      "कृपया मुख्य सम्पर्क नाम उल्लेख गर्नुहोस्",
    "Company name": "कम्पनीको नाम",
    "Please enter Company name": "कृपया कम्पनीको नाम उल्लेख गर्नुहोस्",
    "Please select Working since": "कहिले बाट काम गर्न शुरु गरेको",
    "License number": "इजाजत् पत्र संख्या",
    "Please enter License number": "इजाजतपत्र संख्या उल्लेख गर्नुहोस्",
    "Max length of the license number is 12":
      "इजाजतपत्र नम्बर अधिकतम १२ अंक मात्र हो",
    "Valid till": "इजाजतपत्र सम्म  मान्य छ",
    "Please select Valid till": "कृपया इजाजतपत्र सम्म मान्य हुने चयन गर्नुहोस्",
    "Thai License number": "थाई लाइसेन्स नम्बर",
    "Please select Thai License Number": "कृपया थाई इजाजत नम्बर चयन गर्नुहोस्",
    "Thai License Expiry Date": "थाई इजाजतपत्रको म्याद सकिने मिति",
    "Please select Thai License Expiry Date":
      "कृपया थाई इजाजतपत्रको म्याद सकिने मिति चयन गर्नुहोस्",
    "Please describe the years of experience to specific industries to which countries, the years of experience to specific industries to which countries with zero costs and sub agents information":
      "कृपया कुनै विशेष उद्योगहरू वा देशहरुमा आफूले शुन्य लागतमा काम गरेको वर्षौँको अनुभवका वर्णन गर्नुहोस्, ",
    "About us": "हाम्रो वारेमा",
    "Discard changes": "परिवर्तनहरू खारेज गर्नुहोस्",
    Save: "सेभ गर्नुहोस्",
    "Main contact name:": "मुख्य सम्पर्क नाम:",
    "Company name:": "कम्पनीको नाम",
    "Working since:": "देखि काम गर्दै",
    "License number:": "इजाजत नम्बर",
    "Valid till:": "सम्म मान्य",
    "Thai license number:": "थाई इजाजत नम्बर",
    "Thai license expiry date:": "थाई इजाजतपत्र समाप्ति मिति:",
    "Business Images": "कम्पनीको फोटो",
    "Upload Cover Photo": "कभर फोटो अपलोड गर्नुहोस्",
    "Please enter email": "कृपया इमेल उल्लेख गर्नुहोस्",
    "Please enter Phone Number": "कृपया फोन नम्बर उल्लेख गर्नुहोस्",
    "Add more": "अझै थप गर्नुहोस्",
    "Website:": "वेबसाइट:",
    "Facebook:": "फेसबुक:",
    "Line:": "ल्याण्डलाइन नम्बर",
    "Viber:": "भाइबर:",
    "Phone numbers:": "फोन नम्बरहरुः",
    "Please select Country, State, City":
      "कृपया देश, प्रदेश, शहर चयन गर्नुहोस्",
    "Country:": "देशः",
    "State/Province:": "राज्यप्रदेश",
    "City/District:": "शहरजील्ला",
    "Street:": "सडक",
    "Zip:": "जिप कोड",
    "Issara will contact you to verify that you actually the owner of this business":
      "Issara ले तपाईलाई यस कम्पनीको वास्तविक मालिक हो भनी प्रमाणित गर्न सम्पर्क गर्नेछ",
    "Successfully shortlisted the resume with job !":
      "कामको साथ बायोडाटालाई सफलतापूर्वक सर्टलिस्ट गर्नुहोस् !",
    "Moved to general waitlist": "सामान्य प्रतीक्षा सूचीमा सार्नुहोस्",
    "Are you sure you would like to shortlist this jobseeker? This action cannot be undone later!":
      "तपाई यो निवेदन छनोट गर्न निश्चित हुनुहुन्छ? यो कार्य पछि अनडू गर्न सकिँदैन!",
    "Shortlist now": "सर्टलिस्ट गर्नुहोस्",
    "Please Choose A Job to shortlist the Jobseeker":
      "कृपया निबेदक सर्टलिस्ट गर्नको लागि जागिर छान्नुहोस्",
    "Resume Detail": "वायोोडाटा सम्वन्धी विवरण",
    "Shortlisting...": "छनोट प्रकृया जारि छ...",
    "Successfully shortlisted the resume !":
      "बायोडाटालाई सफलतापूर्वक सर्टलिस्ट गरियो!",
    "Search name": "नाम द्वारा खोज्नुहोस्",
    "Search Phone Number": "फोन नम्बर खोज्नुहोस्",
    "Detail View": "विवरण हर्न",
    "Shortlisted At": "छनोट भएको स्थान",
    "Viewed At": "यसमा हरियो",
    "Applied resumes": "समावेश गरेको वायोडाटा",
    "Shortlisted resumes": "छनोट भएको वायोडाटा",
    "Viewed resumes": "बायोडाटा हेरियो",
    "Fetching...": "डाटा प्राप्त गर्दै...",
    "Shortlist with JOBS": "काम संग सर्टलिस्ट गर्नुहोस्",
    "Are you sure you would like to shortlist ? This action cannot be undone later!":
      "तपाई यो निवेदन छनोट गर्न निश्चित हुनुहुन्छ? यो कार्य पछि अनडू गर्न सकिँदैन!",
    "Golden Dreams": "गोल्डेन ड्रिम्स",
    "Golden Dreams Marketplace ©{date} Created by {issara}":
      "गोल्डेन ड्रीम्स मार्केटप्लेस ©{date} {issara} द्वारा सिर्जना गरिएको",
    "Privacy Policy": "गोपनीयता नीति",
    Disclaimer: "दावी अस्वीकार सम्वन्धी",
    "Issara Staff": "इसाराका कर्मचारी",
    Recruiter: "म्यानपावर कम्पनी",
    "Golden Dreams Marketplace ©{date} Created by Issara Institute":
      "गोल्डेन ड्रीम्स मार्केटप्लेस ©{date} Issara Institute द्वारा सिर्जना गरिएको",
    "Reset your password": "आफ्नो पासवर्ड रिसेट गर्नुहोस्",
    "The input is not valid Email": "उल्लेख गरिएको इमेल मान्य छैन",
    "Please input your Email": "कृपया आफ्नो इमेल उल्लेख गर्नुहोस्",
    "Send reset link": "रिसेट लिङ्क पठाउनुहोस्",
    "We will send password reset link to this email address, please change your password within 24 hours.":
      "हामी यो इमेल ठेगानामा पासवर्ड रिसेट लिङ्क पठाउनेछौं, कृपया 24 घण्टा भित्र आफ्नो पासवर्ड परिवर्तन गर्नुहोस्।",
    "Golden Dreams Job Marketplace": "गोल्डेन ड्रीम्स रोजगार बजार",
    "Powered by Issara Institute": "इसरा इन्स्टिच्यूट द्वारा संचालित",
    "Register and login here to modernize and digitize how your business manages labour recruitment ->":
      "आधुनिकीकरण र डिजिटलाइज गर्दै तपाईंको व्यवसायले कसरी श्रमिक भर्ना व्यवस्थित गर्दछ भन्ने कुराको लागी यहाँ दर्ता गर्नुहोस् र लगइन् गर्नुहोस् ->",
    Login: "लगइन्",
    Password: "पासवर्ड",
    "Forgot Password?": "पासवर्ड विर्सनु भयो?",
    "Please input your password!": "कृपया आफ्नो पासवर्ड उल्लेख गर्नुहोस्!",
    "Not registered?": "दर्ता गर्नु भएको छैन?",
    "Create an account": "खाता खोल्नुहोस्",
    "Oops! You tried to log in with a job seeker account. Please check and try again.":
      "उफ्! तपाईंले जागिर खोज्ने खाताबाट लग इन गर्ने प्रयास गर्नुभयो। कृपया जाँच गर्नुहोस् र पुन: प्रयास गर्नुहोस्।",
    "Log in with Business Account": "व्यापार खाताको साथ लग इन गर्नुहोस्",
    "Sorry, you are not authorized to access this page.":
      "माफ गर्नुहोस्, तपाईंलाई यो पेजमा जाने अनुमती छैन",
    "Use another account": "अर्को खाता प्रयोग गर्नुहोस्",
    "Go back to console": "कन्सोल मा फर्कनुहोस्",
    "Sorry, the page you visited does not exist.":
      "माफ गर्नुहोस्, तपाईंले खोज्नु भएको पेज छैन",
    "Back Home": "पछाडि होममा फर्कनुहोस्",
    "Successfully changed password": "सफलतापूर्वक पासवर्ड परिवर्तन गरियो",
    "You'll be redirected to login page shortly":
      "तपाईंलाई केहिबेरमा लगइन पृष्ठमा पुन: निर्देशित गरिनेछ",
    "Go to login page": "लगइन पृष्ठमा जानुहोस्",
    "Password reset failed": "पासवर्ड रिसेट असफल भयो",
    "The reset link has expired. Please request a new one.":
      "रिसेट लिङ्कको म्याद सकिएको छ। कृपया फेरि नयाँ अनुरोध गर्नुहोस्।",
    "New Password": "नयाँ पासवर्ड",
    "Confirm Password": "पासवर्ड सुनिश्चित गर्नुहोस",
    "Please confirm your password!": "कृपया आफ्नो पासवर्ड उल्लेख गर्नुहोस्!",
    "The two passwords that you entered do not match!":
      "तपाईंले उल्लेख गर्नुभएको दुई पासवर्डहरू मेल खाँदैनन्!",
    "Update password": "पासवर्ड अपडेट गर्नुहोस्",
    "Create Account": "खाता सिर्जना गर्नुहोस्",
    "We are:": "हामी हरु:",
    "Please select your business type!":
      "कृपया आफ्नो कम्पनीको प्रकार चयन गर्नुहोस्!",
    "Recruitment Agency": "म्यानपावर कम्पनी",
    "Business Name": "कम्पनीको नाम",
    "What is your offical business name?":
      "तपाईको कम्पनीको आधिकारिक नाम के हो?",
    "Please input your business name!":
      "तपाईको कम्पनीको आधिकारिक नाम उल्लेख गर्नुहोस्",
    "The input is not valid E-mail!": "उल्लेख गरिएको मान्य इ-मेल हैन!",
    "Please input your E-mail!": "कृपया आफ्नो इमेल उल्लेख गर्नुहोस्!",
    "Create your free account": "आफ्नो नि: शुल्क खाता सिर्जना गर्नुहोस्",
    "By clicking register, you agree to our {privacy_policy}.":
      "दर्तामा क्लिक गरेर, तपाईं हाम्रो {privacy_policy} मा सहमत हुनुहुन्छ।",
    "privacy policy": "गोपनीयता नीति",
    "Already have an account?": "पहिले नै खाता छ?",
    "Login here": "यहाँ लगइन गर्नुहोस्",
    "Email verification in progress..": "इमेल प्रमाणिकरण प्रकृया जारि छ..",
    "Successfully verified email": "इमेल सफलतापूर्वक प्रमाणित भयो",
    "Please follow further instructions in the console":
      "कृपया कन्सोलमा थप निर्देशनहरू पालना गर्नुहोस्",
    "Go to console": "कन्सोलमा जानुहोस्",
    "Email verification failed": "इमेल प्रमाणीकरण असफल भयो",
    "This link has already expired.": "यो लिङ्कको मिति पहिले नै समाप्त भएको छ।",
  },
  th: {
    "Update is available": "อัพเดทเวอร์ชั่นล่าสุด",
    "Please press OK to refresh the app.":
      "โปรดกดตกลง เพื่อทำการรีเฟรชแอปพลิเคชั่น",
    "Loading..": "กำลังโหลด..",
    Male: "ชาย",
    Female: "หญิง",
    "Prefer not to say": "ไม่ประสงค์บอกเพศ",
    "Non-Binary": "ไม่ใช่ไบนารี\n ",
    "Passport Include": "รวมพาสปอร์ต ",
    "Passport Not Include": "ไม่รวมพาสปอร์ต",
    Myanmar: "พม่า",
    Thailand: "ไทย",
    Cambodia: "กัมพูชา",
    Laos: "ลาว",
    Nepal: "เนปาล",
    Indonesia: "อินโดนีเซีย",
    Indian: "อินเดีย",
    Bangladeshi: "บังคลาเทศ",
    Others: "อื่น ๆ",
    Sunday: "วันอาทิตย์",
    Monday: "วันจันทร์",
    Tuesday: "วันอังคาร",
    Wednesday: "วันพุธ",
    Thursday: "วันพฤหัส",
    Friday: "วันศุกร์",
    Saturday: "วันเสาร์",
    "It Will Vary": "ไม่แน่นอน",
    Monthly: "รายเดือน",
    "Daily Wage Rate": "รายวัน",
    "Once A Month": "1 ครั้งต่อเดือน",
    "Twice A Month": "2 ครั้งต่อเดือน",
    "Every Week": "ทุกสัปดาห์",
    "Every 10 days": "ทุก 10 วัน",
    Other: "อื่น ๆ",
    Regularly: "ตามปกติปกติ",
    "From time to time": "บางครั้งบางคราว",
    Infrequently: "ไม่บ่อย",
    Never: "ไม่เคย",
    Day: "กลางวัน",
    Swing: "สลับไป-มา",
    Night: "กลางคืน",
    Rotating: "หมุนเวียน",
    Variable: "ไม่แน่นอน",
    "Full-time": "เต็มเวลา",
    "Part-time": "พาร์ทไทม์",
    "Moved General Waitlist": "ย้ายรายการรอทั่วไปแล้ว",
    Waitlisted: "อยู่ในรายการอแล้ว",
    "Contract Signed": "เซ็นสัญญาแล้ว",
    Accepted: "รับ",
    Applied: "สมัครเรียบร้อยแล้ว",
    Downloaded: "ดาวน์โหลด",
    Offered: "ได้รับการเสนอ",
    Rejected: "ถูกปฏิเสธ",
    Shortlisted: "รับพิจารณา",
    Viewed: "เข้าเยี่ยมชม",
    "Documents Requested": "ขอดูเอกสาร",
    "Documents request accepted": "คำขอดูเอกสารได้รับอนุญาต",
    "Documents request rejected": "คำขอดูเอกสารถูกปฏิเสธ",
    "HR Assistant": "ผู้ช่วยฝ่ายบุคคล",
    "HR Interpreter": "ล่ามฝ่ายบุคคล",
    "HR Manager": "ผู้จัดการฝ่ายบุคคล",
    "HR Officer": "เจ้าหน้าที่ฝ่ายบุคคล",
    "Candidate pays fees in advance, then is reimbursed by company after starting work":
      "ผู้สมัครชำระค่าธรรมเนียมไปก่อนแล้วเบิกกับทางบริษัทภายหลัง",
    "Company pays all fees in advance":
      "บริษัทชำระค่าธรรมเนียมทั้งหมดไว้ล่วงหน้าแล้ว",
    "India (+91)": "อินเดีย (+91)",
    "Myanmar (+95)": "พม่า (+95)",
    "Nepal (+977)": "เนปาล (+977)",
    "Thailand (+66)": "ไทย (+66)",
    "Malaysia (+60)": "มาเลเซีย (+60)",
    "Cambodia (+855)": "กัมพูชา (+855)",
    "A Little Bit": "นิดหน่อย",
    Moderately: "ปานกลาง",
    "Very Well": "ดีมาก",
    None: "ไม่ถนัด",
    Some: "ถนัดบ้าง",
    Proficient: "คล่อง",
    English: "อังกฤษ",
    Thai: "ไทย",
    "Bahasa Malaysia": "บาฮาซามาเลเซีย",
    Hindi: "ฮินดี",
    "Cannot read": "อ่านไม่ได้",
    APPLICABLE: "สมัครได้",
    APPLIED: "สมัครแล้ว",
    VIEWED: "ดูแล้ว",
    DOWNLOADED: "ดาวน์โหลดแล้ว",
    SHORTLISTED: "ช็อตลิสต์แล้ว",
    OFFERED: "เสนอแล้ว",
    ACCEPTED: "รับแล้ว",
    REJECTED_BY_BUSINESS: "ถูกปฏิเสธโดยธุรกิจ",
    REJECTED_BY_APPLICANT: "ถูกปฏิเสธโดยผู้สมัคร",
    CONTRACT_SIGNED: "เซ็นสัญญาแล้ว",
    APPLICANT_NA: "ผู้สมัคร_NA",
    WAITLISTED: "อยู่ในรายการรอแล้ว",
    MOVED_GENERAL_WAITLIST: "ย้ายรสยการรอทั่วไปแล้ว",
    "STATUS N/A": "สถานะ N/A",
    "Cannot speak": "พูดไม่ได้",
    "Network Error!": "เครือข่ายผิดพลาด",
    "User with this username already exists": "ชื่อผู้ใช้นี้มีผู้ใช้แล้ว",
    "You are trying to login with Business User Account !":
      "คุณกำลังพยายามเข้าสู่ระบบด้วยบัญชีผู้ใช้แบบธุรกิจ !",
    "There was an authentication problem": "เกิดปัญหาในการรับรองความถูกต้อง",
    Applicable: "สมัครได้",
    "Not Applicable": "สมัครไม่ได้",
    "N/A": "ไม่เกี่ยวข้อง",
    "Basic Information": "ข้อมูลพื้นฐาน",
    Gender: "เพศ",
    "Date of Birth": "วันเกิด",
    "Marital Status": "สถานภาพการสมรส",
    Language: "ภาษา",
    Education: "การศึกษา",
    "Phone number": "หมายเลขโทรศัพท์",
    Email: "อีเมล",
    Address: "ที่อยู่",
    "National ID": "บัตรประจำตัวประชาชน",
    "Passport Number": "หมายเลขพาสปอร์ต",
    "Expires at": "วันหมดอายุ",
    "Measurements & Health": "สัดส่วน และ สุขภาพ",
    Weight: "น้ำหนัก",
    kg: "กิโลกรัม",
    lb: "ปอนด์",
    Height: "ส่วนสูง",
    cm: "เซนติเมตร",
    ft: "ฟุต",
    in: "นิ้ว",
    "Have undergone medical operation recently":
      "คุณเคยเข้ารับการผ่าตัดเมื่อไม่นานมานี้หรือไม่?",
    yes: "ใช่",
    no: "ไม่ใช่",
    "Work Experience": "ประสบการณ์การทำงาน",
    "{duration} years": "{duration} ปี",
    "Advanced Search": "ค้นหาขั้นสูง",
    "Workers who have more than 12 months of experience in this industry":
      "แรงงานที่มีประสบการณ์การทำงานมากกว่า 12 เดือนในกลุ่มอุตสาหกรรมนี้",
    Industry: "อุตสาหกรรม",
    "Workers {language} proficiency":
      "ความถนัดทางด้านภาษา {language} ของแรงงาน",
    "Language Proficiency": "ความถนัดทางด้านภาษา",
    "Workers current location": "ที่อยู่ปัจจุบันของแรงงาน",
    Province: "จังหวัด",
    "Application has been rejected": "ใบสมัครถูกปฏิเสธ",
    "An error occurred while rejecting application.":
      "เกิดข้อผิดพลาดขึ้นระหว่างปฏิเสธใบสมัคร",
    "A request for documents has been sent.": "คำขอดูเอกสารได้ถูกส่งไปแล้ว",
    "An error occurred while sending request for documents.":
      "เกิดข้อผิดพลาดระหว่างยื่นคำขอดูเอกสาร",
    "Job offer has been successfully sent.":
      "ข้อเสนอรับสมัครงานถูกส่งเรียบร้อยแล้ว",
    "An error occurred while sending job offer.":
      "เกิดข้อผิดพลาดระหว่างยื่นข้อเสนอรับสมัครงาน",
    "Application has been shortlisted": "ใบสมัครถูกรับไปพิจารณา",
    "An error occurred while shortlisting application.":
      "เกิดข้อผิดพลาดขึ้นระหว่างรับพิจารณาใบสมัคร",
    "Working Experience": "ประสบการณ์การทำงาน",
    "{experience} years": "{experience} ปี",
    View: "ดู",
    "Are you sure you would like to shortlist the application? This action cannot be undone later.":
      "คุณแน่ใจหรือไม่ว่าจะรับพิจารณาใบสมัครนี้? คุณจะไม่สามารถย้อนกลับมาแก้ไขได้อีก",
    Continue: "ต่อไป",
    Cancel: "ยกเลิก",
    Shortlist: "รับพิจารณา",
    "Are you sure you would like to send the job offer? This action cannot be undone later.":
      "คุณแน่ใจหรือไม่ที่จะยื่นข้อเสนอนี้? คุณจะไม่สามารถแก้ไขอะไรได้อีกเมือข้อเสนอถูกยื่นไปแล้ว",
    "Send offer": "ยื่นข้อเสนอ",
    "Are you sure you would like to request the documents? This action cannot be undone later":
      "คุณต้องการขอเอกสารใช่หรือไม่? คุณจะไม่สามารถย้อนกลับมาแก้ไขได้อีก",
    "Request documents": "ขอเอกสาร",
    "Please select one of the reasons below.": "โปรดเลือกเหตุผลที่กำหนด",
    "Other rejection reason": "เหตุผลอื่นในการปฏิเสธ",
    Reject: "ปฏิเสธ",
    new: "ใหม่",
    "{number} documents attached": "เอกสารที่แนบมา {number} ไฟล์",
    "Documents requested {timeAgo}": "ขอดูเอกสารไปแล้ว {timeAgo}",
    Yes: "ใช่",
    No: "ไม่ใช่",
    Passport: "พาสปอร์ต",
    Included: "รวมอยู่ด้วย",
    "Application has been waitlisted successfully and sent to application #{id}.":
      "การสมัครงานได้อยู่ในรายการรอเรียบร้อยแล้ว และส่งไปยังแอปพลิเคชัน #{id}",
    "An error occurred while waitlisting the application #{id}.":
      "เกิดข้อผิดพลาดขณะรายการรอการสมัคร #{id}",
    "Job offer Expiry Date has been successfully updated.":
      "วันที่หมดอายุของข้อเสนองานได้รับการอัปเดตเรียบร้อยแล้ว",
    "Roster Application": "ใบสมัครบัญชีรายชื่อ",
    "Offered will Expire at: ": "ข้อเสนอจะหมดอายุเมื่อ:",
    "Update Expiry Date": "อัพเดทวันหมดอายุ",
    "Are you sure you would like to move the selected applicants to waitlist? This action cannot be undone later.":
      "คุณแน่ใจหรือไม่ว่าต้องการย้ายผู้สมัครที่เลือกไปยังรายชการรอ? การดำเนินการนี้ไม่สามารถยกเลิกได้ในภายหลัง",
    "Move to waitlist": "ย้ายไปรายการรอ",
    From: "จาก",
    To: "ถึง",
    "Quick Filter": "ฟิลเตอร์แบบเร็ว",
    "Select Available Shift": "เลือกช่วงกะทำงาน",
    "{number} matches": "เจอ {number} งาน",
    "Select city": "เลือกเขต",
    "Select country": "เลือกประเทศ",
    "Select currency": "เลือกอัตราแลกเปลี่ยน",
    "Select Day of Week Off": "เลือกวันหยุดของแต่ล่ะสัปดาห์",
    "Select Level": "เลือกระดับ",
    "Select policy type": "เลือกประเภทของนโยบาย",
    "Select employer": "เลือกนายจ้าง",
    "Other/not listed here": "อื่น ๆ / ไม่มีอยู่ในลิสต์นี้",
    "Select size": "เลือกขนาด",
    "Select Industry": "เลือกกลุ่มอุตสาหกรรม",
    "Select position category": "เลือกประเภทของตำแหน่งงาน",
    "Select job type": "เลือกประเภทของงาน",
    "Select level": "เลือกระดับ",
    "Select Language": "เลือกภาษา",
    "Select Status": "เลือกสถานะ",
    "Select Overtime Expectations": "เลือกการทำงานล่วงเวลาที่คาดไว้",
    "Select terms": "เลือกเงื่อนไข",
    "Select timing": "เลือกระยะเวลา",
    "Select Reason": "เลือกเหตุผล",
    "Select state": "เลือกจังหวัด",
    "Select industry": "เลือกกลุ่มอุตสาหกรรม",
    Verification: "การยืนยัน",
    Account: "บัญชี:",
    "My jobs": "งานของฉัน",
    "Post new": "ประกาศรับสมัครงานใหม่",
    Resume: "เรซูเม่",
    Roster: "บัญชีรายชื่อ",
    Waitlist: "รายการรอ",
    Logout: "ออก",
    Metrics: "เมตริก",
    Surveys: "แบบสำรวจ",
    "Verify RA": "ยืนยันบริษัทจัดหางาน",
    "Verify Employers": "ยืนยันนายจ้าง",
    "My account": "บัญชีของฉัน",
    Profile: "โปรไฟล์",
    "This business is already managed by another user. Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "ธุรกิจนี้ได้รับการจัดการโดยผู้ใช้รายอื่นแล้ว คุณแน่ใจหรือไม่ว่าต้องการรวมเข้ากับบริษัทจัดหางานรายนี้? การดำเนินการนี้ไม่สามารถยกเลิกได้ในภายหลัง",
    "This business is managed by Issara. Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "ธุรกิจนี้บริหารโดยอิส่รา คุณแน่ใจหรือไม่ว่าต้องการรวมเข้ากับบริษัทจัดหางานรายนี้? การดำเนินการนี้ไม่สามารถยกเลิกได้ในภายหลัง",
    "Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "คุณแน่ใจหรือไม่ว่าต้องการรวมเข้ากับบริษัทจัดหางานรายนี้? การดำเนินการนี้ไม่สามารถยกเลิกได้ในภายหลัง",
    "Yes. Merge": "ใช่ รวม",
    "This business is already in our database. Merge it.":
      "ธุรกิจนี้มีอยู่แล้วในฐานข้อมูลของเรา รวม",
    Merge: "รวม",
    Name: "ชื่อ",
    "Managed By": "จัดการโดย",
    "Businesses have been successfully merged":
      "รวมธุรกิจเข้าด้วยกันเรียบร้อยแล้ว",
    "An error occurred while merging. Please contact a developer.":
      "เกิดข้อผิดพลาดขณะการรวม โปรดติดต่อผู้พัฒนา",
    "Search ...": "ค้นหา...",
    "No employers found": "ไม่พบนายจ้าง",
    "recruiter loaded": "นายหน้าโหลดแล้ว",
    "employers loaded": "นายจ้างโหลดแล้ว",
    "Load More employers": "โหลดนายจ้างเพิ่ม",
    "License Number": "หมายเลขใบอนุญาต",
    "No recruiters found": "ไม่พบบริษัทจัดหางาน",
    "recruiters loaded": "บริษัทจัดหางานโหลดแล้ว",
    "Load More Recruiters": "โหลดบริษัทจัดหางานเพิ่ม",
    "Delete Cover Photo": "ลบรูปปก",
    "Add Cover Photo": "เพิ่มรูปปก",
    "Uploading....": "กำลังโหลด..",
    "Upload Cropped Cover Photo": "อัปโหลดรูปปกที่ครอบตัด",
    "Delete This Photo": "ลบรูปนี้",
    "Available image upload": "อัพโหลดรูปภาพที่มีอยู่",
    "Add business image": "เพิ่มรูปบริษัท",
    "Upload Cropped Image": "อัปโหลดรูปที่ครอบตัด",
    "Password reset link was successfully sent to {email}. Please check your inbox.":
      "การเปลี่ยนรหัสผ่านเสร็จสมบูรณ์ ลิงค์จะถูกส่งไปที่ {email} โปรดดูอินบ๊อกซ์ของคุณ",
    "Profile has been successfully updated.": "โปรไฟล์อัพเดทเสร็จสมบูรณ์",
    "An error occurred while updating profile. Please contact a developer.":
      "เกิดข้อผิดพลาดขึ้นระหว่างการอัพเดทโปรไฟล์ โปรดติดต่อทีมงานของเรา",
    "Please use one of the following formats: .jpeg, .jpg or .png":
      "โปรดใช้ไฟล์ตระกูล .jpeg .jpg หรือ .png",
    "Push notifications have been successfully enabled":
      "เปิดการแจ้งเตือนเสร็จสมบูรณ์",
    "Sample Notification": "ตัวอย่างการแจ้งเตือน",
    "You will receive me whenever new job application submitted or content added.":
      "คุณจะได้รับการแจ้งเตือนเมื่อมีผู้สมัครงานเข้ามาหรือมีการเพิ่มเติมเนื้อหาใหม่ ๆ",
    "If you blocked push notifications, you can only undo it in your browser settings.":
      "หากคุณปิดการแจ้งเตือน คุณสามารถเข้าไปแก้ไขในการตั้งค่าได้",
    "Push notifications have been successfully disabled":
      "ปิดการแจ้งเตือนเสร็จสมบูรณ์",
    "Verified Business": "บริษัทที่ได้รับการยืนยันแล้ว",
    Type: "ประเภท",
    Employer: "นายจ้าง",
    Joined: "เข้าร่วม",
    Updated: "อัพเดท",
    "Push notifications are not supported.":
      "ระบบการแจ้งเตือนไม่สามารถใช้งานได้",
    "Push notifications": "การแจ้งเตือน",
    "Are you sure you want to reset the password?":
      "คุณต้องการเปลี่ยนรหัสผ่านหรือไม่?",
    "The reset link will be sent to this email address: {email}":
      "ลิงค์ที่ใช้ในการเปลี่ยนจะส่งไปยังอีเมล: {email}",
    Reset: "เปลี่ยน",
    "Reset password": "เปลี่ยนรหัสผ่าน",
    "Enable view mode": "เปิดใช้งานโหมดดู",
    "Enable edit mode": "เปิดใช้งานโหมดแก้ไข",
    "Basic information": "ข้อมูลพื้นฐาน",
    Logo: "โลโก้",
    "Update logo": "อัพเดทโลโก้",
    "Company Name ({lang})": "ชื่อบริษัท ({lang})",
    "Please fill in the company name": "โปรดกรอกชื่อบริษัท",
    "Company Name": "ชื่อบริษัท",
    "Working since": "วันก่อตั้ง",
    "Registration number": "หมายเลขจดทะเบียน",
    "Max length of the registration number is 12":
      "ความยาวสูงสุดที่ใส่ได้ 12 ตัวอักษร",
    "Example: 027/2019": "ตัวอย่าง: 027/2019",
    Size: "ขนาด",
    "About us ({lang})": "เกี่ยวกับเรา ({lang})",
    "Contact information": "ข้อมูลการติดต่อ",
    "Main contact name": "ชื่อผู้ติดต่อ",
    "Main contact email": "อีเมลติดต่อ",
    "This is not a valid email.": "อีเมลที่ใส่ไม่ถูกต้อง",
    "Website (URL)": "Website (URL)",
    "Website(URL) format is incorrect": "Website(URL) ไม่ถูกต้อง",
    "Facebook (URL)": "Facebook (URL)",
    "Facebook url format is incorrect": "ชื่อ Facebook ที่ใส่ไม่ถูกต้อง",
    "Viber (phone number)": "Viber (หมายเลขโทรศัพท์)",
    "Please use the following format: +999999999":
      "โปรดใช้รูปแบบต่อไปนี้: +999999999",
    "Line (ID)": "Line (ID)",
    "Line format is incorrect": "ชื่อ Line ที่ใส่ไม่ถูกต้อง",
    "Phone numbers": "หมายเลขโทรศัพท์",
    "Add new": "เพิ่ม",
    "Country, State, City": "ประเทศ จังหวัด เขต",
    "Apt, Building, Street ({lang})": "เลขที่, หมู่, ถนน, ตำบล(แขวง) ({lang})",
    "Please fill in the address details": "โปรดระบุรายละเอียดของที่พัก",
    Zip: "รหัสไปรษณีย์",
    "Update Business Profile": "อัพเดทโปรไพล์ของคุณ",
    Description: "รายละเอียด",
    "Contact email": "อีเมลติดต่อ",
    Website: "Website",
    Facebook: "Facebook",
    Viber: "Viber",
    Line: "Line",
    Country: "ประเทศ",
    State: "จังหวัด",
    City: "เขต",
    "Apt, Building, Street": "ที่พัก อาคาร ถนน",
    "Application Details:": "รายละเอียดใบสมัคร:",
    Back: "กลับ",
    "Full Name": "ชื่อ - นามสกุล",
    "Phone Number": "หมายเลขโทรศัพท์",
    "Current Address": "ที่อยู่ปัจจุบัน",
    "Passport Expiry Date": "วันหมดอายุของพาสปอร์ต",
    "After going through the job description, do you think you can do the job well?":
      "หลังจากอ่านรายละเอียดของงานแล้ว คุณคิดว่าคุณสามารพทำงานนี้ได้ดีหรือไม่?",
    "Do you know someone working here?":
      "คุณรู้จักใครที่ทำงานในบริษัทนี้หรือไม่?",
    "Languages Proficiency": "ความถนัดทางด้านภาษา",
    "Work experience": "ประสบการณ์การทำงาน",
    Duration: "ระยะเวลา",
    Remark: "หมายเหตุ",
    "Uploaded Documents": "อัพโหลดเอกสาร",
    "Identification Card": "บัตรประจำตัว",
    "Birth Certificate": "ใบแจ้งเกิด",
    "Family Record Book": "สมุดบันทึกครอบครัว",
    "Residence Book": "หนังสือถิ่นที่อยู่",
    "Covid Vaccine Certificate": "ใบรับรองการฉีดวัคซีคโควิด",
    NRC: "NRC",
    "Household List": "รายชื่อครัวเรือน",
    "Passport (PJ)": "พาสปอร์ต (PJ)",
    "Labour Card": "บัตรแรงงาน",
    "Generating PDF ...": "กำลังสร้าง PDF ...",
    "Generate PDF & Download": "สร้าง PDF และดาวน์โหลด",
    "Download/Print": "ดาวน์โหลด/พิมพ์",
    "Are you sure you would like to request the documents? This action cannot be undone later.":
      "คุณต้องการที่จะขอดูเอกสารใช่หรือไม่? คุณจะไม่สามารถย้อนกลับมาแก้ไขได้อีก",
    "Request documents dfsdfwe": "ขอเอกสาร",
    "Application #{id} has been successfully rejected":
      "ใบสมัคร #{id} ถูกปฏิเสธเรียบร้อยแล้ว",
    "A request for documents for application #{id} has been sent":
      "คำขอดูเอกสารของผู้สมัคร #{id} ได้ถูกส่งไปแล้ว",
    "Application #{id} has been successfully contract signed":
      "ใบสมัคร #{id} ได้รับการลงนามในสัญญาเรียบร้อยแล้ว",
    "An error occurred while contract signing application #{id}.":
      "เกิดข้อผิดพลาดขณะลงนามในสัญญา #{id}",
    "Application #{id} has been successfully shortlisted":
      "ใบสมัคร #{id} ถูกรับไปพิจารณา",
    "An error occurred while shortlisting application #{id}.":
      "เกิดข้อผิดพลาดขึ้นระหว่างรับพิจารณาใบสมัคร #{id}",
    "Job offer has been successfully sent to application #{id}.":
      "ข้อเสนอรับสมัครงานถูกส่งไปยังผู้สมัคร #{id} เรียบร้อยแล้ว",
    "An error occurred while sending job offer to application #{id}.":
      "เกิดข้อผิดพลาดระหว่างยื่นข้อเสนอให้ผู้สมัคร #{id}",
    "Application has been moved to general waitlisted on application #{id}.":
      "แอปพลิเคชันถูกย้ายไปยังรายการรอทั่วไปในแอปพลิเคชัน #{id}",
    "An error occurred while moving the application #{id}. to general waitlist":
      "เกิดข้อผิดพลาดขณะย้ายแอปพลิเคชัน #{id} ไปยังรายการรอทั่วไป",
    "View Applications": "ดูใบสมัคร",
    "Pending ({count})": "รอ ({count}) ใบสมัคร",
    "Shortlisted ({count})": "รับพิจารณา ({count}) ใบสมัคร",
    "Waitlisted ({count})": "อยู่ในรายการรอแล้ว\n ({count}) ",
    "Offered ({count})": "ยื่นข้อเสนอไปแล้ว ({count}) ใบสมัคร",
    "Rejected by applicant ({count})": "ผู้สมัครปฏิเสธ ({count}) ใบสมัคร",
    "Rejected by business ({count})": "นายจ้างปฏิเสธ ({count}) ใบสมัคร",
    "Accepted ({count})": "รับ ({count}) ใบสมัคร",
    "Contract Signed ({count})": "เซ็นสัญญาแล้ว ({count})",
    "Select all ({number})": "เลือกทั้งหมด ({number})",
    "Deselect all ({number})": "ลบทั้งหมด ({number})",
    "Reject ({number})": "ปฏิเสธ ({number})",
    'Are you sure you would like to change the status to "Contract Signed" to the selected applications? This action cannot be undone later':
      'คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนสถานะเป็น "ลงนามในสัญญา" ของใบสมัครที่เลือก การดำเนินการนี้ไม่สามารถยกเลิกได้ในภายหลัง',
    "Contract Signed ({number})": "เซ็นสัญญาแล้ว ({number})",
    "Are you sure you would like to shortlist selected applications? This action cannot be undone later":
      "คุณแน่ใจหรือไม่ว่าจะรับพิจารณาใบสมัครนี้? คุณจะไม่สามารถย้อนกลับมาแก้ไขได้อีก",
    "Shortlist ({number})": "ใบสมัครที่รับพิจารณา ({number})",
    "Request documents ({number})": "ขอดูเอกสาร ({number})",
    "Move to waitlist ({number})": "ย้ายไปรายการรอ ({number})",
    "Are you sure you would like to send the job offer to selected applicants? This action cannot be undone later.":
      "คุณแน่ใจหรือไม่ที่จะยื่นข้อเสนอนี้ไปยังผู้สมัคร? คุณจะไม่สามารถแก้ไขอะไรได้อีกเมื่อข้อเสนอถูกยื่นไปแล้ว",
    "Send offer ({number})": "ยื่นข้อเสนอ ({number})",
    "Are you sure you would like to move the selected applicants to General Waitlist? This action cannot be undone later.":
      "คุณแน่ใจหรือไม่ว่าต้องการย้ายผู้สมัครที่เลือกไปยังรายการรอทั่วไป การดำเนินการนี้ไม่สามารถยกเลิกได้ในภายหลัง",
    "Move to General Waitlist ({number})": "ย้ายไปรายการรอทั่วไป ({number})",
    "Please select an offer expiry date": "โปรดเลือกวันหมดอายุของข้อเสนองาน",
    "Update Now": "อัพเดทตอนนี้",
    "Offer Now": "เสนองานตอนนี้",
    "Offer expiry date will be {expiry_date}":
      "วันหมดอายุของข้อเสนอจะเป็น {expiry_date}",
    "Resume Detail Information": "ข้อมูลรายละเอียดเรซูเม่",
    "Resume Information": "ข้อมูลเรซูเม่",
    "The job seeker has a National ID": "ผู้หางานมีบัตรประจำตัวประชาชน",
    "The job seeker does not have a National ID":
      "ผู้หางานไม่มีบัตรประจำตัวประชาชน",
    "The job seeker has passport": "ผู้หางานมีพาสปอร์ต",
    "The job seeker does not have a passport": "ผู้หางานไม่มีพาสปอร์ต",
    "Read Level": "ระดับการอ่าน",
    "Speak Level": "ระดับการพูด",
    "Applied At": "สมัครที่",
    Action: "ดำเนินการ",
    "Resume View": "ดูเรซูเม่",
    "General Waitlist": "รายการรอทั่วไป",
    Jobseekers: "ผู้หางาน",
    Previous: "ก่อนหน้า",
    Next: "ต่อไป",
    "Your job has been successfully posted": "ประการรับสมัครงานเสร็จสมบูรณ์",
    "View posted jobs": "ดูงานที่ประกาศรับสมัคร",
    "Post new job": "ประกาศรับสมัครงานใหม่",
    "An error occurred while saving draft.":
      "เกิดข้อผิดพลาดขึ้นระหว่างการบันทึก",
    "Job has been successfully saved as a draft":
      "ร่างประกาศรับสมัครงานถูกบันทึกเรียบร้อยแล้ว",
    "Create new job": "สร้างงานใหม่",
    "View drafts": "ดูร่าง",
    "Post job": "ประกาศรับสมัครงาน",
    "Open to": "เปิด",
    Nationality: "สัญชาติ",
    "Choose at least one nationality": "เลือกอย่างน้อย 1 สัญชาติ",
    Documents: "เอกสาร",
    Pinkcard: "บัตรสีชมพู",
    "Job Information": "รายละเอียดของงาน",
    "Job Title": "ตำแหน่งงาน",
    "Please enter job title": "โปรดระบุตำแหน่งงาน",
    "Job title": "ตำแหน่งงาน",
    "Please select industry": "โปรดเลือกกลุ่มอุตสาหกรรม",
    "Job position category": "ประเภทของตำแหน่งงาน",
    "Job type": "ประเภทของงาน",
    "Please select job type": "โปรดระบุประเภทของงาน",
    "Expiry date": "วันหมดอายุ",
    "Please choose expire date": "โปรดเลือกวันหมดอายุ",
    "Headcount needed": "จำนวนแรงงานที่ต้องการ",
    "Please input headcount needed": "โปรดระบุจำนวนแรงงานที่ต้องการ",
    "Number of employees for this position you would like to recruit":
      "จำนวนพนักงานที่คุณต้องการจ้างในตำแหน่งนี้",
    "Contract duration": "ระยะเวลาของสัญญา",
    Months: "เดือน",
    "Please input duration": "โปรดระบุระยะเวลา",
    "Duration of contract, in months":
      "ระยะเวลาของสัญญา, โปรดระบุเป็นจำนวนเดือน",
    Location: "สถานที่",
    "Please select country": "โปรดเลือกประเทศ",
    "The country of workplace": "สถานที่ทำงานตั้งอยู่ในประเทศ",
    "Please select state": "โปรดเลือกจังหวัด",
    "Please select city": "โปรดเลือกเขต",
    "{language} translation": "{language} แปล",
    "Please enter title": "โปรดระบุตำแหน่ง",
    "Name of the recruited job": "ชื่อของตำแหน่งงานที่จ้าง",
    "Position details": "รายละเอียดของตำแหน่งงาน",
    "Please enter position details": "โปรดระบุรายละเอียดของตำแหน่งงาน",
    "Job Description": "รายละเอียดของงาน",
    "Please enter job description": "โปรดระบุรายละเอียดของงาน",
    "Please describe the responsibilities and environment of this position":
      "โปรดระบุความรับผิดชอบและสภาพแวดล้อมของงานตำแหน่งนี้",
    "Applicant Requirements": "คุณสมบัติของผู้สมัคร",
    "Please enter applicant requirements":
      "โปรดระบุคุณสมบัติของผู้สมัครที่ต้องการ",
    "Please describe the prerequisite skills or qualifications needed to do this job sucessfully, if any":
      "โปรดระบุทักษะหรือความสามารถที่ต้องการในการทำงาน (ถ้ามี)",
    "Contact name": "ชื่อผู้ติดต่อ",
    "Please enter contact name": "โปรดระบุชื่อผู้ติดต่อ",
    "Please provide the name of the contact person for {language} applicants":
      "โปรดระบุชื่อผู้ติดต่อสำหรับผู้สมัคร{language}",
    "Please enter contact phone number": "โปรดระบุหมายเลขโทรศัพท์",
    "Please provide the phone number of the contact person for {language} applicants":
      "โปรดระบุหมายเลขโทรศัพท์ที่ใช้ในการติดต่อสำหรัลผู้สมัคร {language}",
    "Contact job title": "ติดต่อเจ้าหน้าที่",
    "Please select contact job title": "โปรดระบุเจ้าหน้าที่ที่รับการติดต่อ",
    "Recruitment Information": "ข้อมูลเกี่ยวกับการจ้างงาน",
    "Do you have an ethical recruitment policy?":
      "คุณมีนโยบายเกี่ยวกับการจ้างงานอย่างมีจริยธรรมหรือไม่",
    "Ethical recruitment policies specify more transparent and ethical conduct in the recruitment of workers":
      "นโยบายด้านการจ้างงานอย่างมีจริยธรรมกำหนดให้กระบวนการในการจ้างงานมีความโปร่งใสและมีจริยธรรมมากขึ้นสำหรับแรงงาน",
    "Do you have an employer pays policy?":
      "คุณมีนโยบายนายจ้างเป็นผู้รับผิดชอบค่าใช้จ่ายในการจ้างงานหรือไม่?",
    "Employer pays policies specify that all costs of recruitment are paid by the employer, and it is guaranteed that zero fees will be charged to workers, including for document processing and fees to recruiters":
      "นโยบายนายจ้างเป็นผู้รับผิดชอบค่าใช้จ่ายในการจ้างงานกำหนดไว้ว่านายจ้างจะจ่ายค่าใช้จ่ายทั้งหมดที่เกี่ยวของกับการจ้างงาน โดยที่แรงงานจะไม่ต้องเสียค่าใช้จ่ายใด ๆ รวมถึงค่าธรรมเนียมในการดำเนินการด้านเอกสารที่จ่ายให้กับนายหน้าจัดหางาน",
    "Employer Pays Policy Type":
      "ประเภทของนโยบายนายจ้างเป็นผู็รับผิดชอบค่าใช้จ่ายในการจ้างงาน",
    "Please select policy type": "โปรดเลือกประเภทของนโยบาย",
    "Who pays the visa fees?": "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการออกวีซ่า?",
    Worker: "แรงงาน",
    Amount: "จำนวน",
    "Who pays the work permit fees?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการออกใบอนุญาตทำงาน",
    "Who pays the medical checkup fees?":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายในการตรวจสุขภาพ?",
    "Contract Information: Pay": "ข้อมูลในสัญญา: การจ่ายเงิน",
    "Terms of payment": "เงื่อนไขในการจ่ายเงิน",
    "Please select terms of payment": "โปรดระบุเงื่อนไขในการจ่ายเงิน",
    "Please note whether the employee will be paid according to a daily or monthly rate, and how frequently they will be paid":
      "โปรดระบุว่าลูกจ้างจะได้รับค่าจ้างในอัตรารายวันหรือรายเดือน และความถี่ในการจ่ายค่าจ้าง",
    "Timing of payment": "ช่วงเวลาในการจ่ายเงิน",
    "Please select or create timing of payment":
      "โปรดระบุุหรือกำหนดช่วงเวลาในการจ่ายเงิน",
    "Please note whether the employee will be paid according to once a month or twice a month rate, and how frequently they will be paid":
      "โปรดระบุความถี่ในการจ่ายค่าจ้าง 1 ครั้งต่อเดือน หรือ 2 ครั้งต่อเดือน",
    "Timing of Payment Other": "ช่วงเวลาในการจ่ายเงิน",
    "Please input Timing of Payment Other":
      "โปรดระบุุช่วงเวลาอื่นในการจ่ายเงิน",
    "Other type of Payment Timing": "ช่วงเวลาอื่นในการจ่ายเงิน",
    "Enter Timing of Payment Other": "ระบุช่วงเวลาในการจ่ายเงิน",
    Currency: "อัตราแลกเปลี่ยน",
    "Please select currency": "โปรดเลือกอัตราแลกเปลี่ยน",
    "Monthly salary": "เงินเดือน",
    "{currency}/month": "{currency}/เดือน",
    "Please input salary": "โปรดระบุเงินเดือน",
    "Monthly payment to employee, which must be equal to or greater than the minimum wage for the province":
      "อัตราค่าจ้างรายเดือนเท่ากับหรือมากกว่าค่าจ้างขั้นต่ำที่กำหนดไว้ในจังหวัด",
    "Daily Wage": "ค่าจ้างรายวัน",
    "{currency}/day": "{currency}/วัน",
    "Please input daily wage rate": "โปรดระบุอัตราค่าจ้างรายวัน",
    "Daily wage amount for 8 hours of work, equal to or greater than the minimum daily wage for the province":
      "อัตราค่าจ้างรายวันสำหรับการทำงาน 8 ชั่วโมง เท่ากับหรือมากกว่าอัตราค่าจ้างรายวันที่กำหนดไว้ในจังหวัด",
    "Available Shifts": "ช่วงกะทำงานที่ว่าง",
    "What shift is this position expected to offer?":
      "ตำแหน่งนี้จะต้องทำงานในช่วงกะการทำงานใด?",
    "These are the available shift choices the position offers.":
      "ช่วงเวลากะการทำงานสำหรับงานตำแหน่งนี้",
    "Overtime Expectation": "การทำงานล่วงเวลาที่คาดไว้",
    "How often is this position expected to offer voluntary OT?":
      "ความถี่ในการทำงานล่วงเวลาที่คาดหวังไว้สำหรับตำแหน่งนี้?",
    "How often the position will require overtime.":
      "ความถี่ของการทำงานล่วงเวลาในตำแหน่งนี้?",
    "Day of Week Off": "วันหยุดประจำสัปดาห์",
    "What day of the week is the day off for this position?":
      "วันหยุดประจำสัปดาห์ของตำแหน่งนี้คือ?",
    "Helps workers to understand how fixed their schedule will be.":
      "ช่วยเหลือแรงงานให้เข้าใจตารางเวลาการทำงานของแรงงาน",
    "Minimum Working Hours": "จำนวนชั่วโมงขั้นต่ำในการทำงาน",
    "What are the minimum number of hours per week expected for this position?":
      "ชั่วโมงการทำงานขั้นต่ำต่อสัปดาห์สำหรับตำแหน่งนี้",
    "Minimum number of hours per week expected for this position?":
      "จำนวนชั่วโมงการทำงานขั้นต่ำต่อสัปดาห์สำหรับตำแหน่งนี้",
    "Regular OT": "ทำงานล่วงเวลาปกติ",
    "Please input regular OT rate": "โปรดระบุอัตราค่าทำงานล่วงเวลาปกติ",
    "{currency}/hour": "{currency}/ชั่วโมง",
    "Payment rate per hour for overtime worked on a normal working day to employee, which by law must be at least 1.5x regular hourly rate":
      "อัตราค่าจ้างในการทำงานล่วงเวลาในวันทำงานปกติ กฎหมายกำหนดให้จ่ายอย่างต่ำ 1.5 เท่าของอัตราค่าจ้างปกติ",
    "Day-Off Rate": "อัตราค่าจ้างการทำงานในวันหยุด",
    "Please input normal working hours rate during holiday":
      "โปรดระบุอัตราค่าจ้างรายชั่วโมงในการทำงานช่วงวันหยุด",
    "Payment rate for working on the normally scheduled day off must be at least 2x the daily wage rate according to law":
      "อัตราค่าจ้างในการทำงานในวันหยุดประจำสัปดาห์ กฎหมายกำหนดให้จ่ายในอัตรา 2 เท่าของค่าจ้างปกติ",
    "Holiday OT": "ทำงานล่วงเวลาในวันหยุดประจำปี",
    "Please input Day-Off Rate":
      "โปรดระบุอัตราค่าจ้างในการทำงานในวันหยุดประจำสัปดาห์",
    "Payment rate per hour for overtime worked on a holiday, which by law must be at least 3x regular hourly rate":
      "อัตราค่าจ้างในการทำงานล่วงเวลาในวันหยุดประจำปี/วันลาของพนักงาน กฎหมายกำหนดให้จ่ายค่าจ้างในอัตรา 3 เท่าของค่าจ้างปกติ",
    Benefits: "สิทธิประโยชน์",
    "How many days of paid annual leave are provided per year?":
      "มีการจัดวันลาพักผ่อนประจำปี (โดยได้รับค่าจ้าง) ให้กี่วันต่อปี?",
    "Please input number": "โปรดระบุจำนวน",
    "How many days of paid sick leave are provided per year?":
      "มีการจัดวันลาป่วย (โดยได้รับค่าจ้าง) ให้กี่วันต่อปี?",
    "How many days of paid business leave are provided per year?":
      "มีการจัดวันลากิจ (โดยได้รับค่าจ้าง) ให้กี่วันต่อปี?",
    "How many days of paid national holidays are provided per year?":
      "มีการจัดวันหยุดประจำปีให้กี่วันต่อปี?",
    "Are social security and maternity leave benefits provided as required by law?":
      "มีการให้สิทธิประกันสังคมและสิทธิประโยชน์ในการลาคลอดตามกฎหมายหรือไม่?",
    "Yes, these benefits are provided":
      "มี สิทธิประกันสังคมและสิทธิประโยชน์ในการลาคลอดตามกฎหมาย",
    "No, these benefits are not provided":
      "ไม่มี สิทธิประกันสังคมและสิทธิประโยชน์ในการลาคลอดตามกฎหมาย",
    "Benefits details": "รายละเอียดของสิทธิประโยชน์",
    Accommodation: "ที่พัก",
    "Accommodation is on site": "ที่พักอยู่ในโรงงาน",
    "Family members can live together": "สมาชิกในครอบครัวสามารถอยู่ด้วยกันได้",
    "Rent/utilities auto deducted from payroll":
      "ค่าเช่า/ค่าน้ำ ค่าไฟ ถูกหักออกจากเงินเดือนโดยอัตโนมัติ",
    "Accommodation details": "รายละเอียดของที่พัก",
    "Please fill in accommodation details": "โปรดระบุรายละเอียดของที่พัก",
    "Living arrangement details for the employees":
      "รายระเอียดในการจัดที่พักสำหรับพนักงาน",
    "Rent/utilites deduction details": "รายละเอียยของค่าเช่า/ค่าน้ำ ค่าไฟ",
    "Please fill in rent details": "โปรดระบุรายละเอียดของค่าเช่า",
    "Safe, grievance mechanism": "ความปลอดภัย กลไกการร้องทุกข์",
    "Probation period": "ระยะเวลาการทดลองงาน",
    "Grievance mechanism details": "รายละเอียดของกลไกการร้องทุกข์",
    "Please input grievance mechanism details":
      "โปรดระบุรายละเอียดของกลไกการร้องทุกข์",
    "Probation period details": "รายละเอียดของระยะเวลาการทดลองงาน",
    "Please input probation period details":
      "โปรดระบุรายละเอียดของระยะเวลาทดลองงาน",
    Preview: "ดูตัวอย่าง",
    "Save draft": "บันทึกร่าง",
    Post: "ประกาศ",
    "You cannot make changes after posting the job. Are you sure you want to post this job?":
      "คุณไม่สามารถเปลี่ยนแปลงรายละเอียดได้เมื่อประกาศรับสมัครงานไปแล้ว คุณต้องการที่จะประกาศรับสมัครงานนี้หรือไม่?",
    "Job Preview": "ดูตัวอย่าง",
    "General Information": "ข้อมูลทั่วไป",
    "No information were provided": "ไม่มีข้อมูล",
    "Open To": "เปิด",
    "No of Headcount Needed": "จำนวนแรงงานที่ต้องการ",
    "Job will be expired on": "ประกาศรับสมัครงานจะสิ้นสุดลงในวันที่",
    "Pink Card Holder": "ผู้ถือบัตรชมพู",
    "Passport Holder": "ผู้ถือพาสปอร์ต",
    "Job Position Category": "ประเภทของงานที่สมัคร",
    "Job Type": "ประเภทของงาน",
    "Contract Duration": "ระยะเวลาของสัญญา",
    "{duration} months": "{duration} เดือน",
    "Job Position Details": "รายละเอียดของตำแหน่งงานที่สมัคร",
    "Job Requirements": "คุณสมบัติที่ต้องการ",
    "Have an ethical recruitment policy": "มีนโยบายการจ้างงานอย่างมีจริยธรรม",
    "Have an employer pays policy":
      "มีนโยบายนายจ้างเป็นผู้รับผิดชอบค่าธรรมเนียมในการจ้างงาน",
    "Who pays visa fees?": "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการออกวีซ่า?",
    "Who pays work permit fees?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการออกใบอนุญาตทำงาน?",
    "Who pays medical checkup fees?":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายในการตรวจสุขภาพ?",
    "Terms of Payment": "เงื่อนไขในการจ่ายเงิน",
    "Timing of Payment": "ช่วงเวลาในการจ่ายเงิน",
    Salary: "เงินเดือน",
    "OT Rate Regular": "อัตราค่าทำงานล่วงเวลาปกติ",
    "OT Rate Holiday": "อัตราค่าทำงานล่วงเวลาในวันหยุด",
    "Holiday Rate": "อัตรทำงานล่วงเวลาในวันหยุด",
    "Other Benefits": "สิทธิประโยชน์อื่น ๆ",
    "Benefits Details": "รายละเอียดของสิทธิประโยชน์",
    "Other Information": "ข้อมูลอื่น ๆ",
    "Accommodation on-site is available": "มีที่พักในพื้นที่โรงงาน",
    "Rent & utilities autodeducted from payroll":
      "ค่าเช่า/ค่าน้ำ ค่าไฟ ถูกหักออกจากเงินเดือนโดยอัตโนมัติ",
    "Safe & functioning grievance mechanism": "ความปลอดภัย และกลไกการร้องทุกข์",
    "Accommodation Details": "รายละเอียดของที่พัก",
    "Contact Information": "ข้อมูลการติดต่อ",
    Edit: "แก้ไข",
    "Job has been successfully archived.":
      "ประกาศรับสมัครงานถูกจัดเก็บเรียบร้อยแล้ว",
    "An error occurred while archiving draft.":
      "เกิดข้อผิดพลาดระหว่างจัดเก็บร่างประกาศรับสมัครงาน",
    "Job has been successfully deleted.": "ประกาศรับสมัครงานถูกลบเรียบร้อยแล้ว",
    "An error occurred while deleting job.":
      "เกิดข้อผิดพลาดขึ้นระหว่างการรลบประกาศรับสมัครงาน",
    "Recruitment has been successfully stopped.":
      "ปิดรับการรับสมัครงานเรียบร้อยแล้ว",
    "An error occurred while stopping recruitment.":
      "เกิดข้อผิดพลาดขึ้นระหว่างการการปิดการรับสมัครงาน",
    "Job has been successfully set to offline.":
      "งานถูกสร้างแบบออฟไลน์เรียบร้อยแล้ว",
    "An error occurred while setting job to offline.":
      "เกิดข้อผิดพลาดระหว่างการสร้างงานแบบออฟไลน์",
    "Job has been successfully set to online.":
      "งานถูกสร้างแบบออนไลน์เรียบร้อยแล้ว",
    "An error occurred while setting job to online.":
      "เกิดข้อผิดพลาดระหว่างการสร้างงานแบบออนไลน์",
    Title: "ตำแหน่ง",
    Posted: "ประกาศ",
    Expires: "วันสุดท้ายของการรับสมัคร",
    Views: "ดูประวัติ",
    Applicants: "ผู้สมัคร",
    "Filled Vacancies": "จำนวนผู้สมัครที่ได้รับการคัดเลือกไปแล้ว",
    Online: "ออนไลน์",
    "Are you sure you would like to end the recruitment? This action cannot be undone later!":
      "คุณต้องการปิดรับสมัครงานใช่หรือไม่? คุณจะไม่สามารถย้อนกลับมาแก้ไขได้อีก",
    "End recruitment": "ปิดการรับสมัครงาน",
    "Are you sure you would like to archive this job?":
      "คุณต้องการจะเก็บประกาศรับสมัครงานนี้หรือไม่?",
    Archive: "เก็บ",
    Saved: "บันทึก",
    Archived: "เก็บ",
    "Are you sure you would like to delete this job?":
      "คุณต้องการที่จะลบประกาศรับสมัครงานี้?",
    Delete: "ลบ",
    "View Jobs:": "ดูงานที่ประกาศรับสมัคร:",
    "Posted ({count})": "ประกาศไปแล้ว ({count}) งาน",
    "Closed ({count})": "ปิดรับสมัครไปแล้ว ({count}) งาน",
    "Drafts ({count})": "ร่าง ({count}) งาน",
    "Archived ({count})": "เก็บ ({count}) งาน",
    Workpermit: "ใบอนุญาตทำงาน",
    Visa: "วีซ่า",
    "Cancellation Letter": "แบบแจ้งคนต่างด้าวออกจากงาน",
    "Position category": "ประเภทของตำแหน่งงาน",
    "Please select timing of payment": "โปรดระบุุช่วงเวลาในการจ่ายเงิน",
    "Please input Day-Off OT":
      "โปรดระบุอัตราค่าจ้างในการทำงานในวันหยุดประจำสัปดาห์",
    "Successfully shortlisted the resume with job!":
      "ช็อตลิสต์เรซูเม่กับงานได้สำเร็จ!",
    "Error while shortlisting the resume.": "เกิดข้อผิดพลาดขณะช็อตลิสต์เรซูเม่",
    "Moved to general waitlist": "ย้ายไปรายการรอทั่วไป",
    "Are you sure you would like to shortlist this job seeker? This action cannot be undone later!":
      "คุณแน่ใจหรือไม่ว่าจะช็อตลิต์ผู้หางานนี้? คุณจะไม่สามารถย้อนกลับมาแก้ไขได้อีก",
    "Shortlist now": "ช็อตลิสต์ตอนนี้",
    Close: "ปิด",
    "Please Choose A Job to shortlist the Job seeker":
      "โปรดเลือกงานเพื่อช็อตลิสต์ผู้หางาน",
    "Resume Detail": "รายละเอียดเรซูเม่",
    "Shortlisting...": "กำลังช็อตลิสต์...",
    "Successfully shortlisted the resume !": "ช็อตลิสต์เรซูเม่เรียบร้อยแล้ว",
    "Search name": "ค้นหาชื่อ",
    "Search Phone Number": "ค้นหาหมายเลขโทรศัพท์",
    "Detail View": "ดูรายละเอียด",
    "Shortlisted At": "ช็อตลิสต์แล้วที่",
    "Viewed At": "เข้าเยี่ยมชมแล้วที่",
    "Applied resumes": "เรซูเม่ที่สมัคร",
    "Shortlisted resumes": "เรซูเม่ที่ได้รับการช็อตลิสต์",
    "Viewed resumes": "เรซูเม่ที่ได้รับการเข้าเยี่ยมชม",
    "Fetching...": "ค้นหา...",
    "Shortlist with JOBS": "ช็อตลิสต์เข้ากับงาน",
    "Are you sure you would like to shortlist ? This action cannot be undone later!":
      "คุณแน่ใจหรือไม่ว่าจะช็อตลิสต์ใบสมัครนี้? คุณจะไม่สามารถย้อนกลับมาแก้ไขได้อีก",
    "Account Verification Status": "สถานะการยืนยันบัญชี",
    Registration: "ลงทะเบียน",
    "Fill out the registration form": "โปรดกรอกใบลงทะเบียน",
    "Email Verification": "การยืนยันทางอีเมล",
    "Please verify your email using the link sent to {email}":
      "โปรดยืนยันอีเมลของคุณ โดยใช้ลิงค์ที่ส่งไปที่ {email}",
    "Business Verification": "การยืนยันธุรกิจ",
    "Issara will contact you to verify that you are actually the owner of this business":
      "ทางสถาบันอิสราจะติดต่อกลับเพื่อยืนยันกับคุณว่าคุณเป็นเจ้าของธุรกิจนี้",
    Actions: "ดำเนินการ",
    "Are you sure this business is not in our database yet?":
      "คุณแน่ใจหรือว่าธุรกิจนี้ไม่ได้อยู่ในฐานข้อมูลของเรา?",
    "This business is not in our database yet. Verify as new.":
      "ธุรกิจนี้ยังไม่อยู่ในฐานข้อมูลของเรา ตรวจสอบว่าเป็นธุรกิจใหม่",
    "Are you sure you want to reject verification and delete this user?":
      "คุณแน่ใจหรือไม่ว่าต้องการปฏิเสธการยืนยันและลบผู้ใช้รายนี้",
    "The user couldn't prove ownership of the business. Reject it.":
      "ผู้ใช้ไม่สามารถพิสูจน์ความเป็นเจ้าของธุรกิจได้ ปฏิเสธ",
    "Owner's Email": "อีเมลล์เจ้าของ",
    Registered: "ลงทะเบียนแล้ว",
    "Business has been successfully verified":
      "ธุรกิจได้รับการยืนยันเรียบร้อยแล้ว",
    "An error occurred while verifying business. Please contact a developer.":
      "เกิดข้อผิดพลาดขึ้นระหว่างการยืนยันธุรกิจ โปรดติดต่อทีมงานของเรา",
    "Business has been successfully removed": "ลบธุรกิจเรียบร้อยแล้ว",
    "An error occurred while rejecting account. Please contact a developer.":
      "เกิดข้อผิดพลาดขึ้นระหว่างการปฏิเสธบัญชี โปรดติดต่อทีมงานของเรา",
    "Merge Dialogue": "รวมบทสนทนา",
    "To be merged": "ที่จะนำมารวมกัน",
    Into: "เข้าไปข้างใน",
    "Awaiting Employers": "นายจ้างที่รออยู่",
    "Total ({count})": "รวมทั้งหมด ({count}) ",
    "No awaiting employers to verify": "ไม่มีนายจ้างที่รออยู่เพื่อยืนยัน",
    "Previously verified employers": "นายจ้างที่ได้รับการยืนยันก่อนหน้านี้",
    "No previously verified employers":
      "ไม่มีนายจ้างที่ได้รับการยืนยันก่อนหน้านี้",
    Verify: "ยืนยัน",
    "Awaiting Recruitment agencies": "บริษัทจัดหางานที่รออยู่",
    "No businesses awaiting verification": "ไม่มีธุรกิจที่รอการยืนยัน",
    "Previously verified Recruiters":
      "บริษัทจัดหางานที่ได้รับการยืนยันก่อนหน้านี้",
    "No previously verified businesses":
      "ไม่มีธุรกิจที่ได้รับการยืนยันก่อนหน้านี้",
    "Survey Details": "รายละเอียดของแบบสำรวจ",
    "(Burmese)": "(พม่า)",
    "(English)": "(อังกฤษ)",
    "(Nepali)": "(เนปาล)",
    "(Thailand)": "(ไทย)",
    "(Khmer)": "(เขมร)",
    "Update/Post job": "อัปเดท/โพสต์งาน",
    "This job is for the country ! ({country})":
      "งานนี้เป็นของประเทศ! ({ประเทศ})",
    "Need to choose a country for this job!": "ต้องเลือกประเทสสำหรับงานนี้",
    "Please select the sending country !": "โปรดเลือกประเทศที่จะไป",
    Malaysia: "มาเลเซีย",
    UAE: "สหรัฐอาหรับเอมิเรตส์",
    "Employer Information": "ข้อมูลนายจ้าง",
    "Please select employer": "โปรดเลือกนายจ้าง",
    "Please input employer's name": "โปรดระบุชื่อนายจ้าง",
    "The name of the Employer": "ชื่อนายจ้าง",
    "The country where the workplace is located":
      "สถานที่ทำงานตั้งอยู่ในประเทศ",
    "The state where the workplace is located": "สถานที่ทำงานตั้งอยู่ในรัฐ",
    "The city where the workplace is located": "สถานที่ทำงานตั้งอยู่ในเมือง",
    "Please select size": "โปรดเลือกขนาด",
    "Nationalities of Workers working at this workplace":
      "สัญชาติของแรงงานที่ทำงานอยู่ในสถานที่ทำงานนี้",
    "Please specify the nationalities of workers working at this workplace.":
      "โปรดระบุสัญชาติของแรงงานที่ทำงานอยู่ในสถานที่ทำงานนี้",
    "Headcount by Genders": "จำนวนพนักงานแยกตามเพศ",
    Genders: "เพศ",
    "Male headcount needed": "จำนวนแรงงานชายที่ต้องการ",
    "Female headcount needed": "โปรดระบุจำนวนแรงงานหญิงที่ต้องการ",
    "Demand Pre-approval date": "วันที่อนุมัติล่วงหน้าของการขอดีมานด์",
    "Demand Approved Date": "วันที่อนุมัติการขอดีมานด์",
    "Please choose demand pre-approval date":
      "โปรดเลือกวันที่อนุมัติล่วงหน้าของการขอดีมานด์",
    "Please choose demand approved date": "โปรดเลือกวันที่อนุมัติการขอดีมานด์",
    "LT number": "หมายเลข LT",
    "Demand number": "หมายเลขดีมานด์",
    "Please enter LT number": "โปรดใส่หมายเลข LT",
    "Please enter demand number": "โปรดใส่หมายเลขดีมานด์",
    "Application Closed Date": "วันที่ปิดรับสมัคร",
    "Please choose Application Closed date": "โปรดเลือกวันที่ปิดรับสมัคร",
    "Email to receive notifications for this job announcement":
      "อีเมลเพื่อรับการแจ้งเตือนสำหรับประกาศงานนี้",
    "Please enter a valid email address": "โปรดใส่อีเมลล์ที่ติดต่อได้",
    "ER Information": "รายละเอียด ER",
    "Does the employer have an ethical recruitment policy?":
      "นายจ้างมีนโยบายการสรรหาจ้างแรงงานอย่างมีจริยธรรมหรือไม่?",
    Have: "มี",
    "Not Have": "ไม่มี",
    "Does the RA have an ethical recruitment policy?":
      "บริษัทจัดหางานนี้มีนโยบายเกี่ยวกับการจ้างงานอย่างมีจริยธรรมหรือไม่",
    "Does the RA have an ethical recruitment policy? is required":
      "บริษัทจัดหางานนี้มีนโยบายเกี่ยวกับการจ้างงานอย่างมีจริยธรรมหรือไม่ เป็นสิ่งจำเป็น",
    "Does the employer have an employer pays policy?":
      "นายจ้างนี้มีนโยบายนายจ้างเป็นผู้รับผิดชอบค่าใช้จ่ายในการจ้างงานหรือไม่?",
    "Monthly salary ({currency}/month)": "เงินเดือน (\n{currency}/เดือน)",
    "How much will be provided for meals allowance?": "ได้รับค่าอาหารเท่าไร?",
    "Please enter a meal allowance.": "โปรดใส่ค่าอาหาร",
    "Please enter a meal allowance of no less than 300 AED.":
      "โปรดใส่ค่าอาหารไม่น้อยกว่า 300 AED",
    "Any additional leave ?": "มีวันลาเพิ่ม?",
    "Add Detail Here": "ใส่รายละเอียดที่นี่",
    "Any additional leave ? (This is optional)": "มีวันลาเพิ่ม? (ไม่บังคับ)",
    "Add detail here (optional)": "ใส่รายละเอียดที่นี่ (ไม่บังคับ)",
    "Are social security benefits provided as required by law?":
      "มีการให้สิทธิประโยชน์ด้านประกันสังคมตามที่กฎหมายกำหนดหรือไม่?",
    "Skill training information": "ข้อมูลการอบรมทักษะ",
    "Is skill training required for this job?":
      "งานนี้จำเป็นต้องมีการฝึกอบรมทักษะหรือไม่?",
    "if so, who will cover those costs?": "ถ้ามี ใครเป็นคนครอบคลุมค่าใช้จ่าย",
    "if workwes have to pay, please provide the amount they should pay":
      "ถ้าแรงงานเป็นคนจ่าย กรุณาระบุค่าใช้จ่าย",
    "Cost of skill-training?": "ค่าใช้จ่ายการฝึกอบรม",
    "Cost of skill-training? is required": "ค่าใช้จ่ายการฝึกอบรม จำเป็น",
    "Accommodation  during skill training?": "ที่พักระหว่างการฝึกอบรม",
    "Please specify, does the employer provide accommodation during skill-training of Workers":
      "โปรดระบุ นายจ้างมีที่พักสำหรับแรงงานระหว่างการฝึกอบรมหรือไม่",
    "Accommodation  during skill training? is required":
      "ที่พักระหว่างการฝึกอบรม จำเป็น",
    "Meals during skill training?": "อาหารระหว่างการฝึกอบรม",
    "Please specify, does the employer provide meals during skill-training of Workers":
      "โปรดระบุ รายจ้างมีอาหารสำหรับแรงงานระหว่างการฝึกอบรมหรือไม่",
    "Meals during skill training? is required": "อาหารระหว่างการฝึกอบรม จำเป็น",
    "Based on individual cost/spending.":
      "ขึ้นอยู่กับต้นทุน/รายจ่ายของแต่ละบุคคล",
    "Transportation costs to and from for attending skill training?":
      "มีค่าเดินทางไปกลับสำหรับการฝึกอบรมหรือไม่",
    "Transportation costs to and from for attending skill training? is required":
      "มีค่าเดินทางไปกลับสำหรับการฝึกอบรมหรือไม่ จำเป็น",
    "Brief description of skill training": "คำอธิบายโดยย่อของการฝึกทักษะ",
    "Please fill the brief description of skill training":
      "โปรดเติมคำอธิบายโดยย่อของการฝึกทักษะ",
    "Costs for Pre-departure": "ค่าใช้จ่ายก่อนออกเดินทาง",
    "Is pre-departure orientation training required for this job?":
      "การอบรมก่อนการเดินทางจำเป็นสำหรับงานนี้หรือไม่",
    "Cost of pre-departure orientation training?":
      "ค่าใช้จ่ายสำหรับการอบรมก่อนการเดินทาง",
    "Cost of pre-departure orientation training? is required":
      "ค่าใช้จ่ายสำหรับการอบรมก่อนการเดินทาง จำเป็น",
    "Accommodation during pre-departure orientation training?":
      "มีที่พักสำหรับการอบรมก่อนการเดินทางหรือไม่",
    "Please specify, does the employer provide accommodation during pre-departure orientation training of Workers":
      "โปรดระบุ นายจ้างมีที่พักสำหรับแรงงานในการอบรมก่อนการเดินทางหรือไม่",
    "Accommodation during pre-departure orientation training? is required":
      "มีที่พักระหว่างการอบรมก่อนการเดินทาง จำเป็น",
    "Meals during pre-departure orientation training?":
      "มีอาหารระหว่างการอบรมก่อนการเดินทางหรือไม่",
    "Please specify, does the employer provide meals during pre-departure orientation training of Workers":
      "โปรดระบุ นายจ้างมีอาหารสำหรับแรงงานระหว่างการอบรมก่อนการเดินทางหรือไม่",
    "Meals during pre-departure orientation training? is required":
      "มีอาหารระหว่างการอบรมก่อนการเดินทางหรือไม่ จำเป็น",
    "Transportation costs to and from pre-departure orientation training?":
      "มีค่าเดินทางไปกลับระหว่างการอบรมก่อนการเดินทางหรือไม่",
    "Transportation costs to and from pre-departure orientation training? is required":
      "มีค่าเดินทางไปกลับระหว่างการอบรมก่อนการเดินทางหรือไม่ จำเป็น",
    "Additional information for Pre-departure orientation course":
      "ข้อมูลเพิ่มเติมเกี่ยวกับการอบรมก่อนการเดินทาง",
    "Please fill in additional information for Pre-departure orientation course (number of days, location, kind of training, etc)":
      "กรุณากรอกข้อมูลเพิ่มเติมสำหรับหลักสูตรการอบรมก่อนออกเดินทาง (จำนวนวัน สถานที่ ประเภทของการอบรม ฯลฯ)",
    "Costs and fees for recruitment in the origin country":
      "ค่าใช้จ่ายและค่าธรรมเนียมการจ้างงานในประเทศต้นทาง",
    "Select the origin country currency": "เลือกอัตราแลกเปลี่ยนในประเทศต้นทาง",
    "Origin country Currency": "อัตราแลกเปลี่ยนในประเทศต้นทาง",
    "Recruitment service fee to RA and sub-agent?":
      "มีค่าบริการการจัดหางานให้บริษัทจัดหางานและตัวแทนย่อยหรือไม่",
    "Recruitment service fee to RA and sub-agent? is required":
      "มีค่าบริการการจัดหางานให้บริษัทจัดหางานและตัวแทนย่อยหรือไม่ จำเป็น",
    "Accommodation costs for contract signing": "ค่าที่พักสำหรับการเซ็นสัญญา",
    "Accommodation costs for contract signing is required":
      "ค่าที่พักสำหรับการเซ็นสัญญา จำเป็น",
    "Medical check-up fee?": "มีค่าใช้จ่ายในการตรวจสุขภาพหรือไม่",
    "Medical check-up fee? is required":
      "มีค่าใช้จ่ายในการตรวจสุขภาพหรือไม่ จำเป็น",
    "Passport application fee?": "มีค่าธรรมเนียมการทำพาสปอร์ตหรือไม่",
    "Passport application fee? is required":
      "มีค่าธรรมเนียมการทำพาสปอร์ตหรือไม่ จำเป็น",
    "Overseas Worker Identification Card (OWIC) fee?":
      "มีค่าธรรมเนียมบัตรประจำตัวคนงานต่างด้าว (OWIC) หรือไม่",
    "Overseas Worker Identification Card (OWIC) fee? is required":
      "มีค่าธรรมเนียมบัตรประจำตัวคนงานต่างด้าว (OWIC) หรือไม่ จำเป็น",
    "Travel costs for a job interview?":
      "มีค่าเดินทางสำหรับการสัมภาษณ์งานหรือไม่",
    "Travel costs for a job interview? is required":
      "มีค่าเดินทางสำหรับการสัมภาษณ์งานหรือไม่ จำเป็น",
    "Transportation cost to and from for contract signing?":
      "มีค่าเดินทางไปกลับสำหรับการเซ็นสัญญาหรือไม่",
    "Transportation cost to and from for contract signing? is required":
      "มีค่าเดินทางไปกลับสำหรับการเซ็นสัญญาหรือไม่ จำเป็น",
    "Meals for contract signing?": "มีอาหารสำหรับการเซ็นสัญญาหรือไม่",
    "Meals for contract signing? is required":
      "มีอาหารสำหรับการเซ็นสัญญาหรือไม่ จำเป็น",
    "Transportation costs to the border or airport?":
      "มีค่าเดินทางสำหรับการไปกลับชายแดนหรือสนามบินหรือไม่",
    "Transportation costs to the border or airport? is required":
      "มีค่าเดินทางสำหรับการไปกลับชายแดนหรือสนามบินหรือไม่ จำเป็น",
    "Costs and fees for recruitment in the destination country":
      "ค่าใช้จ่ายและค่าธรรมเนียมสำหรับการจ้างงานในประเทศปลายทาง",
    "Select the desitnation country currency":
      "เลือกอัตราแลกเปลี่ยนในประเทศปลายทาง",
    "Destination country Currency": "อัตราแลกเปลี่ยนในประเทศปลายทาง",
    "Transportation cost from the border or airport to the workplace (or to the destination country)?":
      "มีค่าเดินทางจากชายแดนหรือสนามบินไปยังที่ทำงาน (หรือไปยังประเทศปลายทาง) หรือไม่",
    "Transportation cost from the border or airport to the workplace (or to the destination country)? is required":
      "มีค่าเดินทางจากชายแดนหรือสนามบินไปยังที่ทำงาน (หรือไปยังประเทศปลายทาง) หรือไม่ จำเป็น",
    "Visa fee?": "มีค่าธรรมเนียมวีซ่าหรือไม่",
    "Visa fees is required": "ค่าธรรมเนียมวีซ่าจำเป็น",
    "Work permit?": "มีใบอนุญาตทำงานหรือไม่",
    "Work Permit fees is required": "ใบอนุญาตทำงานจำเป็น",
    "Medical check-up fee (if I have to take another check up)?":
      "มีค่าตรวจสุขภาพหรือไม่ (หากต้องตรวจซ้ำอีก) ",
    "Medical check-up fee (if I have to take another check up)? is required":
      "มีค่าตรวจสุขภาพหรือไม่ (หากต้องตรวจซ้ำอีก)  จำเป็น",
    "Hospitalization Insurance": "ประกันสุขภาพ",
    "Other Costs": "ค่าใช่จ่ายอื่น ๆ",
    "Please provide any additional information on other costs that are not being covered above":
      "กรุณาให้ข้อมูลเพิ่มเติมเกี่ยวกับค่าใช้จ่ายอื่น ๆ ที่ไม่ได้ระบุไว้ข้างต้น",
    "Please provide any additional information on other costs that are not being covered above. ":
      "กรุณาให้ข้อมูลเพิ่มเติมเกี่ยวกับค่าใช้จ่ายอื่น ๆ ที่ไม่ได้ระบุไว้ข้างต้น",
    "Information on repatriation": "ข้อมูลของการส่งตัวกลับประเทศ",
    "Please describe how you and the employer would arrange the repatriation of migrant workers":
      "โปรดอธิบายว่าคุณและนายจ้างจะจัดการเรื่องการส่งตัวแรงงานข้ามชาติกลับประเทศอย่างไร",
    "Please describe how you and the employer would arrange the repatriation of migrant workers after the completion of the contract, if the worker has difficulties performing the job or if workers need to return to the origin country for personal reasons.":
      "โปรดอธิบายว่าคุณและนายจ้างจะจัดเตรียมการส่งตัวแรงงานข้ามชาติกลับประเทศอย่างไรหลังจากสิ้นสุดสัญญา หากแรงงานประสบปัญหาในการทำงาน หรือหากแรงงานจำเป็นต้องเดินทางกลับประเทศต้นทางด้วยเหตุผลส่วนตัว",
    "Employer Name": "ชื่อนายจ้าง",
    "Employer Address": "ที่อยู่นายจ้าง",
    "Employer Size": "ขนาดนายจ้าง",
    "Number of Male Needed": "จำนวนแรงงานชายที่ต้องการ",
    "Number of Female Needed": "จำนวนแรงงานหญิงที่ต้องการ",
    "NRC Card Holder": "ผู้ถือบัตร NRC",
    "OWIC Card Holder": "ผู้ถือบัตร OWIC",
    "SKILL TRAINING INFORMATION": "ข้อมูลการฝึกอบรมทักษะ",
    "Who pays cost of skill-training?":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายในการฝึกอบรมทักษะ",
    "Does the employer provide accommodation during skill training?":
      "นายจ้างให้ที่พักระหว่างการฝึกอบรมหรือไม่",
    "Does the employer provide meals during skill training?":
      "นายจ้างมีอาหารให้ระหว่างการฝึกอบรมหรือไม่",
    "Who pays transportation costs to and from for attending skill training?":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายเรื่องการเดินทางไปกลับในการฝึกอบรม",
    "COSTS FOR PRE-DEPARTURE": "ค่าใช้จ่ายก่อนการออกเดินทาง",
    "Who pays cost of pre-departure orientation training?":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายในการอบรมก่อนการเดินทาง",
    "Who pays for acoomodation during pre-departure orientation training?":
      "ใครเป็นผู้รับผิดชอบเรื่องที่พักระหว่างการอบรมก่อนการออกเดินทาง",
    "Who pays for meals during pre-departure orientation training?":
      "ใครเป็นผู้รับผิดชอบค่าอาหารระหว่างการอบรมก่อนการออกเดินทาง",
    "Who pays the cost of transportation to and from pre-departure orientation training?":
      "ใครเป็นผู้รับผิดชอบค่าเดินทางไปกลับระหว่างการอบรมก่อนการออกเดินทาง",
    "COSTS AND FEES FOR RECRUITMENT IN THE ORIGIN COUNTRY":
      "ค่าใช้จ่ายและค่าธรรมเนียมสำหรับการจ้างงานในประเทศต้นทาง",
    "Who pays the recruitment service fee to RA and sub-agent?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมให้กับบริษัทจัดหางานและตัวแทนย่อย",
    "Who pays travel costs for a job interview?":
      "ใครเป็นผู้รับผิดชอบค่าเดินทางไปสัมภาษณ์งาน",
    "Who pays accommodation costs for contract signing":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายเรื่องที่พักในระหว่างการเซ็นสัญญา",
    "Who pays transportation cost to and from for contract signing?":
      "ใครเป็นผู้รับผิดชอบค่าเดินทางไปกลับระหว่างการเซ็นสัญญา",
    "Who pays for meals during contract signing?":
      "ใครเป็นผู้รับผิดชอบค่าอาหารระหว่างการเซ็นสัญญา",
    "Who pays medical check-up fee?":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายในการตรวจสุขภาพ",
    "Who pays passport application fee?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการทำพาสปอร์ต",
    "Who pays Overseas Worker Identification Card (OWIC) fee?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมบัตรประจำตัวแรงานข้ามชาติ (OWIC)",
    "Who pays transportation costs to the border or airport?":
      "ใครเป็นผู้รับผิดชอบค่าเดินทางไปชายแดนหรือไปสนามบิน",
    "COSTS AND FEES FOR RECRUITMENT IN THE DESTINATION COUNTRY":
      "ค่าใช้จ่ายและค่าธรรมเนียมสำหรับการจ้างงานในประเทศปลายทาง",
    "Who pays transportation cost from the border or airport to the workplace (or to the destination country)?":
      "ใครเป็นผู้รับผิดชอบค่าเดินทางจากชายแดนหรือจากสนามบินไปที่ทำงาน (หรือไปประเทศปลายทาง)",
    "Who pays Visa fee?": "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการออกวีซ่า",
    "Who pays work permit?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการออกใบอนุญาตทำงาน",
    "Who pays medical check-up fee (if I have to take another check up)?":
      "ใครเป็นผู้รับผิดชอบค่าตรวจสุขภาพ (ถ้าต้องตรวจอีกครั้ง)",
    "Who pays for Hospitalization Insurance?":
      "ใครเป็นผู้รับผิดชอบค่าประกันสุขภาพ",
    "COVID-19 RELATED COSTS AND PROCEDURES":
      "ค่าใช้จ่ายและกระบวนการที่เกี่ยวกับโควิด",
    "Who pays for the mandatory PCR test fee prior to departure?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการตรวจ PCR ภาคบังคับก่อนออกเดินทาง",
    "Who pays the PCR test fee on Thailand side?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการตรวจ PCR ในประเทศไทย",
    "Who pays for the quarantine in the destination country?":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายในการกักตัวในประเทศปลายทาง",
    "Who pays for the Covid-19 insurance fee in the destination country?":
      "ใครเป็นผู้รับผิดชอบค่าประกันโควิดใครประเทศปลายทาง",
    "Who pays for the Covid-19 vaccination fee?":
      "ใครเป็นผู้รับผิดชอบค่าฉีดวัคซีนโควิด",
    "Other costs": "ค่าใช้จ่ายอื่นๆ",
    "Meals Allowance": "ค่าอาหาร",
    "Any additional leave detail": "รายละเอียดการลาเพิ่มเติมใด ๆ",
    "Edit & Post": "แก้ไข และ โพสต์",
    "Account:": "บัญชี:",
    "Type:": "ประเภท:",
    "Email:": "อีเมล:",
    "Joined:": "เข้าร่วม:",
    "Updated:": "อัพเดท:",
    "Please Select An Employer To Fill Employer's Information":
      "กรุณาเลือกนายจ้างเพื่อกรอกข้อมูลของนายจ้าง",
    "Please Select An Employer": "โปรดเลือกนายจ้าง",
    "Select Size": "เลือกขนาด",
    "Please input title": "โปรดระบุตำแหน่ง",
    "Additional information": "ข้อมูลเพิ่มเติม",
    "Profile:": "โปรไฟล์",
    "Address was successfully updated.": "ที่อยู่ถูกอัพเดทเรียบร้อยแล้ว",
    "Something went wrong while updating the address. Please contact a developer":
      "มีข้อผิดพลาดเกิดขึ้นระหว่าง อัพเดทข้อมูลที่อยู่ โปรดติดต่อทีมงานของเรา",
    "Please enter Main Contact Name": "โปรดระบุชื่อผู้ติดต่อหลัก",
    "Company name": "ชื่อบริษัท",
    "Please enter Company name": "โปรดระบุชื่อบริษัท",
    "Please select Working since": "กรุณาเลือก การทำงานตั้งแต่",
    "License number": "หมายเลขใบอนุญาต",
    "Please enter License number": "กรุณากรอกหมายเลขใบอนุญาต",
    "Max length of the license number is 12":
      "ความยาวสูงสุดที่ใส่ได้ 12 ตัวอักษร",
    "Valid till": "วันหมดอายุ",
    "Please select Valid till": "กรุณาเลือกวันหมดอายุ",
    "Thai License number": "หมายเลขใบอนุญาตของไทย",
    "Please select Thai License Number": "โปรดเลือกหมายเลขใบอนุญาตของไทย",
    "Thai License Expiry Date": "วันหมดอายุใบอนุญาตของไทย",
    "Please select Thai License Expiry Date":
      "กรุณาเลือกวันหมดอายุใบอนุญาตของไทย",
    "Please describe the years of experience to specific industries to which countries, the years of experience to specific industries to which countries with zero costs and sub agents information":
      "โปรดอธิบายจำนวนปีของประสบการณ์ในการทำงานในอุตสาหกรรมเฉพาะ ไปยังประเทศใด จำนวนปีของประสบการณ์ในการทำงานในอุตสาหกรรมเฉพาะไปยังประเทศใด โดยไม่มีค่าใช้จ่ายใดๆ และข้อมูลตัวแทนย่อย",
    "About us": "เกี่ยวกับเรา",
    "Discard changes": "ยกเลิกการเปลี่ยนแปลง",
    Save: "บันทึก",
    "Main contact name:": "ชื่อผู้ติดต่อ:",
    "Company name:": "ชื่อบริษัท:",
    "Working since:": "เริ่มทำงานตั้งแต่:",
    "License number:": "หมายเลขใบอนุญาต",
    "Valid till:": "วันหมดอายุ:",
    "Thai license number:": "หมายเลขใบอนุญาตของไทย:",
    "Thai license expiry date:": "วันหมดอายุใบอนุญาตของไทย:",
    "Business Images": "รูปบริษัท",
    "Upload Cover Photo": "อัปโหลดรูป",
    "Please enter email": "โปรดระบุอีเมลล์",
    "Please enter Phone Number": "โปรดระบุหมายเลขโทรศัพท์",
    "Add more": "เพิ่ม",
    "Website:": "Website:",
    "Facebook:": "Facebook:",
    "Line:": "Line:",
    "Viber:": "Viber:",
    "Phone numbers:": "หมายเลขโทรศัพท์:",
    "Please select Country, State, City": "โปรดเลือกประเทศ จังหวัด เขต",
    "Country:": "ประเทศ:",
    "State/Province:": "จังหวัด:",
    "City/District:": "เขต:",
    "Street:": "ถนน:",
    "Zip:": "รหัสไปรษณีย์",
    "Issara will contact you to verify that you actually the owner of this business":
      "ทางสถาบันอิสราจะติดต่อกลับเพื่อยืนยันว่าคุณเป็นเจ้าของธุรกิจนี้",
    "Golden Dreams": "Golden Dreams",
    "Golden Dreams Marketplace ©{date} Created by {issara}":
      "Golden Dreams Marketplace ©{date} พัฒนาโดยมูลนิธิสถาบันอิสรา",
    "Privacy Policy": "นโยบายความเป็นส่วนตัว",
    Disclaimer: "ข้อความปฏิเสธความรับผิด",
    "Issara Staff": "ทีมงานอิสรา",
    Recruiter: "นายหน้าจัดหางาน",
    "Reset your password": "เปลี่ยนรหัสผ่านของคุณ",
    "The input is not valid Email": "อีเมลที่คุณใส่ไม่ถูกต้อง",
    "Please input your Email": "โปรดใส่อีเมลของคุณ",
    "Send reset link": "ส่งลิงค์",
    "We will send password reset link to this email address, please change your password within 24 hours.":
      "เราจะจัดส่งลิงค์เปลี่ยนรหัสผ่านไปยังอีเมล โปรดเปลี่ยนรหัสผ่านภายใน 24 ชั่วโมง",
    "Golden Dreams Job Marketplace": "Golden Dreams Job Marketplace",
    "Powered by Issara Institute": "พัฒนาโดยมูลนิธิสถาบันอิสรา",
    "Register and login here to modernize and digitize how your business manages labour recruitment ->":
      "ลงทะเบียนและเข้าใช้งาน เพื่อให้ธรุกิจของคุณบริหารจัดการการจ้างแรงงานในรูปแบบดิจิตอลที่ทันสมัย",
    Login: "เข้าใช้งาน",
    Password: "รหัสผ่าน",
    "Forgot Password?": "ลืมรหัสผ่าน?",
    "Please input your password!": "โปรดใส่รหัสผ่าน",
    "Not registered?": "ยังไม่ได้ลงทะเบียน?",
    "Create an account": "สร้างบัญชี",
    "Oops! You tried to log in with a job seeker account. Please check and try again.":
      "ขออภัย คุณพยายามเข้าสู่ระบบด้วยบัญชีผู้หางาน กรุณาตรวจสอบและลองอีกครั้ง",
    "Log in with Business Account": "ล็อกอินด้วยบัญชีธุรกิจ",
    "Sorry, you are not authorized to access this page.":
      "ขออภัย คุณไม่ได้รับอนุญาตให้เข้าหน้านี้",
    "Use another account": "ใช้บัญชีอื่น",
    "Go back to console": "ไปยังหน้าหลัก",
    "Sorry, the page you visited does not exist.":
      "ขออภัย ไม่ปรากฎหน้าที่คุณเยี่ยมชม",
    "Back Home": "กลับไปหน้าแรก",
    "Successfully changed password": "เปลี่ยนรหัสผ่านเรียบร้อยแล้ว",
    "You'll be redirected to login page shortly":
      "คุณจะถูกส่งกลับไปที่หน้าเข้าใช้งานในไม่ช้า",
    "Go to login page": "ไปที่หน้าเข้าใช้งาน",
    "Password reset failed": "การเปลี่ยนรหัสผ่านล้มเหลว",
    "The reset link has expired. Please request a new one.":
      "ลิงค์หมดอายุ โปรดขอลิงค์ใหม่",
    "New Password": "รหัสผ่านใหม่",
    "Confirm Password": "ยืนยันรหัสผ่าน",
    "Please confirm your password!": "โปรดยืนยันรหัสผ่าน!",
    "The two passwords that you entered do not match!":
      "รหัสผ่านที่คุณใส่ไม่ตรงกัน!",
    "Update password": "เปลี่ยนรหัสผ่าน",
    "Create Account": "สร้างบัญชี",
    "We are:": "เราคือ:",
    "Please select your business type!": "โปรดเลือกประเภทของธรุกิจ!",
    "Recruitment Agency": "บริษัทจัดหางาน",
    "Business Name": "ชื่อบริษัท",
    "What is your offical business name?":
      "ชื่ออย่างเป็นทางการของบริษัทคุณคือ?",
    "Please input your business name!": "โปรดใส่ชื่อบริษัทของคุณ",
    "The input is not valid E-mail!": "อีเมลที่คุณใส่ไม่ถูกต้อง",
    "Please input your E-mail!": "โปรดใส่อีเมลของคุณ",
    "Create your free account": "สร้างบัญชีฟรี",
    "By clicking register, you agree to our {privacy_policy}.":
      "เมื่อลงทะเบียน หมายความว่าคุณยอมรับ {privacy_policy} ของเรา",
    "privacy policy": "นโยบายความเป็นส่วนตัว",
    "Already have an account?": "มีบัญชีแล้วหรือยัง?",
    "Login here": "เข้าใช้งานที่นี่",
    "Email verification in progress..": "กำลังยืนยันอีเมล",
    "Successfully verified email": "ยืนยันอีเมลเรียบร้อยแล้ว",
    "Please follow further instructions in the console":
      "โปรดปฏิบัติตามคำสั่งในหน้าหลัก",
    "Go to console": "ไปยังหน้าหลัก",
    "Email verification failed": "การยืนยันอีเมลไม่สำเร็จ",
    "This link has already expired.": "ลิงค์นี้หมดอายุแล้ว",
  },
};
