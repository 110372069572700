// COUNTRY AND NATIONALITY CODES
// -----------------------------
// https://t2a.io/blog/normalising-nationalities-via-a-good-iso-3166-country-list/

const Genders = (props, context, dropdown) => {
  const data = {
    M: context.t("Male"),
    F: context.t("Female"),
    N: context.t("Prefer not to say"),
    X: context.t("Non-Binary"),
  };

  var genders = [];
  for (var key in data) {
    genders.push({ value: key, label: data[key] });
  }

  return dropdown ? genders : data;
};

const PassportStates = (props, context, dropdown) => {
  const data = {
    True: context.t("Passport Include"),
    False: context.t("Passport Not Include"),
  };

  var passportStates = [];
  for (var key in data) {
    passportStates.push({ value: key, label: data[key] });
  }

  return dropdown ? passportStates : data;
};

const WorkersNationalities = (props, context, dropdown) => {
  const data = {
    M: context.t("Myanmar"),
    T: context.t("Thailand"),
    K: context.t("Cambodia"),
    L: context.t("Laos"),
    N: context.t("Nepal"),
    I: context.t("Indonesia"),
    D: context.t("Indian"),
    B: context.t("Bangladeshi"),
    O: context.t("Others"),
  };

  var workersNationalities = [];
  for (var key in data) {
    workersNationalities.push({ value: key, label: data[key] });
  }

  return dropdown ? workersNationalities : data;
};

const WorkersNationalitiesArray = (props, context, dropdown) => {
  const data = [
    { short: "M", value: context.t("Myanmar") },
    { short: "T", value: context.t("Thailand") },
    { short: "K", value: context.t("Cambodia") },
    { short: "L", value: context.t("Laos") },
    { short: "N", value: context.t("Nepal") },
    { short: "I", value: context.t("Indonesia") },
    { short: "D", value: context.t("Indian") },
    { short: "B", value: context.t("Bangladeshi") },
    {},
    { short: "O", value: context.t("Others") },
  ];

  return data;
};

const EmployerSizes = (props, context, dropdown) => {
  const data = {
    XS: "<500",
    S: "500-1000",
    M: "1000-2000",
    L: "2000-5000",
    XL: ">5000",
  };

  var sizes = [];
  for (var key in data) {
    sizes.push({ value: key, label: data[key] });
  }

  return dropdown ? sizes : data;
};

const DayOfWeekOff = (props, context, dropdown) => {
  const data = {
    Sunday: context.t("Sunday"),
    Monday: context.t("Monday"),
    Tuesday: context.t("Tuesday"),
    Wednesday: context.t("Wednesday"),
    Thursday: context.t("Thursday"),
    Friday: context.t("Friday"),
    Saturday: context.t("Saturday"),
    "It Will Vary": context.t("It Will Vary"),
  };

  var terms = [];
  for (var key in data) {
    terms.push({ value: key, label: data[key] });
  }

  return dropdown ? terms : data;
};

const PaymentTerms = (props, context, dropdown) => {
  const data = {
    Monthly: context.t("Monthly"),
    Daily: context.t("Daily Wage Rate"),
  };

  var terms = [];
  for (var key in data) {
    terms.push({ value: key, label: data[key] });
  }

  return dropdown ? terms : data;
};

const PaymentTiming = (props, context, dropdown) => {
  const data = {
    "Once A Month": context.t("Once A Month"),
    "Twice A Month": context.t("Twice A Month"),
    "Every Week": context.t("Every Week"),
    "Every 10 Days": context.t("Every 10 days"),
    Other: context.t("Other"),
  };

  var timing = [];
  for (var key in data) {
    timing.push({ value: key, label: data[key] });
  }

  return dropdown ? timing : data;
};

const OvertimeExpectations = (props, context, dropdown) => {
  const data = {
    Regularly: context.t("Regularly"),
    "From time to time": context.t("From time to time"),
    Infrequently: context.t("Infrequently"),
    Never: context.t("Never"),
  };

  var overtimeexpectations = [];
  for (var key in data) {
    overtimeexpectations.push({ value: key, label: data[key] });
  }

  return dropdown ? overtimeexpectations : data;
};

//Day shift, swing shift, night shift, rotating or variable shifts
const AvailableShifts = (props, context, dropdown) => {
  const data = {
    Day: context.t("Day"),
    Swing: context.t("Swing"),
    Night: context.t("Night"),
    Rotating: context.t("Rotating"),
    Variable: context.t("Variable"),
  };

  var shifts = [];
  for (var key in data) {
    shifts.push({ value: key, label: data[key] });
  }

  return dropdown ? shifts : data;
};

const JobTypes = (props, context, dropdown) => {
  const data = {
    "Full-time": context.t("Full-time"),
    "Part-time": context.t("Part-time"),
  };

  var types = [];
  for (var key in data) {
    types.push({ value: key, label: data[key] });
  }

  return dropdown ? types : data;
};

const JobApplicationStatuses = (props, context, dropdown) => {
  const data = {
    MOVED_GENERAL_WAITLIST: context.t("Moved General Waitlist"),
    WAITLISTED: context.t("Waitlisted"),
    CONTRACT_SIGNED: context.t("Contract Signed"),
    ACCEPTED: context.t("Accepted"),
    APPLIED: context.t("Applied"),
    DOWNLOADED: context.t("Downloaded"),
    OFFERED: context.t("Offered"),
    REJECTED_BY_APPLICANT: context.t("Rejected"),
    REJECTED_BY_BUSINESS: context.t("Rejected"),
    SHORTLISTED: context.t("Shortlisted"),
    VIEWED: context.t("Viewed"),
    DOCUMENTS_REQUESTED: context.t("Documents Requested"),
    DOCUMENTS_REQUEST_ACCEPTED: context.t("Documents request accepted"),
    DOCUMENTS_REQUEST_REJECTED: context.t("Documents request rejected"),
  };

  var statuses = [];
  for (var key in data) {
    statuses.push({ value: key, label: data[key] });
  }

  return dropdown ? statuses : data;
};

const ContactJobTitles = (props, context, dropdown) => {
  const data = {
    HR_ASSISTANT: context.t("HR Assistant"),
    HR_INTERPRETER: context.t("HR Interpreter"),
    HR_MANAGER: context.t("HR Manager"),
    HR_OFFICER: context.t("HR Officer"),
  };

  var titles = [];
  for (var key in data) {
    titles.push({ value: key, label: data[key] });
  }

  return dropdown ? titles : data;
};

const EmployerPaysPolicyTypes = (props, context, dropdown) => {
  const data = {
    CANDIDATE_PAYS_IN_ADVANCE: context.t(
      "Candidate pays fees in advance, then is reimbursed by company after starting work"
    ),
    COMPANY_PAYS_IN_ADVANCE: context.t("Company pays all fees in advance"),
  };

  var types = [];
  for (var key in data) {
    types.push({ value: key, label: data[key] });
  }

  return dropdown ? types : data;
};

const CountryCodes = (context) => {
  const options = [
    { value: +91, label: context.t("India (+91)") },
    { value: +95, label: context.t("Myanmar (+95)") },
    { value: +977, label: context.t("Nepal (+977)") },
    { value: +66, label: context.t("Thailand (+66)") },
    { value: +60, label: context.t("Malaysia (+60)") },
    { value: +855, label: context.t("Cambodia (+855)") },
  ];

  return options;
};

const LanguageProficiencyLevels = (props, context, dropdown) => {
  const data = {
    "A LITTLE BIT": context.t("A Little Bit"),
    MODERATELY: context.t("Moderately"),
    "VERY WELL": context.t("Very Well"),
  };

  var levels = [];
  for (var key in data) {
    levels.push({ value: key, label: data[key] });
  }

  return dropdown ? levels : data;
};

export {
  AvailableShifts,
  ContactJobTitles,
  CountryCodes,
  DayOfWeekOff,
  EmployerPaysPolicyTypes,
  EmployerSizes,
  Genders,
  PassportStates,
  WorkersNationalities,
  WorkersNationalitiesArray,
  JobApplicationStatuses,
  JobTypes,
  LanguageProficiencyLevels,
  OvertimeExpectations,
  PaymentTerms,
  PaymentTiming,
};
