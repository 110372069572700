import React from "react";
import moment from "moment";

export const JOB_SEEKER_LANGUAGES = Object.freeze({
  BURMESE_UNICODE: "mm-u",
  BURMESE_ZAWGYI: "mm-z",
  ENGLISH: "en",
  KHMER: "kh",
  NEPALI: "ne",
});

export const addDefaultImageSrc = (ev) => {
  ev.target.src = "/static/media/ImagePlaceholder.png";
};

export const getHeroImage = (lang) => {
  switch (lang) {
    case "mm-z":
      return "/static/media/burmese-bg.jpg";
      break;
    case "mm-u":
      return "/static/media/burmese-bg.jpg";
      break;
    case "kh":
      return "/static/media/khmer-bg.jpg";
      break;
    case "ne":
      return "/static/media/nepal-bg.jpeg";
      break;
    default:
      return "/static/media/burmese-bg.jpg";
  }
};

export const getTitleImage = (lang) => {
  switch (lang) {
    case "mm-z":
      return "/static/media/burmese-title.png";
      break;
    case "mm-u":
      return "/static/media/burmese-title.png";
      break;
    case "kh":
      return "/static/media/khmer-title.png";
      break;
    case "ne":
      return "/static/media/nepal-title.png";
      break;
    default:
      return "/static/media/eng-title.png";
  }
};

export const translateEmployerSize = (empSize) => {
  switch (empSize) {
    case "<500":
      return "XS";
    case "500-1000":
      return "S";
    case "1000-2000":
      return "M";
    case "2000-5000":
      return "L";
    case ">5000":
      return "XL";
    default:
      return empSize;
  }
};

export const reverseTranslateEmployerSize = (empSize) => {
  switch (empSize) {
    case "XS":
      return "<500";
    case "S":
      return "500-1000";
    case "M":
      return "1000-2000";
    case "L":
      return "2000-5000";
    case "XL":
      return ">5000";
    default:
      return empSize;
  }
};

export const anonymizeComment = (comment) => {
  delete comment["sender_avatar"];
  delete comment["sender_username"];
  delete comment["recipient_username"];
};

export const isMetric = (language) => {
  if (["en", "mm-u", "mm-z"].indexOf(language) >= 0) {
    return false;
  }
  return true;
};

export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

export const urlify = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return '<a target="_blank" href="' + url + '">' + url + "</a>";
  });
};

export const appLangId = (languages) => {
  const languageCode = localStorage.getItem("language") || "en";
  const foundLanguage = languages.itemsArray.find(
    (language) => language.code === languageCode
  );
  const languageId = foundLanguage ? foundLanguage.id : null;
  return languageId;
};

export const langaugeSkills = (context, languages) => {
  const options = [
    { label: context.t("English"), value: getLanguageId(languages, "en") },
    { label: context.t("Thai"), value: getLanguageId(languages, "th") },
    {
      label: context.t("Bahasa Malaysia"),
      value: getLanguageId(languages, "ms"),
    },
    { label: context.t("Hindi"), value: getLanguageId(languages, "hi") },
  ];
  return options;
};

function getLanguageId(languages, code) {
  if (languages.itemsArray && languages.itemsArray.length > 0) {
    const languageItem = languages.itemsArray.find(
      (item) => item.code === code
    );
    if (languageItem) {
      return languageItem.id;
    }
  }
  return null;
}

export const readSkillLevels = (context) => {
  const options = [
    { label: context.t("Cannot read"), value: "NONE" },
    { label: context.t("A Little Bit"), value: "A LITTLE BIT" },
    { label: context.t("Moderately"), value: "MODERATELY" },
    { label: context.t("Very Well"), value: "VERY WELL" },
  ];
  return options;
};

export const rosterStatus = (context, status) => {
  switch (status) {
    case "APPLICABLE":
      return context.t("APPLICABLE");
      break;
    case "APPLIED":
      return context.t("APPLIED");
      break;
    case "VIEWED":
      return context.t("VIEWED");
      break;
    case "DOWNLOADED":
      return context.t("DOWNLOADED");
      break;
    case "SHORTLISTED":
      return context.t("SHORTLISTED");
      break;
    case "OFFERED":
      return context.t("OFFERED");
      break;
    case "ACCEPTED":
      return context.t("ACCEPTED");
      break;
    case "REJECTED_BY_BUSINESS":
      return context.t("REJECTED_BY_BUSINESS");
      break;
    case "REJECTED_BY_APPLICANT":
      return context.t("REJECTED_BY_APPLICANT");
      break;
    case "CONTRACT_SIGNED":
      return context.t("CONTRACT_SIGNED");
      break;
    case "APPLICANT_NA":
      return context.t("APPLICANT_NA");
      break;
    case "WAITLISTED":
      return context.t("WAITLISTED");
      break;
    case "MOVED_GENERAL_WAITLIST":
      return context.t("MOVED_GENERAL_WAITLIST");
      break;
    default:
      return context.t("STATUS N/A");
  }
};

export const speakSkillLevels = (context) => {
  const options = [
    { label: context.t("Cannot speak"), value: "NONE" },
    { label: context.t("A Little Bit"), value: "A LITTLE BIT" },
    { label: context.t("Moderately"), value: "MODERATELY" },
    { label: context.t("Very Well"), value: "VERY WELL" },
  ];
  return options;
};

export const deletePermissionLogic = (post, profile) => {
  let permit = false;
  if (post.user === profile.id && profile.type !== "IS") {
    permit = true;
  } else if (post.user !== profile.id && profile.type !== "IS") {
    permit = false;
  } else if (post.user !== profile.id && profile.type === "IS") {
    permit = true;
  } else if (post.user === profile.id && profile.type === "IS") {
    permit = true;
  }
  return permit;
};

export const editPermissionLogic = (post, profile) => {
  let permit = false;
  if (post.user === profile.id && profile.type !== "IS") {
    permit = true;
  } else if (post.user !== profile.id && profile.type !== "IS") {
    permit = false;
  } else if (post.user !== profile.id && profile.type === "IS") {
    permit = false;
  } else if (post.user === profile.id && profile.type === "IS") {
    permit = true;
  }
  return permit;
};

export const commentDeletePermissionLogic = (comment, profile) => {
  let permit = false;
  if (comment.user === profile.id && profile.type !== "IS") {
    permit = true;
  } else if (comment.user !== profile.id && profile.type !== "IS") {
    permit = false;
  } else if (comment.user !== profile.id && profile.type === "IS") {
    permit = true;
  } else if (comment.user === profile.id && profile.type === "IS") {
    permit = true;
  }
  return permit;
};

export const commentEditPermissionLogic = (comment, profile) => {
  let permit = false;
  if (comment.user === profile.id && profile.type !== "IS") {
    permit = true;
  } else if (comment.user !== profile.id && profile.type !== "IS") {
    permit = false;
  } else if (comment.user !== profile.id && profile.type === "IS") {
    permit = false;
  } else if (comment.user === profile.id && profile.type === "IS") {
    permit = true;
  }
  return permit;
};

export const statusColorizor = (status) => {
  const colorMap = {
    APPLICABLE: "info",
    APPLIED: "warning",
    VIEWED: "info",
    DOWNLOADED: "info",
    SHORTLISTED: "success",
    OFFERED: "success",
    ACCEPTED: "success",
    REJECTED_BY_BUSINESS: "danger",
    REJECTED_BY_APPLICANT: "danger",
    CONTRACT_SIGNED: "success",
    APPLICANT_NA: "info",
    WAITLISTED: "warning",
    MOVED_GENERAL_WAITLIST: "warning",
  };

  return colorMap[status] || "primary";
};

function findCurrencyIdByCode(code, currencies) {
  const foundCurrency = currencies.itemsArray.find(
    (currency) => currency.code === code
  );
  // 1 for usd
  return foundCurrency ? foundCurrency.id : 1;
}

export const getCurrencyIdByCountryCode = (countryCode, currencies) => {
  if (
    countryCode &&
    currencies &&
    currencies.itemsArray &&
    currencies.itemsArray.length > 0
  ) {
    let currencyId;
    switch (countryCode) {
      case "kh":
        currencyId = findCurrencyIdByCode("KHR", currencies);
        break;
      case "mm":
        currencyId = findCurrencyIdByCode("MMK", currencies);
        break;
      case "my":
        currencyId = findCurrencyIdByCode("MYR", currencies);
        break;
      case "ne":
        currencyId = findCurrencyIdByCode("NPR", currencies);
        break;
      default:
        currencyId = findCurrencyIdByCode("USD", currencies);
    }
    return currencyId;
  }
};

export const objectToQueryParams = (obj) => {
  const queryParams = Object.keys(obj)
    .map((key) => {
      const value = obj[key];
      if (value === null || value === undefined || value === "") {
        return "";
      }
      if (Array.isArray(value) && value.length > 0) {
        return value
          .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
          .join("&");
      } else {
        // Convert value to string before checking for commas
        const stringValue = String(value);
        // Exclude commas from being encoded
        const encodedValue = stringValue.includes(",")
          ? stringValue
          : encodeURIComponent(value);
        return `${encodeURIComponent(key)}=${encodedValue}`;
      }
    })
    .filter((param) => param !== "")
    .join("&");
  return `?${queryParams}`;
};

export const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < moment().endOf("day");
};

export const getJobStatusClassName = (job) => {
  if (job.is_closed) {
    return "closed-job";
  }

  if (moment().isAfter(job.expired_at) || moment().isAfter(job.closed_at)) {
    return "expired-job";
  }

  // If the job is neither closed nor expired
  return "";
};

// this function purpose is to start the country code with
// plus sign
export const countryCodeModifier = (countryCode) => {
  // Convert countryCode to a string
  const code = String(countryCode);

  // Check if the string is empty
  if (!code || code.trim() === "") {
    return "";
  }

  // Check if the string starts with a +
  if (code.startsWith("+")) {
    return code;
  } else {
    return "+" + code;
  }
};

// for login and register
export const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;

export const convertPhoneNumberLinks = (phoneNumberString) => {
  if (!phoneNumberString) return null; // Use `null` if component logic tolerates it.

  const linkStyle = { display: "block" };
  const iconStyle = { marginRight: "0.5rem" };

  return phoneNumberString.split(",").map((rawPhoneNumber, index) => {
    const phoneNumber = rawPhoneNumber.trim();
    return (
      <a
        key={`${phoneNumber}-${index}`} // Unique key for React lists
        href={`tel:${phoneNumber}`}
        style={linkStyle}
      >
        <i className="fas fa-phone fa-xs" style={iconStyle}></i>
        {phoneNumber}
      </a>
    );
  });
};

export const getErrorMessage = (message, context) => {
  if (message === "network_error") {
    return context.t("Network Error!");
  } else if (message === "user_already_existed") {
    return context.t("User with this username already exists");
  } else if (message === "invalid_user") {
    return context.t("You are trying to login with Business User Account !");
  } else {
    return context.t("There was an authentication problem");
  }
};

export const defaultSelectedGenders = ["M", "F", "X", "N"];

export const getRosterStatusMessage = (status, context) => {
  const statusMessages = {
    APPLIED: context.t("Applied"),
    APPLICABLE: context.t("Applicable"),
    NOT_APPLICABLE: context.t("Not Applicable"),
  };

  return statusMessages[status] || context.t("N/A");
};

export const ROSTER_STATUS_VARIANTS = {
  GREEN: "green",
  PURPLE: "purple",
  RED: "red",
};

export const checkShortListButton = (status) => {
  let arr = [
    "CONTRACT_SIGNED",
    "ACCEPTED",
    "OFFERED",
    "SHORTLISTED",
    "REJECTED_BY_BUSINESS",
    "REJECTED_BY_APPLICANT",
  ];
  if (arr.includes(status)) {
    return false;
  } else {
    return true;
  }
};

const statusMap = {
  ACCEPTED: { color: "green", text: "Offered" },
  APPLICANT_NA: { color: "brown", text: "N/A" },
  WAITLISTED: { text: "Waitlisted" },
  OFFERED: { color: "green", text: "Offered" },
  SHORTLISTED: { color: "green", text: "Shortlisted" },
  CONTRACT_SIGNED: { color: "green", text: "Contract Signed" },
  MOVED_GENERAL_WAITLIST: { color: "brown", text: "In general waitlist" },
};

export const designStatus = (context, status) => {
  const { color, text } = statusMap[status] || statusMap["APPLICANT_NA"];
  return text ? (
    <span style={color ? { color } : undefined}>{context.t(text)}</span>
  ) : (
    status
  );
};
